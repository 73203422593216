import template from './home.html';
import controller from './home.controller';
import './home.scss';

const homeComponent = {
  bindings: {
    pageTitle: '@',
    pageDescription: '@'
  },
  transclude: {
    homeHeader: '?homeHeader'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope'];

export default homeComponent;
