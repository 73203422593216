export default function ($scope, $rootScope, $state, $window, $timeout, $uibModal, utils, settingsService, userService, ownerService, permissions) {
  let vm;

  class ProfileCtrl {
    constructor () {
      vm = this;
      vm.name = 'profile';
      vm.files = [];
    }

    $onInit () {
      vm.settings = {};
      vm.getSettings();
      $scope.$on('ownerSaved', (owner) => {
        vm.getSettings();
      });

      $scope.$watch('vm.settings', (newVal, oldVal) => {
        if (_.get(vm.forms, 'settingsForm')) {
          vm.forms.settingsForm.$setDirty();
        }
      }, true);
    }

    getSettings () {
      return settingsService.getSettings()
        .then((result) => {
          vm.settings = result.settings;
          vm.files = result.files;
          $rootScope.owner.settings = result.settings;
          $rootScope.owner.crmSettings = result.crmSettings;
          $rootScope.owner.files = vm.files;
          vm.originalSetting = angular.copy(result.settings);
          vm.originalFiles = angular.copy(result.files);
          $rootScope.$broadcast('settingsLoaded', vm.settings);

          if (!vm.forms) {
            return vm.settings;
          }

          Object.keys(vm.forms).forEach((formName) => {
            if (vm.forms[formName]) {
              $timeout(() => vm.forms[formName].$setPristine(), 100);
            }
          });

          return vm.settings;
        });
    }

    editProfile () {
      return $uibModal.open({
        component: 'profileEdit',
        windowClass: 'profile-edit-modal mobile-modal',
        size: 'md',
        backdrop: 'static'
      });
    }

    changePasswordForm () {
      $uibModal.open({
        component: 'profileEdit',
        resolve: {
          passwordEditMode: () => true
        },
        windowClass: 'profile-edit-modal mobile-modal',
        size: 'sm',
        backdrop: 'static'
      });
    }

    editProfileImage () {
      return $uibModal.open({
        component: 'profileEdit',
        resolve: {
          profileImageEditMode: () => true
        },
        windowClass: 'profile-edit-modal mobile-modal',
        size: 'md',
        backdrop: 'static'
      });
    }

    getUserProfileImage () {
      return userService.getUserProfileImage($rootScope.user);
    }

    isA2HSEnabled () {
      return $window.callInstallPrompt !== undefined;
    }

    addToHomeScreen () {
      $window.callInstallPrompt();
    }
  }

  return new ProfileCtrl();
}
