import template from './project-sidebar.html';
import controller from './project-sidebar.controller';
import './project-sidebar.scss';

const projectSidebarComponent = {
  bindings: {
    projectFields: '<'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', '$element', 'projectService', 'settingsService', 'userService', 'counts', 'utils'];

export default projectSidebarComponent;
