import searchComponent from './search.component';
import advancedSearch from './advanced-search/advanced-search';
import advancedSearchResults from './advanced-search-results/advanced-search-results';

const searchModule = angular.module('search', [
  advancedSearch,
  advancedSearchResults
])
  .component('search', searchComponent)
  .name;

export default searchModule;
