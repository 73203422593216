import template from './project-details-show.html';
import controller from './project-details-show.controller';
import './project-details-show.scss';

const projectDetailsShowComponent = {
  require: {
    projectShowCtrl: '^projectShow'
  },
  bindings: {
    fieldsSchema: '<'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$filter', '$uibModal', '$timeout', 'projectService'];

export default projectDetailsShowComponent;
