import template from './customize-table-columns.html';
import controller from './customize-table-columns.controller';
import './customize-table-columns.scss';

const customizeTableColumnsComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', 'settingsService', 'utils'];

export default customizeTableColumnsComponent;
