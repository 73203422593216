export default function ($scope, $rootScope, $state, $uibModal, notify, userService) {
  let vm;

  class UserShowCtrl {
    constructor () {
      vm = this;
      vm.progress = true;
      vm.$state = $state;
    }

    $onInit () {
      vm.userObject = {};

      if ($state.current.name === 'admin.userShow.userLog') {
        vm.activeTab = 2;
      } else {
        vm.activeTab = 0;
      }

      const userId = $state.params.id;
      if (!userId) {
        return;
      }
      if (userId) {
        vm.getUser(userId)
          .then(() => {
            vm.progress = false;
          });
      } else {
        vm.progress = false;
      }
    }

    getUser (userId) {
      vm.getUserProgress = true;
      return userService.getUser(userId)
        .then((user) => {
          if (!user) {
            $state.go('admin.userList');
            return;
          }
          Object.keys(user).forEach((field) => {
            vm.userObject[field] = user[field];
          });
          if (vm.userObject.phone) {
            vm.userObject.phone = vm.localizePhone(vm.userObject.phone);
          }

          return vm.userObject;
        })
        .finally(() => {
          vm.getUserProgress = false;
        });
    }

    getUserProfileImage (user) {
      return userService.getUserProfileImage(user);
    }

    permissionLabel (permission) {
      switch (permission) {
        case 'admin':
          return 'משתמש על';
        case 'manager':
          return 'מנהל';
        case 'user':
          return 'נציג';
        default:
          return permission;
      }
    }

    localizePhone (phone) {
      if (phone) {
        return phone.replace(/^\+972/, '0');
      }
    }
  }

  return new UserShowCtrl();
}
