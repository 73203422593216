export default function ($scope, $rootScope, $state, utils, bidService) {
  let vm;

  class BidEditController {
    constructor () {
      vm = this;
      vm.loadProgress = true;
      vm.summernoteOptions = {
        toolbar: [
          ['misc', ['undo', 'redo']],
          ['style', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript']],
          ['clear', ['clear']],
          ['fontsize', ['fontsize', 'height']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['color', ['color']],
          ['dir', ['rtl', 'ltr']],
          ['table', ['table']],
          ['fontname', ['fontname']]
        ],
        lang: 'he-IL',
        placeholder: 'כתוב הצעת מחיר...',
        fontNames: ['David', 'Arial', 'Times New Roman', 'Courier New']
      };

      vm.summernoteSignatureOptions = {
        toolbar: [
          ['clear', ['clear']],
          ['fontsize', ['fontsize']],
          ['fontname', ['fontname']]
        ],
        lang: 'he-IL',
        height: 100,
        placeholder: 'כתוב חתימה...',
        fontNames: ['David', 'Arial', 'Times New Roman', 'Courier New']
      };
    }

    $onInit () {
      vm.bidFields = {};

      const bidId = $state.params.id;
      const projectId = $state.params.projectId;
      const customerId = $state.params.customerId;

      if (bidId) {
        vm.getBid(bidId)
          .finally(() => {
            vm.loadProgress = false;
          });
      } else {
        vm.loadProgress = false;
        vm.bidFields.projectId = projectId;
        vm.bidFields.customerId = customerId;
      }
    }

    getBid (bidId) {
      return bidService.getProject(bidId)
        .then((bid) => {
          Object.keys(bid).forEach((field) => {
            vm.bidFields[field] = bid[field];
          });

          return vm.bidFields;
        });
    }

    saveProject () {
      if (vm.form.$invalid) {

      } else {
        if (vm.saveProgress) {
          return;
        }
        vm.saveProgress = true;
        bidService.saveBid(vm.bidFields.id, vm.bidFields)
          .then(() => {
            utils.notify('פרטי הצעת מחיר נשמרו', 'alert-success');

            vm.modalInstance.close();
            $rootScope.$broadcast('bidSaved');
          })
          .finally(() => {
            vm.saveProgress = false;
          });
      }
    }

    closeModalBox () {
      vm.modalInstance.dismiss('cancel');
    }
  }

  return new BidEditController();
}
