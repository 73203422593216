import template from './project-board.html';
import controller from './project-board.controller';
import './project-board.scss';

const projectBoardComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$uibModal', '$timeout', 'dragularService', 'projectService', 'settingsService', 'userService', 'utils', 'permissions'];

export default projectBoardComponent;
