import template from './project-nadlan.html';
import controller from './project-nadlan.controller';

const projectNadlanComponent = {
  require: {
    projectShowCtrl: '^projectShow'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', '$state', 'projectService', 'govmapService'];

export default projectNadlanComponent;
