export default function ($scope, $rootScope, $timeout, userService) {
  let vm;

  class AgentController {
    constructor () {
      vm = this;
      vm.name = 'agentSelector';
    }

    $onInit () {
      vm.selectAgent(vm.agent);

      // setup listener after execute selection initially.
      vm.$onChanges = function () {
        if (vm.agent !== vm.previousSelectedAgentId) {
          vm.selectAgent(vm.agent);
        }
      };
    }

    getUserProfileImage (agent) {
      return userService.getUserProfileImage(agent);
    }

    selectAgent (agentId, runCallback) {
      vm.selectedAgentId = agentId;
      vm.previousSelectedAgentId = vm.selectedAgentId; // because $onChanges previousValue is so buggy.
      vm.selectedAgent = $rootScope.userById(agentId) || {};

      if (vm.selectedAgentId === '$null') {
        vm.selectedAgentString = vm.nullSelectedLabel || 'ללא נציג אחראי';
      } else if (!vm.selectedAgentId) {
        if (vm.allOption === false && vm.emptyOption === true) {
          vm.selectedAgentString = '';
        } else {
          vm.selectedAgentString = 'הכל';
        }
      } else if (vm.selectedAgentId) {
        vm.selectedAgentString = `${vm.selectedAgent.firstName || ''} ${vm.selectedAgent.lastName || ''} ${vm.selectedAgent.id && !vm.selectedAgent.active ? '(לא פעיל)' : ''}`;
      }

      if (runCallback) {
        const selectedAgentForCallback = vm.selectedAgentId;
        vm.onAgentSelected({ selectedAgent: selectedAgentForCallback });
      }
    }
  }

  return new AgentController();
}
