import template from './form-field.html';
import controller from './form-field.controller';
import './form-field.scss';

const formFieldComponent = {
  bindings: {
    field: '<',
    onChange: '<?',
    onInit: '<?',
    onDestroy: '<?',
    model: '='
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout'];

export default formFieldComponent;
