// let service;
// let $rootScope;
let request;

class paymentsService {
  constructor (_$rootScope, _request) {
    // service = this;
    // $rootScope = _$rootScope;
    request = _request;
  }

  getOwnerCards (cardId) {
    return request('/payment/get-credit-cards')
      .then((res) => res.data.data);
  }

  getOwnerAddCardUrl (directDebit) {
    return request('/payment/add-credit-card', { directDebit }, 'POST')
      .then((res) => res.data.data);
  }

  getOwnerChargeUrl (modules, invoiceName, directDebit, saveCard) {
    return request('/payment/charge', { modules, invoiceName, directDebit, saveCard }, 'POST')
      .then((res) => res.data.data);
  }

  deleteCard (cardId) {
    return request('/payment/delete-credit-card', { cardId }, 'DELETE')
      .then((res) => res.data.data);
  }

  setCardDirectDebit (cardId) {
    return request('/payment/set-card-direct-debit', { cardId }, 'POST')
      .then((res) => res.data.data);
  }

  unsetCardDirectDebit (cardId) {
    return request('/payment/unset-card-direct-debit', { cardId }, 'POST')
      .then((res) => res.data.data);
  }

  getTransactionList (args = {}) {
    return request('/payment/get-transactions?' + jQuery.param(args))
      .then((res) => res.data.data);
  }
}

paymentsService.$inject = ['$rootScope', 'request'];

const paymentsServiceModule = angular.module('paymentsService', [])
  .service('paymentsService', paymentsService)
  .name;

export default paymentsServiceModule;
