import projectShowComponent from './project-show.component';

import projectDetails from './project-details/project-details';
import projectDocuments from './project-documents/project-documents';
import projectEvents from './project-events/project-events';
import projectGdriveFileList from './project-gdrive-file-list/project-gdrive-file-list';
import projectMap from './project-map/project-map';
import projectNotes from './project-notes/project-notes';
import projectNadlan from './project-nadlan/project-nadlan';
import projectPayments from './project-payments/project-payments';
import projectSidebar from './project-sidebar/project-sidebar';
import projectTasks from './project-tasks/project-tasks';
import projectImages from './project-images/project-images';

const projectShowModule = angular.module('projectShow', [
  projectDetails,
  projectEvents,
  projectDocuments,
  projectGdriveFileList,
  projectMap,
  projectNadlan,
  projectNotes,
  projectPayments,
  projectSidebar,
  projectTasks,
  projectImages
])
  .component('projectShow', projectShowComponent)
  .name;

export default projectShowModule;
