import template from './owner-transactions.html';
import controller from './owner-transactions.controller';
import './owner-transactions.scss';

const ownerTransactionsComponent = {
  require: {
    ownerShowCtrl: '^ownerShow'
  },
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$filter', 'ownerService', 'utils'];

export default ownerTransactionsComponent;
