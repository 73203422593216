function validationCtrl ($scope) {
  $scope.signupForm = function () {
    if ($scope.signup_form.$valid) {
      // Submit as normal
    } else {
      $scope.signup_form.submitted = true;
    }
  };
}

const validationCtrlModule = angular.module('validationCtrl', [])
  .controller('validationCtrl', ['$scope', validationCtrl])
  .name;

export default validationCtrlModule;
