export default function ($scope, $rootScope, $timeout, $sce) {
  let vm;

  class TagListSelectorController {
    constructor () {
      vm = this;
      vm.name = 'tagListSelector';
    }

    $onInit () {
      vm.selectedTags = vm.selectedTags || [];
      if (vm.clearAll === 'true') {
        vm.clearAll = 'ללא תווית';
      }
      if (vm.selectAll === 'true') {
        vm.selectAll = 'בחירת הכל';
      }
    }

    toggleTag (tag) {
      if (vm.selectedTags.includes(tag)) {
        vm.selectedTags.splice(vm.selectedTags.indexOf(tag), 1);
        if (vm.onTagRemoved) {
          vm.onTagRemoved({ tag });
        }
      } else {
        vm.selectedTags.push(tag);
        if (vm.onTagSelected) {
          vm.onTagSelected({ tag });
        }
      }
      if (vm.onTagChanged) {
        vm.onTagChanged({ selectedTags: vm.selectedTags });
      }
    }

    clearAllSelectedTags () {
      if (vm.onTagRemoved) {
        vm.selectedTags.forEach((tag) => {
          vm.onTagRemoved({ tag });
        });
      }
      vm.selectedTags = [];
      if (vm.onTagChanged) {
        vm.onTagChanged({ selectedTags: [] });
      }

      vm.isOpen = false;
    }

    selectAllTags () {
      vm.selectedTags = (vm.tagList || []).map((tag) => tag.title);
      if (vm.onTagChanged) {
        vm.onTagChanged({ selectedTags: vm.selectedTags });
      }

      vm.isOpen = false;
    }
  }

  return new TagListSelectorController();
}
