import template from './navigation.html';
import controller from './navigation.controller';
import './navigation.scss';

const navigationComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', '$state', '$uibModal', 'userService', 'permissions'];

export default navigationComponent;
