// let service;
let request;

class taskService {
  constructor (_request) {
    // service = this;
    request = _request;
  }

  getTaskList (args = {}) {
    return request('/task/?includeProject=true&includeCustomer=true&' + jQuery.param(args))
      .then((res) => res.data.data);
  }

  getTaskListCounts (assignUser, projectId) {
    return request('/task/counts?' + jQuery.param({ assignUser, projectId }))
      .then((res) => res.data.data);
  }

  saveTask (projectId, taskId, taskObject) {
    return request(`/project/${projectId}/task/${taskId || ''}`, { task: taskObject }, (taskId ? 'PUT' : 'POST'))
      .then((res) => res.data.data);
  }

  deleteTask (projectId, taskId) {
    return request(`/project/${projectId}/task/${taskId}`, null, 'DELETE')
      .then((res) => res.data.data);
  }
}

taskService.$inject = ['request'];

const taskServiceModule = angular.module('taskService', [])
  .service('taskService', taskService)
  .name;

export default taskServiceModule;
