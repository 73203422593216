export default function ($scope, $state, bidService) {
  let vm;

  class BidShowController {
    constructor () {
      vm = this;
      vm.name = 'bidShow';
    }

    $onInit () {
      vm.loadBid($state.params.id);
    }

    loadBid (id) {
      return bidService.getBid(id)
        .then((bid) => {
          vm.modelObject = bid || {};
        });
    }
  }

  return new BidShowController();
}
