let service;
let $rootScope;
let $filter;
let $uibModal;
let userService;
let taskService;
let utils;

// const uiSref = 'project.projectShow.projectTasks({id: row.projectId})';

class taskListTableColumnsService {
  constructor (_$rootScope, _$filter, _$uibModal, _userService, _taskService, _utils) {
    service = this;
    $rootScope = _$rootScope;
    $filter = _$filter;
    $uibModal = _$uibModal;
    userService = _userService;
    taskService = _taskService;
    utils = _utils;

    service.agentList = _.get($rootScope, 'owner.users') || [];
    service.statusList = {
      toDo: 'לביצוע',
      inProgress: 'בביצוע',
      done: 'בוצע'
    };

    service.taskListTableHeadList = [
      { name: 'pinned', type: 'compile', customFunction: service.getPinnedString },
      { name: 'status', label: 'סטטוס', type: 'customFunction', customFunction: service.getStatusString, clickFunction: service.editTaskForm },
      { name: 'dueDate', label: 'תאריך יעד', type: 'date', sortable: true, clickFunction: service.editTaskForm },
      { name: 'fullTaskColumn', label: 'משימה', type: 'customFunction', customFunction: service.getFullTaskColumn, clickFunction: service.editTaskForm },
      { name: 'agent', label: 'משויך לנציג', if: service.showAgentColumn, type: 'compile', customFunction: service.getAgentString, clickFunction: service.editTaskForm },
      { name: 'info', type: 'compile', customFunction: service.tooltipString, clickFunction: service.editTaskForm },
      { name: 'changeStatus', type: 'compile', customFunction: service.statusSelectString, changeStatus: service.changeStatus }
    ];

    service.pinnedTableHeadList = angular.copy(service.taskListTableHeadList);
  }

  showAgentColumn () {
    return service.agentList.length > 1;
  }

  getFullTaskColumn (row) {
    if (row.fullTaskContent) {
      return row.fullTaskContent;
    }
    row.fullTaskContent = `<div class="task-content text-bold">${utils.stripHtml(row.content, true)}</div>
    <div class="small">
      ${service.getProjectString(row)}
      <span>${service.getProjectCustomerString(row)}</span>
    <div>`;
    return row.fullTaskContent;
  }

  getProjectCustomerString (row) {
    return (_.get(row.project, 'customer.name') || '');
  }

  getProjectString (row) {
    if (!row.project) { return ''; }
    if (row.project.projectString) {
      return row.project.projectString;
    }
    row.project.projectString = `פרויקט ${row.project.number} `;
    if ($rootScope.hasAddress) {
      row.project.projectString += `${row.project.street || row.project.city ? ' - ' : ''}${row.project.street || ''} ${row.project.house || ''} ${row.project.city || ''}`;
    }
    return row.project.projectString;
  }

  tooltipString (row) {
    return `<i class='fa fa-info-circle' uib-tooltip='נוצרה ${`${$filter('date')(row.createdAt, 'dd/MM/yyyy HH:mm')}
  ${row.userId && service.agentList.length > 1 ? 'ע"י ' + service.agentName($rootScope.userById(row.userId)) : ''}`}' tooltip-append-to-body='true'></i>`;
  }

  statusSelectString (row) {
    return `<div class="btn-group" uib-dropdown dropdown-append-to-body>
    <button type="button" class="btn btn-sm btn-default" uib-dropdown-toggle>
      <span ng-if="row.saveStatusProgress"><i class="fa fa-spin ion-load-c"></i></span>
      <span ng-if="!row.saveStatusProgress">
        <span class="ng-binding">שינוי סטטוס</span>
        <span class="caret"></span>
      </span>
    </button>
    <ul class="dropdown-menu-left" uib-dropdown-menu>
      ${Object.keys(service.statusList).map((status) => `<li class="${row.status === status ? 'selected' : ''}">
        <a ng-click="td.changeStatus(row, '${status}')">${service.statusList[status]}</a>
      </li>`).join(' ')}
    </ul>
  </div>`;
  }

  getAgentString (row) {
    if (row.agentString) {
      return row.agentString;
    }
    const agent = $rootScope.userById(row.assignUser);
    if (!agent) {
      row.agentString = '<small>ללא שיוך לנציג</small>';
      return row.agentString;
    }

    row.agentString = `<span class="avatar" uib-tooltip="${service.agentName(agent)}" tooltip-append-to-body="true">
      <img class="img-circle" src="${userService.getUserProfileImage(agent)}" />
    </span>`;
    return row.agentString;
  }

  agentName (agent) {
    if (!agent) { return; }
    return `${agent.firstName || ''} ${agent.lastName || ''}`.trim();
  }

  getPinnedString (row) {
    return '<task-pin task="row" project-id="row.projectId" confirm="row.assignUser !== $root.user.id"></task-pin>';
  }

  getStatusString (row) {
    return `<span class="badge badge-${row.status} m-l-sm">${service.statusList[row.status]}</span>`;
  }

  changeStatus (task, status) {
    if (task.saveStatusProgress) { return; }
    task.saveStatusProgress = true;
    const taskObject = { status };
    taskService.saveTask(task.projectId, task.id, taskObject)
      .then(() => {
        task.status = status;
        $rootScope.$broadcast('taskSaved');
      })
      .finally(() => {
        task.saveStatusProgress = false;
      });
  }

  editTaskForm ($event, row) {
    $uibModal.open({
      component: 'taskEdit',
      resolve: {
        task: () => row
      },
      windowClass: 'task-edit-modal mobile-modal',
      size: 'lg',
      backdrop: 'static'
    });
  }
}

taskListTableColumnsService.$inject = ['$rootScope', '$filter', '$uibModal', 'userService', 'taskService', 'utils'];

const taskListTableColumnsServiceModule = angular.module('taskListTableColumns', [])
  .service('taskListTableColumns', taskListTableColumnsService)
  .name;

export default taskListTableColumnsServiceModule;
