/* global localStorage */
export default function ($scope, $rootScope, $timeout, request, settingsService) {
  let vm;

  class SearchController {
    constructor () {
      vm = this;
      vm.name = 'search';
      vm.orderBy = '-createdAt';
    }

    $onInit () {
      const enabledColumns = settingsService.getOwnerTableColumns('projectList');
      vm.referrerColumnEnabled = enabledColumns.includes('referrer');
      vm.type = _.get(vm, 'resolve.type') || 'simple';
      vm.localStorageLastSearchType = localStorage.getItem('lastSearchType');
      vm.localStorageLastSearchQuery = JSON.parse(localStorage.getItem('lastSearchQuery') || '{}');
      if (_.isEmpty(vm.localStorageLastSearchQuery)) {
        vm.localStorageLastSearchQuery = undefined;
      }
    }

    toggleType () {
      if (vm.type === 'simple') {
        vm.type = 'advanced';
      } else {
        vm.type = 'simple';
        $timeout(() => {
          document.getElementById('search-input').focus();
        });
      }
    }

    lastAdvancedSearch () {
      if (!vm.localStorageLastSearchQuery) {
        return;
      }
      vm.resolve.advancedSearchTarget = vm.localStorageLastSearchType;
      vm.resolve.advancedSearchQuery = vm.localStorageLastSearchQuery;
      vm.type = 'advanced';
    }

    onSearchChanged () {
      if (!$rootScope.searchValue.length) {
        $rootScope.results = {
          projects: [],
          customers: [],
          projectsLength: 0,
          customersLength: 0
        };
        return;
      }
      vm.searchProgress = true;
      request('/search?q=' + $rootScope.searchValue)
        .then((results) => {
          const resultsObject = {
            projects: results.data.data.projects || [],
            customers: results.data.data.customers || [],
            projectsLength: results.data.data.projects.length,
            customersLength: results.data.data.customers.length
          };

          $rootScope.results = resultsObject;
        })
        .finally(() => {
          vm.searchProgress = false;
        });
    }

    getGushString (row) {
      return [row.gush, row.helka, row.subHelka].filter((v) => v).join('-');
    }

    closeModalBox () {
      vm.modalInstance.close();
    }

    clearSearch () {
      vm.modalInstance.close();
      $rootScope.searchValue = '';
      $rootScope.results = {};
    }
  }

  return new SearchController();
}
