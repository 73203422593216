const templateUrl = 'views/common/notify.html';

function utilsService ($rootScope, $uibModal, notify) {
  return {
    notify (message, classes = 'alert-success') {
      notify({ position: 'left', message, classes, templateUrl });
    },

    sleep (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    setFormFieldsDirty (forms) {
      if (!angular.isArray(forms)) {
        forms = [forms];
      }
      forms.forEach((form) => {
        if (form.$setDirty) {
          form.$setDirty();
        }

        angular.forEach(form, (value, key) => {
          if (typeof value === 'object' && value.hasOwnProperty('$modelValue')) {
            value.$setDirty();
          }
        });
      });
    },

    setFormFieldsPristine (forms) {
      if (!angular.isArray(forms)) {
        forms = [forms];
      }
      forms.forEach((form) => {
        if (form.$setPristine) {
          form.$setPristine();
        }

        angular.forEach(form, (value, key) => {
          if (typeof value === 'object' && value.hasOwnProperty('$modelValue')) {
            value.$setPristine();
          }
        });
      });
    },

    modalAlert (params) {
      return new Promise((resolve, reject) => {
        function ModalInstanceCtrl ($scope, $uibModalInstance) {
          $scope.$uibModalInstance = $uibModalInstance;
          $scope.params = params;
          $scope.click = function (btn) {
            if (btn.onClick) {
              return resolve(btn.onClick($uibModalInstance));
            }

            $uibModalInstance.dismiss('cancel');
            return reject(new Error('cancled'));
          };
        }

        const dialogTemplate = `<div class="inmodal">
          <div class="color-line"></div>
          <div class="modal-header">
            <h4 class="modal-title m-b-xs">{{params.title}}</h4>
            <small ng-if="params.message" class="m-r-sm">{{params.message}}</small>
            ${params.html ? '<div class="m-t">' + params.html + '</div>' : ''}
          </div>
          <div class="modal-footer">
            <button ng-repeat="btn in params.buttons" type="button" class="btn {{btn.btnClass || 'btn-default'}}" ng-click="click(btn)">{{btn.label}}</button>
          </div>
        </div>`;

        $uibModal.open({
          template: dialogTemplate,
          controller: ['$scope', '$uibModalInstance', ModalInstanceCtrl],
          windowClass: params.windowClass,
          backdrop: params.backdrop || true
        });
      });
    },

    unparam (param) {
      if (param[0] === '?') param = param.slice(1);
      const re = {};
      for (var i = 0, arr = param.split('&'), kv; kv = arr[i]; i++) {
        kv = kv.split('=');
        re[kv[0]] = kv[1];
      }
      return re;
    },

    stripHtml (html, insertSpaces) {
      if (insertSpaces) {
        return html.replace(/([\s\n]*<[^>]*>[\s\n]*)+/g, ' ');
      }
      return jQuery('<div>' + html + '</div>').text().trim();
    },

    joinStringArray (array, joiner) {
      return array.filter((str) => !!str).join(joiner);
    },

    isValidId (str) { // http://opencodeoasis.blogspot.com/2008/08/blog-post_10.html
      const R_ELEGAL_INPUT = 0;
      const R_NOT_VALID = 0;
      const R_VALID = 1;
      // INPUT VALIDATION

      // Just in case -> convert to string
      let IDnum = String(str);

      // Validate correct input
      if ((IDnum.length > 9) || (IDnum.length < 5)) { return R_ELEGAL_INPUT; }
      if (isNaN(IDnum)) { return R_ELEGAL_INPUT; }

      // The number is too short - add leading 0000
      if (IDnum.length < 9) {
        while (IDnum.length < 9) {
          IDnum = '0' + IDnum;
        }
      }

      // CHECK THE ID NUMBER
      let mone = 0;

      let incNum;
      for (let i = 0; i < 9; i++) {
        incNum = Number(IDnum.charAt(i));
        incNum *= (i % 2) + 1;
        if (incNum > 9) { incNum -= 9; }
        mone += incNum;
      }
      if (mone % 10 === 0) { return R_VALID; } else { return R_NOT_VALID; }
    }
  };
}

utilsService.$inject = ['$rootScope', '$uibModal', 'notify'];

const utilsServiceModule = angular.module('utilsService', [])
  .factory('utils', utilsService)
  .name;

export default utilsServiceModule;
