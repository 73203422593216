export default function ($scope, $state, $filter, adminService, ticketService) {
  let vm;
  const uiSref = 'ticket.ticketShow({id: row.id})';

  class AdminTicketListCtrl {
    constructor () {
      vm = this;
      vm.name = 'adminTicketList';
      vm.labels = ticketService.labels;
      vm.labelsFilter = [];

      vm.openTicketHeadList = [
        { name: 'createdAt', type: 'customFunction', customFunction: vm.getDateField, label: 'תאריך', sortable: true, uiSref },
        { name: 'number', type: 'customFunction', customFunction: vm.getNumberField, label: 'מספר', sortable: true, uiSref },
        { name: 'user', type: 'customFunction', customFunction: vm.getUserField, label: 'משתמש', sortable: true, uiSref },
        { name: 'owner', type: 'customFunction', customFunction: vm.getOwnerField, label: 'ארגון', sortable: true, uiSref },
        { name: 'title', label: 'נושא', sortable: true, uiSref },
        { name: 'labels', type: 'customFunction', customFunction: vm.getLabelsField, label: 'תוויות', sortable: true, uiSref }
      ];
      vm.allTicketHeadList = angular.copy(vm.openTicketHeadList);
      vm.allTicketHeadList.push({ name: 'status', type: 'customFunction', customFunction: vm.getStatusField, label: '', uiSref });
    }

    $onInit () {
      $scope.$on('ticketSaved', () => {
        vm.getTicketList();
      });
    }

    getAllTicketList (args) {
      return adminService.getAllTickets(args);
    }

    getOpenTicketList (args) {
      return adminService.getAllTickets(args, true);
    }

    getDateField (row) {
      return $filter('date')(row.createdAt, 'dd/MM/yyyy HH:mm');
    }

    getNumberField (row) {
      if (row.number) {
        return '#' + row.number;
      }
    }

    getUserField (row) {
      return `${_.get(row, 'user.firstName') || ''} ${_.get(row, 'user.lastName') || ''}`;
    }

    getOwnerField (row) {
      return _.get(row, 'owner.name') || '';
    }

    getLabelsField (row) {
      return (row.labels || []).map((label) => {
        return `<span class="badge" style="${vm.labelStyle(label)}">${label}</span>`;
      }).join(' ');
    }

    getStatusField (row) {
      if (!row.open) {
        return '<div class="badge badge-danger m-r-sm">סגור</div>';
      } else if (row.isWaiting) {
        return '<div class="badge badge-success m-r-sm">ממתין למענה</div>';
      } else {
        return '<div class="badge badge-default m-r-sm">פתוח</div>';
      }
    }

    onTagChanged (selectedTags) {
      vm.labelsFilter = selectedTags;
    }

    labelStyle (title) {
      const label = vm.labels.find((label) => label.title === title);
      return `color: ${label.color}; background: ${label.background};`;
    }
  }

  return new AdminTicketListCtrl();
}
