let request;

class eventService {
  constructor (_request) {
    request = _request;
  }

  getEventList (args = {}) {
    return request('/event/?' + jQuery.param(args))
      .then((res) => res.data.data);
  }

  getEventListCounts (assignUser, projectId) {
    return request('/event/counts?' + jQuery.param({ assignUser, projectId }))
      .then((res) => res.data.data);
  }

  saveEvent (projectId, eventId, eventObject) {
    return request(`/project/${projectId}/event/${eventId || ''}`, { event: eventObject }, (eventId ? 'PUT' : 'POST'))
      .then((res) => res.data.data);
  }

  deleteEvent (projectId, eventId) {
    return request(`/project/${projectId}/event/${eventId}`, null, 'DELETE')
      .then((res) => res.data.data);
  }
}

eventService.$inject = ['request'];

const eventServiceModule = angular.module('eventService', [])
  .service('eventService', eventService)
  .name;

export default eventServiceModule;
