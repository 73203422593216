import taskBoard from './task-board/task-board';
import taskBoardPage from './task-board-page/task-board-page';
import taskEdit from './task-edit/task-edit';
import taskList from './task-list/task-list';
import taskPin from './task-pin/task-pin';
import callService from './call.service';
import noteService from './note.service';
import taskService from './task.service';

const taskModule = angular.module('task', [
  taskBoard,
  taskBoardPage,
  taskEdit,
  taskList,
  taskPin,
  callService,
  noteService,
  taskService
])
  .name;

export default taskModule;
