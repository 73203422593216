import { Ability, ForbiddenError } from '@casl/ability';
import roles from '../../../roles.json';

function permissionsService ($rootScope) {
  let cachedAbilities;
  $rootScope.$on('userLoggedOut', () => {
    cachedAbilities = null;
  });

  return {
    require (action, subject) {
      ForbiddenError.from(this.abilities).throwUnlessCan(action, subject);
    },
    can (action, subject, fields /** TODO */) {
      return this.abilities.can(action, subject);
    },
    get abilities () {
      if (!cachedAbilities) {
        const userRoles = $rootScope.user.roles || [];
        if ($rootScope.isManager()) {
          userRoles.push('manager');
        }
        cachedAbilities = this.buildAbilities(userRoles);
      }
      return cachedAbilities;
    },
    set abilities (value) {
      cachedAbilities = value;
    },
    buildAbilities (userRoles) {
      const userRules = [];
      const addedRoles = [];

      function addRoleRules (roleRules) {
        if (roleRules.include) {
          for (const role of roleRules.include) {
            addRoleRules(roles[role]);
          }
        }
        if (roleRules.rules) {
          for (const rule of roleRules.rules) {
            userRules.push(rule);
          }
        }
      }

      for (const role of userRoles) {
        if (!addedRoles.includes(role)) { // did a previous role already include it?
          addRoleRules(roles[role] || {});
          addedRoles.push(role);
        }
      }

      return new Ability(userRules);
    }
  };
}

permissionsService.$inject = ['$rootScope'];

const permissionsServiceModule = angular.module('permissions', [])
  .factory('permissions', permissionsService)
  .name;

export default permissionsServiceModule;
