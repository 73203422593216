import template from './document-settings.html';
import controller from './document-settings.controller';
import './document-settings.scss';

const documentSettingsComponent = {
  require: {
    settingsCtrl: '^settings'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', 'fileRequest', '$uibModal', 'utils'];

export default documentSettingsComponent;
