import optionsList from '../project/project-options-list';
import tableColumns from './table-columns';

let $rootScope;
let request;
let fileRequest;
let permissions;

class settingsService {
  constructor (_$rootScope, _request, _fileRequest, _permissions) {
    $rootScope = _$rootScope;
    request = _request;
    fileRequest = _fileRequest;
    permissions = _permissions;
  }

  getSettings (settingsId) {
    const ownerId = _.get($rootScope, 'owner.id');
    if (!ownerId) {
      return Promise.reject(new Error('Owner id is missing'));
    }
    return request('/owner/' + ownerId + '/settings')
      .then((res) => res.data.data);
  }

  saveSettings (settingsObject) {
    permissions.require('edit', 'ownerSettings');
    const ownerId = _.get($rootScope, 'owner.id');
    if (!ownerId) {
      return Promise.reject(new Error('Owner id is missing'));
    }

    const formDataObject = {};
    Object.keys(settingsObject).forEach((field) => {
      if (settingsObject[field] instanceof FileList) {
        formDataObject[field] = settingsObject[field][0];
      }
    });

    formDataObject.settings = JSON.stringify(settingsObject);

    return fileRequest('/owner/' + ownerId + '/settings', formDataObject)
      .then((res) => res.data.data);
  }

  sendGreeninvoiceCode (greeninvoiceApiToken, greeninvoiceApiSecret) {
    permissions.require('edit', 'ownerSettings');
    const ownerId = _.get($rootScope, 'owner.id');
    if (!ownerId) {
      return Promise.reject(new Error('Owner id is missing'));
    }

    return request('/owner/' + ownerId + '/greeninvoice-code', { greeninvoiceApiToken, greeninvoiceApiSecret }, 'POST')
      .then((res) => res.data.data);
  }

  removeGreeninvoice () {
    permissions.require('edit', 'ownerSettings');
    const ownerId = _.get($rootScope, 'owner.id');
    if (!ownerId) {
      return Promise.reject(new Error('Owner id is missing'));
    }

    return request('/owner/' + ownerId + '/remove-greeninvoice-code', null, 'DELETE')
      .then((res) => res.data.data);
  }

  requestGdriveOwnerCode () {
    return request('/gdrive/request-gdrive-owner-code', {}, 'POST')
      .then((res) => res.data.data);
  }

  sendGdriveOwnerCode (code) {
    permissions.require('edit', 'ownerSettings');
    return request('/gdrive/send-gdrive-owner-code', { code }, 'POST');
  }

  requestGcalendarUserCode () {
    return request('/gcalendar/request-gcalendar-user-code', {}, 'POST')
      .then((res) => res.data.data);
  }

  sendGcalendarUserCode (code) {
    return request('/gcalendar/send-gcalendar-user-code', { code }, 'POST');
  }

  getOwnerProjectOptionsList (listName) {
    const fullList = optionsList[listName]
      .map((option) => option.name);
    const inbuiltSettings = optionsList[listName]
      .filter((option) => option.default)
      .map((option) => option.name);
    const customSettings = _.get($rootScope, 'owner.settings.valuesOptionsCustom.' + listName);
    return fullList.filter((name) => customSettings ? customSettings.includes(name) : inbuiltSettings.includes(name));
  }

  getOwnerProjectStatusList () {
    const alwaysInclude = ['בהכנה', 'הושלם', 'מבוטל'];
    const fullList = optionsList.statusOptions.map((option) => option.name);
    const inbuiltSettings = optionsList.statusOptions.filter((option) => option.default)
      .map((option) => option.name);
    const customSettings = _.get($rootScope, 'owner.settings.statusOptions');
    return fullList.filter((name) => {
      return (customSettings ? customSettings.includes(name) : inbuiltSettings.includes(name)) ||
        alwaysInclude.includes(name);
    });
  }

  getOwnerTableColumns (tableName) {
    const fullList = tableColumns[tableName].map((column) => column.name);
    const builtInSettings = tableColumns[tableName].filter((column) => column.showByDefault).map((column) => column.name);
    const customSettings = _.get($rootScope, 'owner.settings.tableColumns.' + tableName);
    return fullList.filter((column, index) => (customSettings ? customSettings.includes(column) : builtInSettings.includes(column)) ||
      tableColumns[tableName][index].alwaysShow);
  }

  getOwnerProjectTags () {
    let projectTags = _.get($rootScope, 'owner.settings.projectTags');
    if (projectTags === undefined) {
      projectTags = optionsList.defaultTags;
    }
    return projectTags || [];
  }
}

settingsService.$inject = ['$rootScope', 'request', 'fileRequest', 'permissions'];

const settingsServiceModule = angular.module('settingsService', [])
  .service('settingsService', settingsService)
  .name;

export default settingsServiceModule;
