import template from './project-notes.html';
import controller from './project-notes.controller';
import './project-notes.scss';

const projectNotesComponent = {
  bindings: {
    projectId: '<',
    type: '<',
    noteList: '<?noteList',
    callList: '<?callList'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', '$uibModal', 'request', 'utils', 'taskService', 'noteService', 'callService', 'counts'];

export default projectNotesComponent;
