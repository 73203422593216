import template from './bid-edit.html';
import controller from './bid-edit.controller';
import './bid-edit.scss';

const bidEditComponent = {
  bindings: {
    modalInstance: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', 'utils', 'bidService'];

export default bidEditComponent;
