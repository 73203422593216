import template from './progress-projects-report.html';
import controller from './progress-projects-report.controller';
import './progress-projects-report.scss';

const progressProjectsReportComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$filter', '$uibModal', 'projectService', 'settingsService', 'userService', 'utils'];

export default progressProjectsReportComponent;
