import template from './project-details-edit.html';
import controller from './project-details-edit.controller';
import './project-details-edit.scss';

const projectDetailsEditComponent = {
  bindings: {
    projectModel: '=?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$rootScope', '$timeout', 'utils', 'projectService', '$transitions'];

export default projectDetailsEditComponent;
