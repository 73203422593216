import paymentsComponent from './payments.component';
import charge from './charge/charge';
import paymentsTransactions from './payments-transactions/payments-transactions';
import paymentsService from './payments.service';

const paymentsModule = angular.module('payments', [
  charge,
  paymentsTransactions,
  paymentsService
])
  .component('payments', paymentsComponent)
  .name;

export default paymentsModule;
