export default function ($scope, $state, $location, $timeout, loginService, utils) {
  let vm;

  const KEY_EVENT_ENTER = 13;

  class LoginCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      _.set($state, 'current.data.specialClass', 'blank');
      vm.email = $state.params.email;

      $timeout(() => {
        jQuery('#login-form').on('keydown keypress', (event) => {
          if (event.which === KEY_EVENT_ENTER) {
            vm.send();
            event.preventDefault();
          }
        });
        jQuery('#email').focus();
      });
    }

    send () {
      if (vm.loginForm.$invalid) {
        jQuery('#login-form')[0].reportValidity();
        utils.setFormFieldsDirty(vm.loginForm);
        return;
      }

      vm.error = false;
      vm.sendProgress = true;
      vm.errorMessage = 'שגיאת שרת';
      $timeout(() => {
        vm.isTimeout = true;
        if (vm.isError) {
          vm.showErrorMessage();
        }
      }, 1500);
      loginService.login(vm.email, vm.password)
        .then((res) => {
          if (res) {
            $location.url($state.params.next || '/dashboard');
          }
          return res;
        })
        .catch((error) => {
          const errorData = _.get(error, 'data.data');
          if (errorData === 'Unknown user' || errorData === 'Invalid password') {
            vm.errorMessage = 'פרטי כניסה שגויים';
          } else if (errorData === 'Account suspended' || errorData === 'Account was deactivated') {
            vm.errorMessage = 'חשבון לא פעיל';
          }
          vm.isError = true;
          if (vm.isTimeout) {
            vm.showErrorMessage();
          }
          return error;
        })
        .finally(() => {
          $timeout(() => {
            vm.sendProgress = false;
          }, 1000);
        });
    }

    showErrorMessage () {
      utils.notify(vm.errorMessage, 'alert-danger');
      vm.error = true;
      vm.isError = false;
      vm.isTimeout = false;
      $timeout(() => {
        vm.sendProgress = false;
      }, 200);
    }
  }

  return new LoginCtrl();
}
