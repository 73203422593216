const ngRightClickModule = angular.module('ngRightClick', [])
  .directive('ngRightClick', ['$parse', function ($parse) {
    return function (scope, element, attrs) {
      const fn = $parse(attrs.ngRightClick);
      element.bind('contextmenu', (event) => {
        scope.$apply(() => {
          event.preventDefault();
          fn(scope, { $event: event });
        });
      });
    };
  }])
  .name;

export default ngRightClickModule;
