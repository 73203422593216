import dayjs from 'dayjs';

export default function ($scope, $rootScope, $state, $uibModal, $timeout, eventService) {
  let vm;

  class ProjectEventsCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.projectId = vm.projectShowCtrl.projectFields.id;
      vm.projectEvents = vm.projectShowCtrl.projectFields.events || [];
      $scope.$on('eventSaved', () => {
        vm.getEvents();
      });
    }

    async getEvents () {
      vm.isLoading = true;
      const events = await eventService.getEventList({
        filter: {
          projectId: vm.projectId
        }
      });
      vm.projectEvents = events;
      vm.projectShowCtrl.projectFields.events = vm.projectEvents;
      vm.isLoading = false;
    }

    editEvent (event) {
      $uibModal.open({
        component: 'eventEdit',
        resolve: {
          event: () => event,
          projectId: () => vm.projectId
        },
        windowClass: 'event-edit-modal mobile-modal',
        size: 'lg',
        backdrop: 'static'
      });
    }

    newEvent () {
      const event = {
        dateTime: dayjs().set('second', 0).set('millisecond', 0),
        contactName: '',
        contactPhone: '',
        contactAffinity: '',
        note: ''
      };

      vm.editEvent(event);
    }
  }

  return new ProjectEventsCtrl();
}
