export default function ($rootScope) {
  let vm;

  class HomeController {
    constructor () {
      vm = this;
      vm.name = 'home';
    }

    $onInit () {
      $rootScope.showHeader = true;
      $rootScope.showNavigation = true;
      $rootScope.showFooter = true;
    }
  }

  return new HomeController();
}
