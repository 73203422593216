import template from './unread-posts.html';
import controller from './unread-posts.controller';
import './unread-posts.scss';

const unreadPostsComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', 'ticketService', 'request'];

export default unreadPostsComponent;
