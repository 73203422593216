import template from './project-images-select.html';
import controller from './project-images-select.controller';
import './project-images-select.scss';

const projectImagesSelectComponent = {
  bindings: {
    resolve: '=?',
    limit: '<?',
    modalInstance: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', 'gdrive'];

export default projectImagesSelectComponent;
