import template from './payments-invoice-preview.html';
import controller from './payments-invoice-preview.controller';
import './payments-invoice-preview.scss';

const paymentsInvoicePreviewComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '=?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', 'projectService', 'utils'];

export default paymentsInvoicePreviewComponent;
