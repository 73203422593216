function modalCtrl ($scope, $uibModal) {
  $scope.open = function () {
    const modalInstance = $uibModal.open({
      templateUrl: 'views/modal/modal_example.html',
      controller: ['$scope', '$uibModalInstance', ModalInstanceCtrl]
    });
  };

  $scope.open1 = function () {
    const modalInstance = $uibModal.open({
      templateUrl: 'views/modal/modal_example1.html',
      controller: ['$scope', '$uibModalInstance', ModalInstanceCtrl]
    });
  };

  $scope.open3 = function (size) {
    const modalInstance = $uibModal.open({
      templateUrl: 'views/modal/modal_example3.html',
      size: size,
      controller: ['$scope', '$uibModalInstance', ModalInstanceCtrl]
    });
  };

  $scope.open2 = function () {
    const modalInstance = $uibModal.open({
      templateUrl: 'views/modal/modal_example2.html',
      controller: ['$scope', '$uibModalInstance', ModalInstanceCtrl],
      windowClass: 'hmodal-info'
    });
  };

  $scope.open4 = function () {
    const modalInstance = $uibModal.open({
      templateUrl: 'views/modal/modal_example2.html',
      controller: ['$scope', '$uibModalInstance', ModalInstanceCtrl],
      windowClass: 'hmodal-warning'
    });
  };

  $scope.open5 = function () {
    const modalInstance = $uibModal.open({
      templateUrl: 'views/modal/modal_example2.html',
      controller: ['$scope', '$uibModalInstance', ModalInstanceCtrl],
      windowClass: 'hmodal-success'
    });
  };

  $scope.open6 = function () {
    const modalInstance = $uibModal.open({
      templateUrl: 'views/modal/modal_example2.html',
      controller: ['$scope', '$uibModalInstance', ModalInstanceCtrl],
      windowClass: 'hmodal-danger'
    });
  };
}

function ModalInstanceCtrl ($scope, $uibModalInstance) {
  $scope.ok = function () {
    $uibModalInstance.close();
  };

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
}

const modalCtrlModule = angular.module('modalCtrl', [])
  .controller('modalCtrl', ['$scope', '$uibModal', modalCtrl])
  .name;

export default modalCtrlModule;
