import template from './project-tasks.html';
import controller from './project-tasks.controller';
import './project-tasks.scss';

const projectTasksComponent = {
  require: {
    projectShowCtrl: '^projectShow'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$uibModal', 'taskService', 'taskListTableColumns', 'counts'];

export default projectTasksComponent;
