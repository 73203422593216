import template from './close-projects.html';
import controller from './close-projects.controller';
import './close-projects.scss';

const closeProjectsComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$location', '$filter', '$uibModal', 'projectService', 'customerService', 'taskService', 'userService', 'utils'];

export default closeProjectsComponent;
