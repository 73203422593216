import dayjs from 'dayjs';

export default function ($scope, $rootScope, $stateParams, $filter, $uibModal, projectService, settingsService, userService, permissions, counts, utils) {
  let vm;

  const uiSrefParams = `({
    id: row.id,
    navigation: {
      query: {
        filter: vm.filter,
        order: vm.paging.orderBy,
        desc: vm.paging.orderDesc
      },
      sref: 'project.projectList'
    },
    navigationIndex: vm.paging.offset + rowIndex
  })`.replace(/\s+/g, '');

  const uiSref = 'project.projectShow' + uiSrefParams;
  const projectPaymentsUiSref = 'project.projectShow.payments' + uiSrefParams;
  const inProgressStatuses = ['בהכנה', 'בטיפול', 'מושהה'];

  class ProjectListCtrl {
    constructor () {
      vm = this;
      vm.name = 'home';
      vm.$stateParams = $stateParams;
      const enabledColumns = settingsService.getOwnerTableColumns('projectList');
      vm.tableHeadList = [
        { name: 'number', label: 'פרויקט', sortable: true, uiSref },
        { name: 'createdAt', label: 'תאריך יצירה', type: 'date', sortable: true, uiSref },
        { name: 'dueDate', label: 'תאריך יעד', type: 'customFunction', customFunction: vm.getProjectDueDate, sortable: true, uiSref },
        { name: 'customer.name', label: 'לקוח', sortable: true, type: 'customFunction', customFunction: vm.getCustomer, uiSref },
        { name: 'street', label: 'כתובת', type: 'customFunction', customFunction: vm.getAddress, sortable: true, uiSref, if: () => !!$rootScope.hasAddress },
        { name: 'city', label: 'עיר', sortable: true, uiSref, if: () => !!$rootScope.hasAddress },
        { name: 'gush', label: 'גוש', sortable: true, uiSref, if: () => !!$rootScope.hasAddress },
        { name: 'helka', label: 'חלקה', sortable: true, uiSref, if: () => !!$rootScope.hasAddress },
        { name: 'subHelka', label: 'תת חלקה', sortable: true, uiSref, if: () => !!$rootScope.hasAddress },
        { name: 'status', label: 'סטטוס', type: 'customFunction', customFunction: vm.getStatus, sortable: true, uiSref },
        // { name: 'referrer', label: 'גורם מפנה', sortable: true, uiSref: uiSref },
        { name: 'agent', label: 'נציג אחראי', if: vm.isAgentColumnShown, type: 'compile', customFunction: vm.getAgentString, sortable: true, uiSref },
        { name: 'tasks', label: 'משימות', type: 'customFunction', customFunction: vm.hasTasks, uiSref, elementClass: 'badge badge-primary' }
      ];
      if (permissions.can('view', 'projectPayments')) {
        vm.tableHeadList.push({
          name: 'paid', label: 'שולם', type: 'compile', customFunction: vm.isPaid, uiSref: projectPaymentsUiSref, clickFunction: vm.paymentTdClick
        });
      }
      vm.tableHeadList.forEach(column => {
        column.enabled = enabledColumns.includes(column.name);
      });
      vm.additionalRow = { name: 'projectTags', customFunction: vm.getProjectTags, if: vm.projectHasTags, uiSref };

      vm.statusOptions = settingsService.getOwnerProjectStatusList();
      vm.ownerTagList = settingsService.getOwnerProjectTags();

      vm.dateFilterOptions = [
        { value: '&gt:' + dayjs().subtract(1, 'week').toISOString(), label: 'שבוע אחרון' },
        { value: '&gt:' + dayjs().subtract(1, 'month').toISOString(), label: 'חודש אחרון' }
      ];
      [3, 6, 12].forEach((num) => {
        vm.dateFilterOptions.push({
          value: '&gt:' + dayjs().subtract(num, 'month').toISOString(), label: num + ' חודשים אחרונים'
        });
      });

      vm.tableFilters = {
        agent: '',
        status: vm.initialStatusFilter,
        tags: []
      };
    }

    $onInit () {
      vm.agentList = _.get($rootScope, 'owner.users') || [];
      if (!vm.$stateParams.query) {
        vm.setSummaryFilter('inProgress');
        if (vm.agentList.length > 1 && !$rootScope.isManager()) {
          vm.tableFilters.agent = $rootScope.user.id;
        }
      } else {
        vm.summaryFilter = null;
        vm.tableFilters = vm.$stateParams.query.filter;
      }
      vm.agentColumnShown = !vm.tableFilters.agent && vm.agentList.length > 1;
      vm.getProjectListCounts();

      $scope.$on('projectSaved', () => {
        vm.getProjectList();
      });
    }

    getProjectList (args) {
      return projectService.getProjectList(true, args);
    }

    addProjectForm () {
      $uibModal.open({
        component: 'projectEdit',
        windowClass: 'project-edit-modal mobile-modal',
        size: 'lg',
        backdrop: 'static'
      });
    }

    getCustomer (row) {
      return (_.get(row, 'customer.name') || '');
    }

    getAddress (row) {
      return utils.joinStringArray([row.street, row.house], ' ');
    }

    getStatus (row) {
      if (!row.status) {
        return '';
      }
      return `<i class="fa fa-circle" style="color:${vm.statusColor(row.status)}" aria-hidden="true"></i><span>${row.status}</span>`;
    }

    getProjectDueDate (row) {
      if (
        row.status &&
        !['הצעה', 'הושלם', 'מבוטל'].includes(row.status) &&
        dayjs(row.dueDate).isBefore(dayjs().startOf('day').add(3, 'days'))
      ) {
        return `<span class="text-danger">${$filter('date')(row.dueDate, 'dd/MM/yyyy')}</span>`;
      }
      return $filter('date')(row.dueDate, 'dd/MM/yyyy');
    }

    hasTasks (row) {
      if (row.tasks && Number(row.tasks.length) > 0) {
        return row.tasks.length;
      }
      return '';
    }

    isPaid (row) {
      if (row.toPayTotal > 0) {
        if (row.toPayTotal < row.priceTotal) {
          return `<pie-chart uib-tooltip="שולם ${vm.paidPercent(row)}%" data="[${row.priceTotal - row.toPayTotal}, ${row.toPayTotal}]" options="{type: 'pie'}"></pie-chart>`;
        } else {
          return '<i uib-tooltip="לא שולם" class="text-danger fa fa-times-circle"><i/>';
        }
      } else if (row.priceTotal) {
        return '<i uib-tooltip="שולם" class="text-success fa fa-check-circle"><i/>';
      }
      return '<i uib-tooltip="לא הוגדר תשלום" class="fa fa-circle"></i>';
    }

    paidPercent (row) {
      return Math.min(100, (row.transactionTotal / row.priceTotal) * 100).toFixed();
    }

    paymentTdClick (event) {
      event.stopPropagation();
    }

    showProjectLink (id) {
      return uiSref;
    }

    getProjectTags (row) {
      if (row.tagsString) {
        return row.tagsString;
      }

      if (!_.get(row, 'tags.length')) {
        row.tagsString = '';
      } else {
        row.tagsString = row.tags.map((tag) => {
          return `<span class="badge" style="${vm.tagStyle(tag)}">${tag}</span>`;
        }).join(' ');
      }
      return row.tagsString;
    }

    projectHasTags (row) {
      return _.get(row, 'tags.length') > 0;
    }

    getProjectListCounts () {
      let dateFilter;
      if (vm.tableFilters.createdAt) {
        dateFilter = vm.tableFilters.createdAt.replace(/^&gt:/, '');
      }
      return projectService.getProjectListCounts(vm.tableFilters.agent, dateFilter)
        .then((result) => {
          let inProgressCount = 0;
          result.forEach((row) => {
            if (inProgressStatuses.includes(row.status)) {
              inProgressCount += Number(row.count);
            }
          });

          vm.counts = {
            preparation: (_.find(result, { status: 'הצעה' }) || {}).count,
            inProgress: inProgressCount,
            review: (_.find(result, { status: 'לבדיקה' }) || {}).count,
            supervised: (_.find(result, { status: 'בליווי' }) || {}).count,
            done: (_.find(result, { status: 'הושלם' }) || {}).count
          };
        });
    }

    setSummaryFilter (summaryStatus) {
      if (vm.summaryFilter === summaryStatus) {
        vm.summaryFilter = null;
        vm.tableFilters.status = vm.initialStatusFilter;
        return;
      }

      vm.summaryFilter = summaryStatus;

      switch (summaryStatus) {
        case 'preparation':
          vm.tableFilters.status = 'הצעה';
          break;
        case 'inProgress':
          vm.tableFilters.status = inProgressStatuses.join('|');
          break;
        case 'supervised':
          vm.tableFilters.status = 'בליווי';
          break;
        case 'review':
          vm.tableFilters.status = 'לבדיקה';
          break;
        case 'done':
          vm.tableFilters.status = 'הושלם';
          break;
        default:
          vm.tableFilters.status = null;
          break;
      }
    }

    isAgentColumnShown () {
      return vm.agentColumnShown;
    }

    onAgentChanged (selectedAgent) {
      vm.tableFilters.agent = selectedAgent;
      vm.agentColumnShown = !vm.tableFilters.agent && vm.agentList.length > 1;
      vm.getProjectListCounts();
    }

    updateStatusFilter (status) {
      vm.statusFilterChanged();
      vm.tableFilters.status = status;
    }

    updateDateFilter (value) {
      vm.tableFilters.createdAt = value;
      vm.getProjectListCounts();
    }

    statusColor (status) {
      return projectService.getProjectStatusColor(status);
    }

    selectedStatus (status) {
      if (status === inProgressStatuses.join('|')) {
        return '';
      } else if (status === '!מבוטל|$null') {
        return 'הכל';
      } else {
        return status;
      }
    }

    isStatusEnables (...statuses) {
      return !vm.statusOptions ||
        // one of the statuses is enabled
        statuses.some((status) => vm.statusOptions.includes(status));
    }

    statusFilterChanged () {
      vm.summaryFilter = null;
    }

    onTagChanged (selectedTags) {
      vm.tableFilters.tags = selectedTags;
    }

    tagStyle (name) {
      const tag = vm.ownerTagList.find((tag) => tag.title === name);
      return `background: ${_.get(tag, 'background') || '#afafaf'}; color: ${_.get(tag, 'color') || 'black'}`;
    }

    getAgentString (row) {
      if (row.htmlString) {
        return row.htmlString;
      }
      let agentsString = '';

      const agent = $rootScope.userById(row.agent);
      const relatedAgentIds = _.uniq(Object.values(row.relatedAgents || {}).filter((id) => id && id !== agent.id));

      const relatedAgents = relatedAgentIds.map((agentId) => {
        return $rootScope.userById(agentId);
      });

      if (!agent && !relatedAgents.length) {
        return '';
      }

      if (agent) {
        agentsString += vm.agentAvatar(agent);
      }

      if (relatedAgents.length) {
        relatedAgents.slice(0, 4).forEach((agent, i) => {
          agentsString += vm.agentAvatar(agent);
        });
      }

      row.htmlString = `<div class="avatars-container">${agentsString}</div>`;
      return row.htmlString;
    }

    agentAvatar (agent) {
      return `<span class="avatar" uib-tooltip="${agent.firstName || ''} ${agent.lastName || ''}" tooltip-append-to-body="true"><img class="img-circle" src="${userService.getUserProfileImage(agent)}" /></span>`;
    }

    getUserProfileImage (agent) {
      return userService.getUserProfileImage(agent);
    }

    contextMenu (row) {
      return [
        {
          text: 'שינוי סטטוס',
          children: [
            ...vm.statusOptions.map((status) => ({
              html: `<span class="dropdown-status-box" style="background: ${projectService.getProjectStatusColor(status)}"></span>
                <span class="label-title ${row.status === status ? 'selected' : ''}">${status}</span>`,
              click ($itemScope, $event, modelValue, text, $li) {
                vm.updateStatus(status, row);
              }
            }))
          ]
        },
        {
          text: 'שינוי תוויות',
          displayed: vm.ownerTagList?.length > 0,
          children: [
            ...vm.ownerTagList.map((tag) => {
              const isTagSelected = (row.tags || []).includes(tag.title);
              return {
                html: `<i class="tag-check ${isTagSelected ? 'selected' : ''}"></i>
                <span class="dropdown-tag-box" style="background: ${tag.background}"></span>
                <span class="tag-title">${tag.title}</span>`,
                click () {
                  const tags = isTagSelected
                    ? row.tags.filter((selectedTag) => selectedTag !== tag.title)
                    : [...(row.tags || []), tag.title];
                  vm.updateProject({ tags }, row);
                }
              };
            })
          ]
        }
      ];
    }

    async updateStatus (status, projectFields) {
      if (projectFields.status !== status && status === 'מבוטל' && projectFields.toPayTotal === 0) {
        const modalParams = {
          windowClass: 'hmodal-danger',
          title: 'קיים חוב לתשלום בפרויקט',
          message: `במידה והפרויקט יבוטל החוב ימחק מדוח התשלומים.
          האם להמשיך בביטול הפרויקט?`,
          buttons: [{
            label: 'לא'
          },
          {
            label: 'כן, לבטל את הפרויקט',
            btnClass: 'btn-danger',
            onClick ($uibModalInstance) {
              $uibModalInstance.close();
              return true;
            }
          }]
        };
        await utils.modalAlert(modalParams);
      }

      vm.updateProject({ status }, projectFields);
    }

    updateProject (changes, projectFields, customAgentRelationId) {
      projectService.saveProject(projectFields.id, changes)
        .then((result) => {
          $rootScope.$broadcast('projectSaved');
          let message = 'הצלחה';
          if (changes.status) {
            message = 'הסטטוס שונה בהצלחה';
          } else if (changes.tags) {
            const tagWasAdded = projectFields.tags?.length < changes.tags?.length;
            message = `התווית ${tagWasAdded ? 'נוספה' : 'הוסרה'} בהצלחה`;
          }
          vm.getProjectListCounts();
          utils.notify(message, 'alert-success');
        })
        .catch((e) => {
          console.log(e);
          utils.notify('שגיאה', 'alert-danger');
        })
        .finally(() => {
          if (changes.status) {
            counts.loadCounts('projectAlerts', 'visits');
          }
        });
    }
  }

  return new ProjectListCtrl();
}
