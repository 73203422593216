import documentSettingsComponent from './document-settings.component';
import documentSettingsEditModule from './document-settings-edit/document-settings-edit';

const documentSettingsModule = angular.module('documentSettings', [
  documentSettingsEditModule
])
  .component('documentSettings', documentSettingsComponent)
  .name;

export default documentSettingsModule;
