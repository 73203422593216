export default function ($scope, $rootScope, $state, $timeout, utils, ownerService) {
  let vm;

  class OwnerEditCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.ownerObject = angular.copy(vm.resolve.owner);
      vm.ownerId = vm.ownerObject.id;
      vm.ownerCrmSettings = angular.copy(vm.ownerObject.crmSettings) || {};
      vm.projectFieldsSchema = angular.copy(_.get(vm.ownerCrmSettings, 'projectFieldsSchema'));
      console.log(vm.projectFieldsSchema);
      if (!vm.ownerCrmSettings.documents) {
        vm.ownerCrmSettings.documents = [];
      }
    }

    saveOwner () {
      if (vm.saveProgress) {
        return;
      }
      vm.saveProgress = true;
      ownerService.saveOwner(vm.ownerObject.id, vm.ownerObject)
        .then((result) => {
          return ownerService.saveOwnerCrmSettings(vm.ownerObject.id, vm.ownerCrmSettings);
        })
        .then((result) => {
          utils.notify('פרטי ארגון נשמרו', 'alert-success');

          vm.modalInstance.close();
          $rootScope.$broadcast('ownerSaved');
        })
        .catch((error) => {
          console.log(error);
          utils.notify('שגיאה בביצוע שמירה', 'alert-danger');
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    closeModalBox () {
      vm.modalInstance.dismiss('cancel');
    }
  }

  return new OwnerEditCtrl();
}
