import template from './project-payments.html';
import controller from './project-payments.controller';
import './project-payments.scss';

const projectPaymentsComponent = {
  require: {
    projectShowCtrl: '^projectShow'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', '$uibModal', 'request', 'utils', 'projectService', 'permissions'];

export default projectPaymentsComponent;
