import projectImagesComponent from './project-images.component';
import projectImagesSelectComponent from './project-images-select/project-images-select';

const projectImagesModule = angular.module('projectImages', [
  projectImagesSelectComponent
])
  .component('projectImages', projectImagesComponent)
  .name;

export default projectImagesModule;
