import fieldsSchema from '../project-details-fields-temp';

export default function ($scope, $state, $rootScope, $timeout, utils, projectService, $transitions) {
  let vm;

  class ProjectDetailsEditCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.fieldsSchema = _.get($rootScope, 'owner.crmSettings.projectFieldsSchema') || fieldsSchema;
      vm.projectId = vm.projectModel.id;
    }
  }

  return new ProjectDetailsEditCtrl();
}
