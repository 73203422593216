import template from './task-list.html';
import controller from './task-list.controller';
import './task-list.scss';

const taskListComponent = {
  bindings: {
    active: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$stateParams', '$timeout', '$filter', '$uibModal', 'taskListTableColumns', 'utils', 'taskService', 'userService', 'counts'];

export default taskListComponent;
