export default function ($scope, $rootScope, $timeout, utils, customerService) {
  let vm;

  class CustomerEditCtrl {
    constructor () {
      vm = this;
      vm.loadProgress = true;
    }

    async $onInit () {
      vm.customerFields = {};
      vm.autocompleteShown = {};

      const customerId = vm.resolve.customerId;
      let customer = vm.resolve.customer;

      if (!customer && customerId) {
        customer = await customerService.getCustomer(customerId);
      }
      if (customer) {
        Object.keys(customer).forEach((field) => {
          vm.customerFields[field] = customer[field];
        });
      }
      vm.loadProgress = false;
    }

    closeModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    saveModalBox () {
      if (vm.saveProgress) {
        return;
      }
      if (vm.form && vm.form.$invalid) {
        document.getElementById('customer-edit-form').reportValidity();
        utils.setFormFieldsDirty(vm.form);
        return;
      }
      vm.saveProgress = true;
      customerService.saveCustomer(vm.customerFields.id, vm.customerFields)
        .then((customerResult) => {
          const message = (vm.customerFields.id ? 'פרטי לקוח נשמרו' : 'לקוח חדש נשמר');
          utils.notify(message, 'alert-success');

          vm.modalInstance.close();
          $rootScope.$broadcast('customerSaved');

          if (vm.resolve.onSaveCustomer) {
            vm.resolve.onSaveCustomer({ customerId: customerResult.id });
          }
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    blurAutocompleteField (shownField) {
      $timeout(() => {
        vm.autocompleteShown[shownField] = false;
      }, 300);
    }

    selectAutocompleteCustomer (customer) {
      vm.customerFields = {
        id: customer.id
      };
      vm.linkedCustomer = customer;
      $timeout(() => {
        vm.autocompleteShown = {};
      }, 100);
    }

    removeLinkedCustomer () {
      vm.customerFields = {};
      vm.linkedCustomer = null;
    }
  }

  return new CustomerEditCtrl();
}
