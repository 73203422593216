import template from './sign-up-lead.html';
import controller from './sign-up-lead.controller';
import './sign-up-lead.scss';

const signUpLeadComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$location', '$window', '$timeout', 'request', 'utils'];

export default signUpLeadComponent;
