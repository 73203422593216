import template from './ticket-edit.html';
import controller from './ticket-edit.controller';
import './ticket-edit.scss';

const ticketEditComponent = {
  // bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', 'utils', 'ticketService'];

export default ticketEditComponent;
