/* global localStorage */
export default function ($scope, $rootScope, $sce, $uibModal, $timeout) {
  let vm;

  class PagingTableController {
    constructor () {
      vm = this;
      vm.name = 'pagingTable';

      vm.paging = {
        orderBy: 'createdAt',
        orderDesc: true,
        limit: 25,
        offset: 0,
        count: 0,
        pages: 1
      };

      vm.paging.current = {
        orderBy: vm.paging.orderBy,
        orderDesc: vm.paging.orderDesc,
        offset: 0,
        count: 0,
        page: 0
      };

      $scope.$on('context-menu-opening', (event, { context }) => {
        const selectedElement = jQuery(context);
        const prevElement = jQuery(selectedElement).prev('tr');
        selectedElement.addClass('target-row');

        if (jQuery(selectedElement).is("[ng-repeat-end]") && jQuery(prevElement).is("[ng-repeat-start]")) {
          prevElement.addClass('target-row');
        }
      });

      $scope.$on('context-menu-all-closed', () => {
        jQuery('tr').removeClass('target-row');
      });

      $scope.$watch('vm.filter', (newValue, oldValue) => {
        if (newValue && !_.isEqual(newValue, oldValue)) {
          vm.paging.offset = 0;
          vm.getList();
        }
      }, true);
    }

    $onInit () {
      vm.originalGetList = vm.getListFunction;
      vm.getListFunction = vm.getList; // allow to call getList from outside of the component.
      if (vm.offset !== undefined) {
        vm.paging.offset = Math.floor(vm.offset / vm.paging.limit) * vm.paging.limit;
        vm.targetRow = vm.offset % vm.paging.limit;
      }

      if (vm.tableName) {
        const storedOrderBy = localStorage.getItem(vm.tableName + 'OrderBy');
        const storedOrderDesc = localStorage.getItem(vm.tableName + 'OrderDesc');
        const columnNamesArray = vm.tableHeadList.map((item) => item.name);

        if (storedOrderBy && columnNamesArray.includes(storedOrderBy)) {
          vm.orderBy = storedOrderBy;
          vm.orderDesc = storedOrderDesc === 'true';
        }
      }

      if (!_.isNil(vm.orderBy)) {
        vm.paging.orderBy = vm.orderBy;
      }
      if (!_.isNil(vm.orderDesc)) {
        vm.paging.orderDesc = vm.orderDesc;
      }

      vm.getList();
    }

    doPaging (page) {
      delete vm.targetRow;
      if (Number(page) < 0 || Number(page) >= vm.paging.pages) {
        return;
      }
      vm.paging.offset = vm.paging.limit * Number(page);
      vm.getList();
    }

    selectTh (th) {
      vm.doOrder(th.name);
    }

    doOrder (orderBy) {
      delete vm.targetRow;
      if (vm.paging.orderBy === orderBy) {
        vm.paging.orderDesc = !vm.paging.orderDesc;
      } else if (orderBy === 'createdAt') {
        vm.paging.orderDesc = true;
      } else {
        vm.paging.orderDesc = false;
      }

      vm.paging.orderBy = orderBy;
      if (vm.paging.count > vm.paging.current.count) {
        vm.getList();
      } else { // do offline sorting
        vm.setPagingFlags();
      }

      if (vm.tableName) {
        localStorage.setItem(vm.tableName + 'OrderBy', vm.paging.orderBy);
        localStorage.setItem(vm.tableName + 'OrderDesc', vm.paging.orderDesc);
      }
    }

    getList () {
      vm.getListProgress = true;

      const query = {
        limit: vm.paging.limit,
        offset: vm.paging.offset,
        order: vm.paging.orderBy,
        desc: vm.paging.orderDesc
      };

      if (angular.isObject(vm.filter)) {
        query.filter = vm.filter;
      }

      return vm.originalGetList(query)
        .then((list) => {
          vm.list = list;
          vm.setPagingFlags();
          $timeout(() => {
            if (vm.targetRow && !vm.doneScrollToTarget) {
              const targetRow = document.querySelector('.target-row');
              targetRow.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
              vm.doneScrollToTarget = true;
            }
          });
          return vm.list;
        })
        .finally(() => {
          vm.getListProgress = false;
        });
    }

    setPagingFlags () {
      vm.paging.current.count = vm.list.rows.length;
      vm.paging.current.orderBy = vm.paging.orderBy;
      vm.paging.current.orderDesc = vm.paging.orderDesc;
      vm.paging.count = vm.list.count;
      vm.paging.pages = Math.ceil(vm.paging.count / vm.paging.limit) || 1;
      vm.paging.current.page = Math.floor(vm.paging.offset / vm.paging.limit);
      vm.paging.current.offset = vm.paging.offset;
      vm.setPageBtnsArray();
    }

    getOrderBy () {
      return (vm.paging.current.orderDesc ? '-' : '') + vm.paging.current.orderBy;
    }

    getField (key, value) {
      return _.get(key, value);
    }

    tableHeadListLength () {
      return vm.tableHeadList.length;
    }

    setPageBtnsArray () {
      const page = vm.paging.current.page;
      const arr = [];
      for (let index = page - 2; index <= page + 2; index++) {
        if (index >= 0 && index < vm.paging.pages) {
          arr.push(index);
        }
      }
      vm.pageBtnsArray = arr;
    }

    getHtml (html) {
      return $sce.trustAsHtml(String(html));
    }
  }

  return new PagingTableController();
}
