import template from './owner-projects.html';
import controller from './owner-projects.controller';
import './owner-projects.scss';

const ownerProjectsComponent = {
  require: {
    ownerShowCtrl: '^ownerShow'
  },
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$uibModal', 'notify', 'userService', 'ownerService', 'utils'];

export default ownerProjectsComponent;
