import template from './project-events.html';
import controller from './project-events.controller';
import './project-events.scss';

const projectEventsComponent = {
  require: {
    projectShowCtrl: '^projectShow'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$uibModal', '$timeout', 'eventService'];

export default projectEventsComponent;
