import isEmail from 'validator/lib/isEmail';

export default function ($scope, $rootScope, utils, ownerService) {
  let vm;

  class OwnerDetailsEditCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      const owner = $rootScope.owner;
      vm.ownerObject = {
        id: owner.id,
        address: owner.address,
        city: owner.city,
        email: owner.email,
        phone: (owner.phone || '').replace(/^\+972/, '0')
      };

      $scope.$watch('vm.ownerObject.email', (newVal, oldVal) => {
        if (vm.form.email) {
          const emailViewValue = vm.form.email.$viewValue;
          const isValidEmail = isEmail(emailViewValue || '');
          vm.form.email.$setValidity('email', !emailViewValue || isValidEmail);
          jQuery('#email-input')[0].setCustomValidity(isValidEmail ? '' : 'דוא"ל לא תקין');
        }
      });
    }

    closeModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    saveOwner () {
      if (vm.resolve.logoEditMode) {
        return vm.saveOwnerLogo();
      }
      if (vm.saveProgress) {
        return;
      }
      if (vm.form.$invalid) {
        jQuery('#owner-form')[0].reportValidity();
        utils.setFormFieldsDirty(vm.form);
        return;
      }
      const successMessage = 'פרטי ארגון נשמרו';
      vm.saveProgress = true;

      const ownerObject = angular.copy(vm.ownerObject);
      if (ownerObject.phone) {
        ownerObject.phone = ownerObject.phone.replace(/^\+972/, '0');
      }
      return ownerService.saveOwner(ownerObject.id, ownerObject)
        .then((ownerResult) => {
          $rootScope.owner = ownerResult;
          utils.notify(successMessage, 'alert-success');
          vm.modalInstance.close();
        })
        .catch((error) => {
          let errorMessage = 'שגיאה';
          if (error.data.data === 'SequelizeValidationError: Validation error: Invalid email.') {
            errorMessage = 'דוא"ל לא תקין';
          }
          utils.notify(errorMessage, 'alert-danger');
          console.log(error);
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    onImageChange (name, model) {
      vm.imageChanged = true;
    }

    saveOwnerLogo () {
      if (vm.saveProgress) {
        return;
      }
      if (!vm.imageChanged) {
        return vm.closeModalBox();
      }
      const successMessage = 'תמונת לוגו עודכנה';
      vm.saveProgress = true;
      return ownerService.saveOwnerLogo(_.get($rootScope, 'owner.id'), vm.logo)
        .then((ownerResult) => {
          let logoUrl = ownerResult.logoUrl;
          if (logoUrl) {
            logoUrl += ('?' + new Date().getTime());
          }
          $rootScope.owner.logoUrl = logoUrl;
          utils.notify(successMessage, 'alert-success');
          vm.modalInstance.close();
        })
        .catch((error) => {
          utils.notify('שגיאה', 'alert-danger');
          console.log(error);
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    getOwnerLogo () {
      return ownerService.getOwnerLogo($rootScope.owner);
    }
  }

  return new OwnerDetailsEditCtrl();
}
