export default function ($scope, $rootScope, $timeout, $sce) {
  let vm;

  class MultiSelectController {
    constructor () {
      vm = this;
      vm.name = 'multiSelect';
    }

    $onInit () {
      vm.init();
      $scope.$watch('vm.model', () => {
        vm.init();
      });
    }

    init () {
      vm.tempModel = {};
      vm.model.forEach((optionName) => {
        vm.tempModel[optionName] = true;
      });
    }

    isOptionDisabled (option) {
      return (vm.disabledOptions || []).includes(option);
    }

    changeAll (status) {
      vm.model = vm.options.filter((value) => {
        if (vm.isOptionDisabled(value)) {
          return vm.model.includes(value);
        }
        return !!status;
      });
      vm.init();
    }

    onChange (optionName, newValue) {
      if (vm.isOptionDisabled(optionName)) {
        return;
      }
      if (newValue) {
        vm.model.push(optionName);
      } else {
        vm.model.splice(vm.model.indexOf(optionName), 1);
      }
    }
  }

  return new MultiSelectController();
}
