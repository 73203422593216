
// import optionsList from '../../components/project/project-options-list';

export default function ($scope, $rootScope, $timeout) {
  let vm;

  class FormFieldController {
    constructor () {
      vm = this;
    }

    $onInit () {
      if (vm.field.type === 'checkboxList') {
        vm.model = vm.model || [];
      }
      vm.fillFieldsDefaultValue();
      vm.summernoteOptions = {
        toolbar: [
          ['style', ['style']],
          ['font', ['bold', 'underline', 'clear']],
          ['fontname', ['fontname']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['table', ['table']],
          ['insert', ['link']]
        ],
        lang: 'he-IL',
        placeholder: vm.field.placeholder,
        fontNames: ['David', 'Arial']
      };
      if (vm.onInit !== undefined) {
        vm.onInit();
      }
      vm.initialValue = angular.copy(vm.model);
    }

    $onDestroy () {
      if (vm.onDestroy !== undefined) {
        vm.onDestroy();
      }
    }

    onValueChanged () {
      $timeout(() => {
        $rootScope.$broadcast('resetSlickHeight');
        if (angular.isFunction(vm.onChange)) {
          vm.onChange();
        }
        $scope.$apply();
      });
    }

    onTextareaChanged () {
      vm.field.isDefaultValue = (vm.field.defaultValue &&
        vm.field.type === 'textarea' &&
        vm.model === vm.field.defaultValue);
    }

    fillFieldsDefaultValue () {
      function isEmpty (model) {
        if (vm.field.type === 'checkboxList') {
          return !model.length;
        }
        return !model;
      }
      if (isEmpty(vm.model) && vm.field.defaultValue) {
        vm.model = vm.field.defaultValue;
      }
      vm.onTextareaChanged();
    }

    checkboxListChanged (fieldName, value) {
      if (vm.model.includes(value)) {
        vm.model.splice(vm.model.indexOf(value), 1);
      } else {
        vm.model.push(value);
      }
    }

    autocompleteInsert (item) {
      let functionalDivisionText = vm.model || '';
      if (functionalDivisionText.length && !functionalDivisionText.endsWith(' ')) {
        functionalDivisionText += ' ';
      }
      functionalDivisionText += item + ',';
      vm.model = functionalDivisionText;
      $timeout(() => {
        const element = document.querySelector('.row-group .functionalDivision textarea');
        element.focus();
        if (element.setSelectionRange) {
          element.setSelectionRange(functionalDivisionText.length, functionalDivisionText.length);
        }
      });
    }
  }

  return new FormFieldController();
}
