import template from './project-agent-selector.html';
import controller from './project-agent-selector.controller';
import './project-agent-selector.scss';

const projectAgentComponent = {
  bindings: {
    agentId: '<',
    statusIcon: '<?',
    label: '@',
    onAgentChange: '&'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', 'projectService', 'settingsService', 'userService'];

export default projectAgentComponent;
