import mainRoutes from './main';
import adminRoutes from './admin';
import customerRoutes from './customer';
import nadlanRoutes from './nadlan';
import paymentRoutes from './payment';
import projectRoutes from './project';
import reportRoutes from './report';
import settingsRoutes from './settings';
import taskRoutes from './task';
import ticketRoutes from './ticket';

export default function (app) {
  return app
    .config(['$locationProvider', '$stateProvider', '$urlRouterProvider', '$compileProvider', ($locationProvider, $stateProvider, $urlRouterProvider, $compileProvider) => {
      // @see: https://github.com/angular-ui/ui-router/wiki/Frequently-Asked-Questions
      // #how-to-configure-your-server-to-work-with-html5mode
      // $locationProvider.html5Mode(true).hashPrefix('!');
      $locationProvider.hashPrefix('');
      $compileProvider.debugInfoEnabled(true);

      // Set default state
      $urlRouterProvider.otherwise('/projects');
    }])
    .config(mainRoutes)
    .config(adminRoutes)
    .config(customerRoutes)
    .config(nadlanRoutes)
    .config(paymentRoutes)
    .config(projectRoutes)
    .config(reportRoutes)
    .config(settingsRoutes)
    .config(taskRoutes)
    .config(ticketRoutes);
}
