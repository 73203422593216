import template from './nadlan.html';
import controller from './nadlan.controller';
import './nadlan.scss';

const nadlanComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'govmapService'];

export default nadlanComponent;
