export default function ($rootScope, $state, projectService, govmapService) {
  let vm;

  class ProjectNadlanCtrl {
    constructor () {
      vm = this;
      vm.$state = $state;
    }

    $onInit () {
      setTimeout(() => {
        if (vm.projectShowCtrl.projectFields && (!vm.projectShowCtrl.projectFields.x || !vm.projectShowCtrl.projectFields.y)) {
          vm.getCoordinates();
        }
      });
    }

    async getCoordinates () {
      const addressQuery = [
        vm.projectShowCtrl.projectFields.street || '',
        String(vm.projectShowCtrl.projectFields.house || '').trim().replace(/\D[^>]*/g, ''), // select only first number
        vm.projectShowCtrl.projectFields.city || ''
      ].filter((field) => !!field).join(' ').trim();

      if (!vm.projectShowCtrl.projectFields.city || !addressQuery.length) {
        return;
      }
      const coordinates = await govmapService.getCoordinates(addressQuery);
      if (!coordinates.x || !coordinates.y) {
        return;
      }
      vm.projectShowCtrl.projectFields.x = coordinates.x;
      vm.projectShowCtrl.projectFields.y = coordinates.y;

      await projectService.saveProject(vm.projectShowCtrl.projectFields.id, {
        x: coordinates.x,
        y: coordinates.y
      });
      $rootScope.$broadcast('projectSaved');
    }
  }

  return new ProjectNadlanCtrl();
}
