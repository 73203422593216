import template from './nadlan-deals.html';
import controller from './nadlan-deals.controller';
import './nadlan-deals.scss';

const nadlanDealsComponent = {
  bindings: {
    projectNumber: '@',
    x: '@',
    y: '@'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', '$uibModal', '$filter'];

export default nadlanDealsComponent;
