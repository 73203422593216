import greeninvoiceModelTemplate from './greeninvoice-modal-template.html';

export default function ($scope, $rootScope, $uibModal, $timeout, $state, settingsService, request, utils, permissions) {
  let vm;

  class LinkedAccountsCtrl {
    constructor () {
      vm = this;
      vm.permissions = permissions;
    }

    $onInit () {
      vm.loadSettings();
      $scope.$on('settingsLoaded', () => {
        vm.loadSettings();
      });
      if ($state.params.message) {
        const messageType = $state.params.status === 'success' ? 'alert-success' : 'alert-danger';
        $timeout(
          () => utils.notify($state.params.message, messageType), 2000
        );
      }
    }

    loadSettings () {
      vm.settings = {
        hasGdriveModule: _.get($rootScope, 'owner.crmSettings.hasGdriveModule'),
        hasGcalendarModule: _.get($rootScope, 'user.crmSettings.hasGcalendarModule'),
        hasGreeninvoiceModule: _.get($rootScope, 'owner.crmSettings.hasGreeninvoiceModule')
      };
      request('http://localhost:1986/amend/version', null, 'GET', { rootUrl: true }, true)
        .then((res) => {
          vm.settings.desktopClientRunning = true;
          vm.settings.desktopClientInstalledVersion = _.get(res, 'data');
          $timeout(() => {
            $scope.$apply();
          });
        })
        .catch(() => { });
    }

    async connectGoogleDrive () {
      if (vm.connectGdriveProgress) {
        return;
      }
      vm.connectGdriveProgress = true;
      try {
        const url = await settingsService.requestGdriveOwnerCode();
        window.location.assign(url);
      } catch (err) {
        console.error(err);
        utils.notify('שגיאה!', 'alert-danger');
      } finally {
        vm.connectOnedriveProgress = false;
      }
    }

    async connectGoogleCalendar () {
      if (vm.connectGcalendarProgress) {
        return;
      }
      vm.connectGcalendarProgress = true;
      try {
        const url = await settingsService.requestGcalendarUserCode();
        window.location.assign(url);
      } catch (err) {
        console.error(err);
        utils.notify('שגיאה!', 'alert-danger');
      } finally {
        vm.connectOnedriveProgress = false;
      }
    }

    openGreeninvoiceForm () {
      $uibModal.open({
        template: greeninvoiceModelTemplate,
        controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
          $scope.vm = vm;
          vm.modalInstance = $uibModalInstance;
        }],
        windowClass: 'greeninvoice-modal linked-account-modal mobile-modal',
        size: 'md',
        backdrop: 'static'
      });
    }

    confirmRemoveGreeninvoice () {
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: 'האם להסיר את החיבור לחשבונית ירוקה?',
        buttons: [{
          label: 'לא, ביטול'
        },
        {
          label: 'כן, אישור הסרה',
          btnClass: 'btn-primary',
          onClick ($uibModalInstance) {
            $uibModalInstance.close();
            return true;
          }
        }]
      };

      return utils.modalAlert(modalParams)
        .then((isConfirm) => {
          if (isConfirm) {
            vm.removeGreeninvoice();
          }
        });
    }

    closeModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    sendGreeninvoiceCode () {
      if (vm.sendGreeninvoiceProgress) { return; }
      if (vm.greeninvoiceConnectionForm.$invalid) {
        jQuery('#greeninvoice-form')[0].reportValidity();
        utils.setFormFieldsDirty(vm.greeninvoiceConnectionForm);
        return;
      }
      vm.sendGreeninvoiceProgress = true;
      return settingsService.sendGreeninvoiceCode(vm.greeninvoiceApiToken, vm.greeninvoiceApiSecret)
        .then((response) => {
          $rootScope.$broadcast('ownerSaved');
          utils.notify('קישור חשבון בוצע', 'alert-success');
          vm.greeninvoiceApiToken = '';
          vm.greeninvoiceApiSecret = '';
          vm.modalInstance.close();
        })
        .catch((error) => {
          utils.notify('שגיאה', 'alert-danger');
          console.log(error);
        })
        .finally(() => {
          vm.sendGreeninvoiceProgress = false;
        });
    }

    removeGreeninvoice () {
      if (vm.removeGreeninvoiceProgress) { return; }
      vm.removeGreeninvoiceProgress = true;
      return settingsService.removeGreeninvoice()
        .then((response) => {
          $rootScope.$broadcast('ownerSaved');
          utils.notify('החשבון הוסר', 'alert-success');
          vm.modalInstance.close();
        })
        .catch((error) => {
          utils.notify('שגיאה', 'alert-danger');
          console.log(error);
        })
        .finally(() => {
          vm.removeGreeninvoiceProgress = false;
        });
    }
  }

  return new LinkedAccountsCtrl();
}
