/* global localStorage */
export default function ($scope, $rootScope, $state, $stateParams, $filter, $uibModal, projectService, settingsService, userService, utils) {
  let vm;

  class ProgressProjectsReportCtrl {
    constructor () {
      vm = this;
      vm.name = 'progressProjectsReport';

      vm.requestCounter = 0;
      vm.orderBy = localStorage.getItem('progressProjectsReportOrderBy') || 'createdAt';
      vm.statusOptions = settingsService.getOwnerProjectStatusList();
      vm.inProgressStatusList = ['בהכנה', 'בטיפול', 'מושהה'];
      vm.inProgressStatusListString = vm.inProgressStatusList.join('|');
      vm.inProgressStatusList = vm.inProgressStatusList.filter((status) => vm.statusOptions.includes(status));

      vm.tableFilters = {
        status: vm.inProgressStatusListString
      };
    }

    $onInit () {
      vm.agentList = angular.copy(_.get($rootScope, 'owner.users') || []);

      vm.getProjectList();
    }

    getProjectList () {
      const args = {
        filter: {
          status: vm.tableFilters.status
        },
        limit: 200
      };

      vm.loading = true;
      vm.requestCounter++;
      const requestCounter = vm.requestCounter;
      vm.projects = {};
      return projectService.getProjectList(false, args)
        .then((res) => {
          if (requestCounter >= vm.requestCounter) {
            res.rows.forEach((project) => {
              if (!_.isArray(vm.projects[project.agent])) {
                vm.projects[project.agent] = [];
              }
              vm.projects[project.agent].push(project);
            });

            vm.res = res;
          }
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    orderByChanged () {
      localStorage.setItem('progressProjectsReportOrderBy', vm.orderBy);
    }

    agentsOrderBy (agent) {
      return 0 - (_.get(vm.projects, agent.id + '.length') || 0);
    }
  }

  return new ProgressProjectsReportCtrl();
}
