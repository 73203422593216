import dayjs from 'dayjs';

export default function ($scope, $rootScope, $uibModal, $timeout, dragularService, projectService, settingsService, userService, utils, permissions) {
  let vm;

  class ProjectBoardCtrl {
    constructor () {
      vm = this;
      vm.name = 'ProjectBoard';

      vm.statusObject = {};
      settingsService.getOwnerProjectStatusList()
        .forEach((status) => {
          vm.statusObject[status] = {
            color: projectService.getProjectStatusColor(status),
            count: 0,
            list: [],
            collapsed: isColumnCollapsed(status)
          };
        });
      vm.ownerTagList = settingsService.getOwnerProjectTags();
      vm.dateFilterOptions = [
        { value: '&gt:' + dayjs().subtract(1, 'week').toISOString(), label: 'שבוע אחרון' },
        { value: '&gt:' + dayjs().subtract(1, 'month').toISOString(), label: 'חודש אחרון' }
      ];
      [3, 6, 12].forEach((num) => {
        vm.dateFilterOptions.push({
          value: '&gt:' + dayjs().subtract(num, 'month').toISOString(), label: num + ' חודשים אחרונים'
        });
      });

      vm.filters = {
        agent: '',
        tags: []
      };
    }

    $onInit () {
      vm.getListRequestCounter = {};
      $timeout(() => {
        $rootScope.showFooter = false;
      });
      vm.agentList = _.get($rootScope, 'owner.users') || [];
      if (vm.agentList.length > 1 && !$rootScope.isManager()) {
        vm.filters.agent = $rootScope.user.id;
      }

      vm.initialGetList()
        .then(() => {
          dragularService(document.querySelectorAll('.board-projects'), {
            scope: $scope,
            containersModel: () => vm.dragularModelList,
            classes: { mirror: 'project-dragged-mirror' },
            mirrorContainer: document.querySelector('.board'),
            nameSpace: 'column',
            moves: (element) =>
              !element.classList.contains('status-list-empty') &&
              !element.classList.contains('status-list-loading')
          });
        });

      $scope.$on('dragulardrag', (event, el, container) => {
        vm.isDragging = true;
      });
      $scope.$on('dragulardragend', (event, el, container) => {
        setTimeout(() => {
          vm.isDragging = false;
        }, 300);
      });

      $scope.$on('dragulardrop', (event, element, target, source, model, previousModelIndexInColumn) => {
        vm.saveInProgress = true;
        $timeout(async () => {
          const { projectId } = element.dataset;
          const previousStatus = source.dataset.columnStatus;
          const newStatus = target.dataset.columnStatus;
          vm.updateStatus(projectId, previousStatus, newStatus, previousModelIndexInColumn);
        });
      });
    }

    async initialGetList () {
      Object.values(vm.statusObject).forEach((status) => { status.list = []; });
      vm.getProjectListCounts();
      await Promise.all(Object.keys(vm.statusObject).map((status) => {
        return vm.getProjectList({
          filter: { status }
        }, true);
      }));
    }

    getProjectList (args, cleanList) {
      const status = args.filter.status;
      vm.statusObject[status].loading = true;

      const defaults = {
        limit: 25,
        order: 'createdAt',
        desc: true,
        offset: cleanList ? 0 : vm.statusObject[status].list.length
      };
      if (!_.isEmpty(vm.filters)) {
        defaults.filter = vm.filters;
      }
      args = _.merge(defaults, args); // is merging deep.

      if (!vm.getListRequestCounter[status]) {
        vm.getListRequestCounter[status] = 0;
      }
      vm.getListRequestCounter[status]++;
      const getListRequestCounter = vm.getListRequestCounter[status];
      return projectService.getProjectList(false, args)
        .then((results) => {
          if (vm.getListRequestCounter[status] <= getListRequestCounter) {
            vm.statusObject[status].count = results.count;
            if (cleanList) {
              vm.statusObject[status].list = results.rows;
            } else {
              vm.statusObject[status].list.push(...results.rows);
            }
          }
        })
        .finally(() => {
          if (vm.getListRequestCounter[status] <= getListRequestCounter) {
            vm.dragularModelList = Object.values(vm.statusObject)
              .map((status) => status.list);

            $timeout(() => {
              vm.statusObject[status].loading = false;
            }, 400);
          }
        });
    }

    async getProjectListCounts () {
      let dateFilter;
      if (vm.filters.createdAt) {
        dateFilter = vm.filters.createdAt.replace(/^&gt:/, '');
      }
      const counts = await projectService.getProjectListCounts(vm.filters.agent, dateFilter);
      Object.keys(vm.statusObject).forEach((status) => {
        vm.statusObject[status].count = (counts.find((item) => item.status === status) || {}).count || 0;
      });
    }

    async updateStatus (projectId, previousStatus, newStatus, previousModelIndexInColumn) {
      if (!projectId || previousStatus === newStatus) {
        return;
      }
      const project = vm.dragularModelList.flat().find((project) => project.id === projectId);

      try {
        if (newStatus === 'מבוטל' && project.toPayTotal > 0) {
          await vm.alertBeforeCancelProject(project);
        }

        project.status = newStatus;
        await projectService.saveProject(projectId, { status: newStatus });
        vm.getProjectListCounts();
      } catch (error) {
        project.status = previousStatus;
        // move back the project to previous status column, & in the old index place, using previousModelIndexInColumn.
        const newStatusModelList = vm.dragularModelList[Object.keys(vm.statusObject).indexOf(newStatus)];
        const prevStatusModelList = vm.dragularModelList[Object.keys(vm.statusObject).indexOf(previousStatus)];
        newStatusModelList.splice(newStatusModelList.indexOf(project), 1);
        prevStatusModelList.splice(previousModelIndexInColumn, 0, project);
        $timeout(() => $scope.$apply());
      }
      vm.saveInProgress = false;
    }

    async alertBeforeCancelProject (project) {
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: 'קיים חוב לתשלום בפרויקט',
        message: `במידה והפרויקט יבוטל החוב ימחק מדוח התשלומים.
        האם להמשיך בביטול הפרויקט?`,
        buttons: [{
          label: 'לא'
        },
        {
          label: 'כן, לבטל את הפרויקט',
          btnClass: 'btn-danger',
          onClick ($uibModalInstance) {
            $uibModalInstance.close();
            return true;
          }
        }]
      };
      return utils.modalAlert(modalParams);
    }

    toggleCollapsedColumn (status) {
      const collapsed = vm.statusObject[status].collapsed = !vm.statusObject[status].collapsed;
      localStorage.setItem(`board-${status}-collapsed`, collapsed);
      $timeout(() => { $scope.$apply(); });
    }

    getProjectDueDateClass (project) {
      if (
        project.status &&
        !['הצעה', 'הושלם', 'מבוטל'].includes(project.status) &&
        dayjs(project.dueDate).isBefore(dayjs().startOf('day').add(3, 'days'))
      ) {
        return 'text-danger';
      }
      return '';
    }

    onAgentChanged (selectedAgent) {
      vm.filters.agent = selectedAgent;
      vm.initialGetList();
    }

    updateDateFilter (value) {
      vm.filters.createdAt = value;
      vm.initialGetList();
    }

    tagStyle (name) {
      const tag = vm.ownerTagList.find((tag) => tag.title === name);
      return `background: ${_.get(tag, 'background') || '#afafaf'}; color: ${_.get(tag, 'color') || 'black'}`;
    }

    getProjectAgentName (project) {
      const agent = $rootScope.userById(project.agent);
      if (!agent) { return; }
      return `${agent.firstName || ''} ${agent.lastName || ''}`.trim();
    }

    getUserProfileImage (agent) {
      return userService.getUserProfileImage(agent);
    }
  }

  return new ProjectBoardCtrl();
}

const defaultCollapsedColumns = ['הצעה', 'בליווי', 'מושהה', 'מבוטל'];

function isColumnCollapsed (status) {
  const savedState = localStorage.getItem(`board-${status}-collapsed`);
  if (!savedState) {
    return defaultCollapsedColumns.includes(status);
  } else {
    return savedState === 'true';
  }
}
