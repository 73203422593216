export default function ($scope, $state, $uibModal, customerService, $stateParams) {
  let vm;
  const uiSref = `customer.customerShow({
    id: row.id,
    navigation: {
      query: {
        filter: vm.filter,
        order: vm.paging.orderBy,
        desc: vm.paging.orderDesc
      },
      sref: 'customer.customerList'
    },
    navigationIndex: vm.paging.offset + rowIndex
  })`.replace(/\s+/g, '');

  class CustomerListCtrl {
    constructor () {
      vm = this;
      vm.name = 'customerList';
      vm.$stateParams = $stateParams;

      vm.tableHeadList = [
        { name: 'number', label: 'מספר לקוח', sortable: true, uiSref },
        { name: 'name', label: 'שם לקוח', sortable: true, uiSref },
        { name: 'idNumber', label: 'מספר זהות', sortable: true, uiSref },
        { name: 'address', label: 'כתובת', sortable: true, uiSref },
        { name: 'city', label: 'עיר', sortable: true, uiSref },
        { name: 'phone', label: 'טלפון', uiSref, elementClass: 'phone' },
        { name: 'email', label: 'דוא"ל', uiSref, elementClass: 'email' }
      ];
    }

    $onInit () {
      $scope.$on('customerSaved', () => {
        vm.getCustomerList();
      });
    }

    getCustomerList (args) {
      return customerService.getCustomerList(args);
    }

    showCustomerLink (id) {
      return uiSref;
    }

    addCustomerForm () {
      $uibModal.open({
        component: 'customerEdit',
        resolve: {
          onSaveCustomer: () => {
            return (params) => {
              $state.go('customer.customerShow', { id: params.customerId });
            };
          }
        },
        windowClass: 'customer-edit-modal mobile-modal',
        size: 'lg',
        backdrop: 'static'
      });
    }
  }

  return new CustomerListCtrl();
}
