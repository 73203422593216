export default function (projectService, utils) {
  let vm;

  class ExcelImportController {
    constructor () {
      vm = this;
    }

    validate () {
      return projectService.validateExcel(vm.file)
        .then((data) => {
          vm.warnings = data.warnings;
          vm.data = data.data;
          vm.errors = data.errors;
        });
    }

    fileChanged () {
      delete vm.data;
      delete vm.warnings;
      delete vm.errors;
      vm.showData = false;
    }

    import () {
      vm.importProgress = true;
      return projectService.importExcel(vm.file)
        .then((data) => {
          console.log(data);
          utils.notify('ייבוא פרויקטים הושלם‎', 'alert-success');
        })
        .catch((e) => {
          console.error(e);
          utils.notify('שגיאה!', 'alert-error');
        })
        .finally(() => {
          vm.importProgress = false;
        });
    }

    cancel () {
      vm.file = null;
      vm.fileChanged();
    }

    columnHasWarning (name) {
      return vm.warnings.find((warning) => warning.column === name && warning.row === 0);
    }

    getItemWarning (column, row) {
      return vm.warnings.find((warning) => warning.column === column && warning.row === row);
    }
  }

  return new ExcelImportController();
}
