// import optionsList from '../project-options-list';

const fields = ['id', 'city', 'street', 'house', 'apartmentNumber', 'gush', 'helka', 'subHelka', 'plot', 'x', 'y'];

export default function ($scope, $rootScope, $state, $timeout, utils, projectService, govmapService) {
  let vm;

  class ProjectAddressEditCtrl {
    constructor () {
      vm = this;
      vm.loadProgress = true;
      vm.gushRequestCounter = 0;
      vm.coordinatesRequestCounter = 0;
    }

    $onInit () {
      const projectId = vm.resolve.projectId;
      const project = vm.resolve.project;
      vm.projectFields = {};

      if (project) {
        fields.forEach((field) => {
          vm.projectFields[field] = project[field];
        });

        vm.loadProgress = false;
      } else if (projectId) {
        vm.getProject(projectId)
          .then(() => {
            vm.loadProgress = false;
          });
      } else {
        vm.loadProgress = false;
      }
    }

    getProject (projectId) {
      return projectService.getProject(projectId)
        .then((project) => {
          fields.forEach((field) => {
            vm.projectFields[field] = project[field];
          });

          return vm.projectFields;
        });
    }

    saveProject () {
      if (!vm.form.$valid) {
        $timeout(() => {
          document.querySelector('form').reportValidity();
          utils.setFormFieldsDirty(vm.form);
        }, 400);
        return;
      }
      if (vm.saveProgress) {
        return;
      }
      vm.saveProgress = true;
      projectService.saveProject(vm.projectFields.id, vm.projectFields)
        .then((result) => {
          const message = 'פרטי כתובת פרויקט נשמרו';
          utils.notify(message, 'alert-success');

          $rootScope.$broadcast('projectSaved');
          vm.modalInstance.close();
        })
        .catch((error) => {
          console.log('save project failed', error);
          utils.notify('שגיאה בעת שמירת כתובת פרויקט', 'alert-danger');
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    closeModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    getCoordinates () {
      vm.coordinatesRequestCounter++;
      const requestCounter = vm.coordinatesRequestCounter;
      const addressQuery = [
        vm.projectFields.street || '',
        String(vm.projectFields.house || '').trim().replace(/\D[^>]*/g, ''), // select only first number
        vm.projectFields.city || ''
      ].filter((field) => !!field).join(' ').trim();

      if (!vm.projectFields.city || !addressQuery.length) {
        return;
      }

      govmapService.getCoordinates(addressQuery)
        .then((coordinates) => {
          if (vm.coordinatesRequestCounter > requestCounter) {
            return;
          }
          vm.projectFields.x = coordinates.x;
          vm.projectFields.y = coordinates.y;
        });
    }

    getGushHelka () {
      vm.gushRequestCounter++;
      const requestCounter = vm.gushRequestCounter;
      const addressQuery = (vm.projectFields.street || '') + ' ' +
        String(vm.projectFields.house || '').trim().replace(/\D[^>]*/g, '') + ' ' + // select only first number
        (vm.projectFields.city || '');

      if (!vm.projectFields.city || !addressQuery.length) {
        return;
      }

      govmapService.getGushHelka(addressQuery)
        .then((gushArray) => {
          if (vm.gushRequestCounter > requestCounter) {
            return;
          }
          $timeout(() => {
            $scope.$apply(() => {
              vm.projectFields.gush = gushArray[0] || vm.projectFields.gush;
              vm.projectFields.helka = gushArray[1] || vm.projectFields.helka;
              vm.projectFields.subHelka = gushArray[2] || vm.projectFields.subHelka;
            });
          });
        });
    }

    onAddressChanged () {
      vm.getGushHelka(); vm.getCoordinates();
    }
  }

  return new ProjectAddressEditCtrl();
}
