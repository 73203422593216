import template from './admin-ticket-list.html';
import controller from './admin-ticket-list.controller';
import './admin-ticket-list.scss';

const adminTicketListComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$filter', 'adminService', 'ticketService'];

export default adminTicketListComponent;
