import template from './shamaut-machria.html';
import controller from './shamaut-machria.controller';
import './shamaut-machria.scss';

const shamautMachriaComponent = {
  bindings: {
    gush: '@'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', 'nadlanService'];

export default shamautMachriaComponent;
