/* global localStorage */
import formatDate from './format-date/format-date';
import imagePreview from './image-preview/image-preview';
import inputImage from './input-image/input-image';
import inputNumber from './input-number/input-number';
import ngRightClick from './ng-right-click/ng-right-click';
import regexInput from './regex-input/regex-input';
import selectOther from './select-other/select-other';
import uiSelectBlur from './ui-select-blur/ui-select-blur';

const $ = window.jQuery;

const directiveModule = angular.module('app.directives', [
  formatDate,
  imagePreview,
  inputImage,
  inputNumber,
  ngRightClick,
  regexInput,
  selectOther,
  uiSelectBlur
])
  .directive('pageTitle', ['$rootScope', '$timeout', pageTitle])
  .directive('minimizeMenu', ['$rootScope', minimizeMenu])
  .directive('icheck', ['$timeout', icheck])
  .directive('panelTools', ['$timeout', panelTools])
  .directive('panelToolsFullscreen', ['$timeout', panelToolsFullscreen])
  .directive('smallHeader', [smallHeader])
  .directive('animatePanel', ['$timeout', '$state', animatePanel])
  .directive('landingScrollspy', [landingScrollspy])
  .name;

export default directiveModule;

/**
 * pageTitle - Directive for set Page title - mata title
 */
function pageTitle ($rootScope, $timeout) {
  return {
    link (scope, element, attrs) {
      document.title = [attrs.pageTitle, 'Amend'].filter((i) => i).join(' | ');
    }
  };
}

/**
 * minimalizaSidebar - Directive for minimalize sidebar
 */
function minimizeMenu ($rootScope) {
  return {
    restrict: 'EA',
    template: '<div class="header-link hide-menu" ng-click="minimalize()"><i class="feather-icon icon-menu"></i></div>',
    controller: ['$scope', '$element', ($scope, $element) => {
      $scope.minimalize = function () {
        if (jQuery(window).width() < 769) {
          jQuery('body').toggleClass('show-sidebar');
        } else {
          jQuery('body').toggleClass('hide-sidebar');
        }
      };
    }]
  };
}

/**
 * icheck - Directive for custom checkbox icheck
 */
function icheck ($timeout) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link ($scope, element, $attrs, ngModel) {
      return $timeout(() => {
        const value = $attrs.value;

        $scope.$watch($attrs.ngModel, (newValue) => {
          $(element).iCheck('update');
        });

        return $(element).iCheck({
          checkboxClass: 'icheckbox_flat-blue',
          radioClass: 'iradio_flat-blue'

        })
          .on('ifChanged', (event) => {
            if (jQuery(element).attr('type') === 'checkbox' && $attrs.ngModel) {
              $scope.$apply(() => {
                return ngModel.$setViewValue(event.target.checked);
              });
            }
            if (jQuery(element).attr('type') === 'radio' && $attrs.ngModel) {
              return $scope.$apply(() => {
                return ngModel.$setViewValue(value);
              });
            }
          });
      });
    }
  };
}

/**
 * panelTools - Directive for panel tools elements in right corner of panel
 */
function panelTools ($timeout) {
  return {
    restrict: 'A',
    scope: {
      controllers: '@?'
    },
    bindToController: true,
    controllerAs: 'vm',
    templateUrl: 'views/common/panel_tools.html',
    controller: ['$scope', '$element', function ($scope, $element) {
      // Function for collapse ibox
      const vm = this;
      vm.$onInit = function () {
        if (!vm.controllers) {
          return;
        }
        const controllerArray = vm.controllers.trim().split(' ');
        vm.controllerList = {};
        controllerArray.forEach((item) => {
          if (item) {
            vm.controllerList[item] = true;
          }
        });
      };

      vm.showhide = function () {
        const hpanel = $element.closest('div.hpanel');
        const icon = $element.find('i:first');
        const body = hpanel.find('div.panel-body');
        const footer = hpanel.find('div.panel-footer');
        body.slideToggle(300);
        footer.slideToggle(200);
        // Toggle icon from up to down
        icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        hpanel.toggleClass('').toggleClass('panel-collapse');
        $timeout(() => {
          hpanel.resize();
          hpanel.find('[id^=map-]').resize();
        }, 50);
      };

      // Function for close ibox
      vm.closebox = function () {
        const hpanel = $element.closest('div.hpanel');
        hpanel.remove();
      };
    }]
  };
}

/**
 * panelToolsFullscreen - Directive for panel tools elements in right corner of panel with fullscreen option
 */
function panelToolsFullscreen ($timeout) {
  return {
    restrict: 'A',
    scope: true,
    bindToController: true,
    controllerAs: 'vm',
    templateUrl: 'views/common/panel_tools_fullscreen.html',
    controller: ['$scope', '$element', function ($scope, $element) {
      const vm = this;
      // Function for collapse ibox
      vm.showhide = function () {
        const hpanel = $element.closest('div.hpanel');
        const icon = $element.find('i:first');
        const body = hpanel.find('div.panel-body');
        const footer = hpanel.find('div.panel-footer');
        body.slideToggle(300);
        footer.slideToggle(200);
        // Toggle icon from up to down
        icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        hpanel.toggleClass('').toggleClass('panel-collapse');
        $timeout(() => {
          hpanel.resize();
          hpanel.find('[id^=map-]').resize();
        }, 50);
      };

      // Function for close ibox
      vm.closebox = function () {
        const hpanel = $element.closest('div.hpanel');
        hpanel.remove();
        if (jQuery('body').hasClass('fullscreen-panel-mode')) {
          jQuery('body').removeClass('fullscreen-panel-mode');
        }
      };

      // Function for fullscreen
      vm.fullscreen = function () {
        const hpanel = $element.closest('div.hpanel');
        const icon = $element.find('i:first');
        jQuery('body').toggleClass('fullscreen-panel-mode');
        icon.toggleClass('fa-expand').toggleClass('fa-compress');
        hpanel.toggleClass('fullscreen');
        setTimeout(() => {
          jQuery(window).trigger('resize');
        }, 100);
      };
    }]
  };
}

/**
 * smallHeader - Directive for page title panel
 */
function smallHeader () {
  return {
    restrict: 'A',
    scope: true,
    controller: ['$scope', '$element', '$timeout', ($scope, $element, $timeout) => {
      const icon = $element.find('i:first');
      const breadcrumb = $element.find('#hbreadcrumb');

      $scope.small = function () {
        $scope.smallHeader ? $scope.removeSmall() : $scope.addSmall();
        localStorage.setItem('smallHeader', $scope.smallHeader);
      };

      $scope.addSmall = function () {
        $scope.smallHeader = true;
        $element.addClass('small-header');
        $timeout(() => { breadcrumb.removeClass('m-t-lg'); });
        icon.removeClass('fa-arrow-up').addClass('fa-arrow-down');
      };

      $scope.removeSmall = function () {
        $scope.smallHeader = false;
        $element.removeClass('small-header');
        $timeout(() => { breadcrumb.addClass('m-t-lg'); });
        icon.addClass('fa-arrow-up').removeClass('fa-arrow-down');
      };

      $scope.smallHeader = localStorage.getItem('smallHeader') !== 'true';
      $scope.small();
    }]
  };
}

function animatePanel ($timeout, $state) {
  return {
    restrict: 'A',
    link (scope, element, attrs) {
      if (attrs.effect === 'none') {
        return;
      }
      // Set defaul values for start animation and delay
      const startAnimation = 0;
      let delay = 0.06; // secunds
      let start = Math.abs(delay) + startAnimation;

      // Store current state where directive was start
      // let currentState = $state.current.name;

      // Set default values for attrs
      if (!attrs.effect) { attrs.effect = 'zoomIn'; }
      if (attrs.delay) { delay = attrs.delay / 10; } else { delay = 0.06; }
      if (!attrs.child) { attrs.child = '.row > div'; } else { attrs.child = '.' + attrs.child; }

      // Get all visible element and set opactiy to 0
      let panel = element.find(attrs.child);
      panel.addClass('opacity-0');

      // Count render time
      // let renderTime = panel.length * delay * 1000 + 700;

      // Wrap to $timeout to execute after ng-repeat
      $timeout(() => {
        // Get all elements and add effect class
        panel = element.find(attrs.child);
        panel.addClass('stagger').addClass('animated-panel').addClass(attrs.effect);

        const panelsCount = panel.length + 10;
        const animateTime = (panelsCount * delay * 10000) / 10;

        // Add delay for each child elements
        panel.each((i, elm) => {
          start += delay;
          const rounded = Math.round(start * 10) / 10;
          jQuery(elm).css('animation-delay', rounded + 's');
          // Remove opacity 0 after finish
          jQuery(elm).removeClass('opacity-0');
        });

        // Clear animation after finish
        $timeout(() => {
          jQuery('.stagger').css('animation', '');
          jQuery('.stagger').removeClass(attrs.effect).removeClass('animated-panel').removeClass('stagger');
          panel.resize();
        }, animateTime);
      });
    }
  };
}

function landingScrollspy () {
  return {
    restrict: 'A',
    link (scope, element, attrs) {
      element.scrollspy({
        target: '.navbar-fixed-top',
        offset: 80
      });
    }
  };
}
