import template from './add-project-button.html';
import './add-project-button.scss';

const addProjectButtonDirective = [function () {
  return {
    restrict: 'EA',
    replace: true,
    controllerAs: 'vm',
    scope: {},
    template,
    controller: ['$scope', '$uibModal', function ($scope, $uibModal) {
      const vm = this;

      vm.addProjectForm = function () {
        $uibModal.open({
          component: 'projectEdit',
          windowClass: 'project-edit-modal mobile-modal',
          size: 'lg',
          backdrop: 'static'
        });
      };
    }]
    // link (scope, element, attrs, vm) { }
  };
}];

export default addProjectButtonDirective;
