import template from './real-estate-information-search.html';
import controller from './real-estate-information-search.controller';
import './real-estate-information-search.scss';

const realEstateInformationSearchComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['realEstateInformationService'];

export default realEstateInformationSearchComponent;
