import template from './owner-edit.html';
import controller from './owner-edit.controller';
import './owner-edit.scss';

const ownerEditComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'utils', 'ownerService'];

export default ownerEditComponent;
