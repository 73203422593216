export default function ($scope, $rootScope, $state, $stateParams, $uibModal, notify, customerService, projectService, utils) {
  let vm;

  class CustomerShowCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      $scope.$on('customerSaved', () => {
        vm.getCustomer(vm.customerFields.id);
      });

      vm.customerFields = {};
      vm.initCustomer(vm.customer);
      vm.navigation = $stateParams.navigation;
      vm.navigationIndex = $stateParams.navigationIndex;
      if (_.get(vm.navigation, 'sref') === 'search') {
        vm.returnToSref = `search({
          type: vm.navigation.type,
          query: '${encodeURIComponent(JSON.stringify(vm.navigation.query.filter).replace(/:"%/g, ':"'))}',
          index: vm.navigationIndex
        })`.replace(/\s+/g, '');
      } else if (_.get(vm.navigation, 'sref')) {
        vm.returnToSref = `${vm.navigation.sref}({
          query: vm.navigation.query,
          index: vm.navigationIndex
        })`.replace(/\s+/g, '');
      }

      customerService.getCustomerSiblings(vm.customerFields.id, _.get(vm.navigation, 'query'), vm.navigationIndex)
        .then((siblings) => {
          vm.previousCustomer = siblings.prev;
          vm.nextCustomer = siblings.next;
        });
    }

    getCustomer (customerId) {
      vm.getCustomerProgress = true;
      return customerService.getCustomer(customerId)
        .then((customer) => {
          vm.initCustomer(customer);
        })
        .finally(() => {
          vm.getCustomerProgress = false;
        });
    }

    initCustomer (customer) {
      if (!customer) {
        $state.go('customer.customerList');
        return;
      }
      Object.keys(customer).forEach((field) => {
        vm.customerFields[field] = customer[field];
      });

      return vm.customerFields;
    }

    editCustomerForm () {
      $uibModal.open({
        component: 'customerEdit',
        resolve: {
          customer: () => vm.customerFields
        },
        windowClass: 'customer-edit-modal mobile-modal',
        size: 'md',
        backdrop: 'static'
      });
    }

    deleteCustomer (id) {
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: 'האם ברצונך למחוק את הלקוח?        ',
        message: 'לא ניתן יהיה לשחזר את הפעולה.',
        buttons: [{
          label: 'בטל',
          btnClass: 'btn-default',
          onClick ($uibModalInstance) {
            $uibModalInstance.dismiss('cancel');
          }
        },
        {
          label: 'מחק את הלקוח',
          btnClass: 'btn-primary',
          onClick ($uibModalInstance) {
            customerService.deleteCustomer(id)
              .then(() => {
                utils.notify('הלקוח נמחק בהצלחה', 'alert-success');
                $state.go('customer.customerList');
              })
              .catch((e) => {
                utils.notify('שגיאה', 'alert-danger');
                console.error(e);
              });
            $uibModalInstance.close();
          }
        }]
      };

      utils.modalAlert(modalParams);
    }

    addProjectForm () {
      $uibModal.open({
        component: 'projectEdit',
        windowClass: 'project-edit-modal mobile-modal',
        resolve: {
          customerId: () => vm.customerFields.id,
          hideCustomer: () => true
        },
        size: 'lg',
        backdrop: 'static'
      });
    }

    gushHelkaString (row) {
      return [row.gush, row.helka, row.subHelka].filter((v) => v).join('-');
    }

    statusColor (status) {
      return projectService.getProjectStatusColor(status);
    }

    projectUiSref (projectId) {
      return `project.projectShow({
        id: '${projectId}',
        navigation: {
          query: {
            filter: {customerId: '${vm.customerFields.id}'},
            order: 'createdAt',
            desc: false
          },
          sref: 'customer.customerShow',
          srefParams: '${JSON.stringify($stateParams)}'
        },
        navigationIndex: $index
      })`.replace(/\s+/g, '');
    }
  }

  return new CustomerShowCtrl();
}
