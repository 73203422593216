import dayjs from 'dayjs';

import optionList from '../../project-options-list';
import transactionModalTemplate from './transaction-modal-template.html';

export default function ($scope, $rootScope, $state, $timeout, $uibModal, request, utils, projectService, permissions) {
  let vm;

  class ProjectPaymentsCtrl {
    constructor () {
      vm = this;
      vm.permissions = permissions;
      vm.priceList = [];
      vm.transactionList = [];
      vm.vat = 0.17;
      vm.transactionTypeList = optionList.transactionTypeList;
      vm.transactionCardBrandsList = optionList.transactionCardBrandsList;
      vm.transactionCardDealsList = optionList.transactionCardDealsList;
      vm.transactionAppTypes = optionList.transactionAppTypes;
      vm.transactionVatList = optionList.transactionVatList;
      vm.transactionTypeDetails = optionList.transactionTypeDetails;
    }

    $onInit () {
      vm.paymentDetailsProtected = _.get(vm.projectShowCtrl.projectFields, 'metadata.paymentDetailsProtected');
      if (!$rootScope.isManager() && vm.paymentDetailsProtected) {
        $state.go('project.projectShow', { id: vm.projectShowCtrl.projectFields.id });
      }

      vm.hasGreeninvoiceModule = !!_.get($rootScope, 'owner.crmSettings.hasGreeninvoiceModule');
      // vm.rowsIsExpanded = true;
      vm.projectId = vm.projectShowCtrl.projectFields.id;
      $scope.$watch('vm.projectShowCtrl.projectFields.prices', (newValue, oldValue) => {
        vm.priceList = newValue || [];
        vm.priceTotal = vm.priceList.reduce((total, price) => total + Number(price.sum) + vm.getPriceVat(price), 0) || 0;
        vm.priceTotalVat = vm.priceList.reduce((total, price) => total + vm.getPriceVat(price), 0) || 0;
      });

      $scope.$watch('vm.projectShowCtrl.projectFields.transactions', (newValue, oldValue) => {
        vm.transactionList = newValue || [];
        vm.transactionList.forEach((transaction) => {
          if (!_.get(transaction, 'details.date')) {
            _.set(transaction, 'details.date', transaction.createdAt);
          }
        });
        vm.transactionTotal = vm.transactionList.reduce((total, transaction) => {
          return total + transaction.sum;
        }, 0) || 0;
      });

      $scope.$on('transactionSaved', () => {
        vm.getTransactionList();
      });
    }

    fixed (number) {
      return Number(Number(number).toFixed(2));
    }

    closeModalBox () {
      if (_.get(vm, '$uibModalInstance.dismiss')) {
        vm.$uibModalInstance.dismiss('cancel');
      }
    }

    getPriceVat (price) {
      if (price.sum > 0 && price.excludeVat) {
        return price.sum * vm.vat;
      }
      return 0;
    }

    priceEditForm () {
      $uibModal.open({
        component: 'projectPaymentsPrices',
        resolve: {
          projectFields: () => vm.projectShowCtrl.projectFields,
          isForm: true
        },
        windowClass: 'price-edit-modal mobile-modal',
        size: 'lg',
        backdrop: 'static'
      });
    }

    getTransactionList () {
      return projectService.getProjectTransactionList(vm.projectId)
        .then((transactionList) => {
          vm.projectShowCtrl.projectFields.transactions = transactionList;
        });
    }

    editTransactionForm (transaction, expand) {
      if (_.get(transaction, 'invoiceDocumentUrl')) {
        return;
      }
      $uibModal.open({
        template: transactionModalTemplate,
        controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
          this.$onInit = function () {
            $scope.vm = vm;
            vm.$uibModalInstance = $uibModalInstance;
            if (transaction) {
              vm.transaction = angular.copy(transaction);
              vm.transactionIsExpanded = !!transaction.type || expand;
            } else {
              vm.transaction = {
                vat: 1,
                details: {
                  documentDescription: '',
                  date: dayjs()
                }
              };
              vm.transactionIsExpanded = false;
              if (vm.priceTotal && (vm.priceTotal > vm.transactionTotal)) {
                vm.transaction.sum = Number((vm.priceTotal - vm.transactionTotal).toFixed(2));
              } else {
                vm.transaction.sum = '';
              }
            }
            vm.transaction.details.customerInvoiceName = vm.transaction.details.customerInvoiceName || _.get(vm.projectShowCtrl, 'projectFields.customer.name');
            vm.transaction.details.customerInvoiceEmail = vm.transaction.details.customerInvoiceEmail || _.get(vm.projectShowCtrl, 'projectFields.customer.email');
          };
          this.$onDestroy = function () {
            vm.transaction = undefined;
          };

          vm.saveTransaction = function (openInvoicePreview) {
            if (vm.saveTransactionProgress) { return; }
            if (vm.transactionForm.$invalid) {
              document.getElementById('transaction-form').reportValidity();
              utils.setFormFieldsDirty(vm.transactionForm);
              return;
            }
            vm.saveTransactionProgress = true;
            projectService.saveProjectTransaction(vm.projectId, (vm.transaction.id || null), vm.transaction)
              .then((transaction) => {
                $uibModalInstance.close();
                $rootScope.$broadcast('transactionSaved');
                if (openInvoicePreview) {
                  vm.createInvoiceForm(transaction);
                }
              })
              .finally(() => {
                vm.saveTransactionProgress = false;
              });
          };
        }],
        windowClass: 'transaction-edit-modal mobile-modal',
        size: 'lg',
        backdrop: 'static'
      });
    }

    confirmDeleteTransaction (transaction) {
      if (transaction.invoiceDocumentUrl && !$rootScope.isAdmin()) {
        return;
      }
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: 'האם למחוק תשלום?',
        message: 'לא ניתן יהיה לשחזר את הפעולה.',
        buttons: [{
          label: 'בטל',
          btnClass: 'btn-default',
          onClick ($uibModalInstance) {
            $uibModalInstance.dismiss('cancel');
          }
        },
        {
          label: 'מחק תשלום',
          btnClass: 'btn-primary',
          onClick ($uibModalInstance) {
            vm.deleteTransaction(transaction);
            $uibModalInstance.close();
          }
        }]
      };

      utils.modalAlert(modalParams);
    }

    deleteTransaction (transaction) {
      projectService.deleteProjectTransaction(vm.projectId, transaction.id)
        .then((result) => {
          const transactionIndex = vm.projectShowCtrl.projectFields.transactions.indexOf(transaction);
          vm.projectShowCtrl.projectFields.transactions.splice(transactionIndex, 1);
          $rootScope.$broadcast('transactionSaved');
        });
    }

    getTransactionDetailsValue (transaction, field) {
      if (field === 'cardBrands') {
        return vm.transactionCardBrandsList[_.get(transaction, 'details.' + field) || ''];
      } else if (field === 'cardDeals') {
        return vm.transactionCardDealsList[_.get(transaction, 'details.' + field) || ''];
      } else if (field === 'paymentAppAppType') {
        return vm.transactionAppTypes[_.get(transaction, 'details.' + field) || ''];
      } else {
        return _.get(transaction, 'details.' + field) || '';
      }
    }

    createInvoiceForm (transaction) {
      if (!transaction || transaction.invoiceDocumentUrl) {
        return;
      }
      $uibModal.open({
        component: 'paymentsInvoicePreview',
        resolve: {
          transaction: () => transaction,
          project: () => vm.projectShowCtrl.projectFields,
          editTransactionFn: () => {
            return vm.editTransactionForm;
          }
        },
        windowClass: 'payments-invoice-preview-modal mobile-modal',
        size: 'lg',
        backdrop: 'static'
      });
    }

    invoiceLink (transactionId) {
      return window.SERVER_URL + '/project/' + vm.projectId + '/transaction/' + transactionId + '/invoice';
    }

    togglePaymentDetailsProtected () {
      vm.paymentDetailsProtected = !vm.paymentDetailsProtected;
      _.set(vm.projectShowCtrl.projectFields, 'metadata.paymentDetailsProtected', vm.paymentDetailsProtected);
      projectService.saveProjectMetadata(vm.projectId, { paymentDetailsProtected: vm.paymentDetailsProtected });
    }
  }

  return new ProjectPaymentsCtrl();
}
