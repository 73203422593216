import optionLists from '../../../project/project-options-list';

export default function ($scope, $rootScope, $uibModal, settingsService, request, utils) {
  let vm;

  class ProjectOptionsDetailsCtrl {
    constructor () {
      vm = this;
      vm.projectOptionLists = [
        // {
        //   name: 'referrerOptions',
        //   title: 'גורם מפנה',
        //   optionList: optionLists.referrerOptions.map((option) => option.name)
        // }
      ];
      vm.selectedValues = {};
      vm.projectOptionLists.forEach((list) => {
        vm.selectedValues[list.name] = settingsService.getOwnerProjectOptionsList(list.name);
      });
      vm.savedObject = angular.copy(vm.selectedValues);
    }

    saveSettings () {
      if (vm.saveProgress) {
        return;
      }

      vm.saveProgress = true;

      return settingsService.saveSettings({ valuesOptionsCustom: vm.selectedValues })
        .then((result) => {
          vm.savedObject = angular.copy(vm.selectedValues);
          utils.notify('הגדרות נשמרו', 'alert-success');
          $rootScope.$broadcast('ownerSaved');

          return result;
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    resetSettings () {
      vm.selectedValues = angular.copy(vm.savedObject);
    }
  }

  return new ProjectOptionsDetailsCtrl();
}
