const formatDateModule = angular.module('formatDate', [])
  .directive('formatDate', function () {
    return {
      require: 'ngModel',
      link (scope, elem, attr, modelCtrl) {
        modelCtrl.$formatters.push((modelValue) => {
          if (modelValue) {
            return new Date(modelValue);
          }
          return null;
        });
      }
    };
  })
  .name;

export default formatDateModule;
