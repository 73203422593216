import dayjs from 'dayjs';

export default function ($scope, $rootScope, $state, $timeout, utils, taskService) {
  let vm;

  class TaskEditCtrl {
    constructor () {
      vm = this;
      vm.statusObject = {
        toDo: { title: 'לביצוע', color: '#e74c3c' },
        inProgress: { title: 'בביצוע', color: '#0033cc' },
        done: { title: 'בוצע', color: '#62cb31' }
      };
    }

    async $onInit () {
      vm.agentList = _.get($rootScope, 'owner.users') || [];
      vm.taskModelObject = angular.copy(vm.resolve.task);
      vm.projectId = vm.resolve.projectId || vm.taskModelObject.projectId;

      if (!vm.taskModelObject) {
        vm.taskModelObject = {
          assignUser: $rootScope.user.id,
          dueDate: dayjs().set('second', 0).set('millisecond', 0),
          status: 'toDo',
          content: ''
        };
      }

      vm.showProjectLink = vm.taskModelObject.id && !$state.current.name.startsWith('project.projectShow');

      $timeout(() => {
        vm.autoFocus();
      }, 100);
    }

    autoFocus () {
      const el = document.querySelector('.task-edit .task-content-field');
      try {
        // Move the cursor at end of text
        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(el.childNodes[0], _.get(vm.taskModelObject, 'content.length', 0));
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      } catch (error) { }
      el.focus();
    }

    onAgentChanged (selectedAgent) {
      vm.taskModelObject.assignUser = selectedAgent;
    }

    agentName (agentId) {
      const agent = $rootScope.userById(agentId) || {};
      if (!agent) { return; }
      return `${agent.firstName || ''} ${agent.lastName || ''}`;
    }

    closeModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    saveTask () {
      if (vm.saveProgress) {
        return;
      }
      if (vm.form && vm.form.$invalid) {
        document.getElementById('task-edit-form').reportValidity();
        utils.setFormFieldsDirty(vm.form);
        return;
      }
      vm.saveProgress = true;
      taskService.saveTask(vm.projectId, vm.taskModelObject.id, vm.taskModelObject)
        .then((savedTask) => {
          const message = (vm.taskModelObject.id ? 'פרטי משימה נשמרו' : 'משימה חדשה נשמרה');
          utils.notify(message, 'alert-success');
          vm.modalInstance.close();
          $rootScope.$broadcast('taskSaved');
          if (vm.resolve.onSaveTask) {
            vm.resolve.onSaveTask({ savedTask });
          }
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    async deleteTask () {
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: 'האם למחוק משימה?',
        buttons: [{
          label: 'לא, ביטול'
        },
        {
          label: 'כן, למחוק',
          btnClass: 'btn-danger',
          onClick ($uibModalInstance) {
            $uibModalInstance.close();
            return true;
          }
        }]
      };

      await utils.modalAlert(modalParams);
      await taskService.deleteTask(vm.projectId, vm.taskModelObject.id);
      vm.modalInstance.close();
      $rootScope.$broadcast('taskSaved');
      if (vm.resolve.onSaveTask) {
        vm.resolve.onSaveTask({ savedTask: undefined });
      }
    }
  }

  return new TaskEditCtrl();
}
