import ownerEdit from './owner-edit/owner-edit';
import ownerList from './owner-list/owner-list';
import ownerShow from './owner-show/owner-show';
import ownerTickets from './owner-tickets/owner-tickets';
import ownerService from './owner.service';

const ownerModule = angular.module('owner', [
  ownerEdit,
  ownerList,
  ownerShow,
  ownerTickets,
  ownerService
])
  .name;

export default ownerModule;
