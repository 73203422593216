export default function ($scope, $http, $timeout) {
  let vm;

  class StreetController {
    constructor () {
      vm = this;
      vm.name = 'street';
      vm.list = [];
    }

    async $onInit () {
      if (vm.model && vm.model.length) {
        await vm.streetAutocomplete();
      }
    }

    async onFieldChange () {
      await vm.streetAutocomplete();
      vm.isOpen = true;
      if (angular.isFunction(vm.onChange)) {
        vm.onChange();
      }
    }

    onBlur () {
      $timeout(() => {
        vm.isOpen = false;
      }, 500);
    }

    streetAutocomplete () {
      if (!vm.model.length || !vm.cityCode) {
        vm.list = [];
        return Promise.resolve();
      }

      return $http.post('https://ags.govmap.gov.il/AdvancedSearch/GetSearchComponentValues', {
        Filter: vm.model,
        SourceTable: 'SDE.address',
        FieldValue: 'str_fulnam',
        FieldCode: 'str_id',
        CondField: 'setl_code',
        NumberOfRecords: '500',
        CondValue: vm.cityCode,
        SortMixedAlphaNumeric: false
      })
        .then((res) => {
          const list = _.get(res, 'data.data') || [];
          vm.list = list;
        });
    }

    async selectStreet (item) {
      vm.model = item.VALUE;
      await vm.streetAutocomplete();
      if (angular.isFunction(vm.onChange)) {
        vm.onChange();
      }
    }
  }

  return new StreetController();
}
