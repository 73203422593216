import template from './input-image.html';
import './input-image.scss';

const inputImageModule = angular.module('inputImage', [])
  .directive('inputImage', [function () {
    return {
      restrict: 'A',
      controllerAs: 'vm',
      bindToController: true,
      replace: true,
      scope: {
        model: '=?',
        imageUrl: '@',
        name: '@',
        onChange: '<?',
        confirmDelete: '<?'
      },
      template,

      controller: ['$scope', '$timeout', '$uibModal', 'utils', function ($scope, $timeout, $uibModal, utils) {
        const vm = this;
        vm.$timeout = $timeout;
        vm.$uibModal = $uibModal;
        vm.utils = utils;

        vm.$onInit = function () {
          if (!(vm.model instanceof File)) {
            vm.image = vm.imageUrl; // + '?' + Date.now();
          }

          $scope.$watch('vm.model', (newVal, oldVal) => {
            if (!vm.model && newVal !== oldVal) {
              vm.deleteImage(true);
            }
          });
        };
      }],

      link (scope, element, attrs, vm) {
        const inputElement = element.find('input');
        const progressElement = element.find('.input-image-loader');

        inputElement.on('click', (e) => {
          vm.deleteImage();
        });

        inputElement.on('change', (e) => {
          const files = inputElement[0].files;
          if (_.get(files, '0')) {
            vm.addImage(files);
          } else {
            vm.deleteImage();
          }
        });

        vm.$onChanges = function (changes) {
          vm.image = vm.model;
          if (vm.model instanceof File) {
            inputElement[0].value = vm.model;
            inputElement.trigger('change');
          } else if (vm.imageUrl) {
            vm.image = vm.imageUrl; // + '?' + Date.now();
          }
        };

        vm.changeImage = async function () {
          if (vm.confirmDelete) {
            await vm.confirmDeletion('change');
          }
          inputElement.trigger('click');
        };

        vm.addImage = function (imageFileList, silent) {
          vm.image = undefined;
          vm.model = imageFileList;

          const reader = new FileReader();
          reader.onload = function (e) {
            scope.$apply(() => {
              vm.image = e.target.result;
              progressElement.hide();
            });
          };
          progressElement.show();
          vm.$timeout(() => {
            reader.readAsDataURL(imageFileList[0]);
          });
          if (!silent && _.isFunction(vm.onChange)) {
            vm.onChange(vm.name, vm.model);
          }
        };

        vm.deleteImage = async function (silent) {
          if (vm.confirmDelete && !silent) {
            await vm.confirmDeletion('remove');
          }
          const fileId = _.get(vm.model, 'id');
          vm.image = undefined;
          vm.model = undefined;
          inputElement[0].value = '';
          vm.$timeout(() => {
            scope.$apply(() => { });
          });
          if (!silent && _.isFunction(vm.onChange)) {
            vm.onChange(vm.name, vm.model, fileId);
          }
        };

        vm.confirmDeletion = function (action = 'remove') {
          return new Promise((resolve, reject) => {
            const modalParams = {
              windowClass: 'hmodal-danger',
              title: `האם ${action === 'remove' ? 'למחוק' : 'לשנות'} קובץ?`,
              message: 'לא ניתן יהיה לשחזר את הפעולה.',
              buttons: [{
                label: 'בטל',
                btnClass: 'btn-default',
                onClick ($uibModalInstance) {
                  $uibModalInstance.dismiss('cancel');
                  return reject(new Error('canceled'));
                }
              },
              {
                label: `${action === 'remove' ? 'מחק את ה' : 'שנה '}קובץ`,
                btnClass: 'btn-primary',
                onClick ($uibModalInstance) {
                  $uibModalInstance.close();
                  return resolve();
                }
              }]
            };
            vm.utils.modalAlert(modalParams);
          });
        };

        vm.zoomImage = function () {
          vm.$uibModal.open({
            template: `<img ng-src="${vm.image}"/>`,
            windowClass: 'input-image-zoom-view',
            size: 'lg'
          });
        };
      }
    };
  }])
  .name;

export default inputImageModule;
