import helpers from './helpers';

export default ['$stateProvider', ($stateProvider) => {
  $stateProvider
    .state('settings', {
      redirectTo: 'settings.profile'
    })
    .state('settings.profile', {
      url: '/profile',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth]
      },
      component: 'profile'
    })
    .state('settings.ownerSettings', {
      url: '/settings',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth]
      },
      component: 'settings'
    })
    .state('settings.ownerSettings.linkedAccounts', {
      url: '/linked-accounts?message?status',
      component: 'linkedAccounts'
    })
    .state('settings.ownerSettings.agentList', {
      url: '/agents',
      component: 'agentList'
    })
    .state('settings.ownerSettings.documentSettings', {
      url: '/document',
      component: 'documentSettings'
    })
    .state('settings.ownerSettings.valuesOptionsCustom', {
      url: '/custom-options-values',
      component: 'valuesOptionsCustom'
    })
    .state('settings.ownerSettings.collaborationSettings', {
      url: '/collaboration',
      component: 'collaborationSettings'
    })
    .state('settings.ownerSettings.excelImport', {
      url: '/excel-import',
      component: 'excelImport'
    });
}];
