import template from './custom-options-status.html';
import controller from './custom-options-status.controller';
import './custom-options-status.scss';

const customOptionsStatusComponent = {
  require: {
    settingsCtrl: '^settings'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$uibModal', 'settingsService', 'request', 'utils'];

export default customOptionsStatusComponent;
