export default function ($scope, $rootScope, $state, $filter, request, utils, userService) {
  let vm;

  class UserLogCtrl {
    constructor () {
      vm = this;
      vm.name = 'userLog';

      vm.tableHeadList = [
        { name: 'createdAt', label: 'תאריך פעולה', type: 'customFunction', customFunction: vm.getRowDate, sortable: true },
        { name: 'action', label: 'סוג פעולה', type: 'customFunction', customFunction: vm.getActionLabel },
        { name: 'link', label: 'קישור', type: 'customFunction', customFunction: vm.getRowLink, elementClass: 'btn btn-default btn-xs', customUiSref: vm.getRowLinkSref }
      ];

      vm.actions = {
        'customer created': { label: 'יצירת לקוח', link: 'customer.customerShow', linkLabel: 'קישור ללקוח' },
        'customer shown': { label: 'הצגת לקוח', link: 'customer.customerShow', linkLabel: 'קישור ללקוח' },
        'customer edited': { label: 'עריכת לקוח', link: 'customer.customerShow', linkLabel: 'קישור ללקוח' },
        'project created': { label: 'יצירת פרויקט', link: 'project.projectShow', linkLabel: 'קישור לפרויקט' },
        'project shown': { label: 'הצגת פרויקט', link: 'project.projectShow', linkLabel: 'קישור לפרויקט' },
        'project edited': { label: 'עריכת פרויקט', link: 'project.projectShow', linkLabel: 'קישור לפרויקט' },
        'project event edited': { label: 'עריכת פרטי אירוע בפרויקט', link: 'project.projectShow.projectEvents', linkLabel: 'קישור לאירועי פרויקט' },
        'project additional details edited': { label: 'עריכת פרטי דוח שומה בפרויקט', link: 'project.projectShow.projectAdditionalDetailsShow', linkLabel: 'קישור לפרטי דוח שומה בפרויקט' },
        'project document details edited': { label: 'עריכת פרטי מסמך שומה בפרויקט', link: 'project.projectShow.documentDetailsShow', linkLabel: 'קישור לפרטי מסמך שומה בפרויקט' },
        'project status changed': { label: 'שינוי סטטוס פרויקט', link: 'project.projectShow', linkLabel: 'קישור לפרויקט' },
        'customer removed from project': { label: 'הסרת לקוח מפרויקט', link: 'project.projectShow', linkLabel: 'קישור לפרויקט' }
      };
    }

    $onInit () {
      vm.userId = $state.params.id;
      vm.getUserLog();
    }

    getUserLog (args = {}) {
      vm.getListProgress = true;
      return userService.getUserLog(vm.userId, args)
        .then((userList) => {
          return userList;
        })
        .finally(() => {
          vm.getListProgress = false;
        });
    }

    getRowDate (row) {
      return $filter('date')(row.createdAt, 'dd/MM/yyyy HH:mm:ss');
    }

    getActionLabel (row) {
      return `${_.get(vm.actions, row.action + '.label') || row.action} ${row.objectNumber ? `<small>(${row.objectNumber})</small>` : ''}`;
    }

    getRowLink (row) {
      return _.get(vm.actions, row.action + '.linkLabel');
    }

    getRowLinkSref (row) {
      return _.get(vm.actions, row.action + '.link') + '({id: row.objectId})';
    }
  }

  return new UserLogCtrl();
}
