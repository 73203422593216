import dayjs from 'dayjs';

const helpers = {
  checkLogin ($location, $rootScope, $state, $stateParams, loginService, counts) {
    if ($stateParams.email) {
      return;
    }
    return helpers.userAuth($location, $rootScope, $state, $stateParams, loginService, counts)
      .then((data) => {
        if (data) {
          $location.url($stateParams.next || '/projects');
        }
      });
  },

  checkAuth ($location, $rootScope, $state, $stateParams, loginService, permissionList, counts) {
    let next = $stateParams.next;
    if (!$stateParams.noRedirect) {
      next = $location.url();
    }
    return loginService.isAuthenticated()
      .then((user) => {
        if (_.get(user, 'owner.settings.useBetaSite') && window.location.host === 'app.amend.co.il') {
          window.location.host = 'beta.amend.co.il';
          return null;
        }
        if (!user || (permissionList && !permissionList.includes(user.permission))) {
          $state.transitionTo('login', { next });
          return null;
        }
        counts.loadCounts();
        return helpers.checkAccountNotEnded(user, $rootScope, $state, $stateParams);
      })
      .catch(() => {
        $state.transitionTo('login', { next });
      });
  },

  userAuth ($location, $rootScope, $state, $stateParams, loginService, counts) {
    return helpers.checkAuth($location, $rootScope, $state, $stateParams, loginService, null, counts);
  },

  managerAuth ($location, $rootScope, $state, $stateParams, loginService, counts) {
    return helpers.checkAuth($location, $rootScope, $state, $stateParams, loginService, ['admin', 'manager'], counts);
  },

  adminAuth ($location, $rootScope, $state, $stateParams, loginService, counts) {
    return helpers.checkAuth($location, $rootScope, $state, $stateParams, loginService, ['admin'], counts);
  },

  checkAccountNotEnded (user, $rootScope, $state, $stateParams) {
    const accountEnd = dayjs(_.get(user, 'owner.accountEnd'));

    if (user.permission === 'admin') {
      return user;
    }
    if (dayjs().isAfter(accountEnd)) {
      $rootScope.accountEnded = true;
      if ($stateParams.allowAccessForEndedAccount) {
        return user;
      } else if (user.permission === 'manager') {
        $state.transitionTo('payments.charge');
        return user;
      } else {
        $state.transitionTo('login');
        return null;
      }
    }
    $rootScope.accountEnded = false;
    return user;
  }
};

export default helpers;
