import template from './project-folders-settings.html';
import controller from './project-folders-settings.controller';
import './project-folders-settings.scss';

const projectFoldersSettingsComponent = {
  require: {
    settingsCtrl: '^settings'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', 'settingsService', 'utils'];

export default projectFoldersSettingsComponent;
