let request;

class adminService {
  constructor (_request) {
    request = _request;
  }

  getAllPayments (args = {}) {
    return request('/admin/payments?' + jquery.param(args))
      .then((res) => res.data.data);
  }

  getAllTickets (args = {}, openOnly) {
    return request('/admin/ticket?' + jquery.param(args) + (openOnly ? '&openOnly=true' : ''))
      .then((res) => res.data.data);
  }
}

adminService.$inject = ['request'];

const adminServiceModule = angular.module('adminService', [])
  .service('adminService', adminService)
  .name;

export default adminServiceModule;
