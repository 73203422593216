function fileRequestService (Upload) {
  return function (url, dataObject) {
    const headers = {
      'Content-Type': undefined,
      transformRequest: angular.identity
    };

    return Upload.upload({
      url: window.SERVER_URL + url,
      headers,
      withCredentials: true,
      data: dataObject
    });
  };
}

fileRequestService.$inject = ['Upload'];

const fileRequestServiceModule = angular.module('fileRequestService', [])
  .factory('fileRequest', fileRequestService)
  .name;

export default fileRequestServiceModule;
