export default function ($scope, $rootScope, $timeout, $uibModal, govmapService, projectService) {
  let vm;

  class ProjectMapCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      if (vm.x && vm.y) {
        govmapService.createMap('map', {}, { x: vm.x, y: vm.y });
        vm.getCloseProjects();
      }

      $scope.$watchGroup(['vm.x', 'vm.y'], (newVal, oldVal) => {
        if (newVal[0] && newVal[1] && (newVal[0] !== oldVal[0] || newVal[1] !== oldVal[1])) {
          if (!oldVal[0] || !oldVal[1]) {
            govmapService.createMap('map', {}, { x: vm.x, y: vm.y });
          } else {
            govmapService.zoomMap({ x: vm.x, y: vm.y });
          }
          vm.getCloseProjects();
        }
      });
    }

    linkToSharedDraw () {
      if (!vm.gush || !vm.helka) {
        return;
      }

      jQuery(document.body).append(`
        <form action="http://fileextractor.justice.gov.il/TabuSearchDocuments/Search" method="post" aria-hidden="true" role="document" target="_blank" id="link-to-shared-draw">
          <input id="Block" name="Block" type="hidden" value="${vm.gush}">
          <input id="Parcel" name="Parcel" type="hidden" value="${vm.helka}">
        </form>`);
      jQuery('#link-to-shared-draw').submit().remove();
    }

    async getCloseProjects () {
      const { closeProjects, closeExternalProjects } = await projectService.getCloseProjects(vm.projectId);
      vm.closeProjectsCount = _.get(closeProjects, 'length', 0);
      vm.closeExternalProjectsCount = _.get(closeExternalProjects, 'length', 0);
      const locations = closeProjects.map((project) => {
        return {
          x: project.x,
          y: project.y,
          name: 'פרויקט ' + project.number
        };
      });

      $timeout(() => {
        return govmapService.createPoints(locations);
      });
    }

    showCloseProjects (tab) {
      $uibModal.open({
        component: 'closeProjects',
        resolve: {
          projectId: () => vm.projectId,
          tab: () => tab
        },
        windowClass: 'close-projects-modal mobile-modal',
        size: 'lg'
      });
    }

    isExternalProjectShown () {
      return _.get($rootScope.owner, 'settings.externalProjectsSettings.show') === true;
    }
  }

  return new ProjectMapCtrl();
}
