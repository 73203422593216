import tableColumns from '../../table-columns';

export default function ($rootScope, settingsService, utils) {
  let vm;

  class CustomizeTableColumnsController {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.enabledColumns = {};
      vm.columnLabels = {};
      vm.enabledColumnLabels = {};
      for (const table in tableColumns) {
        vm.columnLabels[table] = tableColumns[table]
          .filter((column) => !column.alwaysShow && (!column.hasAddressOnly || $rootScope.hasAddress))
          .map((column) => column.label);

        vm.enabledColumns[table] = settingsService.getOwnerTableColumns(table);
        vm.enabledColumnLabels[table] = tableColumns[table]
          .filter((column) => vm.enabledColumns[table].includes(column.name))
          .map((column) => column.label);
      }
    }

    saveSettings () {
      for (const table in tableColumns) {
        vm.enabledColumns[table] = tableColumns[table]
          .filter((column) => vm.enabledColumnLabels[table].includes(column.label))
          .map((column) => column.name);
      }
      settingsService.saveSettings({ tableColumns: vm.enabledColumns })
        .then((result) => {
          // vm.savedObject = angular.copy(vm.selectedValues);
          utils.notify('ההגדרות נשמרו', 'alert-success');
          $rootScope.$broadcast('ownerSaved');
          return result;
        });
    }

    resetSettings () {
      for (const table in tableColumns) {
        vm.enabledColumnLabels[table] = tableColumns[table]
          .filter((column) => vm.enabledColumns[table].includes(column.name))
          .map((column) => column.label);
      }
    }
  }

  return new CustomizeTableColumnsController();
}
