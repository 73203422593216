import dayjs from 'dayjs';
import isUrl from 'is-url';

export default function ($scope, $rootScope, $window, $timeout, $uibModal, utils, paymentsService) {
  let vm;

  class PaymentsCtrl {
    constructor () {
      vm = this;
      vm.name = 'payments';
    }

    $onInit () {
      vm.cardsLoaded = false;
      vm.loadCards();
    }

    loadCards () {
      return paymentsService.getOwnerCards()
        .then((res) => {
          vm.cardsLoaded = true;
          vm.cards = res;
        });
    }

    getOwnerChargePage () {
      if (vm.getLinkProgress) {
        return;
      }
      vm.getLinkProgress = true;
      return paymentsService.getOwnerAddCardUrl(vm.directDebit)
        .then((res) => {
          if (isUrl(res)) {
            $timeout(() => {
              window.open(res, '_self');
            });
          }
        })
        .finally(() => {
          vm.getLinkProgress = false;
        });
    }

    deleteCard (cardId, event) {
      vm.progressAnimation(event);
      return paymentsService.deleteCard(cardId)
        .then((res) => {
          vm.loadCards();
        });
    }

    setCardDirectDebit (cardId, event) {
      vm.progressAnimation(event);
      return paymentsService.setCardDirectDebit(cardId)
        .then((res) => {
          vm.loadCards();
        });
    }

    unsetCardDirectDebit (cardId, event) {
      vm.progressAnimation(event);
      return paymentsService.unsetCardDirectDebit(cardId)
        .then((res) => {
          vm.loadCards();
        });
    }

    confirmDeleteCard (cardId, event) {
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: 'האם למחוק כרטיס אשראי?',
        message: 'לא ניתן יהיה לשחזר את הפעולה.',
        buttons: [{
          label: 'בטל',
          btnClass: 'btn-default',
          onClick ($uibModalInstance) {
            $uibModalInstance.dismiss('cancel');
          }
        },
        {
          label: 'מחק את הכרטיס',
          btnClass: 'btn-primary',
          onClick ($uibModalInstance) {
            vm.deleteCard(cardId, event);
            $uibModalInstance.close();
          }
        }]
      };

      utils.modalAlert(modalParams);
    }

    confirmUnsetCardDirectDebit (cardId, event) {
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: 'האם לבטל הוראת קבע מכרטיס?',
        buttons: [{
          label: 'לא',
          btnClass: 'btn-default',
          onClick ($uibModalInstance) {
            $uibModalInstance.dismiss('cancel');
          }
        },
        {
          label: 'בטל הוראת קבע',
          btnClass: 'btn-primary',
          onClick ($uibModalInstance) {
            vm.unsetCardDirectDebit(cardId, event);
            $uibModalInstance.close();
          }
        }]
      };

      utils.modalAlert(modalParams);
    }

    progressAnimation (event) {
      jQuery(event.currentTarget).find('i.fa').attr('class', 'fa fa-spinner fa-spin');
    }

    isAccountEnd () {
      if (!_.get($rootScope, 'owner')) { return; }
      return dayjs($rootScope.owner.accountEnd).isBefore(dayjs().add(5, 'days').startOf('day')) &&
        !_.get($rootScope.owner, 'ownerCreditCardTokens.length');
    }
  }

  return new PaymentsCtrl();
}
