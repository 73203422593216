import template from './project-list.html';
import controller from './project-list.controller';
import './project-list.scss';

const projectListComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$stateParams', '$filter', '$uibModal', 'projectService', 'settingsService', 'userService', 'permissions', 'counts', 'utils'];

export default projectListComponent;
