import template from './event-edit.html';
import controller from './event-edit.controller';
import './event-edit.scss';

const eventEditComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '=?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', 'eventService', 'counts', 'utils'];

export default eventEditComponent;
