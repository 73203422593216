import filesIdentifying from './files-identifying';

export default function ($scope, $rootScope, $location, $timeout, $interval, $uibModal, $sce, request, fileRequest, utils, projectService) {
  let vm;

  class ProjectGdriveFileListCtrl {
    constructor () {
      vm = this;
      vm.gdriveFiles = [];
      vm.fileList = [];
      vm.uploadFilesList = [];
      vm.inputFiles = [];
      vm.fileNamesDictionary = {};
      vm.filesIdentifying = filesIdentifying;
    }

    async $onInit () {
      vm.getAvailableFileOptions()
        .then((res) => {
          vm.availableFileOptions = res;
        });
      vm.$location = $location;
      vm.projectId = vm.projectShowCtrl.projectFields.id;
      const locationQueryFolder = $location.$$search.folder;
      vm.shownFolder = locationQueryFolder ? { id: locationQueryFolder } : null;
      vm.getGdriveFiles();
      vm.gdriveRequestCounter = 0;

      $scope.$on('getGdriveFiles', () => {
        vm.getGdriveFiles();
      });

      $scope.$watch('vm.$location.$$search.folder', (newValue, oldValue) => {
        if (newValue !== oldValue && newValue !== _.get(vm, 'shownFolder.id')) {
          if (vm.fileNamesDictionary[newValue]) {
            vm.shownFolder = vm.fileNamesDictionary[newValue];
          } else {
            _.set(vm, 'shownFolder.id', newValue);
          }
          vm.getGdriveFiles();
        }
      });

      $timeout(() => {
        vm.getGdriveFiles(true);
      }, 2000);

      vm.runningInterval = $interval(() => {
        if (jQuery('project-gdrive-file-list').is(':hidden')) {
          return;
        }
        vm.getGdriveFiles(true);
      }, 60000); // 1 minute

      // Reduce files check frequency - after 1 hour of idle screen
      vm.runningTimeout = $timeout(() => {
        if (angular.isDefined(vm.runningInterval)) {
          $interval.cancel(vm.runningInterval);
          delete vm.runningInterval;
        }

        vm.runningInterval = $interval(() => {
          if (jQuery('project-gdrive-file-list').is(':hidden')) {
            return;
          }
          vm.getGdriveFiles(true);
        }, 1800000);// 30 minutes
      }, 3600000); // 1 hour
    }

    $onDestroy () {
      if (angular.isDefined(vm.runningInterval)) {
        $interval.cancel(vm.runningInterval);
        delete vm.runningInterval;
      }
      if (angular.isDefined(vm.runningTimeout)) {
        $timeout.cancel(vm.runningTimeout);
        delete vm.runningTimeout;
      }
    }

    getGdriveFiles (silent) {
      if (!vm.projectId) { return; }
      vm.shownFolder = vm.shownFolder || {};
      let requestUrl = '/project/' + vm.projectId + '/gdrive-file?';
      const shownFolderId = _.get(vm.shownFolder, 'id');
      if (shownFolderId) {
        requestUrl += 'folderId=' + shownFolderId;
        $location.search({ folder: shownFolderId });
        if (!vm.fileNamesDictionary[shownFolderId]) {
          requestUrl += '&includeCurrent=true';
        }
      } else {
        $location.search({});
      }

      if (!silent) {
        vm.getListProgress = true;
      }
      vm.gdriveRequestCounter++;
      const gdriveRequestCounter = vm.gdriveRequestCounter;
      return request(requestUrl, null, 'GET', {}, !!silent)
        .then((response) => {
          if (gdriveRequestCounter < vm.gdriveRequestCounter) {
            console.log('Request aborted');
            return;
          }
          const resultList = _.get(response, 'data.data.list') || [];
          const resultFolder = _.get(response, 'data.data.folder');
          if (shownFolderId) {
            vm.shownFolder.list = resultList;
            if (resultFolder) {
              vm.shownFolder = resultFolder;
              vm.fileNamesDictionary[resultFolder.id] = {
                id: resultFolder.id,
                name: resultFolder.name,
                parents: resultFolder.parents
              };
            }
          } else {
            vm.gdriveFiles = resultList;
          }
          if (!vm.fileList || !angular.equals(resultList, vm.fileList)) {
            vm.fileList = resultList;
          }
        })
        .catch((error) => {
          if (shownFolderId && (/^Error: File not found:/).test(error.data.data)) {
            vm.shownFolder = null;
            vm.getGdriveFiles();
          }
        })
        .finally(() => {
          vm.getListProgress = false;
        });
    }

    selectFiles () {
      jQuery('.gdrive-drop-box').trigger('click');
    }

    showFile (file) {
      if (filesIdentifying.isFolder(file)) {
        vm.showFolder(file);
      } else if (filesIdentifying.isImage(file)) {
        vm.zoomImage(file);
      } else if (filesIdentifying.isPdf(file)) {
        vm.zoomPdf(file);
      } else if (filesIdentifying.isTxt(file)) {
        vm.zoomTxt(file);
      } else if (filesIdentifying.isVideo(file)) {
        vm.zoomVideo(file);
      } else if (filesIdentifying.isMsg(file)) {
        vm.zoomEmailFile(file, 'msg');
      } else if (filesIdentifying.isEml(file)) {
        // vm.zoomEmailFile(file, 'eml');
      }
    }

    editFile (file) {
      if (vm.filesIdentifying.isOffice(file)) {
        let urlKeyword = '';
        if (vm.filesIdentifying.isWord(file)) {
          urlKeyword = 'document';
        } else if (vm.filesIdentifying.isExcel(file)) {
          urlKeyword = 'spreadsheets';
        } else if (vm.filesIdentifying.isPpt(file)) {
          urlKeyword = 'presentation';
        }
        window.open(`https://docs.google.com/${urlKeyword}/d/${file.id}/edit`, '_blank');
      }
    }

    showFolder (folder) {
      vm.fileNamesDictionary[folder.id] = folder;
      vm.shownFolder = folder;
      vm.getGdriveFiles();
    }

    goToParnentFolder (event) {
      event.preventDefault();
      $timeout(() => {
        const parentFolderId = _.get(vm.shownFolder, 'parents[0]') ||
          _.get(vm.fileNamesDictionary[vm.shownFolder.id], 'parents[0]');
        const projectRootFolder = vm.projectShowCtrl.projectFields.gdriveFolder;
        if (parentFolderId && parentFolderId !== projectRootFolder) {
          vm.shownFolder = {
            id: parentFolderId,
            ...vm.fileNamesDictionary[parentFolderId]
          };
        } else {
          vm.shownFolder = null;
        }
        vm.getGdriveFiles();
      });
    }

    keyPressOnFile (event, file, primaryAction) {
      if (event.which === 13 && file.id) { // enter
        primaryAction(file);
      } else if (event.which === 37) { // left
        vm.moveToFile('next');
      } else if (event.which === 39) { // right
        vm.moveToFile('prev');
      }
    }

    moveToFile (direction) {
      if (direction !== 'next' && direction !== 'prev') {
        return;
      }
      let nextElement = jQuery('.file:focus').closest('li')[direction]().find('.file');
      if (!nextElement.length) {
        nextElement = jQuery('.file:focus').closest('ul')[direction]('ul').find('.file' + (direction === 'next' ? ':first' : ':last'));
      }
      if (nextElement.length) {
        nextElement.focus();
      }
    }

    getFileIcon (file) {
      return filesIdentifying.getFileIcon(file);
    }

    getFileUrl (file, download) {
      return window.SERVER_URL + '/project/' + vm.projectId + '/gdrive-file/' + file.id + (download ? '?download' : '');
    }

    async openInDesktopClient (fileId) {
      if (vm.isDesktopClientEnabled || await vm.isDesktopClientCheck()) {
        fileId = fileId || _.get(vm, 'shownFolder.id') || vm.projectShowCtrl.projectFields.gdriveFolder;
        return jQuery.get('http://localhost:1986/quicker/open-folder?id=' + fileId);
      }
    }

    isZoomFile (file) {
      return vm.filesIdentifying.isImage(file) ||
        vm.filesIdentifying.isPdf(file) ||
        vm.filesIdentifying.isVideo(file) ||
        vm.filesIdentifying.isTxt(file) ||
        vm.filesIdentifying.isEmailFile(file);
    }

    isEditable (file) {
      return vm.filesIdentifying.isOffice(file);
    }

    zoomImage (file) {
      const url = vm.getFileUrl(file);
      $uibModal.open({
        template: `<div class="image-container"><img ng-src="${url}"/><div>`,
        windowClass: 'image-preview-zoom-view',
        size: 'lg'
      });
    }

    zoomPdf (file) {
      const url = vm.getFileUrl(file);
      $uibModal.open({
        template: `<div class="pdf-container">
          <embed width="100%" height="100%" src="${url}" type="application/pdf" style="margin:0px;padding:0px;overflow:hidden;display:block;">
        </div>`,
        windowClass: 'pdf-preview-zoom-view',
        size: 'lg'
      });
    }

    zoomVideo (file) {
      const url = vm.getFileUrl(file);
      $uibModal.open({
        template: `<div class="video-container">
          <video controls preload="auto" autoplay>
            <source src="${url}" type="${file.mimeType}">
          </video>
        <div>`,
        windowClass: 'video-preview-zoom-view',
        size: 'lg'
      });
    }

    zoomTxt (file) {
      const url = vm.getFileUrl(file);
      let fileContent;
      request(url, null, 'GET', {
        rootUrl: true
      })
        .then((response) => {
          fileContent = response.data;

          $uibModal.open({
            template: `<div class="txt-container">
              <pre style="font-size: 14px;white-space: pre-wrap;word-break: break-word;">${fileContent}</pre>
            </div>`,
            windowClass: 'txt-preview-zoom-view',
            size: 'lg'
          });
        });
    }

    zoomEmailFile (file, type = 'msg') {
      const fileUrl = vm.getFileUrl(file);

      $uibModal.open({
        component: (type === 'eml' ? 'emlViewer' : 'msgViewer'),
        resolve: {
          fileUrl: () => fileUrl
        },
        windowClass: 'email-preview-zoom-view',
        size: 'lg'
      });
    }

    confirmDeleteFile (file) {
      const fileLabel = filesIdentifying.isFolder(file) ? 'תיקייה' : 'קובץ';
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: `האם למחוק ${fileLabel}?`,
        buttons: [{
          label: 'בטל',
          btnClass: 'btn-default',
          onClick ($uibModalInstance) {
            $uibModalInstance.dismiss('cancel');
          }
        },
        {
          label: `מחק את ה${fileLabel}`,
          btnClass: 'btn-primary',
          onClick ($uibModalInstance) {
            vm.deleteFile(file);
            $uibModalInstance.close();
          }
        }]
      };

      utils.modalAlert(modalParams);
    }

    deleteFile (file) {
      request(`/project/${vm.projectId}/gdrive-file/${file.id}`, {}, 'DELETE')
        .finally(() => {
          $rootScope.$broadcast('getGdriveFiles');
        });
    }

    uploadFiles ($files = [], $invalidFiles = []) {
      if ($invalidFiles.length) {
        utils.notify($invalidFiles.length + ' קבצים לא הועלו', 'alert-danger');
      }
      if (!$files.length) {
        return;
      }

      const chunkSize = 8;
      const promiseList = [];
      vm.uploadFilesList.push(...$files);

      $files.slice(0, chunkSize).forEach((file) => {
        if (!(file instanceof File)) {
          return;
        }
        promiseList.push(vm.uploadFile(file));
      });

      return Promise.all(promiseList)
        .finally(() => {
          $rootScope.$broadcast('getGdriveFiles');
          console.log('chunk ended');
          if ($files.length > chunkSize) {
            vm.uploadFiles($files.slice(chunkSize), []);
          }
        });
    }

    uploadFile (file) {
      const formData = {};
      let requestUrl = `/project/${vm.projectId}/gdrive-file`;
      formData.file = file;
      if (_.get(vm, 'shownFolder.id')) {
        requestUrl += '?parentFolder=' + vm.shownFolder.id;
      }

      return fileRequest(requestUrl, formData)
        .then((res) => {
          console.log(res);
          return res;
        }, (res) => {
          // handle error
        }, (evt) => {
          formData.file.progress = parseInt(100.0 * evt.loaded / evt.total);
          console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total));
        })
        .catch((errorCallback) => {
          return errorCallback;
        })
        .finally((callback, notifyCallback) => {
          vm.removeUploadFile(file);
          console.log(callback, notifyCallback);
        });
    }

    createFolderForm () {
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: 'יצירת תיקייה',
        html: '<input type="text" id="new-folder-name-input" class="form-control" placeholder="שם תיקייה" style="max-width: 350px;" autofocus/>',
        buttons: [{
          label: 'ביטול',
          btnClass: 'btn-default',
          onClick ($uibModalInstance) {
            $uibModalInstance.dismiss('cancel');
          }
        },
        {
          label: 'אישור',
          btnClass: 'btn-primary',
          onClick ($uibModalInstance) {
            vm.createFolder(jQuery('#new-folder-name-input').val());
            $uibModalInstance.close();
          }
        }]
      };

      utils.modalAlert(modalParams);
    }

    createFolder (folderName) {
      let requestUrl = `/project/${vm.projectId}/gdrive-file/folder`;
      if (_.get(vm, 'shownFolder.id')) {
        requestUrl += '?parentFolder=' + vm.shownFolder.id;
      }
      return request(requestUrl, { folderName }, 'POST')
        .then((res) => {
          $rootScope.$broadcast('getGdriveFiles');
        });
    }

    renameFileForm (file) {
      const fileLabel = filesIdentifying.isFolder(file) ? 'תיקייה' : 'קובץ';
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: 'שינוי שם ' + fileLabel,
        html: '<input type="text" id="file-rename-input" class="form-control" value="' + file.name + '" placeholder="שם ' + fileLabel + '" style="max-width: 350px;" dir="auto" autofocus onload="this.select()"/>',
        buttons: [{
          label: 'ביטול',
          btnClass: 'btn-default',
          onClick ($uibModalInstance) {
            $uibModalInstance.dismiss('cancel');
          }
        },
        {
          label: 'אישור',
          btnClass: 'btn-primary',
          onClick ($uibModalInstance) {
            vm.renameFile(file, jQuery('#file-rename-input').val());
            $uibModalInstance.close();
          }
        }]
      };

      utils.modalAlert(modalParams);
    }

    renameFile (file, newName) {
      const requestUrl = `/project/${vm.projectId}/gdrive-file/rename`;

      return request(requestUrl, { fileId: file.id, fileName: newName }, 'PUT')
        .then((res) => {
          _.set(_.find(vm.fileList, { id: file.id }), 'name', newName);
        });
    }

    removeUploadFile (file) {
      vm.uploadFilesList = vm.uploadFilesList.filter((f) => f !== file);
    }

    async getAvailableFileOptions () {
      vm.isDesktopClientCheck();

      const availableFileOptions = { image: [], video: [], pdf: [], office: [], txt: [], emailFile: [], other: [] };

      const generalOptions = [
        { title: 'שינוי שם קובץ', action: vm.renameFileForm, icon: '<i class="fa fa-edit"></i>' },
        { title: 'הורדת קובץ', downloadUrl: vm.getFileUrl, icon: '<i class="fa fa-download"></i>' },
        { title: 'מחיקת קובץ', action: vm.confirmDeleteFile, icon: '<i class="fa fa-trash-o"></i>' }
      ];
      const showFile = { title: 'תצוגה מוגדלת', action: vm.showFile, icon: '<i class="fa fa-search"></i>' };
      const editFile = { title: 'עריכה בגוגל דרייב', action: vm.editFile, icon: '<img src="assets/icons/gdrive.svg">' };

      for (const option in availableFileOptions) {
        if (option !== 'other' && option !== 'office') {
          availableFileOptions[option].push(showFile);
        }
        if (option === 'office') {
          availableFileOptions[option].push(editFile);
        }
        availableFileOptions[option].push(...generalOptions);
      }

      return availableFileOptions;
    }

    async isDesktopClientCheck () {
      vm.desktopClientVersion = await request('http://localhost:1986/quicker/version', null, 'GET', { rootUrl: true }, true)
        .catch((res) => { });
      vm.desktopClientHasGfs = await request('http://localhost:1986/quicker/has-gfs', null, 'GET', { rootUrl: true }, true)
        .catch((res) => { });
      vm.isDesktopClientEnabled = vm.desktopClientVersion && vm.desktopClientHasGfs;
      return vm.isDesktopClientEnabled;
    }

    filesOptionsListFilter (option) {
      if (option.enabled) {
        return option.enabled();
      }
      return true;
    }
  }

  return new ProjectGdriveFileListCtrl();
}
