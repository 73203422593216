export default function ($scope, $rootScope, gdrive, $uibModal) {
  let vm;

  class ProjectImagesCtrl {
    constructor () {
      vm = this;
      vm.fileList = [];
    }

    $onInit () {
      vm.getImages();
      $scope.$on('projectLoaded', vm.getImages);
    }

    getImages () {
      vm.loading = true;
      gdrive.getImagesFromList(vm.projectShowCtrl.project.id, vm.images)
        .then((response) => {
          vm.fileList = response.data.data;
        })
        .finally(() => {
          vm.loading = false;
          $rootScope.$broadcast('resetSlickHeight');
        });
    }

    selectFiles () {
      return $uibModal.open({
        component: 'projectImagesSelect',
        windowClass: 'project-image-select-modal mobile-modal',
        size: 'lg',
        resolve: {
          images: () => vm.images,
          projectShowCtrl: () => vm.projectShowCtrl,
          limit: () => vm.limit
        }
      }).result
        .then((imageIds) => {
          vm.images = imageIds;
          vm.getImages();
          $rootScope.$broadcast('resetSlickHeight');
        })
        .catch(() => { /* modal was dismissed */ });
    }
  }

  return new ProjectImagesCtrl();
}
