/**
 * HOMER - Responsive Admin Theme
 * Copyright 2015 Webapplayers.com
 *
 * Sweet Alert Directive
 * Official plugin - http://tristanedwards.me/sweetalert
 * Angular implementation inspiring by https://github.com/oitozero/ngSweetAlert
 */

function sweetAlertService ($timeout, $window) {
  const swal = $window.swal;
  return {
    swal (arg1, arg2, arg3) {
      $timeout(() => {
        if (typeof (arg2) === 'function') {
          swal(arg1, (isConfirm) => {
            $timeout(() => {
              arg2(isConfirm);
            });
          }, arg3);
        } else {
          swal(arg1, arg2, arg3);
        }
      }, 200);
    },
    success (title, message) {
      $timeout(() => {
        swal(title, message, 'success');
      }, 200);
    },
    error (title, message) {
      $timeout(() => {
        swal(title, message, 'error');
      }, 200);
    },
    warning (title, message) {
      $timeout(() => {
        swal(title, message, 'warning');
      }, 200);
    },
    info (title, message) {
      $timeout(() => {
        swal(title, message, 'info');
      }, 200);
    }

  };
}

sweetAlertService.$inject = ['$timeout', '$window'];

const sweetAlertServiceModule = angular.module('sweetAlertService', [])
  .service('sweetAlert', sweetAlertService)
  .name;

export default sweetAlertServiceModule;
