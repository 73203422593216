function wizardOneCtrl ($scope, notify, sweetAlert) {
  // Initial user
  $scope.user = {
    username: 'Mark Smith',
    email: 'mark@company.com',
    password: 'secret_password',
    approve: false
  };

  // Initial step
  $scope.step = 1;

  // Wizard functions
  $scope.wizard = {
    show (number) {
      $scope.step = number;
    },
    next () {
      $scope.step++;
    },
    prev () {
      $scope.step--;
    }
  };

  $scope.submit = function () {
    // Show notification
    sweetAlert.swal({
      title: 'Thank you!',
      text: 'You approved our example form!',
      type: 'success'
    });

    // 'Redirect' to step 1
    $scope.step = 1;
  };
}

const wizardOneCtrlModule = angular.module('wizardOneCtrl', [])
  .controller('wizardOneCtrl', ['$scope', 'notify', 'sweetAlert', wizardOneCtrl])
  .name;

export default wizardOneCtrlModule;
