import settingsComponent from './settings.component';
import agentList from './agent-list/agent-list';
import collaborationSettings from './collaboration-settings/collaboration-settings';
import documentSettings from './document-settings/document-settings';
import linkedAccounts from './linked-accounts/linked-accounts';
import ownerDetailsEdit from './owner-details-edit/owner-details-edit';
import projectFoldersSettings from './project-folders-settings/project-folders-settings';
import projectTagsSettings from './project-tags-settings/project-tags-settings';
import projectAgentRelationsSettings from './project-agent-relations-settings/project-agent-relations-settings';
import projectDefaultEvents from './project-default-events/project-default-events';
import valuesOptionsCustomModule from './custom-options-values/custom-options-values';
import projectOptionsDetails from './custom-options-values/project-options-details/project-options-details';
import customOptionsStatus from './custom-options-values/custom-options-status/custom-options-status';
import excelImport from './excel-import/excel-import';
import agentService from './agent.service';
import settingsService from './settings.service';

const settingsModule = angular.module('settings', [
  agentList,
  collaborationSettings,
  documentSettings,
  linkedAccounts,
  ownerDetailsEdit,
  projectFoldersSettings,
  projectTagsSettings,
  projectAgentRelationsSettings,
  projectDefaultEvents,
  agentService,
  settingsService,
  valuesOptionsCustomModule,
  projectOptionsDetails,
  customOptionsStatus,
  excelImport
])
  .component('settings', settingsComponent)
  .name;

export default settingsModule;
