export default function ($scope, $state, $filter, ownerService, ticketService) {
  let vm;
  const uiSref = 'ticket.ticketShow({id: row.id})';

  class TicketListCtrl {
    constructor () {
      vm = this;
      vm.name = 'ownerTickets';

      vm.tableHeadList = [
        { name: 'createdAt', type: 'customFunction', customFunction: vm.getDateField, label: 'תאריך', sortable: true, uiSref },
        { name: 'number', type: 'customFunction', customFunction: vm.getNumberField, label: 'מספר', sortable: true, uiSref },
        { name: 'user', type: 'customFunction', customFunction: vm.getUserField, label: 'משתמש', sortable: true, uiSref },
        { name: 'title', label: 'נושא', sortable: true, uiSref },
        { name: 'status', type: 'customFunction', customFunction: vm.getStatusField, label: '', uiSref }
      ];
    }

    $onInit () {
      vm.ownerId = $state.params.id;
    }

    getTicketList (args) {
      return ownerService.getOwnerTickets(vm.ownerId, args);
    }

    getDateField (row) {
      return $filter('date')(row.createdAt, 'dd/MM/yyyy HH:mm');
    }

    getNumberField (row) {
      if (row.number) {
        return '#' + row.number;
      }
    }

    getUserField (row) {
      return `${_.get(row, 'user.firstName') || ''} ${_.get(row, 'user.lastName') || ''}`;
    }

    getStatusField (row) {
      if (!row.open) {
        return '<div class="badge badge-danger m-r-sm">סגור</div>';
      } else if (row.isWaiting) {
        return '<div class="badge badge-success m-r-sm">ממתין למענה</div>';
      } else {
        return '<div class="badge badge-default m-r-sm">פתוח</div>';
      }
    }
  }

  return new TicketListCtrl();
}
