export default function ($scope, $rootScope, projectService, settingsService, utils) {
  let vm;

  class CollaborationSettingsController {
    constructor () {
      vm = this;
      vm.panels = [{ isOpen: true }, {}];
    }

    $onInit () {
      vm.loadSettings();
      $scope.$on('settingsLoaded', () => {
        vm.loadSettings();
      });
    }

    loadSettings () {
      const owner = $rootScope.owner || {};
      vm.externalProjectsSettings = _.get(owner, 'settings.externalProjectsSettings', {});
      vm.externalProjectsSettings.displayName = vm.externalProjectsSettings.displayName || owner.name;
      vm.originalSettings = angular.copy(vm.externalProjectsSettings);
    }

    async saveSettings (changes = {}) {
      if (vm.saveProgress) { return; }
      if (vm.externalProjectsSettingsForm.$invalid) {
        document.getElementById('external-projects-settings-form').reportValidity();
        utils.setFormFieldsDirty(vm.externalProjectsSettingsForm);
        return;
      }

      vm.saveProgress = true;
      try {
        await settingsService.saveSettings(changes);
        vm.savedObject = angular.copy(vm.selectedValues);
        vm.externalProjectsSettingsForm.$setPristine();
        utils.notify('הגדרות נשמרו', 'alert-success');
        $rootScope.$broadcast('ownerSaved');
      } catch (error) {
        console.log(error);
      } finally {
        vm.saveProgress = false;
      }
    }

    resetSettings () {
      vm.externalProjectsSettings = angular.copy(vm.originalSettings);
      vm.externalProjectsSettingsForm.$setPristine();
    }
  }

  return new CollaborationSettingsController();
}
