const filesIdentifying = {
  isFolder (file) {
    return checkFileType(file, 'application/vnd.google-apps.folder');
  },
  isImage (file) {
    return checkFileType(file, 'image/png', 'image/jpg', 'image/gif', 'image/jpeg', 'image/gif');
  },
  isPdf (file) {
    return checkFileType(file, 'application/pdf');
  },
  isWord (file) {
    return checkFileType(file, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'application/vnd.google-apps.document');
  },
  isOdt (file) {
    return checkFileType(file, 'application/vnd.oasis.opendocument.text');
  },
  isExcel (file) {
    return checkFileType(file, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel');
  },
  isPpt (file) {
    return checkFileType(file, 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-powerpoint');
  },
  isAccess (file) {
    return checkFileType(file, 'application/vnd.ms-access');
  },
  isTxt (file) {
    return checkFileType(file, 'text/plain');
  },
  isVideo (file) {
    return checkFileType(file, 'video/mp4', 'video/avi', 'video/x-matroska', 'video/x-msvideo');
  },
  isEmailFile (file) {
    return checkFileType(file, 'application/vns.ms-outlook', 'message/rfc822');
  },
  isMsg (file) {
    return checkFileType(file, 'application/vns.ms-outlook');
  },
  isEml (file) {
    return checkFileType(file, 'message/rfc822');
  },
  isOffice (file) {
    return this.isWord(file) || this.isExcel(file) || this.isPpt(file);
  }
};

filesIdentifying.getFileIcon = function (file) {
  if (filesIdentifying.isImage(file)) {
    return 'zmdi zmdi-image red-icon';
  } else if (filesIdentifying.isPdf(file)) {
    return 'zmdi zmdi-collection-pdf red-icon';
  } else if (filesIdentifying.isWord(file)) {
    return 'zmdi flaticon-word';
  } else if (filesIdentifying.isExcel(file)) {
    return 'zmdi flaticon-excel green-icon';
  } else if (filesIdentifying.isPpt(file)) {
    return 'zmdi flaticon-powerpoint yellow-icon';
  } else if (filesIdentifying.isTxt(file)) {
    return 'zmdi zmdi-file-text blue-icon';
  } else if (filesIdentifying.isVideo(file)) {
    return 'zmdi zmdi-movie video-icon';
  } else if (filesIdentifying.isMsg(file)) {
    return 'zmdi zmdi-email msg-icon';
  } else if (filesIdentifying.isEml(file)) {
    return 'zmdi zmdi-email eml-icon';
  } else {
    return 'zmdi zmdi-file blue-icon';
  }
};

function checkFileType (file = {}, ...typeList) {
  return typeList.includes(file.mimeType);
}

filesIdentifying.getFileType = function (file) {
  if (filesIdentifying.isImage(file)) {
    return 'image';
  } else if (filesIdentifying.isVideo(file)) {
    return 'video';
  } else if (filesIdentifying.isPdf(file)) {
    return 'pdf';
  } else if (filesIdentifying.isOffice(file)) {
    return 'office';
  } else if (filesIdentifying.isTxt(file)) {
    return 'txt';
  } else if (filesIdentifying.isEmailFile(file)) {
    return 'emailFile';
  } else {
    return 'other';
  }
};

export default filesIdentifying;
