export default function ($scope, $rootScope, $state, $timeout, $uibModal, utils, settingsService, ownerService, permissions) {
  let vm;

  class SettingsCtrl {
    constructor () {
      vm = this;
      vm.name = 'settings';
      vm.files = [];
      vm.permissions = permissions;

      vm.panels = [
        {}, {}
      ];
    }

    $onInit () {
      vm.settings = {};
      vm.getSettings();
      $scope.$on('ownerSaved', (owner) => {
        vm.getSettings();
      });

      $scope.$watch('vm.settings', (newVal, oldVal) => {
        if (_.get(vm.forms, 'settingsForm')) {
          vm.forms.settingsForm.$setDirty();
        }
      }, true);
    }

    getSettings () {
      return settingsService.getSettings()
        .then((result) => {
          vm.settings = result.settings;
          vm.files = result.files;
          $rootScope.owner.settings = result.settings;
          $rootScope.owner.crmSettings = result.crmSettings;
          $rootScope.owner.files = vm.files;
          vm.originalSetting = angular.copy(result.settings);
          vm.originalFiles = angular.copy(result.files);
          $rootScope.$broadcast('settingsLoaded', vm.settings);

          if (!vm.forms) {
            return vm.settings;
          }

          Object.keys(vm.forms).forEach((formName) => {
            if (vm.forms[formName]) {
              $timeout(() => vm.forms[formName].$setPristine(), 100);
            }
          });

          return vm.settings;
        });
    }

    editOwnerDetails () {
      return $uibModal.open({
        component: 'ownerDetailsEdit',
        windowClass: 'owner-details-modal mobile-modal',
        size: 'lg',
        backdrop: 'static'
      });
    }

    editOwnerLogo () {
      return $uibModal.open({
        component: 'ownerDetailsEdit',
        resolve: {
          logoEditMode: () => true
        },
        windowClass: 'owner-details-modal mobile-modal',
        size: 'md',
        backdrop: 'static'
      });
    }

    resetSettings () {
      vm.settings = angular.copy(vm.originalSetting);
      vm.files = angular.copy(vm.originalFiles);

      if (!vm.forms) {
        return;
      }
      Object.keys(vm.forms).forEach((formName) => {
        if (vm.forms[formName]) {
          $timeout(() => vm.forms[formName].$setPristine(), 100);
        }
      });
    }

    saveSettings (form) {
      if (vm.saveProgress) {
        return;
      }

      const settingsObject = {};
      Object.keys(form.$$controls).forEach((item) => {
        settingsObject[form.$$controls[item].$name] = vm.settings[form.$$controls[item].$name] || null;
      });

      vm.saveProgress = true;
      return settingsService.saveSettings(settingsObject)
        .then((result) => {
          utils.notify('הגדרות נשמרו', 'alert-success');
          vm.getSettings();
          return result;
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    getOwnerLogo () {
      return ownerService.getOwnerLogo($rootScope.owner) || '/assets/images/owner-logo.png';
    }

    getFileNameById (fileId) {
      if (!fileId) { return; }
      const file = vm.files.filter((file) => file.id === fileId)[0];
      if (!file) { return; }
      const fileName = window.SERVER_URL + '/file/settings/' + file.name + file.ext;
      return fileName;
    }
  }

  return new SettingsCtrl();
}
