export default function ($scope, $rootScope, projectService) {
  let vm;

  class ProjectAlertsController {
    constructor () {
      vm = this;
      vm.name = 'projectAlerts';
      vm.projectAlertList = [];
      vm.orderBy = localStorage.getItem('projectAlertsOrderBy') || 'dueDate';
    }

    $onInit () {
      $scope.$watch('$root.counts.projectAlerts', (newValue, oldValue) => {
        if (newValue !== oldValue && newValue > 0) {
          vm.getProjectAlerts(true);
        } else {
          vm.projectAlertList = [];
        }
      });
      $rootScope.$on('getProjectAlerts', () => {
        vm.getProjectAlerts(true);
      });
      vm.getProjectAlerts();
    }

    async getProjectAlerts (refresh) {
      const projectAlertList = await projectService.getProjectAlerts(refresh) || [];
      vm.projectAlertList = projectAlertList.map((project) => {
        project.address = [
          project.street,
          project.house,
          project.city
        ].join(' ').trim() || 'ללא כתובת';
        return project;
      });
    }

    toggleOrderBy () {
      vm.orderBy = (vm.orderBy === '-dueDate' ? '' : '-') + 'dueDate';
      localStorage.setItem('projectAlertsOrderBy', vm.orderBy);
    }
  }

  return new ProjectAlertsController();
}
