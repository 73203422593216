import helpers from './helpers';

export default ['$stateProvider', ($stateProvider) => {
  $stateProvider
    .state('admin', {
      redirectTo: 'admin.ownerList'
    })
    .state('admin.ownerList', {
      url: '/admin/owner',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.adminAuth]
      },
      component: 'ownerList'
    })
    .state('admin.ownerShow', {
      url: '/admin/owner/:id',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.adminAuth]
      },
      params: {
        activeTab: 0
      },
      component: 'ownerShow'
    })
    .state('admin.ownerShow.ownerUsers', {
      url: '/users',
      params: {
        activeTab: 1
      }
    })
    .state('admin.ownerShow.support', {
      url: '/support',
      params: {
        activeTab: 2
      }
    })
    .state('admin.ownerShow.ownerCustomers', {
      url: '/customers',
      params: {
        activeTab: 3
      }
    })
    .state('admin.ownerShow.ownerProjects', {
      url: '/projects',
      params: {
        activeTab: 4
      }
    })
    .state('admin.ownerShow.ownerPayments', {
      url: '/payments',
      params: {
        activeTab: 5
      }
    })
    .state('admin.ownerShow.log', {
      url: '/log',
      params: {
        activeTab: 6
      }
    })
    .state('admin.userList', {
      url: '/admin/user',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.adminAuth]
      },
      component: 'userList'
    })
    .state('admin.userShow', {
      url: '/admin/user/:id',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.adminAuth]
      },
      params: {
        activeTab: 0
      },
      component: 'userShow'
    })
    .state('admin.userShow.support', {
      url: '/support',
      params: {
        activeTab: 1
      }
    })
    .state('admin.userShow.log', {
      url: '/log',
      params: {
        activeTab: 2
      }
    })
    .state('admin.adminPaymentsList', {
      url: '/admin/payments',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.adminAuth]
      },
      component: 'adminPaymentsList'
    })
    .state('admin.adminTicketList', {
      url: '/admin/ticket',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.adminAuth]
      },
      params: {
        activeTab: 0
      },
      component: 'adminTicketList'
    });
}];
