import dayjs from 'dayjs';

export default function ($scope, $rootScope, $state, $stateParams, $uibModal, $timeout, utils, sweetAlert, projectService, recipient, noteService, taskService, callService, counts, permissions) {
  let vm;

  class ProjectShowCtrl {
    constructor () {
      vm = this;

      vm.priceTotal = 0;
      vm.transactionTotal = 0;
      vm.permissions = permissions;
    }

    async $onInit () {
      vm.projectFields = {};
      vm.$stateParams = $stateParams;
      vm.navigation = vm.$stateParams.navigation;
      vm.navigationIndex = vm.$stateParams.navigationIndex;
      vm.navigationChildView = '';

      if (_.get(vm.navigation, 'sref') === 'search') {
        vm.returnToSref = `search({
          type: vm.navigation.type,
          query: '${encodeURIComponent(JSON.stringify(vm.navigation.query.filter).replace(/:"%/g, ':"'))}',
          index: vm.navigationIndex
        })`;
      } else if (_.get(vm.navigation, 'sref') === 'task.taskList') {
        vm.returnToSref = `${vm.navigation.sref}({
          query: vm.navigation.query,
          index: vm.$stateParams.navigation.index,
          pinnedIndex: vm.$stateParams.navigation.pinnedIndex
        })`;
      } else if (_.get(vm.navigation, 'sref') === 'customer.customerShow') {
        vm.returnToSref = `${vm.navigation.sref}(${vm.navigation.srefParams})`;
      } else if (_.get(vm.navigation, 'sref')) {
        vm.returnToSref = `${vm.navigation.sref}({
          query: vm.navigation.query,
          index: vm.navigationIndex
        })`;
      }
      if (_.get(vm.navigation, 'sref') === 'report.projectPayments') {
        vm.navigationChildRoute = '.payments';
      }

      let navigationType;
      switch (_.get(vm.navigation, 'sref')) {
        case 'search':
          navigationType = vm.navigation.type;
          break;
        // case 'task.taskList':
        //   navigationType = 'tasks';
        //   break;
        case 'project.projectList':
          navigationType = 'projects';
          break;
        case 'report.projectPayments':
          navigationType = 'report';
          break;
        default:
          navigationType = 'projects';
      }

      $scope.$on('projectSaved', () => {
        vm.getProject(vm.projectFields.id);
      });
      $scope.$on('transactionSaved', () => {
        vm.getProject(vm.projectFields.id);
      });
      $scope.$on('pricesSaved', () => {
        vm.getProject(vm.projectFields.id);
      });
      $scope.$on('noteSaved', () => {
        vm.getNotes();
      });
      $scope.$on('callSaved', () => {
        vm.getCalls();
      });

      vm.initProject(vm.project);
      vm.recipientList = await recipient.list();
      const siblingsQuery = _.get(vm.navigation, 'query');
      vm.nextIndex = vm.navigationIndex + 1;
      vm.previousIndex = vm.navigationIndex - 1;

      // if (_.get(vm.navigation, 'sref') === 'task.taskList' || _.get(vm.navigation, 'type') === 'tasks') {
      //   siblingsQuery = null;
      //   vm.nextIndex = vm.navigationIndex;
      //   vm.previousIndex = vm.navigationIndex;
      // }

      projectService.getProjectSiblings(navigationType, vm.projectFields.id, siblingsQuery, vm.navigationIndex)
        .then((siblings) => {
          vm.previousProject = siblings.prev;
          vm.nextProject = siblings.next;
        });
    }

    getProject (projectId) {
      return projectService.getProject(projectId)
        .then((project) => {
          return vm.initProject(project);
        })
        .then(() => {
          $rootScope.$broadcast('projectLoaded');
        });
    }

    getCalls () {
      return callService.getProjectCallList(vm.projectFields.id)
        .then((callList) => {
          vm.projectFields.calls = angular.copy(callList);
        });
    }

    getNotes () {
      return noteService.getProjectNoteList(vm.projectFields.id)
        .then((noteList) => {
          vm.projectFields.notes = angular.copy(noteList);
        });
    }

    initProject (project) {
      if (!project) { // TODO perhaps a friendly message?
        $state.go('project.projectList');
        return;
      }
      vm.projectFields = angular.copy(project);
      return vm.projectFields;
    }

    duplicateProject () {
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: 'האם לשכפל פרויקט?',
        buttons: [{
          label: 'לא, ביטול'
        },
        {
          label: 'כן, שכפל',
          btnClass: 'btn-primary',
          onClick ($uibModalInstance) {
            $uibModalInstance.close();
            return true;
          }
        }]
      };

      return utils.modalAlert(modalParams)
        .then((isConfirm) => {
          if (isConfirm) {
            return projectService.duplicateProject(vm.projectFields.id)
              .then((result) => {
                const projectCopyNumber = _.get(result, 'number');
                const message = 'פרויקט שוכפל בהצלחה' + (projectCopyNumber ? ' (פרויקט ' + projectCopyNumber + ')' : '');
                utils.notify(message, 'alert-success');
              })
              .catch(() => {
                const message = 'שגיאה בעת שכפול פרויקט';
                utils.notify(message, 'alert-danger');
              });
          }
        });
    }

    editProjectForm () {
      $uibModal.open({
        component: 'projectEdit',
        resolve: {
          project: () => vm.projectFields,
          projectId: () => vm.projectFields.id,
          hideCustomer: () => true
        },
        windowClass: 'project-edit-modal mobile-modal',
        size: 'lg',
        backdrop: 'static'
      });
    }

    editProjectAddressForm () {
      $uibModal.open({
        component: 'projectAddressEdit',
        resolve: {
          project: () => vm.projectFields,
          projectId: () => vm.projectFields.id
        },
        windowClass: 'project-edit-modal mobile-modal',
        size: 'lg',
        backdrop: 'static'
      });
    }

    addCustomerForm () {
      $uibModal.open({
        component: 'customerEdit',
        resolve: {
          onSaveCustomer: () => {
            return (params) => {
              vm.saveCustomerToProject(params.customerId);
            };
          }
        },
        windowClass: 'customer-edit-modal mobile-modal',
        size: 'md',
        backdrop: 'static'
      });
    }

    saveCustomerToProject (customerId) {
      projectService.saveProject(vm.projectFields.id, { customerId })
        .then(() => {
          const message = (customerId ? 'לקוח נוסף לפרויקט' : 'לקוח הוסר מהפרויקט');
          utils.notify(message, 'alert-success');
          $rootScope.$broadcast('projectSaved');
        });
    }

    removeCustomerFromProject () {
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: 'האם להסיר לקוח?',
        buttons: [{
          label: 'לא, ביטול'
        },
        {
          label: 'כן, אישור הסרה',
          btnClass: 'btn-primary',
          onClick ($uibModalInstance) {
            $uibModalInstance.close();
            return true;
          }
        }]
      };

      return utils.modalAlert(modalParams)
        .then((isConfirm) => {
          if (isConfirm) {
            vm.saveCustomerToProject('');
          }
        });
    }

    addCustomerToProjectForm () {
      $uibModal.open({
        component: 'customerSearch',
        resolve: {
          placeholder: () => 'חיפוש לקוח קיים',
          onCustomerClicked: () => vm.addCustomerToProject
        },
        windowClass: 'customer-search-modal mobile-modal',
        size: 'lg'
      });
    }

    addCustomerToProject (customer) {
      if (customer.id) {
        vm.saveCustomerToProject(customer.id);
      }
    }

    deleteProject () {
      if (!$rootScope.isManager()) {
        return;
      }

      const modalParams = {
        windowClass: 'hmodal-danger',
        title: 'האם למחוק פרויקט?',
        message: `
          מחיקת הפרויקט תביא למחיקת כל המשימות, הערות, תיעודי שיחות, מחירים ותשלומים בפרויקט.
          תיקיית הקבצים בגוגל דרייב תועבר לאשפה.
          לא ניתן יהיה לשחזר את הפעולה.`,
        buttons: [{
          label: 'לא, ביטול'
        },
        {
          label: 'כן, למחוק',
          btnClass: 'btn-danger',
          onClick ($uibModalInstance) {
            $uibModalInstance.close();
            return true;
          }
        }]
      };

      return utils.modalAlert(modalParams)
        .then((isConfirm) => {
          if (isConfirm) {
            return projectService.deleteProject(vm.projectFields.id)
              .then((result) => {
                const message = 'הפרויקט נמחק';
                utils.notify(message, 'alert-success');
                $state.go('project.projectList');
              })
              .catch(() => {
                const message = 'שגיאה בעת מחיקת פרויקט';
                utils.notify(message, 'alert-danger');
              });
          }
        });
    }

    projectTotalTasksAndNotes () {
      return [
        _.get(vm.projectFields, 'tasks.length') || 0,
        _.get(vm.projectFields, 'notes.length') || 0,
        _.get(vm.projectFields, 'calls.length') || 0]
        .reduce((previous, current) => {
          return previous + current;
        }, 0);
    }

    projectHasNotDoneTasks () {
      return (_.get(vm.projectFields, 'tasks') || []).some((task) => task.status !== 'done');
    }

    isPaidClassName () {
      if (vm.projectFields.toPayTotal > 0) {
        if (vm.projectFields.toPayTotal < vm.projectFields.priceTotal) {
          return 'partially-paid';
        } else {
          return 'not-paid';
        }
      }
      return 'paid';
    }

    addressString () {
      let addressString = '';
      addressString += vm.projectFields.street ? vm.projectFields.street : '';
      addressString += vm.projectFields.house ? ' ' + vm.projectFields.house : '';
      addressString += vm.projectFields.apartmentNumber ? ' ' + 'דירה ' + vm.projectFields.apartmentNumber : '';
      if (addressString.trim().length && vm.projectFields.city) {
        addressString += ', ';
      }
      addressString += vm.projectFields.city || '';

      return addressString;
    }

    dueDateClassName () {
      if (
        _.get(vm, 'projectFields.status') &&
        !['הצעה', 'הושלם', 'מבוטל'].includes(_.get(vm, 'projectFields.status')) &&
        dayjs(_.get(vm, 'projectFields.dueDate')).isBefore(dayjs().startOf('day').add(3, 'days'))
      ) {
        return 'text-danger';
      }
      return '';
    }

    isPaymentTabShown () {
      return $rootScope.isManager() ||
        (vm.permissions.can('view', 'projectPayments') &&
          !_.get(vm.projectFields.metadata, 'paymentDetailsProtected'));
    }

    hasDocumentsModule () {
      return _.get($rootScope.owner, 'crmSettings.documents', []).length;
    }
  }

  return new ProjectShowCtrl();
}
