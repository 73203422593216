import template from './bid-show.html';
import controller from './bid-show.controller';
import './bid-show.scss';

const bidShowComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', 'bidService'];

export default bidShowComponent;
