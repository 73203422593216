import dayjs from 'dayjs';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import heLocale from '@fullcalendar/core/locales/he';

const hebrewHolydaysUrl = 'https://www.hebcal.com/hebcal?cfg=fc&v=1&i=on&maj=on&min=off&mf=on&mod=on&lg=h';

export default function ($scope, $rootScope, $state, $timeout, projectService, settingsService, utils, permissions) {
  let vm;

  class ProjectCalendarCtrl {
    constructor () {
      vm = this;
      vm.name = 'ProjectCalendar';

      vm.listType = {
        createdAt: {
          text: 'תאריך יצירה',
          color: '#3788d8',
          display: 'list-item'
        },
        dueDate: {
          text: 'תאריך יעד',
          color: '#d9534f',
          display: 'list-item'
        }
      };

      vm.customButtons = {};

      vm.explodeCompletedProjects = localStorage.getItem('project_calendar_explode_completed_projects') === 'true';
      Object.keys(vm.listType).forEach((key) => {
        vm.listType[key].disabled = localStorage.getItem(`project_calendar_${key}_disabled`) !== 'false';

        vm.customButtons[key + 'Toggle'] = {
          text: vm.listType[key].text,
          click () {
            $scope.$apply(vm.listType[key].disabled = !vm.listType[key].disabled);
            localStorage.setItem(`project_calendar_${key}_disabled`, vm.listType[key].disabled);
            vm.calendar.refetchEvents();
          }
        };
      });

      vm.customButtons.toggleCompletedProjects = {
        text: 'ללא פרויקטים שהושלמו',
        click () {
          $scope.$apply(vm.explodeCompletedProjects = !vm.explodeCompletedProjects);
          localStorage.setItem('project_calendar_explode_completed_projects', vm.explodeCompletedProjects);
          vm.calendar.refetchEvents();
        }
      };

      if (!Object.values(vm.listType).find((type) => !type.disabled)) {
        vm.listType.createdAt.disabled = false;
      }

      vm.fullcalendarOption = {
        plugins: [dayGridPlugin],
        customButtons: vm.customButtons,
        headerToolbar: {
          right: 'prev,next today title',
          left: 'createdAtToggle,dueDateToggle toggleCompletedProjects dayGridMonth,dayGridWeek',
          center: ''
        },
        direction: 'rtl',
        locale: heLocale,
        eventSources: [
          vm.getProjectList,
          {
            events: vm.getHebrewHolydays,
            color: '#ffffff',
            textColor: '#3888d8'
          }
        ],
        eventOrder: 'hebrew,number,title',
        views: {
          dayGridMonth: {
            dayMaxEvents: 4
          },
          dayGridWeek: {
            dayMaxEventRows: true// adjust to 6 only for timeGridWeek/timeGridDay
          }
        },
        eventClick (arg) {
          if (arg.event.url) {
            window.open(arg.event.url, '_blank');
          }
          arg.jsEvent.preventDefault();
        },
        eventDidMount (info) {
          if (!info.event.groupId) { return; }
          const project = info.event.extendedProps.project || {};
          info.el.querySelector('.fc-event-title').innerHTML +=
            `<span title="${vm.listType[info.event.groupId].text} \nסטטוס: ${project.status}">${vm.getProjectString(project)}</span>`;
        }
      };
    }

    $onInit () {
      $timeout(() => {
        $rootScope.showFooter = false;
      });
      vm.agentList = _.get($rootScope, 'owner.users') || [];
      if (vm.agentList.length > 1 && !$rootScope.isManager()) {
        vm.agentFilter = $rootScope.user.id;
      }
    }

    initCalendar () {
      vm.calendarEl = document.getElementById('calendar');
      vm.calendar = new Calendar(vm.calendarEl, vm.fullcalendarOption);
      vm.calendar.render();
    }

    getProjectList (info, successCallback, failureCallback) {
      vm.loading = true;

      vm.projectList = [];
      const dateRange = '&gt:' + dayjs(info.start.valueOf()).format() +
        '|&lt:' + dayjs(info.end.valueOf()).format();

      return Promise.all(Object.keys(vm.listType).filter((key) => !vm.listType[key].disabled)
        .map((key) => {
          const query = {
            order: key,
            limit: 400,
            filter: {
              [key]: dateRange,
              status: '!מבוטל'
            }
          };

          if (vm.agentFilter) {
            query.filter.agent = vm.agentFilter;
          }
          if (vm.explodeCompletedProjects) {
            query.filter.status += '|!הושלם';
          }

          return projectService.getProjectList(false, query)
            .then((list) => {
              vm.projectList.push(...list.rows.map((project) => {
                return {
                  project,
                  id: project.id,
                  title: `${!$rootScope.hasAddress ? 'פרויקט ' : ''}${project.number}`,
                  start: dayjs(project[key]).format('YYYY-MM-DD'),
                  url: $state.href('project.projectShow', { id: project.id }, { absolute: true }),
                  display: vm.listType[key].display,
                  groupId: key,
                  color: vm.listType[key].color,
                  backgroundColor: vm.listType[key].backgroundColor
                };
              }));
            });
        }))
        .catch((err) => { failureCallback(err); })
        .finally(() => {
          successCallback(vm.projectList);
          vm.loading = false;
        });
    }

    onAgentChanged (selectedAgent) {
      vm.agentFilter = selectedAgent;
      vm.calendar.refetchEvents();
    }

    getProjectString (project) {
      if (!project.id) { return; }
      let string = '';
      if ($rootScope.hasAddress) {
        string += `${project.street || ''} ${project.house || ''} ${project.city || ''}`;
      }
      string += _.get(project, 'customer.name') ? ` - ${project.customer.name}` : '';
      return string;
    }

    calendarClasses () {
      let classList = Object.keys(vm.listType)
        .filter((key) => vm.listType[key].disabled)
        .map((key) => key + '-disabled').join(' ');
      classList += vm.explodeCompletedProjects ? ' explodeCompletedProjects' : '';
      return classList;
    }

    getHebrewHolydays (info, successCallback, failureCallback) {
      jQuery.get(hebrewHolydaysUrl + '&' + jQuery.param({
        start: dayjs(info.start.valueOf()).format(),
        end: dayjs(info.end.valueOf()).format()
      }))
        .then((response) => {
          response = response.filter((item) => {
            item.title = item.hebrew.replace('צום תמוז', 'צום יז בתמוז');
            delete item.url;
            return !['סיגד', 'תענית בכורות', 'סליחות', 'יום העלייה'].includes(item.hebrew) && !item.hebrew.includes('ערב');
          });
          successCallback(response);
        })
        .catch((err) => { failureCallback(err); });
    }
  }

  return new ProjectCalendarCtrl();
}
