export default function (realEstateInformationService) {
  let vm;

  class RealEstateInformationSearchController {
    constructor () {
      vm = this;
      vm.name = 'realEstateInformationSearch';
    }

    $onInit () {
      vm.address = _.get(vm, 'resolve.address');
      vm.realEstateInformation = [];
      vm.getRealEstateInformation();
    }

    fieldChanged () {
      vm.getRealEstateInformation();
    }

    getRealEstateInformation () {
      const query = {
        limit: 5,
        city: vm.address.city,
        street: vm.address.street
      };

      realEstateInformationService.query(query)
        .then((result) => {
          vm.realEstateInformation = result;
        });
    }

    removeProperty (property, index) {
      vm.realEstateInformation.splice(index, 1);
    }

    cancel () {
      vm.modalInstance.close();
    }

    saveResultList () {
      if (!vm.realEstateInformation.length) {
        return;
      }
      vm.resolve.onResult({
        list: vm.realEstateInformation
      });
      vm.modalInstance.close();
    }
  }

  return new RealEstateInformationSearchController();
}
