import dayjs from 'dayjs';

export default function ($scope, $rootScope, eventService, request) {
  let vm;

  class EventsController {
    constructor () {
      vm = this;
      vm.today = new Date().getDate();
      vm.name = 'events';
      vm.eventList = [];
    }

    $onInit () {
      $scope.$watch('$root.counts.events', (newValue, oldValue) => {
        if (newValue !== oldValue && oldValue && newValue > 0) {
          vm.getEvents();
        } else {
          vm.eventList = [];
        }
      });
      vm.getEvents();
    }

    async getEvents () {
      vm.eventList = await eventService.getEventList({
        includeProject: true,
        includeCustomer: true,
        filter: {
          dateTime: `&gt:${dayjs().startOf('day').toISOString()}|&lt:${dayjs().endOf('day').toISOString()}`
        }
      }) || [];
    }

    eventAddress (event) {
      return [
        event.project.street,
        event.project.house,
        event.project.city
      ].join(' ').trim() || 'ללא כתובת';
    }
  }

  return new EventsController();
}
