import helpers from './helpers';

export default ['$stateProvider', ($stateProvider) => {
  $stateProvider
    .state('customer', {
      redirectTo: 'customer.customerList'
    })
    .state('customer.customerList', {
      url: '/customers',
      params: {
        index: undefined
      },
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth]
      },
      component: 'customerList'
    })
    .state('customer.customerShow', {
      url: '/customers/:id',
      params: {
        navigation: null,
        navigationIndex: null
      },
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth],
        customer: ['$stateParams', 'customerService', ($stateParams, customerService) => {
          return customerService.getCustomer($stateParams.id)
            .catch(() => null);
        }]
      },
      component: 'customerShow'
    });
}];
