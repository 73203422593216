export default function ($scope, $rootScope, $state, $filter, ticketService) {
  let vm;
  const uiSref = 'ticket.ticketShow({id: row.id})';

  class TicketListCtrl {
    constructor () {
      vm = this;
      vm.name = 'ticketList';

      vm.tableHeadList = [
        { name: 'createdAt', type: 'customFunction', customFunction: vm.getDateField, label: 'תאריך', sortable: true, uiSref },
        { name: 'number', type: 'customFunction', customFunction: vm.getNumberField, label: 'מספר', sortable: true, uiSref },
        { name: 'title', label: 'נושא', sortable: true, uiSref },
        { name: 'publicLabels', label: '', type: 'customFunction', customFunction: vm.getPublicLabels, uiSref },
        { name: 'closed', type: 'customFunction', customFunction: vm.getClosedField, label: '', uiSref },
        { name: 'unreadPosts', type: 'customFunction', customFunction: vm.getUnreadPosts, label: '', uiSref }
      ];

      vm.publicLabels = ticketService.publicLabels;
    }

    $onInit () {
      $scope.$on('ticketSaved', () => {
        vm.getTicketList();
      });
    }

    labelStyle (title) {
      const label = vm.publicLabels.find((label) => label.title === title);
      return `color: ${label.color}; background: ${label.background}`;
    }

    getTicketList (args) {
      return ticketService.getTicketList(args);
    }

    getDateField (row) {
      return $filter('date')(row.createdAt, 'dd/MM/yyyy HH:mm');
    }

    getNumberField (row) {
      if (row.number) {
        return '#' + row.number;
      }
    }

    getPublicLabels (row) {
      if (row.publicLabelsString) {
        return row.publicLabelsString;
      }

      if (!_.get(row, 'publicLabels.length')) {
        row.publicLabelsString = '';
      } else {
        row.publicLabelsString = row.publicLabels.map((label) => {
          return `<span class="badge" style="${vm.labelStyle(label)}">${label}</span>`;
        }).join(' ');
      }
      return row.publicLabelsString;
    }

    getClosedField (row) {
      if (!row.open) {
        return '<div class="badge badge-danger m-r-sm">פניה סגורה</div>';
      }
    }

    getUnreadPosts (row) {
      if (row.unreadPosts && row.userId === $rootScope.user.id) {
        return '<div class="badge badge-success">' + row.unreadPosts + '</div>';
      }
    }
  }

  return new TicketListCtrl();
}
