import dayjs from 'dayjs';
import optionsList from '../../../components/project/project-options-list';

export default function ($scope, $rootScope, $state, $stateParams, $location, $filter, $uibModal, projectService, customerService, taskService, utils, settingsService, userService) {
  let vm;

  function uiSrefParams (state, rowId, type) {
    return `${state}({
      id: ${rowId},
      navigation: {
        query: {
          filter: vm.filter,
          order: vm.paging.orderBy,
          desc: vm.paging.orderDesc
        },
        sref: 'search',
        type: '${type}'
      },
      navigationIndex: vm.paging.offset + rowIndex
    })`.replace(/\s+/g, '');
  }

  const projectsUiSref = uiSrefParams('project.projectShow', 'row.id', 'projects');
  const projectsPaymentsUiSref = uiSrefParams('project.projectShow.payments', 'row.id', 'projects');
  const customersUiSref = uiSrefParams('customer.customerShow', 'row.id', 'customers');
  // const tasksUiSref = uiSrefParams('project.projectShow', 'row.projectId', 'tasks');

  const tasksUiSref = 'project.projectShow({id: row.projectId})';

  class AdvancedSearchResultsController {
    constructor () {
      vm = this;
      vm.name = 'advancedSearchResults';
      vm.$stateParams = $stateParams;

      vm.statusOptions = optionsList.statusOptions.map((option) => option.name);
      vm.projectAgentRelations = _.get($rootScope, 'owner.settings.projectAgentRelations') || [];

      vm.isObject = angular.isObject;
      vm.filterNames = {
        projectCreatedFrom: {
          title: 'מתאריך פתיחה',
          type: 'date'
        },
        projectCreatedTo: {
          title: 'עד תאריך פתיחה',
          type: 'date'
        },
        projectDueDateForm: {
          title: 'מתאריך יעד',
          type: 'date'
        },
        projectDueDateTo: {
          title: 'עד תאריך יעד',
          type: 'date'
        },
        projectNumberFrom: {
          title: 'ממספר פרויקט',
          type: 'number'
        },
        projectNumberTo: {
          title: 'עד מספר פרויקט',
          type: 'number'
        },
        customerNumberFrom: {
          title: 'ממספר לקוח',
          type: 'number'
        },
        customerNumberTo: {
          title: 'עד מספר לקוח',
          type: 'number'
        },
        customerName: 'שם לקוח',
        // referrer: 'גורם מפנה',
        street: 'רחוב',
        house: 'מספר בית',
        city: 'עיר',
        gush: 'גוש',
        helka: 'חלקה',
        status: {
          title: 'סטטוס',
          type: 'values',
          values: {
            '!מבוטל|$null': 'הכל - ללא מבוטלים'
          }
        },
        tags: {
          title: 'תוויות',
          type: 'arrayValues',
          values: []
        },
        agent: {
          title: 'נציג אחראי',
          type: 'values',
          values: {}
        },
        name: 'שם לקוח',
        address: 'כתובת לקוח',
        email: 'דוא"ל',
        phone: 'טלפון',
        assignUser: {
          title: 'משויך לנציג',
          type: 'values',
          values: {}
        },
        done: {
          title: 'בוצע',
          type: 'values',
          values: {
            true: 'בוצע',
            false: 'לא בוצע'
          }
        },
        pinned: {
          title: 'מוצמד',
          type: 'values',
          values: {
            true: 'מוצמד',
            false: 'לא מוצמד'
          }
        },
        taskCreatedFrom: {
          title: 'מתאריך יצירה',
          type: 'date'
        },
        taskCreatedTo: {
          title: 'עד תאריך יצירה',
          type: 'date'
        }
      };
      vm.statusOptions.forEach((option) => {
        vm.filterNames.status.values[option] = option;
      });
      vm.projectAgentRelations.forEach((relation) => {
        vm.filterNames[`relatedAgents.${relation.id}`] = {
          title: relation.title,
          type: 'values',
          values: {}
        };
      });

      // const enabledColumns = settingsService.getOwnerTableColumns('projectList');
      vm.projectsTableHeadList = [
        { name: 'number', label: 'פרויקט', sortable: true, uiSref: projectsUiSref },
        { name: 'createdAt', label: 'תאריך יצירה', type: 'date', sortable: true, uiSref: projectsUiSref },
        { name: 'dueDate', label: 'תאריך יעד', type: 'customFunction', customFunction: vm.getProjectDueDate, sortable: true, uiSref: projectsUiSref },
        { name: 'customer.name', label: 'לקוח', sortable: true, type: 'customFunction', customFunction: vm.getCustomer, uiSref: projectsUiSref },
        { name: 'street', label: 'כתובת', type: 'customFunction', customFunction: vm.getAddress, sortable: true, uiSref: projectsUiSref, if: () => !!$rootScope.hasAddress },
        { name: 'city', label: 'עיר', sortable: true, uiSref: projectsUiSref, if: () => !!$rootScope.hasAddress },
        { name: 'gush', label: 'גוש', sortable: true, uiSref: projectsUiSref, if: () => !!$rootScope.hasAddress },
        { name: 'helka', label: 'חלקה', sortable: true, uiSref: projectsUiSref, if: () => !!$rootScope.hasAddress },
        { name: 'subHelka', label: 'תת חלקה', sortable: true, uiSref: projectsUiSref, if: () => !!$rootScope.hasAddress },
        { name: 'status', label: 'סטטוס', type: 'customFunction', customFunction: vm.getStatus, sortable: true, uiSref: projectsUiSref },
        // { name: 'referrer', label: 'גורם מפנה', sortable: true, uiSref: projectsUiSref },
        { name: 'agent', label: 'נציג אחראי', if: vm.isAgentColumnShown, type: 'compile', customFunction: vm.getAgentString, sortable: true, uiSref: projectsUiSref },
        { name: 'tasks', label: 'משימות', type: 'customFunction', customFunction: vm.hasTasks, uiSref: projectsUiSref, elementClass: 'badge badge-primary' },
        { name: 'paid', label: 'שולם', type: 'compile', customFunction: vm.isPaid, uiSref: projectsPaymentsUiSref, clickFunction: vm.paymentTdClick }
      ];
      // vm.projectsTableHeadList.forEach(column => {
      //   column.enabled = enabledColumns.includes(column.name);
      // });

      vm.customersTableHeadList = [
        { name: 'number', label: 'מספר לקוח', sortable: true, uiSref: customersUiSref },
        { name: 'name', label: 'שם לקוח', sortable: true, uiSref: customersUiSref },
        { name: 'idNumber', label: 'מספר זהות', sortable: true, uiSref: customersUiSref },
        { name: 'address', label: 'כתובת', sortable: true, uiSref: customersUiSref },
        { name: 'city', label: 'עיר', sortable: true, uiSref: customersUiSref },
        { name: 'phone', label: 'טלפון', uiSref: customersUiSref, elementClass: 'phone' },
        { name: 'email', label: 'דוא"ל', uiSref: customersUiSref, elementClass: 'email' }
      ];

      vm.tasksTableHeadList = [
        { name: 'createdAt', label: 'תאריך', type: 'date', sortable: true, uiSref: tasksUiSref },
        { name: 'project', label: 'פרויקט', type: 'customFunction', customFunction: vm.getProjectString, uiSref: tasksUiSref },
        { name: 'customer', label: 'לקוח', type: 'customFunction', customFunction: vm.getProjectCustomerString, uiSref: tasksUiSref },
        { name: 'assignUserImage', if: vm.isAgentColumnShown, type: 'customFunction', customFunction: vm.getUserProfileImage, uiSref: tasksUiSref },
        { name: 'assignUser', label: 'משויך לנציג', if: vm.isAgentColumnShown, type: 'customFunction', customFunction: vm.getUserString, uiSref: tasksUiSref },
        { name: 'content', label: 'משימה', type: 'html', elementClass: 'task-content', uiSref: tasksUiSref },
        { name: 'done', label: 'בוצע', sortable: true, type: 'customFunction', customFunction: vm.getDoneString, uiSref: tasksUiSref },
        { name: 'pinned', label: 'מוצמד', sortable: true, type: 'customFunction', customFunction: vm.getPinnedString, uiSref: tasksUiSref }
      ];
    }

    $onInit () {
      vm.type = $state.params.type || 'projects';

      vm.agentList = _.get($rootScope, 'owner.users') || [];
      vm.agentList.forEach((agent) => {
        vm.filterNames.agent.values[agent.id] = `${agent.firstName || ''} ${agent.lastName || ''}`;
        vm.projectAgentRelations.forEach((relation) => {
          vm.filterNames[`relatedAgents.${relation.id}`].values[agent.id] = `${agent.firstName || ''} ${agent.lastName || ''}`;
        });
      });
      vm.filterNames.assignUser.values = vm.filterNames.agent.values;
      vm.filterNames.agent.values.$null = 'ללא נציג אחראי';
      vm.filterNames.assignUser.values.$null = 'ללא שיוך לנציג';
      vm.projectAgentRelations.forEach((relation) => {
        vm.filterNames[`relatedAgents.${relation.id}`].values.$null = `ללא ${relation.title}`;
      });

      switch (vm.type) {
        case 'projects':
          vm.typeTitle = 'פרויקטים';
          break;
        case 'customers':
          vm.typeTitle = 'לקוחות';
          break;
        case 'tasks':
          vm.typeTitle = 'משימות';
          break;
      }

      vm.queryParams = JSON.parse(decodeURIComponent($state.params.query || '') || '{}');
      vm.queryParamsArray = Object.keys(vm.queryParams);

      const searchFormParams = {};
      Object.keys(vm.queryParams).forEach((param) => {
        addParamToSearchForm(searchFormParams, param, vm.queryParams[param]);
      });

      vm.searchForm = searchFormParams;
      vm.agentColumnShown = !vm.searchForm.agent && !vm.searchForm.assignUser && vm.agentList.length > 1;
    }

    removeFilter (filter) {
      $state.current.reloadOnSearch = false;
      delete vm.queryParams[filter];
      $location.search('query', encodeURIComponent(JSON.stringify(vm.queryParams || {})));
    }

    reopenSearch () {
      $uibModal.open({
        component: 'search',
        windowClass: 'search-modal mobile-modal',
        resolve: {
          type: () => 'advanced',
          advancedSearchTarget: () => vm.type,
          advancedSearchQuery: () => vm.queryParams
        },
        size: 'lg'
      });
    }

    isAgentColumnShown () {
      return vm.agentColumnShown;
    }

    // project list functions
    getProjectList (args) {
      vm.getProjectListArgs = args;
      const customerNameFilter = _.get(args.filter, 'customerName');
      if (customerNameFilter) {
        delete args.filter.customerName;
        args.filter['$customer.name$'] = customerNameFilter;
      }
      return projectService.getProjectList(true, args);
    }

    showProjectLink (id) {
      return projectsUiSref;
    }

    getCustomer (row) {
      return (_.get(row, 'customer.name') || '');
    }

    getAddress (row) {
      return utils.joinStringArray([row.street, row.house], ' ');
    }

    getStatus (row) {
      if (!row.status) {
        return '';
      }
      return `<i class="fa fa-circle" style="color:${vm.statusColor(row.status)}" aria-hidden="true"></i><span>${row.status}</span>`;
    }

    getProjectDueDate (row) {
      if (
        row.status &&
        !['הצעה', 'הושלם', 'מבוטל'].includes(row.status) &&
        dayjs(row.dueDate).isBefore(dayjs().add(3, 'days').startOf('day'))
      ) {
        return `<span class="text-danger">${$filter('date')(row.dueDate, 'dd/MM/yyyy') || ''}</span>`;
      }
      return $filter('date')(row.dueDate, 'dd/MM/yyyy') || '';
    }

    getAgentString (row) {
      const agent = $rootScope.userById(row.agent);
      if (!agent) { return ''; }
      return `<span uib-tooltip="${agent.firstName || ''} ${agent.lastName || ''}"><img class="img-circle" src="${userService.getUserProfileImage(agent)}" /></span>`;
    }

    hasTasks (row) {
      if (row.tasks && Number(row.tasks.length) > 0) {
        return row.tasks.length;
      }
      return '';
    }

    isPaid (row) {
      if (row.toPayTotal > 0) {
        if (row.toPayTotal < row.priceTotal) {
          return `<pie-chart uib-tooltip="שולם ${vm.paidPercent(row)}%" data="[${row.priceTotal - row.toPayTotal}, ${row.toPayTotal}]" options="{type: 'pie'}"></pie-chart>`;
        } else {
          return '<i uib-tooltip="לא שולם" class="text-danger fa fa-times-circle"><i/>';
        }
      } else if (row.priceTotal) {
        return '<i uib-tooltip="שולם" class="text-success fa fa-check-circle"><i/>';
      }
      return '<i uib-tooltip="לא הוגדר תשלום" class="fa fa-circle"></i>';
    }

    paidPercent (row) {
      return Math.min(100, (row.transactionTotal / row.priceTotal) * 100).toFixed();
    }

    paymentTdClick (event) {
      event.stopPropagation();
    }

    statusColor (status) {
      return projectService.getProjectStatusColor(status);
    }

    // customer list functions
    getCustomerList (args) {
      vm.getCustomerListArgs = args;
      return customerService.getCustomerList(args);
    }

    showCustomerLink (id) {
      return customersUiSref;
    }

    // task list functions
    getTaskList (args) {
      vm.getTaskListArgs = args;
      return taskService.getTaskList(args);
    }

    showTaskProjectLink () {
      return tasksUiSref;
    }

    getProjectString (row) {
      return `פרויקט ${row.project.number} ${row.project.street || row.project.city ? ' - ' : ''}${row.project.street || ''} ${row.project.house || ''} ${row.project.city || ''}`;
    }

    getProjectCustomerString (row) {
      return _.get(row.project, 'customer.name') || '';
    }

    getUserString (row) {
      const agent = $rootScope.userById(row.assignUser);
      if (!agent) { return 'ללא שיוך לנציג'; }
      return `${agent.firstName || ''} ${agent.lastName || ''}`;
    }

    getUserProfileImage (row) {
      const agent = $rootScope.userById(row.assignUser);
      if (!agent) { return ''; }
      return `<img class="img-circle" src="${userService.getUserProfileImage(agent)}" />`;
    }

    getDoneString (row) {
      return `<div class="badge badge-${row.done ? 'success' : 'danger'} m-r-sm">${row.done ? '' : 'לא '}בוצע</div>`;
    }

    getPinnedString (row) {
      if (!row.pinned) { return ''; }
      return '<div class="badge m-r-sm">מוצמד</div>';
    }

    exportToExcel () {
      if (vm.getExcelExportQueryProgress) {
        return;
      }
      vm.getExcelExportQueryProgress = true;
      let tableHeadListName;
      let fileName = 'תוצאות חיפוש - ';

      return new Promise((resolve, reject) => {
        switch (vm.type) {
          case 'projects':
            fileName += 'פרויקטים';
            tableHeadListName = 'projectsTableHeadList';
            return resolve(projectService.getProjectList(true, {
              order: vm.getProjectListArgs.order,
              desc: vm.getProjectListArgs.desc,
              filter: vm.getProjectListArgs.filter,
              limit: 2000
            }));
          case 'customers':
            fileName += 'לקוחות';
            tableHeadListName = 'customersTableHeadList';
            return resolve(customerService.getCustomerList({
              order: vm.getCustomerListArgs.order,
              desc: vm.getCustomerListArgs.desc,
              filter: vm.getCustomerListArgs.filter,
              limit: 2000
            }));
          case 'tasks':
            fileName += 'משימות';
            tableHeadListName = 'tasksTableHeadList';
            return resolve(taskService.getTaskList({
              order: vm.getTaskListArgs.order,
              desc: vm.getTaskListArgs.desc,
              filter: vm.getTaskListArgs.filter,
              limit: 2000
            }));
          default:
            return resolve([]);
        }
      })
        .then((list) => {
          if (!_.get(list, 'rows.length')) {
            return;
          }
          const data = list.rows.map((row) => {
            return vm.parseExcelRow(tableHeadListName, row);
          });

          try {
            const XLSX = window.XLSX;
            const ws = XLSX.utils.json_to_sheet(data, { dateNF: 'dd/mm/yyyy' });
            const wb = XLSX.utils.book_new();
            _.set(wb, 'Workbook.Views.0.RTL', true);
            XLSX.utils.book_append_sheet(wb, ws, fileName);

            XLSX.writeFile(wb, fileName + '.xlsx');
          } catch (error) {
            console.log(error);
          }
        })
        .finally(() => {
          vm.getExcelExportQueryProgress = false;
        });
    }

    parseExcelRow (tableHeadListName, row) {
      const rowObject = {};

      vm[tableHeadListName]
        .filter((column) => !column.if || column.if(row[column.name]))
        .forEach((column) => {
          // custom parsers
          if (column.name === 'agent') {
            const agent = $rootScope.userById(row.agent);
            if (agent) {
              rowObject[column.label] = (`${agent.firstName || ''} ${agent.lastName || ''}`) || 'ללא שמאי אחראי';
            }
          } else if (column.name === 'paid') {
            if (row.toPayTotal > 0) {
              rowObject[column.label] = row.toPayTotal < row.priceTotal ? `${vm.paidPercent(row)}%` : '0%';
            } else {
              rowObject[column.label] = row.priceTotal ? '100%' : '';
            }

            // general parsers
          } else if (column.type === 'customFunction' || column.type === 'compile') {
            rowObject[column.label] = utils.stripHtml(column.customFunction(row)) || '';
          } else if (column.type === 'html') {
            rowObject[column.label] = utils.stripHtml(row[column.name]) || '';
          } else if (column.type === 'date') {
            rowObject[column.label] = $filter('date')(row[column.name], 'dd/MM/yyyy') || '';
          } else if (column.type === 'currency') {
            rowObject[column.label] = $filter('currency')(row[column.name], '') || '';
          } else {
            rowObject[column.label] = _.get(row, column.name) || '';
          }
        });
      return rowObject;
    }

    joinArray (array) {
      return (array || []).join(', ');
    }
  }

  return new AdvancedSearchResultsController();
}

function addParamToSearchForm (searchFormParams, param, value) {
  if (param === 'name' || param === 'street' || param === 'address' || param === 'customerName') {
    searchFormParams[param] = '%' + value;
  } else if (param === 'projectCreatedFrom' || param === 'taskCreatedFrom') {
    setupPreviousItem(searchFormParams, 'createdAt');
    searchFormParams.createdAt += '&gt:' + value;
  } else if (param === 'projectCreatedTo' || param === 'taskCreatedTo') {
    setupPreviousItem(searchFormParams, 'createdAt');
    searchFormParams.createdAt += '&lt:' + value;
  } else if (param === 'projectDueDateForm') {
    setupPreviousItem(searchFormParams, 'dueDate');
    searchFormParams.dueDate += '&gt:' + value;
  } else if (param === 'projectDueDateTo') {
    setupPreviousItem(searchFormParams, 'dueDate');
    searchFormParams.dueDate += '&lt:' + value;
  } else if (param === 'projectNumberFrom' || param === 'customerNumberFrom') {
    setupPreviousItem(searchFormParams, 'number');
    searchFormParams.number += '&num-gte:' + value;
  } else if (param === 'projectNumberTo' || param === 'customerNumberTo') {
    setupPreviousItem(searchFormParams, 'number');
    searchFormParams.number += '&num-lte:' + value;
  } else {
    searchFormParams[param] = value;
  }
}

function setupPreviousItem (searchFormParams, item) {
  if (searchFormParams[item]) {
    searchFormParams[item] += '|';
  } else {
    searchFormParams[item] = '';
  }
}
