import template from './select-other.html';
import './select-other.scss';

const selectOtherModule = angular.module('selectOther', [])
  .directive('selectOther', selectOtherDirective)
  .name;

function selectOtherDirective ($filter, $timeout) {
  return {
    scope: {
      values: '<?',
      model: '=?',
      fieldName: '@',
      onChange: '<?',
      required: '<?'
    },
    template,
    bindToController: true,
    controllerAs: 'vm',
    restrict: 'E',
    controller: ['$scope', '$uibModal', function ($scope, $uibModal) {
      const vm = this;
      vm.$onInit = function () {
        vm.showInput = !vm.isSelectedOption();
      };
      vm.isSelectedOption = function () {
        return !vm.model || (vm.values || []).includes(vm.model);
      };
      vm.selectChanged = function (event) {
        if (vm.model === 'אחר') {
          vm.model = '';
          vm.showInput = true;
          $timeout(() => {
            jQuery('.select-other.' + vm.fieldName + ' input').focus();
          }, 100);
        }
        if (_.isFunction(vm.onChange)) {
          vm.onChange(event, vm.model);
        }
      };
      vm.selectInputBlur = function () {
        if (!vm.model || vm.model === 'אחר') {
          vm.showInput = false;
        }
      };
      vm.inputChanged = function (event) {
        if (_.isFunction(vm.onChange)) {
          vm.onChange(event, vm.model);
        }
      };
    }]
  };
}

selectOtherDirective.$inject = ['$filter', '$timeout'];

export default selectOtherModule;
