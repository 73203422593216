import isEmail from 'validator/lib/isEmail';

export default function ($scope, $rootScope, utils, userService) {
  let vm;

  class ProfileEditCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.profileFields = {
        active: true
      };

      const userFieldKeys = ['id', 'firstName', 'lastName', 'lastName', 'email', 'phone'];
      const user = $rootScope.user;
      if (user) {
        if (user.phone) {
          user.phone = user.phone.replace(/^\+972/, '0');
        }
        userFieldKeys.forEach((field) => {
          if (user.hasOwnProperty(field)) {
            vm.profileFields[field] = user[field];
          }
        });
      }

      if (vm.resolve.passwordEditMode) {
        vm.headerTitle = 'שינוי סיסמה';
      } else {
        vm.headerTitle = 'עריכת פרטים אישיים';
      }

      $scope.$watchGroup(['vm.profileFields.password', 'vm.profileFields.password2'], (newVal, oldVal) => {
        if (_.get(vm, 'form.password2')) {
          const passwordMatch = vm.profileFields.password === vm.profileFields.password2;
          vm.form.password2.$setValidity('passwordMatch', passwordMatch);
          jQuery('#password2-input')[0].setCustomValidity(passwordMatch || !vm.profileFields.password2 ? '' : 'הסיסמאות אינן תואמות');
        }
      });

      $scope.$watch('vm.profileFields.email', (newVal, oldVal) => {
        if (vm.form.email) {
          vm.form.email.$setValidity('registeredEmail', true);
          const isValidEmail = isEmail(vm.profileFields.email || '');
          vm.form.email.$setValidity('email', isValidEmail);
          jQuery('#email-input')[0].setCustomValidity(isValidEmail ? '' : 'דוא"ל לא תקין');
        }
      });
    }

    closeModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    saveProfile () {
      if (vm.resolve.profileImageEditMode) {
        return vm.saveProfileImage();
      }
      if (vm.saveProgress) {
        return;
      }
      if (vm.form.$invalid) {
        jQuery('#profile-form')[0].reportValidity();
        utils.setFormFieldsDirty(vm.form);
        return;
      }
      let successMessage;
      if (vm.resolve.passwordEditMode) {
        successMessage = 'סיסמה שונתה';
      } else {
        successMessage = 'פרטים אישיים נשמרו';
      }
      vm.saveProgress = true;

      let userObject = angular.copy(vm.profileFields);
      if (userObject.phone) {
        userObject.phone = userObject.phone.replace(/^\+972/, '0');
      }
      /* temp: */ delete userObject.email; // !TODO
      if (vm.resolve.passwordEditMode) {
        userObject = {
          password: vm.profileFields.password,
          password2: vm.profileFields.password2
        };
      }

      return userService.saveUser(_.get($rootScope, 'user.id'), userObject)
        .then((userResult) => {
          $rootScope.user = userResult;
          utils.notify(successMessage, 'alert-success');
          vm.modalInstance.close();
        })
        .catch((error) => {
          let errorMessage = 'שגיאה';
          if (error.data.data === 'SequelizeUniqueConstraintError: Email is already registered.') {
            errorMessage = 'כתובת הדוא"ל כבר רשומה במערכת';
            vm.form.email.$setValidity('registeredEmail', false);
            jQuery('#email-input')[0].setCustomValidity(errorMessage);
          }
          if (error.data.data === 'SequelizeValidationError: Validation error: Invalid email.') {
            errorMessage = 'דוא"ל לא תקין';
          }
          utils.notify(errorMessage, 'alert-danger');
          console.log(error);
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    onImageChange (name, model) {
      vm.imageChanged = true;
    }

    saveProfileImage () {
      if (vm.saveProgress) {
        return;
      }
      if (!vm.imageChanged) {
        return vm.closeModalBox();
      }
      const successMessage = 'תמונת פרופיל עודכנה';
      vm.saveProgress = true;
      return userService.saveUserImage(_.get($rootScope, 'user.id'), vm.profileImage)
        .then((userResult) => {
          let profileImageUrl = userResult.profileImageUrl;
          if (profileImageUrl) {
            profileImageUrl += ('?' + new Date().getTime());
          }
          $rootScope.user.profileImageUrl = profileImageUrl;
          utils.notify(successMessage, 'alert-success');
          vm.modalInstance.close();
        })
        .catch((error) => {
          utils.notify('שגיאה', 'alert-danger');
          console.log(error);
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    getUserProfileImage () {
      return userService.getUserProfileImage($rootScope.user);
    }
  }

  return new ProfileEditCtrl();
}
