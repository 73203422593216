import template from './ticket-show.html';
import controller from './ticket-show.controller';
import './ticket-show.scss';

const ticketShowComponent = {
  bindings: {
    ticket: '<'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', '$interval', '$uibModal', 'utils', 'notify', 'ticketService', 'userService', 'counts'];

export default ticketShowComponent;
