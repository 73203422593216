// let service;
let request;

class customerService {
  constructor (_request) {
    // service = this;
    request = _request;
  }

  getCustomerList (args = {}) {
    return request('/customer/?' + jQuery.param(args))
      .then((res) => res.data.data);
  }

  getCustomer (customerId) {
    return request('/customer/' + customerId)
      .then((res) => res.data.data)
      .catch((error) => {
        console.error('GetCustomer failed', error);
        return Promise.reject(error);
      });
  }

  getCustomerSiblings (customerId, query, index) {
    return request(`/customer/siblings/${customerId}?query=${encodeURIComponent(JSON.stringify(query))}&index=${index}`)
      .then((res) => res.data.data)
      .catch((error) => {
        console.error('GetCustomerSiblings failed', error);
        return Promise.reject(error);
      });
  }

  saveCustomer (customerId, customerObject) {
    return request('/customer/' + (customerId || ''), { customer: customerObject }, (customerId ? 'PUT' : 'POST'))
      .then((res) => res.data.data);
  }

  deleteCustomer (customerId) {
    return request('/customer/' + customerId || '', null, 'DELETE')
      .then((res) => res.data.data);
  }
}

customerService.$inject = ['request'];

const customerServiceModule = angular.module('customerService', [])
  .service('customerService', customerService)
  .name;

export default customerServiceModule;
