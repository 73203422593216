import appConfig from './routes';
import AppComponent from './app.component';

import Common from './common/common';
import Controllers from './controllers/controllers';
import Components from './components/components';
import Directives from './directives/directives';
import Filters from './filters/filters';
import Layouts from './layouts/layouts';
import Services from './services/services';
import Vendors from './app.vendors';
import '../assets/pwa';

const app = angular.module('amend', [
  Common,
  Controllers,
  Components,
  Directives,
  Filters,
  Services,
  Layouts,
  Vendors
])
  .component('app', AppComponent)
  .config([
    '$compileProvider', function ($compileProvider) {
      $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|chrome-extension|file|tel|waze):/);
    }
  ])
  .run(['$rootScope', '$state', ($rootScope, $state) => {
    $rootScope.$state = $state;

    // Fix mobile-modal-inner height
    // see: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    function fixVh () {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    function getWindowDimensions () {
      $rootScope.windowDimensions = {
        height: window.innerHeight,
        width: window.innerWidth
      };
      $rootScope.$apply();
    }

    fixVh();
    window.addEventListener('resize', fixVh);

    getWindowDimensions();
    window.addEventListener('resize', getWindowDimensions);
  }])
  .run(['$transitions', '$uibModalStack', '$rootScope', '$timeout', ($transitions, $uibModalStack, $rootScope, $timeout) => {
    $transitions.onStart({}, (transition) => {
      const rootScopeData = _.get(transition.$to(), 'data.rootScopeData');
      if (_.isObject(rootScopeData)) {
        Object.assign($rootScope, rootScopeData);
        $timeout(() => { $rootScope.$apply(); });
      }

      // close any modal-box when navigating back in history.
      const top = $uibModalStack.getTop();
      if (top) {
        $uibModalStack.dismiss(top.key);
        console.log('Open modal-box found, transition ignored');
        return false;
      }
    });
  }]);

appConfig(app);
