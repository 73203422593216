import template from './project-edit.html';
import controller from './project-edit.controller';
import './project-edit.scss';

const projectEditComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'utils', 'projectService', 'govmapService', 'counts', 'recipient', 'settingsService'];

export default projectEditComponent;
