import MsgReader from '@freiraum/msgreader';

export default function ($scope, $rootScope, $timeout, $sce, request) {
  let vm;

  class MsgViewerController {
    constructor () {
      vm = this;
      vm.name = 'msgViewer';
      vm.loading = true;
    }

    $onInit () {
      request(vm.resolve.fileUrl, null, 'GET', {
        responseType: 'arraybuffer',
        rootUrl: true
      })
        .then((response) => {
          try {
            const testMsg = new MsgReader(response.data);
            vm.testMsgInfo = testMsg.getFileData();
            console.log(vm.testMsgInfo.headers);
            vm.nsgSubject = vm.testMsgInfo.subject;

            // vm.nsgContent = $sce.trustAsHtml(vm.testMsgInfo.body);
            vm.nsgContent = vm.testMsgInfo.body.trim();
            vm.msgFrom = parseHeaders(vm.testMsgInfo.headers).From || '';
            vm.msgTo = parseHeaders(vm.testMsgInfo.headers).To || '';
            const ccPositionStart = vm.testMsgInfo.headers.indexOf('CC: ') + 4;
            const ccPositionEnd = Math.max(ccPositionStart, vm.testMsgInfo.headers.indexOf('>\n', ccPositionStart), vm.testMsgInfo.headers.indexOf('Subject: '));
            if (ccPositionStart > 3) {
              console.log(ccPositionStart, ccPositionEnd);
              console.log(vm.testMsgInfo.headers.indexOf('>\n', ccPositionStart));
              console.log(vm.testMsgInfo.headers.substring(ccPositionStart, ccPositionEnd));
              vm.msgCc = vm.testMsgInfo.headers.substring(ccPositionStart, ccPositionEnd).split(',');
            }
            vm.msgDate = getMsgDate(vm.testMsgInfo.headers);
            vm.msgAttachments = vm.testMsgInfo.attachments;
            $timeout(() => {
              $scope.$apply();
            });
          } catch (error) { console.log(error); }
        })
        .finally(() => {
          vm.loading = false;
        });
    }
  }

  return new MsgViewerController();
}

function parseHeaders (headers) {
  const parsedHeaders = {};
  if (!headers) {
    return parsedHeaders;
  }
  const headerRegEx = /(.*)\: (.*)/g;
  let m;
  while (m = headerRegEx.exec(headers)) {
    parsedHeaders[m[1]] = m[2];
  }
  return parsedHeaders;
}

function getMsgDate (rawHeaders) {
  const headers = parseHeaders(rawHeaders);
  if (!headers.Date) {
    return '-';
  }
  return new Date(headers.Date);
}
