export default function ($scope, $rootScope, $state, utils, ticketService) {
  let vm;

  class TicketEditCtrl {
    constructor () {
      vm = this;

      vm.titleList = [
        'זקוק לתמיכה',
        'שאלה מקצועית הקשורה למערכת',
        'תקלה במערכת',
        'הצעה לייעול או שיפור',
        'סתם להגיד שלום'
      ];
    }

    $onInit () {
      vm.ticketFields = {};
    }

    sendTicket () {
      if (vm.form.$invalid) {
        jQuery('#ticket-form')[0].reportValidity();
        utils.setFormFieldsDirty(vm.form);
        return;
      }
      if (vm.saveProgress) {
        return;
      }
      vm.saveProgress = true;
      ticketService.sendTicket(vm.ticketFields)
        .then((ticketResult) => {
          const message = 'הפניה נשלחה';
          utils.notify(message, 'alert-success');
          $state.go('ticket.ticketShow', { id: ticketResult.id });
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }
  }

  return new TicketEditCtrl();
}
