import template from './project-options-details.html';
import controller from './project-options-details.controller';
import './project-options-details.scss';

const projectOptionsDetailsComponent = {
  require: {
    settingsCtrl: '^settings'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$uibModal', 'settingsService', 'request', 'utils'];

export default projectOptionsDetailsComponent;
