// import dayjs from 'dayjs';

export default function ($scope, $rootScope) {
  let vm;

  class TaskBoardPageCtrl {
    constructor () {
      vm = this;
      vm.name = 'taskBoardPage';

      vm.filter = {
        assignUser: ''
      };
    }

    $onInit () {
      vm.userList = _.get($rootScope, 'owner.users') || [];
      if (vm.userList.length > 1 && !$rootScope.isManager()) {
        vm.filter.assignUser = $rootScope.user.id;
      }
    }

    onUserChanged (selectedUser) {
      vm.filter.assignUser = selectedUser;
    }
  }

  return new TaskBoardPageCtrl();
}
