import template from './project-payments-prices.html';
import controller from './project-payments-prices.controller';
import './project-payments-prices.scss';

const projectPaymentsPricesComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '=?',
    projectFields: '=?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', 'projectService'];

export default projectPaymentsPricesComponent;
