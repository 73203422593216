import taskListComponent from './task-list.component';
import taskListTableColumns from './task-list-table-columns';

const taskListModule = angular.module('taskList', [
  taskListTableColumns
])
  .component('taskList', taskListComponent)
  .name;

export default taskListModule;
