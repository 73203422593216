import dayjs from 'dayjs';

import optionList from '../../project/project-options-list';

export default function ($scope, $rootScope, $state, $stateParams, $filter, $uibModal, projectService, settingsService, userService, utils) {
  let vm;
  const uiSref = 'project.projectShow.payments({id: row.projectId})';

  class ProjectPaymentsReportCtrl {
    constructor () {
      vm = this;
      vm.name = 'projectPaymentsReport';
      vm.$stateParams = $stateParams;
      vm.transactionTypeList = optionList.transactionTypeList;
      vm.transactionTypeDetails = optionList.transactionTypeDetails;
      vm.transactionCardBrandsList = optionList.transactionCardBrandsList;
      vm.transactionCardDealsList = optionList.transactionCardDealsList;
      vm.transactionAppTypes = optionList.transactionAppTypes;
      vm.transactionTypeName = {
        2: 'cheque', 4: 'transfer', 3: 'creditCard', 10: 'paymentApp'
      };

      vm.tableHeadList = [
        { name: 'createdAt', label: 'תאריך קליטת תשלום', type: 'date', sortable: true, uiSref },
        { name: 'sum', label: 'סכום כולל מע"מ', elementClass: 'text-semi-bold', type: 'customFunction', customFunction: vm.getSumString, sortable: true, uiSref },
        { name: 'description', label: 'פירוט', uiSref },
        { name: 'type', label: 'אמצעי תשלום', type: 'customFunction', customFunction: vm.getPaymentMethod, uiSref },
        { name: 'project.number', label: 'פרויקט', uiSref },
        { name: 'project.customer.name', label: 'לקוח', uiSref },
        { name: 'project.street', label: 'כתובת פרויקט', type: 'customFunction', customFunction: vm.getAddress, uiSref },
        { name: 'invoiceTicket', label: 'חשבונית', type: 'compile', customFunction: vm.invoiceButton, clickFunction: vm.openInvoiceTicket }
      ];

      if ($stateParams.query) {
        vm.tableFilters = $stateParams.query.filter;
      } else {
        vm.tableFilters = {};
        vm.resetFilters();
      }
    }

    $onInit () {
      vm.isManyAgentsInOwner = _.get($rootScope, 'owner.users') || [].length > 1;
    }

    filterDateChanged () {
      const createdAtFilter = [];
      if (vm.dateFilters.from) {
        createdAtFilter.push('&gt:' + dayjs(vm.dateFilters.from).toISOString());
      }
      if (vm.dateFilters.to) {
        createdAtFilter.push('&lt:' + dayjs(vm.dateFilters.to).toISOString());
      }
      vm.tableFilters.createdAt = createdAtFilter.join('|');
    }

    resetFilters () {
      _.set(vm, 'dateFilters.from', undefined);
      _.set(vm, 'dateFilters.to', undefined);
      _.set(vm, 'tableFilters.createdAt', undefined);
    }

    getProjectTransactionsList (args) {
      vm.sumTotal = undefined;
      vm.getProjectListArgs = args;
      return projectService.getProjectTransactionsReportList(args)
        .then((res) => {
          vm.sumTotal = res.sumTotal;
          return res;
        });
    }

    getSumString (row) {
      return `<span class="sum">${$filter('currency')(row.sum || 0, '')}</span> ₪</span>`;
    }

    getPaymentMethod (row) {
      let method = vm.transactionTypeList[row.type] || '';
      if (!method) { return ''; }
      method += ` <div class="type-details">${vm.getPaymentMethodDetails(row)}</div>`;

      return method;
    }

    getPaymentMethodDetails (transaction) {
      if (angular.isDefined(transaction.formattedString)) {
        return transaction.formattedString;
      }
      const type = vm.transactionTypeName[transaction.type];
      if (!type) {
        transaction.formattedString = '';
        return transaction.formattedString;
      }
      transaction.formattedString = Object.keys(vm.transactionTypeDetails[type])
        .map((field) => (transaction.details?.[field] ? `${vm.transactionTypeDetails[type][field]} <span class="text-semi-bold">${vm.getTransactionDetailsValue(transaction, field)}</span>` : ''))
        .filter((item) => item)
        .join(' <br>');
      return transaction.formattedString;
    }

    getTransactionDetailsValue (transaction, field) {
      if (field === 'cardBrands') {
        return vm.transactionCardBrandsList[_.get(transaction, 'details.' + field) || ''];
      } else if (field === 'cardDeals') {
        return vm.transactionCardDealsList[_.get(transaction, 'details.' + field) || ''];
      } else if (field === 'paymentAppAppType') {
        return vm.transactionAppTypes[_.get(transaction, 'details.' + field) || ''];
      } else {
        return _.get(transaction, 'details.' + field) || '';
      }
    }

    getCustomer (row) {
      return (_.get(row, 'customer.name') || '');
    }

    getAddress (row) {
      if (!row.project) { return ''; }
      return utils.joinStringArray([
        utils.joinStringArray([row.project.street, row.project.house], ' '),
        row.project.city
      ], ', ');
    }

    invoiceButton (row) {
      return row && row.invoiceDocumentUrl ? '<span class="btn btn-sm btn-default">הצגת חשבונית</span>' : '';
    }

    openInvoiceTicket (event, transaction) {
      if (transaction.invoiceDocumentUrl) {
        window.open(vm.invoiceLink(transaction), '_blank');
      }
    }

    invoiceLink (transaction) {
      return window.SERVER_URL + '/project/' + transaction.projectId + '/transaction/' + transaction.id + '/invoice';
    }

    exportToExcel () {
      if (vm.getExcelExportQueryProgress) {
        return;
      }
      vm.getExcelExportQueryProgress = true;
      const fileName = 'דוח תנועות תשלום - ' + dayjs().format('DD-MM-YYYY');

      return projectService.getProjectTransactionsReportList({
        order: vm.getProjectListArgs.order,
        desc: vm.getProjectListArgs.desc,
        filter: vm.getProjectListArgs.filter,
        limit: 2000
      })
        .then((list) => {
          if (!_.get(list, 'rows.length')) {
            return;
          }
          const tableHeadList = angular.copy(vm.tableHeadList);
          tableHeadList.splice(-1, 1);

          const data = list.rows.map((row) => {
            return vm.parseExcelRow(tableHeadList, row);
          });

          try {
            const XLSX = window.XLSX;
            const ws = XLSX.utils.json_to_sheet(data, { dateNF: 'dd/mm/yyyy' });
            const wb = XLSX.utils.book_new();
            _.set(wb, 'Workbook.Views.0.RTL', true);
            XLSX.utils.book_append_sheet(wb, ws, fileName);

            XLSX.writeFile(wb, fileName + '.xlsx');
          } catch (error) {
            console.log(error);
          }
        })
        .finally(() => {
          vm.getExcelExportQueryProgress = false;
        });
    }

    parseExcelRow (tableHeadList, row) {
      const rowObject = {};

      tableHeadList.forEach((column) => {
        if (column.name === 'sum' || column.name === 'project.priceTotal') {
          rowObject[column.label] = row[column.name] || 0;
        } else if (column.name === 'project.agent' && vm.isAgentColumnShown()) {
          rowObject[column.label] = vm.getAgentPlainString(row) || '';
        } else if (column.type === 'customFunction' || column.type === 'compile') {
          rowObject[column.label] = utils.stripHtml(column.customFunction(row)) || '';
        } else if (column.type === 'html') {
          rowObject[column.label] = utils.stripHtml(row[column.name]) || '';
        } else if (column.type === 'date') {
          rowObject[column.label] = $filter('date')(row[column.name], 'dd/MM/yyyy') || '';
        } else {
          rowObject[column.label] = _.get(row, column.name) || '';
        }
      });
      return rowObject;
    }
  }

  return new ProjectPaymentsReportCtrl();
}
