export default function ($scope, $rootScope, $state, $uibModal, notify, ownerService) {
  let vm;

  class OwnerCustomersCtrl {
    constructor () {
      vm = this;
      vm.progress = true;
      vm.orderBy = '-createdAt';

      vm.tableHeadList = [
        { name: 'number', label: 'מספר לקוח', sortable: true },
        { name: 'name', label: 'שם לקוח', sortable: true },
        { name: 'address', label: 'כתובת', sortable: true },
        { name: 'city', label: 'עיר', sortable: true },
        { name: 'phone', label: 'טלפון', elementClass: 'phone' },
        { name: 'email', label: 'דוא"ל', elementClass: 'email' }
      ];
    }

    // $onInit () { }

    getCustomerList (args = {}) {
      return ownerService.getOwnerCustomers(vm.ownerShowCtrl.ownerObject.id, args)
        .then((res) => {
          vm.ownerShowCtrl.ownerObject.customersCount = res.count || 0;
          return res;
        });
    }
  }

  return new OwnerCustomersCtrl();
}
