export default function ($scope, $rootScope, $timeout, $uibModal, utils, agentService, userService) {
  let vm;

  class AgentListCtrl {
    constructor () {
      vm = this;
      vm.name = 'agent';
      vm.orderBy = 'firstName';
    }

    $onInit () {
      vm.getAgentList();
      $scope.$on('ownerSaved', (owner) => {
        vm.getAgentList();
      });
      $scope.$on('agentSaved', (owner) => {
        vm.getAgentList();
      });
    }

    list () {
      if (!$rootScope.owner || !angular.isArray($rootScope.owner.users)) {
        return [];
      }
      return $rootScope.owner.users.filter((agent) => agent.id !== $rootScope.user.id);
    }

    getAgentList () {
      vm.getListProgress = true;
      return agentService.getAgentList() // agentService will update rootScope.owner.users
        .finally(() => {
          vm.getListProgress = false;
        });
    }

    getPhone (phone) {
      if (!phone) {
        return '';
      }
      return phone.replace(/^\+972/, '0');
    }

    editAgentForm (agentId, onlyPassword) {
      if (vm.editAgentFormProgress) {
        return;
      }
      vm.editAgentFormProgress = true;
      $uibModal.open({
        component: 'agentEdit',
        resolve: {
          agent: () => agentId ? agentService.getAgent(agentId) : '',
          passwordEditMode: () => !!onlyPassword
        },
        windowClass: 'agent-edit-modal mobile-modal',
        size: 'md',
        backdrop: 'static'
      })
        .closed.then(() => {
          vm.editAgentFormProgress = false;
        });
    }

    getAgentProfileImage (agent) {
      return userService.getUserProfileImage(agent);
    }

    editAgentPassword (agentId) {
      vm.editAgentForm(agentId, true);
    }

    exitAgent (agentId) {
      return agentService.exitAgent(agentId)
        .then(() => {
          utils.notify('משתמש הוצא מהמערכת', 'alert-success');
        });
    }
  }

  return new AgentListCtrl();
}
