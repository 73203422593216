/* global localStorage */
export default function ($scope, $rootScope, $timeout, $element, projectService, settingsService, userService, counts, utils) {
  let vm;

  class ProjectSidebarCtrl {
    constructor () {
      vm = this;

      vm.statusIcon = {};

      vm.collapsed = localStorage.getItem('projectSidebarCollapsed') === 'true';
      if (vm.collapsed) {
        $element.attr('collapsed', true);
      }
    }

    $onInit () {
      vm.statusField = vm.projectFields.status;
      vm.statusOptions = settingsService.getOwnerProjectStatusList();
      vm.ownerTagList = angular.copy(settingsService.getOwnerProjectTags());
      vm.projectTags = angular.copy(vm.projectFields.tags || []);
      vm.tagsInDropdown = vm.tagListToShow(vm.ownerTagList, vm.projectTags);
      vm.ownerProjectAgentRelations = _.get($rootScope, 'owner.settings.projectAgentRelations') || [];
      vm.relatedAgents = _.get(vm.projectFields, 'relatedAgents') || {};
    }

    editStatus () {
      $timeout(() => {
        vm.isStatusEditOpen = true;
      }, 100);
    }

    async updateStatus (status) {
      if (vm.statusField !== status && status === 'מבוטל' && vm.projectFields.toPayTotal > 0) {
        const modalParams = {
          windowClass: 'hmodal-danger',
          title: 'קיים חוב לתשלום בפרויקט',
          message: `במידה והפרויקט יבוטל החוב ימחק מדוח התשלומים.
          האם להמשיך בביטול הפרויקט?`,
          buttons: [{
            label: 'לא'
          },
          {
            label: 'כן, לבטל את הפרויקט',
            btnClass: 'btn-danger',
            onClick ($uibModalInstance) {
              $uibModalInstance.close();
              return true;
            }
          }]
        };
        await utils.modalAlert(modalParams);
      }

      vm.statusField = status;
      vm.updateProject({ status });
    }

    statusColor (status) {
      return projectService.getProjectStatusColor(status);
    }

    toggleCollapsed () {
      vm.collapsed = !vm.collapsed;
      localStorage.setItem('projectSidebarCollapsed', vm.collapsed);
      if (vm.collapsed) {
        $element.attr('collapsed', true);
      } else {
        $element.removeAttr('collapsed');
      }
    }

    onAgentChange (agentId, agentTypeKey, customAgentRelationId) {
      if (customAgentRelationId) {
        let updatedRelatedAgents = angular.copy(vm.projectFields.relatedAgents) || {};
        updatedRelatedAgents = { ...updatedRelatedAgents, [customAgentRelationId]: agentId };

        return vm.updateProject({ relatedAgents: updatedRelatedAgents }, customAgentRelationId);
      }
      vm.updateProject({ [agentTypeKey]: agentId });
    }

    onLabelSelected (tag) {
      vm.projectTags = _.uniq([...(vm.projectTags || []), tag]);
      vm.saveTag('addTag');
    }

    onLabelRemoved (tag) {
      vm.projectTags = vm.projectTags.filter((item) => item !== tag);
      vm.saveTag('removeTag');
      vm.tagsInDropdown = vm.tagListToShow(vm.ownerTagList, vm.projectTags);
    }

    saveTag (action) {
      vm.statusIcon.tag = 'progress';

      projectService[action](vm.projectFields.id, vm.projectTags)
        .then(() => {
          vm.statusIcon.tag = 'done animate__animated animate__fadeOut';
        })
        .catch((e) => {
          vm.statusIcon.tag = 'failed animate__animated animate__fadeOut';
        });
    }

    tagStyle (title) {
      const tag = vm.ownerTagList.find((tag) => tag.title === title);
      return {
        color: _.get(tag, 'color') || 'black',
        background: _.get(tag, 'background') || '#afafaf'
      };
    }

    tagListToShow (ownerTags, existingProjectTags) {
      const ownerTagNames = ownerTags.map((tag) => tag.title);
      const projectTagsDeletedByOwner = existingProjectTags.filter((tag) => !ownerTagNames.includes(tag));
      const deletedProjectTags = projectTagsDeletedByOwner.map((tag) => ({ title: tag, background: '#afafaf' }));
      const tags = _.uniqBy([...deletedProjectTags, ...ownerTags], 'title');
      return tags;
    }

    editTags () {
      $timeout(() => {
        vm.isTagsEditOpen = true;
      }, 100);
    }

    updateProject (changes, customAgentRelationId) {
      vm.saveProgress = true;
      let isTimeOut;
      let isProgressDone;
      let isFailed;

      let changedFields = Object.keys(changes);
      if (customAgentRelationId) {
        changedFields = [customAgentRelationId];
      }

      changedFields.forEach((key) => {
        vm.statusIcon[key] = 'progress';
      });

      function setDone () {
        if (isTimeOut && isProgressDone) {
          changedFields.forEach((key) => {
            if (isFailed) {
              $timeout(() => {
                vm.statusIcon[key] = 'failed animate__animated animate__fadeOut';
                vm[key + 'Field'] = vm.projectFields[key];
              }, 1000);
            } else {
              vm.statusIcon[key] = 'done animate__animated animate__fadeOut';
            }
          });
        }
      }

      $timeout(() => {
        isTimeOut = true;
        setDone();
      }, 600);

      projectService.saveProject(vm.projectFields.id, changes)
        .then((result) => {
          $rootScope.$broadcast('projectSaved');
        })
        .catch(() => {
          isFailed = true;
        })
        .finally(() => {
          vm.saveProgress = false;
          isProgressDone = true;
          if (changes.status) {
            counts.loadCounts('projectAlerts', 'events');
          }
          setDone();
        });
    }
  }

  return new ProjectSidebarCtrl();
}
