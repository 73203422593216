export default function ($rootScope, $state, $uibModal, userService, permissions) {
  let vm;
  const navigationItems = [
    // {
    //   name: 'dashboard',
    //   label: 'דף הבית',
    //   sref: 'dashboard'
    // },
    {
      name: 'project',
      label: 'פרויקטים',
      iconClass: 'icon-folder',
      children: [
        {
          name: 'projectList',
          label: 'רשימה',
          sref: 'project.projectList'
        },
        {
          name: 'projectBoard',
          label: 'לוח',
          sref: 'project.projectBoard'
        },
        {
          name: 'projectCalendar',
          label: 'יומן',
          sref: 'project.projectCalendar'
        },
        {
          if: () => !!$rootScope.hasAddress,
          name: 'projectMap',
          label: 'מפה',
          sref: 'project.projectMap'
        }
      ]
    },
    {
      name: 'customerList',
      label: 'לקוחות',
      sref: 'customer.customerList',
      iconClass: 'icon-user'
    },
    {
      name: 'task',
      label: 'משימות',
      badge: '<span class="badge badge-primary" ng-if="$root.counts.pinnedTasks">{{$root.counts.pinnedTasks}}</span>',
      iconClass: 'icon-check-square',
      children: [
        {
          name: 'taskList',
          label: 'רשימה',
          sref: 'task.taskList'
        },
        {
          name: 'taskBoard',
          label: 'לוח',
          sref: 'task.taskBoard'
        }
      ]
    },
    // {
    //   name: 'nadlan',
    //   label: 'מידע נדל"ן',
    //   sref: 'nadlan',
    // },
    {
      name: 'report',
      label: 'דוחות',
      roleName: 'isManager',
      iconClass: 'icon-file-text',
      children: [
        {
          name: 'reportProjectsPayments',
          label: 'תשלומים לפרויקטים',
          sref: 'report.projectPayments'
        },
        {
          name: 'reportProjectsTransactions',
          label: 'תנועות תשלום',
          sref: 'report.projectTransactions'
        },
        {
          if: () => _.get($rootScope, 'owner.users.length', 0) > 1,
          name: 'reportsProgressProjects',
          label: 'פרויקטים בביצוע',
          sref: 'report.progressProjects'
        }
      ]
    },
    {
      name: 'ticketList',
      label: 'תמיכה',
      sref: 'ticket',
      iconClass: 'icon-headphones'
    },
    {
      name: 'settings',
      label: 'הגדרות',
      iconClass: 'icon-settings',
      children: [
        {
          name: 'profile',
          label: 'הגדרות פרופיל',
          sref: 'settings.profile'
        },
        {
          name: 'ownerSettings',
          label: 'הגדרות ארגון',
          sref: 'settings.ownerSettings' + ($rootScope.isManager() || permissions.can('edit', 'ownerSettings') ? '' : '.linkedAccounts')
        }
      ]
    },
    {
      name: 'payments',
      label: 'תשלומים',
      sref: 'payments',
      roleName: 'isManager',
      iconClass: 'icon-credit-card'
    },
    {
      name: 'admin',
      label: 'ניהול',
      roleName: 'isAdmin',
      iconClass: 'icon-settings',
      children: [
        {
          name: 'adminOwners',
          label: 'ארגונים',
          sref: 'admin.ownerList'
        },
        {
          name: 'adminUsers',
          label: 'משתמשים',
          sref: 'admin.userList'
        },
        {
          name: 'adminTicket',
          label: 'פניות',
          sref: 'admin.adminTicketList'
        },
        {
          name: 'adminPayments',
          label: 'תשלומים',
          sref: 'admin.adminPaymentsList'
        }
      ]
    }
  ];

  class NavigationController {
    constructor () {
      vm = this;
      vm.name = 'navigation';
    }

    $onInit () {
      vm.navigationItems = navigationItems.map((item) => {
        item.isExpanded = $state.includes(item.name) ||
          (item.activeStates || []).find((state) => $state.includes(state));
        return item;
      });
    }

    addProjectForm () {
      $uibModal.open({
        component: 'projectEdit',
        windowClass: 'project-edit-modal mobile-modal',
        size: 'lg',
        backdrop: 'static'
      });
    }

    getUserProfileImage () {
      return userService.getUserProfileImage($rootScope.user);
    }

    expandItem (selectedItem) {
      const isExpanded = selectedItem.isExpanded;
      if (!isExpanded) { // close others
        vm.navigationItems.forEach((item) => {
          if (selectedItem.name !== item.name) {
            item.isExpanded = false;
          }
        });
      }
      selectedItem.isExpanded = !isExpanded;
    }

    minimizeMenu (smallOnly) {
      if (jQuery(window).width() < 769) {
        jQuery('body').toggleClass('show-sidebar');
      } else if (!smallOnly) {
        jQuery('body').toggleClass('hide-sidebar');
      }
    }

    itemDbClicked (item) {
      if (_.get(item, 'children.0.sref')) {
        $state.go(item.children[0].sref);
      }
    }

    checkRole (roleName) {
      return !roleName || $rootScope[roleName]();
    }
  }

  return new NavigationController();
}
