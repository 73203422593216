import template from './user-tickets.html';
import controller from './user-tickets.controller';
import './user-tickets.scss';

const userTicketsComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$filter', 'userService', 'ticketService'];

export default userTicketsComponent;
