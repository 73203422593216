import customerAutocomplete from './customer-autocomplete/customer-autocomplete';
import customerEdit from './customer-edit/customer-edit';
import customerList from './customer-list/customer-list';
import customerSearch from './customer-search/customer-search';
import customerShow from './customer-show/customer-show';
import customerService from './customer.service';

const customerModule = angular.module('customer', [
  customerAutocomplete,
  customerEdit,
  customerList,
  customerSearch,
  customerShow,
  customerService
])
  .name;

export default customerModule;
