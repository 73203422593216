import template from './project-gdrive-file-list.html';
import controller from './project-gdrive-file-list.controller';
import './project-gdrive-file-list.scss';

const projectGdriveFileListComponent = {
  require: {
    projectShowCtrl: '^projectShow'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$location', '$timeout', '$interval', '$uibModal', '$sce', 'request', 'fileRequest', 'utils', 'projectService'];

export default projectGdriveFileListComponent;
