let service;
let request;

class recipientService {
  constructor (_request) {
    service = this;
    request = _request;
    service.recipients = {};
  }

  getRecipient (recipientId) {
    if (service.recipients[recipientId]) {
      return Promise.resolve(service.recipients[recipientId]);
    }

    return request('/recipient/' + recipientId)
      .then((res) => {
        service.recipients[recipientId] = res.data.data;
        return service.recipients[recipientId];
      });
  }

  list () {
    if (Object.keys(service.recipients).length) {
      return Promise.resolve(service.recipients);
    }

    return service.getRecipientList();
  }

  getRecipientList () {
    return request('/recipient')
      .then((res) => {
        const recipients = _.get(res, 'data.data') || [];
        recipients.forEach((recipient) => {
          service.recipients[recipient.id] = recipient;
        });

        return service.recipients;
      });
  }
}

recipientService.$inject = ['request'];

const recipientModule = angular.module('recipient', [])
  .service('recipient', recipientService)
  .name;

export default recipientModule;
