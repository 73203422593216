import template from './customer-edit.html';
import controller from './customer-edit.controller';
import './customer-edit.scss';

const customerEditComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', 'utils', 'customerService'];

export default customerEditComponent;
