export default function ($scope, $rootScope, fileRequest, utils) {
  let vm;

  class DocumentSettingsEditCtrl {
    constructor () {
      vm = this;
      vm.images = {};
      vm.changedFiles = [];
    }

    $onInit () {
      vm.images = vm.resolve.images;
    }

    headerImageChanged () {
      if (!vm.changedFiles.includes('appraisalHeader')) { vm.changedFiles.push('appraisalHeader'); }
    }

    footerImageChanged () {
      if (!vm.changedFiles.includes('appraisalFooter')) { vm.changedFiles.push('appraisalFooter'); }
    }

    signatureImageChanged () {
      if (!vm.changedFiles.includes('appraisalSignature')) { vm.changedFiles.push('appraisalSignature'); }
    }

    closeModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    updateImages () {
      if (vm.saveProgress) {
        return;
      }
      if (!vm.changedFiles.length) { return vm.modalInstance.close(); }
      const ownerId = _.get($rootScope, 'owner.id');
      const files = {
        changes: vm.changedFiles.join(','),
        appraisalHeader: _.get(vm, 'headerImage[0]'),
        appraisalFooter: _.get(vm, 'footerImage[0]'),
        appraisalSignature: _.get(vm, 'signatureImage[0]')
      };
      vm.saveProgress = true;
      fileRequest('/owner/' + ownerId + '/document-images', files)
        .then((updatedImages) => {
          vm.modalInstance.close();
          vm.resolve.settingsCtrl.getSettings();
          utils.notify('תמונות עודכנו בהצלחה!', 'alert-success');
        })
        .catch(() => utils.notify('שגיאה', 'alert-danger'))
        .finally(() => {
          vm.saveProgress = false;
        });
    }
  }
  return new DocumentSettingsEditCtrl();
}
