import template from './taba.html';
import controller from './taba.controller';
import './taba.scss';

const tabaComponent = {
  bindings: {
    x: '@',
    y: '@'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', 'nadlanService'];

export default tabaComponent;
