import template from './reports.html';
import controller from './reports.controller';
import './reports.scss';

const reportsComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state'];

export default reportsComponent;
