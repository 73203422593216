import template from './customer-show.html';
import controller from './customer-show.controller';
import './customer-show.scss';

const customerShowComponent = {
  bindings: {
    customer: '<'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$uibModal', 'notify', 'customerService', 'projectService', 'utils'];

export default customerShowComponent;
