import alerts from './alerts';
import draggable from './draggable';
import editor from './editor';
import forms from './forms';
import modal from './modal';
import timeline from './timeline';
import tour from './tour';
import validation from './validation';
import wizard from './wizard';

const controllerModule = angular.module('app.controllers', [
  alerts,
  draggable,
  editor,
  forms,
  modal,
  timeline,
  tour,
  validation,
  wizard
])
  .name;

export default controllerModule;
