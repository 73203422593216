import template from './owner-tickets.html';
import controller from './owner-tickets.controller';
import './owner-tickets.scss';

const ownerTicketsComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$filter', 'ownerService', 'ticketService'];

export default ownerTicketsComponent;
