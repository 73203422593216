
let request;
// let service;

class bidService {
  constructor (_request) {
    // service = this;
    request = _request;
  }

  getBidList () {
    return request('/bid/')
      .then((res) => res.data.data);
  }

  getBid (bidId) {
    return request('/bid/' + bidId)
      .then((res) => res.data.data);
  }

  saveBid (bidId, bidObject) {
    return request('/bid/' + (bidId || ''), { bid: bidObject }, (bidId ? 'PUT' : 'POST'))
      .then((res) => res.data.data);
  }

  addBid (bidObject) {
    return this.saveBid(null, bidObject);
  }

  deleteBid (bidId) {
    return request('/bid/' + bidId || '', null, 'DELETE')
      .then((res) => res.data.data);
  }
}

bidService.$inject = ['request'];

const bidServiceModule = angular.module('bidService', [])
  .service('bidService', bidService)
  .name;

export default bidServiceModule;
