import helpers from './helpers';

export default ['$stateProvider', ($stateProvider) => {
  $stateProvider
    .state('ticket', {
      redirectTo: 'ticket.ticketList'
    })
    .state('ticket.ticketList', {
      url: '/ticket',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth]
      },
      params: {
        allowAccessForEndedAccount: true
      },
      component: 'ticketList'
    })
    .state('ticket.ticketEdit', {
      url: '/ticket/edit',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth]
      },
      params: {
        allowAccessForEndedAccount: true
      },
      component: 'ticketEdit'
    })
    .state('ticket.ticketShow', {
      url: '/ticket/:id',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth],
        ticket: ['$stateParams', 'ticketService', ($stateParams, ticketService) => {
          return ticketService.getTicket($stateParams.id)
            .catch(() => null);
        }]
      },
      params: {
        allowAccessForEndedAccount: true
      },
      component: 'ticketShow'
    });
}];
