function requestService ($http) {
  return function (url, data, method, config = {}, ignoreLoadingBar) {
    method = method || (data ? 'POST' : 'GET');
    const headers = {
      'Content-Type': 'application/json'
    };

    return $http({
      method: method,
      withCredentials: true,
      headers,
      url: (config.rootUrl ? url : window.SERVER_URL + url),
      data: data || {},
      ignoreLoadingBar: !!ignoreLoadingBar,
      ...config
    });
  };
}

requestService.$inject = ['$http'];

const requestServiceModule = angular.module('requestService', [])
  .factory('request', requestService)
  .name;

export default requestServiceModule;
