import template from './project-images.html';
import controller from './project-images.controller';
import './project-images.scss';

const projectImagesComponent = {
  require: {
    projectShowCtrl: '^projectShow'
  },
  bindings: {
    images: '=?',
    limit: '<?',
    editable: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', 'gdrive', '$uibModal'];

export default projectImagesComponent;
