import template from './payments-transactions.html';
import controller from './payments-transactions.controller';
import './payments-transactions.scss';

const paymentsTransactionsComponent = {
  // bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$filter', 'paymentsService', 'utils'];

export default paymentsTransactionsComponent;
