import helpers from './helpers';

export default ['$stateProvider', ($stateProvider) => {
  $stateProvider
    .state('task', {
      url: '/task',
      redirectTo: 'task.taskList'
    })
    .state('task.taskList', {
      params: {
        query: null,
        index: undefined,
        pinnedIndex: undefined
      },
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth]
      },
      component: 'taskList'
    })
    .state('task.taskBoard', {
      url: '/board',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth]
      },
      component: 'taskBoardPage'
    });
}];
