export default function ($scope, $rootScope, $state, $timeout, govmapService) {
  let vm;

  class NadlanCtrl {
    constructor () {
      vm = this;
      vm.name = 'nadlan';
      vm.$state = $state;
      vm.searchModel = {};
      vm.coordinatesRequestCounter = 0;
      vm.addressRequestCounter = 0;
    }

    // $onInit () { }

    onAddressChanged () {
      vm.getGushHelka();
    }

    formIsEmpty () {
      return !Object.keys(vm.searchModel).some((key) => key !== 'house' && key !== 'subHelka' && vm.searchModel[key]);
    }

    search () {
      if (vm.formIsEmpty()) {
        return;
      }
      vm.results = {};
      vm.loading = true;

      $timeout(() => {
        $scope.$apply(() => {
          if (vm.searchModel.gush) {
            vm.results.gush = vm.searchModel.gush;
          }
          if (vm.searchModel.helka) {
            vm.results.helka = vm.searchModel.helka;
          }
          vm.loading = false;
          vm.showResults = true;
        });
      }, 500);

      vm.getCoordinates()
        .then((coordinates) => {
          $timeout(() => {
            $scope.$apply(() => {
              vm.results.x = coordinates.x;
              vm.results.y = coordinates.y;

              vm.loading = false;
              vm.showResults = true;
            });
          });
        });
    }

    async getCoordinates () {
      vm.coordinatesRequestCounter++;
      const requestCounter = vm.coordinatesRequestCounter;
      let addressQuery = [
        vm.searchModel.street || '',
        String(vm.searchModel.house || '').trim().replace(/\D[^>]*/g, ''), // select only first number
        vm.searchModel.city || ''
      ].filter((field) => !!field).join(' ').trim();

      if (!vm.searchModel.city || !addressQuery.length) {
        if (!vm.searchModel.gush || !vm.searchModel.helka) {
          return;
        }
        addressQuery = await vm.getAddressByGushHelka(vm.searchModel.gush, vm.searchModel.helka);
        if (!addressQuery.length) {
          return;
        }
      }

      const coordinates = await govmapService.getCoordinates(addressQuery);
      if (vm.coordinatesRequestCounter > requestCounter) {
        return;
      }
      return coordinates;
    }

    getGushHelka () {
      vm.gushRequestCounter++;
      const requestCounter = vm.gushRequestCounter;
      const addressQuery = (vm.searchModel.street || '') + ' ' +
        String(vm.searchModel.house || '').trim().replace(/\D[^>]*/g, '') + ' ' + // select only first number
        (vm.searchModel.city || '');

      if (!vm.searchModel.city || !addressQuery.length) {
        return;
      }

      govmapService.getGushHelka(addressQuery)
        .then((gushArray) => {
          if (vm.gushRequestCounter > requestCounter) {
            return;
          }
          $timeout(() => {
            $scope.$apply(() => {
              vm.searchModel.gush = gushArray[0] || vm.searchModel.gush;
              vm.searchModel.helka = gushArray[1] || vm.searchModel.helka;
              vm.searchModel.subHelka = gushArray[2] || vm.searchModel.subHelka;
            });
          });
        });
    }

    async onGushHelkaChanged () {
      if (!vm.searchModel.gush || !vm.searchModel.helka || vm.searchModel.city || vm.searchModel.street) {
        return;
      }

      vm.addressRequestCounter++;
      const requestCounter = vm.addressRequestCounter;

      const address = await govmapService.getAddressByGushHelka(vm.searchModel.gush, vm.searchModel.helka);
      if (vm.addressRequestCounter > requestCounter) {
        return;
      }
      $timeout(() => {
        $scope.$apply(() => {
          vm.searchModel.city = address.city || '';
          vm.searchModel.street = address.street || '';
          vm.searchModel.house = address.house || '';
        });
      });

      return address;
    }
  }

  return new NadlanCtrl();
}
