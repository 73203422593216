import dayjs from 'dayjs';

import optionList from '../../../project-options-list';

export default function ($scope, $rootScope, $timeout, projectService, utils) {
  let vm;

  class PaymentsInvoicePreviewCtrl {
    constructor () {
      vm = this;
      vm.vat = 0.17;

      vm.transactionTypeList = optionList.transactionTypeList;
      vm.transactionCardBrandsList = optionList.transactionCardBrandsList;
      vm.transactionCardDealsList = optionList.transactionCardDealsList;
      vm.transactionVatList = optionList.transactionVatList;
      vm.transactionAppTypes = optionList.transactionAppTypes;
      vm.transactionTypeDetails = {
        cheque: {
          chequeBankName: 'מספר בנק',
          chequeBankBranch: 'מספר סניף',
          chequeBankAccount: 'מספר חשבון',
          chequeNumber: 'מספר שיק'
        },
        transfer: {
          transferBankName: 'מספר בנק',
          transferBankBranch: 'מספר סניף',
          transferBankAccount: 'מספר חשבון'
        },
        creditCard: {
          creditNumber: 'מספר כרטיס',
          cardBrands: 'סוג כרטיס',
          cardDeals: 'סוג עסקה',
          creditPayments: 'תשלומים'
        },
        paymentApp: {
          paymentAppAppType: 'אפליקציה',
          paymentAppAccountId: 'חשבון משלם',
          paymentAppTransactionId: 'מס\' עסקה'
        }
      };
    }

    $onInit () {
      vm.now = dayjs().format();
      vm.transaction = vm.resolve.transaction;
      vm.project = vm.resolve.project;
      vm.editTransactionFn = vm.resolve.editTransactionFn;
      vm.transaction.details.customerInvoiceName = vm.transaction.details.customerInvoiceName || _.get(vm.project.customer, 'name');
      vm.transaction.details.customerInvoiceEmail = vm.transaction.details.customerInvoiceEmail || _.get(vm.project.customer, 'email');
      vm.checkErrors();
    }

    checkErrors () {
      vm.errors = [];
      vm.warnings = [];

      if (!_.get($rootScope, 'owner.email')) {
        vm.errors.push({
          title: 'יש להגדיר כתובת דוא"ל בהגדרות הארגון'
        });
      }
      if (!vm.transaction.details.customerInvoiceName) {
        vm.warnings.push({
          title: 'יש להזין שם לקוח'
        });
      }
      if (!vm.transaction.details.customerInvoiceEmail) {
        vm.warnings.push({
          title: 'חסרה כתובת דוא"ל ללקוח'
        });
      }
      if (_.isNil(vm.transaction.type)) {
        vm.errors.push({
          title: 'יש לבחור אמצעי תשלום'
        });
      }
      if (vm.transaction.type === 2) { // cheque
        Object.keys(vm.transactionTypeDetails.cheque).forEach((field) => {
          if (!_.get(vm.transaction, 'details.' + field)) {
            vm.errors.push({
              title: 'חסר ' + vm.transactionTypeDetails.cheque[field]
            });
          }
        });
      }
      if (vm.transaction.type === 3) { // creditCard
        Object.keys(vm.transactionTypeDetails.creditCard).forEach((field) => {
          if (!_.get(vm.transaction, 'details.' + field)) {
            vm.errors.push({
              title: (field === 'creditPayments' ? 'חסרים' : 'חסר') + ' ' + vm.transactionTypeDetails.creditCard[field]
            });
          }
        });
      }
      if (vm.transaction.type === 4) { // transfer
        Object.keys(vm.transactionTypeDetails.transfer).forEach((field) => {
          if (!_.get(vm.transaction, 'details.' + field)) {
            vm.warnings.push({
              title: 'חסר ' + vm.transactionTypeDetails.transfer[field]
            });
          }
        });
      }
      if (vm.transaction.type === 10) { // payment app
        if (!_.get(vm.transaction, 'details.paymentAppAppType')) {
          vm.errors.push({
            title: 'לא נבחרה אפליקציית תשלום'
          });
        }
      }
      if (!_.get(vm.transaction, 'details.customerIdNumber')) {
        vm.warnings.push({
          title: 'לא הוזן מספר זהות'
        });
      } else if (!utils.isValidId(vm.transaction.details.customerIdNumber)) {
        vm.warnings.push({
          title: 'מספר הזהות שהוזן אינו תקין'
        });
      }
    }

    createInvoice () {
      if (vm.createInvoiceProgress) { return; }
      if (vm.errors.length) {
        jQuery('.payments-invoice-preview > .mobile-modal-inner > .panel-body').animate({
          scrollTop: 9999
        }, 1000);
        return;
      }
      vm.createInvoiceProgress = true;
      projectService.createInvoice(vm.project.id, vm.transaction.id)
        .then((response) => {
          $rootScope.$broadcast('transactionSaved');
          $timeout(() => {
            $rootScope.$broadcast('transactionSaved');
          }, 4500);
          utils.notify('חשבונית/קבלה הופקה בהצלחה', 'alert-success');
          vm.modalInstance.close();
        })
        .catch((response) => {
          let errorMessage;
          if (_.get(response, 'data.data') === 'Error: Invoice for transaction already created') {
            errorMessage = 'חשבונית קיימת כבר עבור תשלום זה';
          } else {
            errorMessage = 'שגיאה בהפקת חשבונית/קבלה';
          }
          utils.notify(errorMessage, 'alert-danger');
        })
        .finally(() => {
          vm.createInvoiceProgress = false;
        });
    }

    closeModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    getTransactionDetailsValue (transaction, field) {
      if (field === 'cardBrands') {
        return vm.transactionCardBrandsList[_.get(transaction, 'details.' + field) || ''];
      } else if (field === 'cardDeals') {
        return vm.transactionCardDealsList[_.get(transaction, 'details.' + field) || ''];
      } else if (field === 'paymentAppAppType') {
        return vm.transactionAppTypes[_.get(transaction, 'details.' + field) || ''];
      } else {
        return _.get(transaction, 'details.' + field) || '';
      }
    }

    returnToEditDetails () {
      vm.editTransactionFn(vm.transaction, true);
      vm.closeModalBox();
    }
  }

  return new PaymentsInvoicePreviewCtrl();
}
