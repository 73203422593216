import template from './project-default-events.html';
import controller from './project-default-events.controller';
import './project-default-events.scss';

const projectDefaultEventsComponent = {
  require: {
    settingsCtrl: '^settings'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', 'settingsService', 'utils'];

export default projectDefaultEventsComponent;
