export default function ($scope, $rootScope, $state, $uibModal, notify, userService, ownerService, utils) {
  let vm;

  class OwnerProjectsCtrl {
    constructor () {
      vm = this;
      vm.progress = true;
      vm.orderBy = '-createdAt';

      vm.tableHeadList = [
        { name: 'number', label: 'מספר פרויקט', sortable: true },
        { name: 'createdAt', label: 'תאריך יצירה', type: 'date', sortable: true },
        { name: 'dueDate', label: 'תאריך יעד', type: 'date', sortable: true },
        { name: 'customer.name', label: 'לקוח', sortable: true, type: 'customFunction', customFunction: vm.getCustomer },
        { name: 'street', label: 'כתובת', type: 'customFunction', customFunction: vm.getAddress, sortable: true },
        { name: 'city', label: 'עיר', sortable: true },
        { name: 'gush', label: 'גוש', sortable: true },
        { name: 'helka', label: 'חלקה', sortable: true },
        { name: 'subHelka', label: 'תת חלקה', sortable: true },
        { name: 'userId', label: 'נוצר ע"י', type: 'compile', customFunction: vm.getUserString },
        { name: 'status', label: 'סטטוס', sortable: true }
      ];
    }

    $onInit () {
      vm.userList = _.get(vm.ownerShowCtrl.ownerObject, 'users') || [];
    }

    getProjectList (args = {}) {
      return ownerService.getOwnerProjects(vm.ownerShowCtrl.ownerObject.id, args)
        .then((res) => {
          vm.ownerShowCtrl.ownerObject.projectsCount = res.count || 0;
          return res;
        });
    }

    getCustomer (row) {
      return (_.get(row, 'customer.name') || '');
    }

    getAddress (row) {
      return utils.joinStringArray([row.street, row.house], ' ');
    }

    getUserString (row) {
      if (row.htmlString) {
        return row.htmlString;
      }
      let userString = '';

      const user = vm.userList.find((user) => user.id === row.userId);
      const relatedUserIds = _.uniq(Object.values(row.relatedUsers || {}).filter((id) => id && id !== user.id));

      const relatedUsers = relatedUserIds.map((userId) => {
        return vm.userList.find((user) => user.id === userId);
      });

      if (!user && !relatedUsers.length) {
        return '';
      }

      if (user) {
        userString += vm.agentAvatar(user);
      }

      if (relatedUsers.length) {
        relatedUsers.slice(0, 4).forEach((user, i) => {
          userString += vm.agentAvatar(user);
        });
      }

      row.htmlString = `<div class="avatars-container">${userString}</div>`;
      return row.htmlString;
    }

    agentAvatar (agent) {
      return `<span class="avatar" uib-tooltip="${agent.firstName || ''} ${agent.lastName || ''}" tooltip-append-to-body="true"><img class="img-circle" src="${userService.getUserProfileImage(agent)}" /></span>`;
    }

    getUserProfileImage (agent) {
      return userService.getUserProfileImage(agent);
    }
  }

  return new OwnerProjectsCtrl();
}
