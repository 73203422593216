const formatDateModule = angular.module('uiSelectBlur', [])
  .directive('uiSelectBlur', function () {
    return {
      require: 'uiSelect',
      link ($scope, $element, attrs, $select) {
        const searchIntputElemet = jQuery($element).find('.ui-select-search');
        searchIntputElemet.on('blur', (e) => {
          $select.close(true);
        });
      }
    };
  })
  .name;

export default formatDateModule;
