let $rootScope;
let request;

class loginService {
  constructor (_$rootScope, _request) {
    $rootScope = _$rootScope;
    request = _request;
  }

  login (email, password) {
    return request('/login', { email, password })
      .then((res) => {
        const user = _.get(res, 'data.data');
        $rootScope.user = user;
        $rootScope.owner = user.owner;
        $rootScope.$broadcast('userLoggedIn', user);
        $rootScope.$broadcast('ownerSaved', user.owner);
        return res;
      });
  }

  isAuthenticated () {
    return request('/is-authenticated', {}, 'get', { ignoreLoadingBar: true })
      .then(async (res) => {
        if (_.get(res, 'data.status') !== 'success') {
          return Promise.reject(new Error('is-authenticated response failed'));
        }
        const user = _.get(res, 'data.data');

        if (!$rootScope.user) {
          $rootScope.user = user;
        }
        if (!$rootScope.owner) {
          const owner = await getOwner(user.ownerId);
          $rootScope.owner = owner;
          $rootScope.hasAddress = _.get($rootScope.owner, 'crmSettings.projectHasAddress');
          $rootScope.$broadcast('userAuthenticated', user);
          $rootScope.$broadcast('ownerSaved', owner);
        } else {
          $rootScope.$broadcast('userAuthenticated', user);
        }

        return user;
      });
  }

  logout () {
    $rootScope.showNavigation = undefined;
    return request('/logout')
      .then((res) => {
        if (res.data.data === 'disconnected') {
          $rootScope.$broadcast('userLoggedOut');
          $rootScope.user = undefined;
          $rootScope.owner = undefined;
        }
        return res;
      });
  }

  restorePassword (email) {
    return request('/public-api/restore-password', { email }, 'POST');
  }

  checkToken (token) {
    return request('/public-api/check-restore-token', { token }, 'POST', { ignoreLoadingBar: true });
  }

  newPassword (token, password) {
    return request('/public-api/new-password', { token, password }, 'POST', { ignoreLoadingBar: true });
  }
}

function getOwner (ownerId) {
  return request('/owner/' + ownerId + '/?includeUsers=true')
    .then((res) => _.get(res, 'data.data'));
}

loginService.$inject = ['$rootScope', 'request'];

const loginServiceModule = angular.module('loginService', [])
  .service('loginService', loginService)
  .name;

export default loginServiceModule;
