import AddProjectButton from './add-project-button/add-project-button';
import Address from './address/address';
import AgentSelector from './agent-selector/agent-selector';
import AppSelect from './app-select/app-select';
// import EmlViewer from './eml-viewer/eml-viewer';
import Events from './events/events';
import Footer from './footer/footer';
import FormField from './form-field/form-field';
import FormFieldRepeatable from './form-field-repeatable/form-field-repeatable';
import Header from './header/header';
import MsgViewer from './msg-viewer/msg-viewer';
import Navigation from './navigation/navigation';
import PagingTable from './paging-table/paging-table';
import ProjectAlerts from './project-alerts/project-alerts';
import Search from './search/search';
import SortingTable from './sorting-table/sorting-table';
import TagListSelector from './tag-list-selector/tag-list-selector';
import UnreadPosts from './unread-posts/unread-posts';
import multiSelect from './multi-select/multi-select';

import './homer';
import './status-icon/status-icon.scss';
import './fix-animate.css';

const commonModule = angular.module('app.common', [
  AddProjectButton,
  Address,
  AgentSelector,
  AppSelect,
  // EmlViewer,
  Events,
  Footer,
  FormField,
  FormFieldRepeatable,
  Header,
  MsgViewer,
  Navigation,
  multiSelect,
  PagingTable,
  ProjectAlerts,
  Search,
  SortingTable,
  TagListSelector,
  UnreadPosts
])
  .name;

export default commonModule;
