export default function ($scope, $timeout) {
  let vm;

  class AppSelectController {
    constructor () {
      vm = this;
      vm.name = 'appSelect';
    }

    $onInit () {
      vm.options = vm.options || [];
      const selectedOption = vm.options.find((option) => option.value === vm.ngModel) || {};
      vm.selectedOption = selectedOption.label || vm.allOption === false ? '' : 'הכל';
    }

    selectOption (option) {
      vm.ngModel = option.value;
      vm.selectedOption = option.label;
      vm.onChange({ value: option.value });
    }
  }

  return new AppSelectController();
}
