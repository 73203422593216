export default function ($scope, $state, $filter, userService, ticketService) {
  let vm;
  const uiSref = 'ticket.ticketShow({id: row.id})';

  class TicketListCtrl {
    constructor () {
      vm = this;
      vm.name = 'userTickets';

      vm.tableHeadList = [
        { name: 'createdAt', type: 'customFunction', customFunction: vm.getDateField, label: 'תאריך', sortable: true, uiSref },
        { name: 'number', type: 'customFunction', customFunction: vm.getNumberField, label: 'מספר', sortable: true, uiSref },
        { name: 'title', label: 'נושא', sortable: true, uiSref },
        { name: 'status', type: 'customFunction', customFunction: vm.getStatusField, label: '', uiSref }
      ];
    }

    $onInit () {
      vm.userId = $state.params.id;
    }

    getTicketList (args) {
      return userService.getUserTickets(vm.userId, args);
    }

    getDateField (row) {
      return $filter('date')(row.createdAt, 'dd/MM/yyyy HH:mm');
    }

    getNumberField (row) {
      if (row.number) {
        return '#' + row.number;
      }
    }

    getStatusField (row) {
      if (!row.open) {
        return '<div class="badge badge-danger m-r-sm">סגור</div>';
      } else if (row.isWaiting) {
        return '<div class="badge badge-success m-r-sm">ממתין למענה</div>';
      } else {
        return '<div class="badge badge-default m-r-sm">פתוח</div>';
      }
    }
  }

  return new TicketListCtrl();
}
