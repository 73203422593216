import template from './agent-list.html';
import controller from './agent-list.controller';
import './agent-list.scss';

const agentListComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', '$uibModal', 'utils', 'agentService', 'userService'];

export default agentListComponent;
