export default function () {
  let vm;

  class FooterController {
    constructor () {
      vm = this;
      vm.name = 'footer';
      vm.now = new Date();
    }
  }

  return new FooterController();
}
