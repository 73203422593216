export default function ($scope, $rootScope, $timeout, projectService) {
  let vm;

  class ProjectPaymentsPricesCtrl {
    constructor () {
      vm = this;
      vm.vat = 0.17;
    }

    $onInit () {
      vm.priceList = [];
      if (_.get(vm.resolve, 'isForm')) {
        $scope.$watch('vm.resolve.projectFields.prices', (newValue, oldValue) => {
          vm.priceList = angular.copy(newValue) || [];
          if (!vm.priceList.length) {
            vm.addPrice();
          }
        });

        $timeout(() => {
          jQuery('.price-edit-modal .price-sum-td:last input').focus();
        });
      } else {
        vm.projectFields.prices = vm.projectFields.prices || [];
        vm.priceList = vm.projectFields.prices;
        if (!vm.priceList.length) {
          vm.priceList.push({
            sum: '',
            title: '',
            excludeVat: false
          });
        }
      }

      vm.resetSlickHeight();
    }

    closeModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    addPrice () {
      vm.priceList.push({
        sum: '',
        title: '',
        excludeVat: false
      });
      $timeout(() => {
        jQuery('.price-edit-modal .price-sum-td:last input').focus();
      });
      vm.resetSlickHeight();
    }

    removePrice (price) {
      const priceIndex = vm.priceList.indexOf(price);
      vm.priceList.splice(priceIndex, 1);
      if (!vm.priceList.length) {
        vm.addPrice();
      }
      $timeout(() => {
        jQuery('.price-edit-modal .price-sum-td:last input').focus();
      });
      vm.resetSlickHeight();
    }

    savePrices () {
      if (vm.saveProgress || vm.priceForm.$invalid) {
        return;
      }
      vm.saveProgress = true;
      const projectId = vm.resolve.projectFields.id;
      projectService.saveProjectPrices(projectId, vm.priceList)
        .then((prices) => {
          $rootScope.$broadcast('pricesSaved');
          vm.modalInstance.close();
          vm.resolve.projectFields.prices = prices;
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    priceTotal () {
      return Number((vm.priceList.reduce((total, price) => total + Number(price.sum) + vm.getPriceVat(price), 0).toFixed(2)) || 0);
    }

    priceTotalVat () {
      return Number((vm.priceList.reduce((total, price) => total + vm.getPriceVat(price), 0) || 0).toFixed(2));
    }

    getPriceVat (price) {
      if (price.sum > 0 && price.excludeVat) {
        return price.sum * vm.vat;
      }
      return 0;
    }

    resetSlickHeight () {
      $timeout(() => {
        const el = jQuery('.slick-list.draggable');
        const newHeight = el.find('.slick-current.slick-active').height();
        el.animate({ height: newHeight }, 300);
      }, 100);
    }
  }

  return new ProjectPaymentsPricesCtrl();
}
