import template from './task-board-page.html';
import controller from './task-board-page.controller';
import './task-board-page.scss';

const taskBoardPageComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope'];

export default taskBoardPageComponent;
