export default function ($scope, $rootScope, $timeout, settingsService, utils) {
  let vm;

  class ProjectTagsSettingsCtrl {
    constructor () {
      vm = this;

      vm.defaultTagColors = [
        { color: 'white', background: '#0029d0' },
        { color: 'white', background: '#428bca' },
        { color: 'white', background: '#44ad8e' },
        { color: 'black', background: '#a8d695' },
        { color: 'white', background: '#5cb85c' },
        { color: 'black', background: '#69d100' },
        { color: 'white', background: '#004f00' },
        { color: 'white', background: '#34495e' },
        { color: 'white', background: '#7f8c8d' },
        { color: 'black', background: '#a295d6' },
        { color: 'white', background: '#5843ad' },
        { color: 'white', background: '#8e44ad' },
        { color: 'black', background: '#ffecdb' },
        { color: 'white', background: '#ad4363' },
        { color: 'white', background: '#d10069' },
        { color: 'white', background: '#cc0033' },
        { color: 'white', background: '#ff0000' },
        { color: 'white', background: '#d9534f' },
        { color: 'black', background: '#d1d300' },
        { color: 'black', background: '#f0ad4e' },
        { color: 'white', background: '#ae8e3c' }
      ];
    }

    $onInit () {
      vm.tagList = angular.copy(settingsService.getOwnerProjectTags());
      vm.tagListBeforeChanges = angular.copy(vm.tagList); // save for a reset

      $scope.$on('settingsLoaded', () => {
        vm.tagList = angular.copy(settingsService.getOwnerProjectTags());
      });
    }

    saveAllTagsSettings () {
      vm.tagList = vm.filterDuplicateTagsByTilte(vm.tagList);
      const validTagList = vm.tagList.filter((tag) => tag.title !== '')
        .map((tag) => ({
          title: tag.title,
          background: tag.background || '#888',
          color: tag.color || 'black'
        }));

      settingsService.saveSettings({ projectTags: validTagList })
        .then(() => {
          $rootScope.$broadcast('ownerSaved');
          utils.notify('התוויות עודכנו בהצלחה', 'alert-success');
          // forget about old changes
          vm.tagListBeforeChanges = angular.copy(vm.tagList);
        })
        .catch((error) => {
          utils.notify('שגיאה', 'alert-danger');
          console.log(error);
        });
    }

    deleteTag (tagName) {
      vm.tagList = vm.tagList.filter((tag) => tag.title !== tagName);
    }

    resetChanges () {
      vm.tagList = angular.copy(vm.tagListBeforeChanges);
    }

    addEmptyTag () {
      vm.tagList.push({ title: '', background: '' });
    }

    filterDuplicateTagsByTilte (tagsArray) {
      return tagsArray.filter((tag, i, tags) => tags.findIndex(t => t.title === tag.title) === i);
    }
  }

  return new ProjectTagsSettingsCtrl();
}
