import city from './city/city';
import street from './street/street';

const addressModule = angular.module('address', [
  city,
  street
])
  .name;

export default addressModule;
