/**
 * @binding-options:
 *  allOption | return: '' | label: 'הכל' | shown by default
 *  nullOption | return: '$null' | label: 'ללא נציג אחראי' or custom `nullSelectedLabel` | shown by default
 *  emptyOption | return: '' | label: '' | hidden by default
 */

import template from './agent-selector.html';
import controller from './agent-selector.controller';
import './agent-selector.scss';

const agentSelectorComponent = {
  bindings: {
    nullSelectedLabel: '@',
    agent: '<?',
    allOption: '<?',
    nullOption: '<?',
    emptyOption: '<?',
    appendToBody: '<?',
    onAgentSelected: '&?',
    isOpen: '=?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', 'userService'];

export default agentSelectorComponent;
