import template from './task-edit.html';
import controller from './task-edit.controller';
import './task-edit.scss';

const taskEditComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'utils', 'taskService'];

export default taskEditComponent;
