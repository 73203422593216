import template from './payments.html';
import controller from './payments.controller';
import './payments.scss';

const paymentsComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$window', '$timeout', '$uibModal', 'utils', 'paymentsService'];

export default paymentsComponent;
