import loginComponent from './login.component';
import newPassword from './new-password/new-password';
import restorePassword from './restore-password/restore-password';
import signUp from './sign-up/sign-up';
import signUpLead from './sign-up-lead/sign-up-lead';
import loginService from './login.service';

const loginModule = angular.module('login', [
  newPassword,
  restorePassword,
  signUp,
  signUpLead,
  loginService
])
  .component('login', loginComponent)
  .name;

export default loginModule;
