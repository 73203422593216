import template from './advanced-search-results.html';
import controller from './advanced-search-results.controller';
import './advanced-search-results.scss';

const advancedSearchResultsComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$location', '$filter', '$uibModal', 'projectService', 'customerService', 'taskService', 'utils', 'settingsService', 'userService'];

export default advancedSearchResultsComponent;
