import template from './app-select.html';
import controller from './app-select.controller';
import './app-select.scss';

const appSelectComponent = {
  bindings: {
    ngModel: '=?',
    options: '<?',
    onChange: '&?',
    allOption: '<?',
    appendToBody: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', 'userService'];

export default appSelectComponent;
