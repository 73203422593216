
export default function ($scope, $timeout) {
  let vm;
  const operators = {
    '=' (value, parentValue) {
      return parentValue == value;
    },
    '!=' (value, parentValue) {
      return parentValue != value;
    },
    '>' (value, parentValue) {
      return parentValue > value;
    },
    '<' (value, parentValue) {
      return !parentValue || parentValue < value;
    },
    '!' (value, parentValue) {
      return !parentValue;
    }
  };

  class FormFieldRepeatableController {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.modelObject = vm.modelObject || {};
    }

    shouldShowField (field) {
      if (angular.isUndefined(_.get(field, 'if.operator'))) {
        return true;
      }

      return operators[field.if.operator](field.if.value, vm.modelObject[vm.field.name]);
    }
  }

  return new FormFieldRepeatableController();
}
