import adminPaymentsList from './admin-payments-list/admin-payments-list';
import adminTicketList from './admin-ticket-list/admin-ticket-list';
import owner from './owner/owner';
import user from './user/user';
import adminService from './admin.service';

const adminModule = angular.module('admin', [
  adminPaymentsList,
  adminTicketList,
  owner,
  user,
  adminService
])
  .name;

export default adminModule;
