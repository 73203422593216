export default function ($scope, $rootScope, $timeout, projectService, settingsService, userService) {
  let vm;

  class ProjectAgentSelectorCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      if (vm.agentId && angular.isArray($rootScope.owner.users)) {
        vm.currentAgent = $rootScope.userById(vm.agentId);
        vm.selectedAgentInactive = angular.isObject(vm.currentAgent) && !vm.currentAgent.active;
      }
    }

    editAgent () {
      vm.agentEdit = true;
      $timeout(() => {
        vm.agentEditIsOpen = true;
      }, 100);
    }

    agentEditToggled (open) {
      if (!open) {
        vm.agentEditIsOpen = false;
        vm.agentEdit = false;
      }
    }

    changeAgent (agentId) {
      vm.agentEditIsOpen = false;
      vm.agentEdit = false;
      vm.agentId = agentId;
      vm.agentField = agentId;
      vm.onAgentChange({ agentId });

      if (!agentId || !angular.isArray($rootScope.owner.users)) {
        vm.selectedAgentInactive = false;
        vm.currentAgent = undefined;
        return;
      }
      vm.currentAgent = $rootScope.userById(agentId);
      vm.selectedAgentInactive = angular.isObject(vm.currentAgent) && !vm.currentAgent.active;
    }

    getUserProfileImage (agent) {
      return userService.getUserProfileImage(agent);
    }
  }

  return new ProjectAgentSelectorCtrl();
}
