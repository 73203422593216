// let service;
let request;

class noteService {
  constructor (_request) {
    // service = this;
    request = _request;
  }

  getNoteList (args = {}) {
    return request('/note/?includeProject=true&includeCustomer=true&' + jQuery.param(args))
      .then((res) => res.data.data);
  }

  getProjectNoteList (projectId) {
    return request('/project/' + projectId + '/note')
      .then((res) => res.data.data);
  }

  saveNote (projectId, noteId, noteObject) {
    return request('/project/' + projectId + '/note/' + (noteId || ''), { note: noteObject }, (noteId ? 'PUT' : 'POST'))
      .then((res) => res.data.data);
  }

  deleteNote (projectId, noteId) {
    return request('/project/' + projectId + '/note/' + noteId, null, 'DELETE')
      .then((res) => res.data.data);
  }
}

noteService.$inject = ['request'];

const noteServiceModule = angular.module('noteService', [])
  .service('noteService', noteService)
  .name;

export default noteServiceModule;
