const regexInputModule = angular.module('regexInput', [])
  .directive('regexInput', [function () {
    return {
      restrict: 'A',
      require: '?ngModel',
      scope: {},
      replace: false,
      link (scope, element, attrs, ctrl) {
        element.bind('keypress', (event) => {
          const regex = new RegExp(attrs.regex);
          const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
          if (!regex.test(ctrl.$viewValue + key)) {
            event.preventDefault();
            return false;
          }
        });
      }
    };
  }])
  .name;

export default regexInputModule;
