import isEmail from 'validator/lib/isEmail';

export default function ($scope, $rootScope, utils, agentService) {
  let vm;

  class AgentEditCtrl {
    constructor () {
      vm = this;
      const roles = require('../../../../../../roles.json');
      vm.agentRoles = _.pickBy(roles, (role) => role.showInManagerAgentUI);
    }

    $onInit () {
      vm.agentFields = {
        active: true,
        roles: new Set()
      };

      const agentFieldKeys = ['id', 'firstName', 'lastName', 'lastName', 'email', 'phone', 'active'];
      const agent = vm.resolve.agent;
      if (agent) {
        agentFieldKeys.forEach((field) => {
          if (agent.hasOwnProperty(field)) {
            vm.agentFields[field] = agent[field];
          }
        });
        if (_.isArray(agent.roles)) {
          vm.agentFields.roles = new Set(agent.roles);
        }
      }

      if (vm.resolve.passwordEditMode) {
        vm.headerTitle = 'שינוי סיסמת נציג';
      } else if (vm.agentFields.id) {
        vm.headerTitle = 'עריכת פרטי נציג';
      } else {
        vm.headerTitle = 'הוספת נציג';
      }

      $scope.$watchGroup(['vm.agentFields.password', 'vm.agentFields.password2'], (newVal, oldVal) => {
        if (_.get(vm, 'form.password2')) {
          const passwordMatch = vm.agentFields.password === vm.agentFields.password2;
          vm.form.password2.$setValidity('passwordMatch', passwordMatch);
          jQuery('#password2-input')[0].setCustomValidity(passwordMatch || !vm.agentFields.password2 ? '' : 'הסיסמאות אינן תואמות');
        }
      });

      $scope.$watch('vm.agentFields.email', (newVal, oldVal) => {
        if (vm.form.email) {
          vm.form.email.$setValidity('registeredEmail', true);
          const isValidEmail = isEmail(vm.agentFields.email || '');
          vm.form.email.$setValidity('email', isValidEmail);
          jQuery('#email-input')[0].setCustomValidity(isValidEmail ? '' : 'דוא"ל לא תקין');
        }
      });
    }

    closeModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    roleClick (roleName) {
      const doneRoles = [];
      function recursivelyEnableRole (roleName) {
        vm.toggleRole(roleName, true);
        doneRoles.push(roleName);
        for (const role of vm.agentRoles[roleName].include || []) {
          if (!doneRoles.includes(role)) {
            recursivelyEnableRole(role);
          }
        }
      }

      function recursivelyDisableRole (roleName) {
        vm.toggleRole(roleName, false);
        doneRoles.push(roleName);
        for (const [name, value] of Object.entries(vm.agentRoles)) {
          if ((value.include || []).includes(roleName) && !doneRoles.includes(name)) {
            recursivelyDisableRole(name);
          }
        }
      }

      if (vm.agentFields.roles.has(roleName)) {
        recursivelyDisableRole(roleName);
      } else {
        recursivelyEnableRole(roleName);
      }
    }

    toggleRole (roleName, bool) {
      if (bool) {
        vm.agentFields.roles.add(roleName);
      } else {
        vm.agentFields.roles.delete(roleName);
      }
    }

    clearRoles () {
      vm.agentFields.roles.clear();
    }

    isActive (roleName) {
      return vm.agentFields.roles.has(roleName);
    }

    saveAgent () {
      if (vm.saveProgress) {
        return;
      }
      if (vm.form.$invalid) {
        jQuery('#user-details-form')[0].reportValidity();
        utils.setFormFieldsDirty(vm.form);
        return;
      }
      let successMessage;
      if (vm.resolve.passwordEditMode) {
        successMessage = 'סיסמת נציג שונתה';
      } else if (vm.agentFields.id) {
        successMessage = 'פרטי נציג נשמרו';
      } else {
        successMessage = 'נציג חדש נשמר';
      }

      vm.saveProgress = true;

      let agentObject = vm.agentFields;
      agentObject.roles = [...agentObject.roles];
      if (vm.resolve.passwordEditMode) {
        agentObject = {
          password: vm.agentFields.password,
          password2: vm.agentFields.password2
        };
      }

      return agentService.saveAgent(vm.agentFields.id, agentObject)
        .then((agentResult) => {
          utils.notify(successMessage, 'alert-success');
          vm.modalInstance.close();
          $rootScope.$broadcast('agentSaved');
        })
        .catch((error) => {
          let errorMessage = 'שגיאה';
          if (error.data.data === 'SequelizeUniqueConstraintError: Email is already registered.') {
            errorMessage = 'כתובת הדוא"ל כבר רשומה במערכת';
            vm.form.email.$setValidity('registeredEmail', false);
            jQuery('#email-input')[0].setCustomValidity(errorMessage);
          }
          if (error.data.data === 'SequelizeValidationError: Validation error: Invalid email.') {
            errorMessage = 'דוא"ל לא תקין';
          }
          utils.notify(errorMessage, 'alert-danger');
          console.log(error);
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }
  }

  return new AgentEditCtrl();
}
