import uuidv1 from 'uuid/v1';

export default function ($scope, $rootScope, $timeout, settingsService, utils) {
  let vm;

  class ProjectAgentRelationsCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.relationsList = angular.copy(_.get($rootScope, 'owner.settings.projectAgentRelations')) || [];
      vm.relationsListBeforeUpdate = angular.copy(vm.relationsList) || [];

      $scope.$on('settingsLoaded', () => {
        vm.relationsList = angular.copy(_.get($rootScope, 'owner.settings.projectAgentRelations')) || [];
        vm.relationsListBeforeUpdate = angular.copy(vm.relationsList) || [];
      });
    }

    addNewRelation () {
      vm.relationsList = vm.relationsList.filter((relation) => relation.id); // !TODO: good ux?
      vm.relationsList.push({ title: '' });
      $timeout(() => {
        jQuery('.relation:last').focus();
      }, 100);
    }

    saveRelation (title, index, id) {
      const isNewRelation = !id;
      const existingRelationTitles = vm.relationsListBeforeUpdate.map(relation => relation.title);
      const relationTitleExists = existingRelationTitles.includes(title);
      const didNotEdit = !isNewRelation && vm.relationsListBeforeUpdate[index].title === title;

      if (!title || didNotEdit || relationTitleExists) return; // !TODO: need to show error for user?

      if (!isNewRelation) {
        vm.relationsList.find((relation) => relation.id === id).title = title;
      } else {
        const id = uuidv1();
        vm.relationsList = [...vm.relationsListBeforeUpdate, { id, title }];
      }

      settingsService.saveSettings({ projectAgentRelations: vm.relationsList })
        .then(() => {
          $rootScope.$broadcast('ownerSaved');
          const message = `השיוך ${isNewRelation ? 'נוצר' : 'עודכן'}`;
          utils.notify(message, 'alert-success');
        })
        .catch((error) => {
          utils.notify('שגיאה', 'alert-danger');
          console.log(error);
        });
    }

    deleteRelation (id, index) {
      const relationToDelete = vm.relationsList[index];
      if (!id) {
        vm.relationsList = vm.relationsList.filter((_, i) => i !== index);
        return;
      }

      vm.warnOnDeletion(relationToDelete.title)
        .then((selection) => {
          if (selection) {
            vm.relationsList = vm.relationsList.filter((_, i) => i !== index);

            settingsService.saveSettings({ projectAgentRelations: vm.relationsList })
              .then(() => {
                $rootScope.$broadcast('ownerSaved');
                const message = 'השיוך נמחק';
                utils.notify(message, 'alert-success');
              })
              .catch((error) => {
                utils.notify('שגיאה', 'alert-danger');
                console.log(error);
              });
          }
        });
    }

    warnOnDeletion (relationTitle) {
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: `האם למחוק את "${relationTitle}?"`,
        buttons: [{
          label: 'מחיקה',
          btnClass: 'btn-primary',
          onClick ($uibModalInstance) {
            $uibModalInstance.dismiss('cancel');
            return true;
          }
        },
        {
          label: 'ביטול',
          btnClass: 'btn-default',
          onClick ($uibModalInstance) {
            $uibModalInstance.close();
          }
        }]
      };
      return utils.modalAlert(modalParams);
    }
  }

  return new ProjectAgentRelationsCtrl();
}
