import helpers from './helpers';

export default ['$stateProvider', ($stateProvider) => {
  $stateProvider
    .state('report', {
      url: '/reports',
      component: 'reports',
      redirectTo: 'report.projectPayments'
    })
    .state('report.projectPayments', {
      url: '/project-payments',
      params: {
        query: null,
        index: undefined
      },
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.managerAuth]
      },
      component: 'projectPaymentsReport'
    })
    .state('report.projectTransactions', {
      url: '/project-transactions',
      params: {
        query: null,
        index: undefined
      },
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.managerAuth]
      },
      component: 'projectTransactionsReport'
    })
    .state('report.progressProjects', {
      url: '/progress-projects',
      params: {
        query: null,
        index: undefined
      },
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.managerAuth]
      },
      component: 'progressProjectsReport'
    });
}];
