import template from './search.html';
import controller from './search.controller';
import './search.scss';

const searchComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', 'request', 'settingsService'];

export default searchComponent;
