import projectPaymentsComponent from './project-payments.component';
import projectPaymentsPrices from './project-payments-prices/project-payments-prices';
import paymentsInvoicePreview from './payments-invoice-preview/payments-invoice-preview';

const projectPaymentsModule = angular.module('projectPayments', [
  projectPaymentsPrices,
  paymentsInvoicePreview
])
  .component('projectPayments', projectPaymentsComponent)
  .name;

export default projectPaymentsModule;
