import template from './owner-customers.html';
import controller from './owner-customers.controller';
import './owner-customers.scss';

const ownerCustomersComponent = {
  require: {
    ownerShowCtrl: '^ownerShow'
  },
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$uibModal', 'notify', 'ownerService'];

export default ownerCustomersComponent;
