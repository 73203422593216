// import optionsList from '../project-options-list';

export default function ($scope, $rootScope, $state, $timeout, utils, projectService, govmapService, counts, recipient, settingsService) {
  let vm;

  class ProjectEditCtrl {
    constructor () {
      vm = this;

      vm.loadProgress = true;
      vm.gushRequestCounter = 0;
      vm.coordinatesRequestCounter = 0;

      vm.step = 0;
      vm.wizard = {
        steps: [],
        forms: {},
        show (number) {
          vm.step = number;
        },
        next () {
          vm.step++;
        },
        prev () {
          vm.step--;
        }
      };

      $scope.$watch('vm.projectFields.prices.length', (newValue, oldValue) => {
        if (newValue !== oldValue) {
          jQuery('.slick-list').removeAttr('style');
          $timeout(() => {
            jQuery('.slick-list').height(jQuery('.slick-current').height());
          });
        }
      });

      $scope.$watch('vm.step', (newValue, oldValue) => {
        vm.checkMobileTabsScroll();
      });
    }

    async $onInit () {
      const steps = [];
      if (!vm.resolve.hideCustomer) {
        steps.push({
          name: 'customer',
          title: 'פרטי לקוח',
          iconClass: 'fa fa-user'
        });
      }
      if ($rootScope.hasAddress) {
        steps.push({
          name: 'address',
          title: 'כתובת פרויקט',
          iconClass: 'fa fa-map-marker'
        });
      }
      steps.push(
        {
          name: 'details',
          title: 'פרטי פרויקט',
          iconClass: 'fa fa-folder'
        },
        {
          name: 'payment',
          title: 'תשלום והערות',
          iconClass: 'fa fa-info-circle'
        }
      );

      vm.wizard.steps = steps;
      recipient.list()
        .then((recipients) => {
          vm.recipientList = recipients;
        });
      vm.projectFields = {
        status: 'בהכנה'
      };
      vm.customerFields = {};
      vm.autocompleteShown = {};

      const projectId = vm.resolve.projectId;
      const customerId = vm.resolve.customerId;
      const project = angular.copy(vm.resolve.project);

      if (project) {
        Object.keys(project).forEach((field) => {
          vm.projectFields[field] = project[field];
        });

        vm.loadProgress = false;
      } else if (projectId) {
        vm.getProject(projectId)
          .then(() => {
            vm.loadProgress = false;
          });
      } else {
        vm.loadProgress = false;
      }

      if (!vm.projectFields.id) {
        if (customerId) {
          vm.projectFields.customerId = customerId;
        }
        vm.projectFields.agent = $rootScope.user.id;
      }

      $scope.$on('resetSlickHeight', vm.resetSlickHeight);
    }

    getOptionsValues (name, sort) {
      let list = settingsService.getOwnerProjectOptionsList(name) || [];
      if (sort) {
        list = list.sort();
      }
      return list;
    }

    getProject (projectId) {
      return projectService.getProject(projectId)
        .then((project) => {
          Object.keys(project).forEach((field) => {
            vm.projectFields[field] = project[field];
          });

          return vm.projectFields;
        });
    }

    saveProject (isNewCustomer) {
      if (!vm.isFormsValid()) {
        let invalidForm;
        if (vm.projectFields.id) {
          invalidForm = vm.form;
        } else {
          const invalidFormStep = vm.wizard.steps
            .find((step) => vm.wizard.forms[step.name].$invalid);
          const invalidFormStepIndex = vm.wizard.steps.indexOf(invalidFormStep);
          invalidForm = vm.wizard.forms[invalidFormStep.name];
          vm.wizard.show(invalidFormStepIndex);
        }
        $timeout(() => {
          document.querySelector('form[name="' + invalidForm.$name + '"]').reportValidity();
          utils.setFormFieldsDirty(invalidForm);
        }, 400);
        return;
      }
      if (vm.saveProgress) {
        return;
      }
      vm.saveProgress = true;
      projectService.saveProject(vm.projectFields.id, vm.projectFields, (isNewCustomer ? vm.customerFields : undefined))
        .then((result) => {
          const message = (vm.projectFields.id ? 'פרטי פרויקט נשמרו' : 'פרויקט חדש נשמר');
          utils.notify(message, 'alert-success');

          vm.modalInstance.close();
          $rootScope.$broadcast('projectSaved');
          $rootScope.$broadcast('getProjectAlerts');
          const getProjectCountsParams = ['projectAlerts'];
          if (!vm.projectFields.id) {
            getProjectCountsParams.push('events');
          }
          counts.loadCounts(...getProjectCountsParams);
          $state.go('project.projectShow', { id: result.id });
        })
        .catch((error) => {
          console.log('save project failed', error);
          utils.notify('שגיאה בעת שמירת פרויקט', 'alert-danger');
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    closeModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    isFormsValid () {
      if (vm.projectFields.id) {
        return vm.form.$valid;
      }
      return !Object.values(vm.wizard.forms).some((form) => !!form && form.$invalid);
    }

    getCoordinates () {
      vm.coordinatesRequestCounter++;
      const requestCounter = vm.coordinatesRequestCounter;
      const addressQuery = [
        vm.projectFields.street || '',
        String(vm.projectFields.house || '').trim().replace(/\D[^>]*/g, ''), // select only first number
        vm.projectFields.city || ''
      ].filter((field) => !!field).join(' ').trim();

      if (!vm.projectFields.city || !addressQuery.length) {
        return;
      }

      govmapService.getCoordinates(addressQuery)
        .then((coordinates) => {
          if (vm.coordinatesRequestCounter > requestCounter) {
            return;
          }
          vm.projectFields.x = coordinates.x;
          vm.projectFields.y = coordinates.y;
        });
    }

    getGushHelka () {
      vm.gushRequestCounter++;
      const requestCounter = vm.gushRequestCounter;
      const addressQuery = (vm.projectFields.street || '') + ' ' +
        String(vm.projectFields.house || '').trim().replace(/\D[^>]*/g, '') + ' ' + // select only first number
        (vm.projectFields.city || '');

      if (!vm.projectFields.city || !addressQuery.length) {
        return;
      }

      govmapService.getGushHelka(addressQuery)
        .then((gushArray) => {
          if (vm.gushRequestCounter > requestCounter) {
            return;
          }
          $timeout(() => {
            $scope.$apply(() => {
              vm.projectFields.gush = gushArray[0] || vm.projectFields.gush;
              vm.projectFields.helka = gushArray[1] || vm.projectFields.helka;
              vm.projectFields.subHelka = gushArray[2] || vm.projectFields.subHelka;
            });
          });
        });
    }

    onAddressChanged () {
      vm.getGushHelka(); vm.getCoordinates();
    }

    onAgentChanged (selectedAgent) {
      vm.projectFields.agent = selectedAgent;
    }

    checkMobileTabsScroll () {
      if (jQuery('.wizard-tabs-container').innerWidth() > 500) {
        return;
      }
      let leftScroll;
      $timeout(() => {
        leftScroll = (jQuery('.wizard-tabs').innerWidth() / 4) * (3 - vm.step) - 100; // !!TODO: calculation.
        jQuery('.project-edit .wizard-tabs-container').animate({ scrollLeft: leftScroll }, 400);
      });
    }

    allowSwipe () {
      return (window.innerWidth < 701).toString();
    }

    blurAutocompleteField (shownField) {
      $timeout(() => {
        vm.autocompleteShown[shownField] = false;
      }, 300);
    }

    selectAutocompleteCustomer (customer) {
      vm.customerFields = {};
      vm.projectFields.customerId = customer.id;
      vm.linkedCustomer = customer;
      $timeout(() => {
        vm.autocompleteShown = {};
        vm.resetSlickHeight();
      }, 100);
    }

    removeLinkedCustomer () {
      vm.projectFields.customerId = null;
      vm.linkedCustomer = null;
      vm.resetSlickHeight();
    }

    resetSlickHeight () {
      $timeout(() => {
        const el = jQuery('.slick-list.draggable');
        const newHeight = el.find('.slick-current.slick-active').height();
        el.animate({ height: newHeight }, 300);
      }, 100);
    }

    canEditProjectNumber () {
      return $rootScope.isManager() && _.get($rootScope.owner, 'crmSettings.canEditProjectNumber');
    }
  }

  return new ProjectEditCtrl();
}
