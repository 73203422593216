import valuesOptionsCustomComponent from './custom-options-values.component';
import customizeTableColumnsComponent from './customize-table-columns/customize-table-columns';

const valuesOptionsCustomModule = angular.module('valuesOptionsCustom', [
  customizeTableColumnsComponent
])
  .component('valuesOptionsCustom', valuesOptionsCustomComponent)
  .name;

export default valuesOptionsCustomModule;
