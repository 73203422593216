// let service;
let $rootScope;
let request;

class agentService {
  constructor (_$rootScope, _request) {
    // service = this;
    $rootScope = _$rootScope;
    request = _request;
  }

  getAgent (agentId) {
    const ownerId = _.get($rootScope, 'owner.id');
    if (!ownerId) {
      return Promise.reject(new Error('Owner id is missing'));
    }
    return request('/owner/' + ownerId + '/agent/' + agentId)
      .then((res) => res.data.data);
  }

  getAgentList () {
    const ownerId = _.get($rootScope, 'owner.id');
    if (!ownerId) {
      return Promise.reject(new Error('Owner id is missing'));
    }
    return request('/owner/' + ownerId + '/agent')
      .then((res) => {
        const agentList = res.data.data;
        $rootScope.owner.users = agentList;
        // service.agentList = agentList;
        return agentList;
      });
  }

  saveAgent (agentId, agentObject) {
    const ownerId = _.get($rootScope, 'owner.id');
    if (!ownerId) {
      return Promise.reject(new Error('Owner id is missing'));
    }

    return request('/owner/' + ownerId + '/agent/' + (agentId || ''), { agent: agentObject }, (agentId ? 'PUT' : 'POST'))
      .then((res) => res.data.data);
  }

  exitAgent (agentId) {
    const ownerId = _.get($rootScope, 'owner.id');
    return request('/owner/' + ownerId + '/agent/' + agentId + '/exit', null, 'POST');
  }
}

agentService.$inject = ['$rootScope', 'request'];

const agentServiceModule = angular.module('agentService', [])
  .service('agentService', agentService)
  .name;

export default agentServiceModule;
