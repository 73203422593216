import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

// import { json2excel, excel2json } from 'js2excel';

export default function ($scope, $rootScope, $timeout, nadlanService) {
  let vm;

  class TabaCtrl {
    constructor () {
      vm = this;

      vm.orderBy = '-date';
      vm.queryRadius = 50;
      vm.tableHeadList = [
        { name: 'date', label: 'תאריך', type: 'date', sortable: true },
        { name: 'TOCHNIT', label: 'תכנית', sortable: true },
        { name: 'SHEMISHUV', label: 'ישוב', sortable: true },
        { name: 'URL', label: '', type: 'customFunction', customFunction: vm.planUrl, clickFunction: vm.planLinkClick, tdClass: 'text-left' },
        { name: 'planMap', label: '', type: 'customFunction', customFunction: vm.planMapUrl, clickFunction: vm.planMapLinkClick, tdClass: 'text-left' }
      ];
    }

    $onInit () {
      vm.getTabaList();

      $scope.$watchGroup(['vm.x', 'vm.y'], (newVal, oldVal) => {
        if (newVal[0] && newVal[1] && (newVal[0] !== oldVal[0] || newVal[1] !== oldVal[1])) {
          vm.getTabaList();
        }
      });
    }

    selectRadius (radius) {
      vm.queryRadius = radius;
      vm.showCustomRadius = false;
      vm.getTabaList();
    }

    getTabaList () {
      if (!vm.x || !vm.y) {
        return;
      }
      vm.list = [];
      vm.getListProgress = true;
      nadlanService.getTabaList(vm.x, vm.y, vm.queryRadius)
        .then((list) => {
          vm.list = list.map((item) => {
            item.date = dayjs(item.TAARICH, 'DD/MM/YYYY').toISOString();
            return item;
          });
          $scope.$apply();
        })
        .finally(() => {
          vm.getListProgress = false;
        });
    }

    planUrl (row) {
      return `<a class="btn btn-default" href="${row.URL}" target="_blank">מעבר לתכנית</a>`;
    }

    planLinkClick (event, row) {
      window.open(row.URL, '_blank');
    }

    planMapUrl (row) {
      return `<a class="btn btn-default" href="${row.URL}" target="_blank"> הצגת תכנית על המפה</a>`;
    }

    planMapLinkClick (event, row) {
      if (!row.MISHASAVA) { return; }
      window.open('//www.govmap.gov.il/mmi?app=app07&ma=' + row.MISHASAVA, '_blank');
    }
  }

  return new TabaCtrl();
}
