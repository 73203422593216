import template from './project-transactions-report.html';
import controller from './project-transactions-report.controller';
import './project-transactions-report.scss';

const projectTransactionsReportComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$filter', '$uibModal', 'projectService', 'settingsService', 'userService', 'utils'];

export default projectTransactionsReportComponent;
