export default function ($scope, $timeout, $uibModal, projectService) {
  let vm;

  class DashboardController {
    constructor () {
      vm = this;
      vm.name = 'dashboard';

      vm.setHouers();

      /**
       * Some Flot chart data and options used in Dashboard
       */

      const data1 = [[0, 55], [1, 48], [2, 40], [3, 36], [4, 40], [5, 60], [6, 50], [7, 51]];
      const data2 = [[0, 56], [1, 49], [2, 41], [3, 38], [4, 46], [5, 67], [6, 57], [7, 59]];

      vm.chartUsersData = [data1, data2];
      vm.chartUsersOptions = {
        series: {
          splines: {
            show: true,
            tension: 0.4,
            lineWidth: 1,
            fill: 0.4
          }
        },
        grid: {
          tickColor: '#f0f0f0',
          borderWidth: 1,
          borderColor: 'f0f0f0',
          color: '#6a6c6f'
        },
        colors: ['#62cb31', '#efefef']
      };

      /**
       * Some Pie chart data and options
       */

      vm.PieChart = {
        data: [1, 5],
        options: {
          fill: ['#62cb31', '#edf0f5']
        }
      };

      vm.PieChart2 = {
        data: [226, 360],
        options: {
          fill: ['#62cb31', '#edf0f5']
        }
      };
      vm.PieChart3 = {
        data: [0.52, 1.561],
        options: {
          fill: ['#62cb31', '#edf0f5']
        }
      };
      vm.PieChart4 = {
        data: [1, 4],
        options: {
          fill: ['#62cb31', '#edf0f5']
        }
      };
      vm.PieChart5 = {
        data: [226, 134],
        options: {
          fill: ['#62cb31', '#edf0f5']
        }
      };
      vm.PieChart6 = {
        data: [0.52, 1.041],
        options: {
          fill: ['#62cb31', '#edf0f5']
        }
      };
    }

    $onInit () {
      vm.getProjectList();

      $scope.$on('projectSaved', () => {
        vm.getProjectList();
      });
    }

    animationReplay () {
      vm.stopAnimation = true;
      $timeout(() => {
        vm.stopAnimation = false;
      });
    }

    setHouers () {
      vm.houers = new Date().getHours();
      if (vm.houers > 20 || vm.houers < 6) {
        vm.intro = 'לילה טוב';
        vm.introClassName = 'pe-7s-moon';
        vm.introAnimateClassName = 'animate__rotateInUpLeft';
      } else if (vm.houers < 12) {
        vm.intro = 'בוקר טוב';
        vm.introClassName = 'pe-7s-coffee';
        vm.introAnimateClassName = 'animate__slideInDown';
      } else if (vm.houers < 15) {
        vm.intro = 'צהריים טובים';
        vm.introClassName = 'pe-7s-sun';
        vm.introAnimateClassName = 'animate__rotateIn';
      } else if (vm.houers < 18) {
        vm.intro = 'אחר הצהריים טובים';
        vm.introClassName = 'pe-7s-portfolio';
        vm.introAnimateClassName = 'animate__swing';
      } else if (vm.houers < 21) {
        vm.intro = 'ערב טוב';
        vm.introClassName = 'pe-7s-home';
        vm.introAnimateClassName = 'animate__pulse';
      }
    }

    getProjectList () {
      vm.getProjectListProgress = true;
      projectService.getProjectList()
        .then((list) => {
          vm.projectList = list.rows;
          return vm.projectList;
        })
        .finally(() => {
          vm.getProjectListProgress = false;
        });
    }

    getGushString (row) {
      return [row.gush, row.helka, row.subHelka].filter((v) => v).join('-');
    }

    addCustomerForm () {
      $uibModal.open({
        component: 'customerEdit',
        windowClass: 'customer-edit-modal mobile-modal',
        size: 'lg'
      });
    }
  }

  return new DashboardController();
}
