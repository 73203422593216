import template from './project-show.html';
import controller from './project-show.controller';
import './project-show.scss';

const projectShowComponent = {
  bindings: {
    project: '<'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$uibModal', '$timeout', 'utils', 'sweetAlert', 'projectService', 'recipient', 'noteService', 'taskService', 'callService', 'counts', 'permissions'];

export default projectShowComponent;
