import template from './customer-search.html';
import controller from './customer-search.controller';
import './customer-search.scss';

const customerSearchComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$timeout', 'request'];

export default customerSearchComponent;
