
import nadlanDealsComponent from './nadlan-deals/nadlan-deals';
import shamautMachriaComponent from './shamaut-machria/shamaut-machria';
import tabaComponent from './taba/taba';
import nadlanService from './nadlan.service';
import nadlanComponent from './nadlan.component';

const nadlanModule = angular.module('nadlan', [
  nadlanDealsComponent,
  shamautMachriaComponent,
  tabaComponent,
  nadlanService
])
  .component('nadlan', nadlanComponent)
  .name;

export default nadlanModule;
