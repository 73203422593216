import template from './project-agent-relations-settings.html';
import controller from './project-agent-relations-settings.controller';
import './project-agent-relations-settings.scss';

const projectAgentRelationsSettingsComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', 'settingsService', 'utils'];

export default projectAgentRelationsSettingsComponent;
