import template from './project-documents.html';
import controller from './project-documents.controller';
import './project-documents.scss';

const projectDocumentsComponent = {
  require: {
    projectShowCtrl: '^projectShow'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', 'projectService', 'utils'];

export default projectDocumentsComponent;
