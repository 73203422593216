import template from './paging-table.html';
import controller from './paging-table.controller';
import './paging-table.scss';

const pagingTableComponent = {
  bindings: {
    tableName: '@',
    orderBy: '@',
    orderDesc: '<?',
    tableHeadList: '<?',
    rowLink: '<?',
    additionalRow: '<?',
    filter: '<?',
    getListFunction: '=?',
    showLoading: '<?',
    offset: '<?',
    contextMenuObject: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$sce', '$uibModal', '$timeout'];

export default pagingTableComponent;
