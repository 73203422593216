import marked from 'marked';
import attachMediaModal from './attach-media-modal.html';

export default function ($scope, $rootScope, $state, $timeout, $interval, $uibModal, utils, notify, ticketService, userService, counts) {
  const KEY_EVENT_ENTER = 13;

  let vm;

  class TicketShowCtrl {
    constructor () {
      vm = this;

      vm.labels = ticketService.labels;
      vm.publicLabels = ticketService.publicLabels;
      vm.showManageChat = true;

      vm.markedOps = {
        renderer: new marked.Renderer(),
        gfm: true,
        tables: true,
        breaks: true,
        pedantic: false,
        sanitize: true,
        smartLists: true,
        smartypants: false
      };

      vm.markedOps.renderer.link = function (href, title, text) {
        return `<a ${(text === href ? 'class="a-ltr"' : '')} href="${href}" target="_blank">${text}</a>`;
      };
    }

    $onInit () {
      if (!vm.ticket) {
        $state.go('ticket.ticketList');
      }

      vm.ticket.labels = vm.ticket.labels || [];
      vm.ticket.publicLabels = vm.ticket.publicLabels || [];
      if (vm.ticket.userId === $rootScope.user.id) {
        ticketService.markAsRead(vm.ticket.id)
          .then((res) => {
            counts.loadCounts('unreadPosts');
          });
      }

      vm.stopInterval = $interval(() => {
        vm.getTicket(vm.ticket.id);
      }, 20000);

      vm.postsEditMode = [];
      vm.managePostsEditMode = [];
      vm.postContent = {};
      vm.postEditModes = {
        regular: vm.postsEditMode,
        manage: vm.managePostsEditMode
      };

      vm.postsAndActionsSortedByDate = vm.sortByDate([...vm.ticket.posts, ...vm.ticket.files, ...vm.ticket.ticket_actions]);
    }

    $onDestroy () {
      $interval.cancel(vm.stopInterval);
    }

    bindKeyPressEvent () {
      angular.element(document.querySelector('#ticket-form'))
        .bind('keydown keypress', function (event) {
          if (event.which === KEY_EVENT_ENTER && event.originalEvent.ctrlKey) {
            vm.sendPost();
            event.preventDefault();
          }
        });
    }

    getTicket (ticketId) {
      vm.getProgressProgress = true;
      return ticketService.getTicket(ticketId)
        .then((ticket) => {
          if (!ticket) {
            $state.go('ticket.ticketList');
          }
          vm.ticket = ticket;
          vm.postsAndActionsSortedByDate = vm.sortByDate([...vm.ticket.posts, ...vm.ticket.files, ...vm.ticket.ticket_actions]);
          vm.ticket.labels = vm.ticket.labels || [];
          vm.ticket.publicLabels = vm.ticket.publicLabels || [];
        })
        .finally(() => {
          jQuery('#ticket-form').css('opacity', 1);
          jQuery('#manage-ticket-form').css('opacity', 1); // doesn't affect #ticket-form, so no need to modify function
          vm.getProgressProgress = false;

          const postsInEditing = vm.postsInEditing([vm.postsEditMode, vm.managePostsEditMode]);
          const notEditing = !postsInEditing.some((postIndex) => postIndex !== -1);
          if (notEditing) {
            return;
          }

          vm.focusEditingPosts(postsInEditing);
        });
    }

    sendPost () {
      if (vm.form.$invalid) {
        jQuery('#ticket-form')[0].reportValidity();
        utils.setFormFieldsDirty(vm.form);
        return;
      }
      if (vm.sendProgress || !vm.ticket.open) {
        return;
      }
      vm.sendProgress = true;
      jQuery('#ticket-form').css('opacity', 0);
      ticketService.sendPost(vm.ticket.id, vm.postFields)
        .then((postResult) => {
          const message = 'תגובה נשלחה';
          utils.notify(message, 'alert-success');
          vm.postFields = {};
          vm.form.$setPristine();
          vm.getTicket(vm.ticket.id);
        })
        .finally(() => {
          vm.sendProgress = false;
        });
    }

    sendManagePost () {
      if (vm.manageForm.$invalid) {
        jQuery('#manage-ticket-form')[0].reportValidity();
        utils.setFormFieldsDirty(vm.manageForm);
        return;
      }
      if (vm.manageSendProgress) {
        return;
      }
      vm.manageSendProgress = true;
      jQuery('#manage-ticket-form').css('opacity', 0);
      ticketService.sendManagePost(vm.ticket.id, vm.managePostFields)
        .then((postResult) => {
          const message = 'תגובה נשלחה';
          utils.notify(message, 'alert-success');
          vm.managePostFields = {};
          vm.manageForm.$setPristine();
          vm.getTicket(vm.ticket.id);
        })
        .finally(() => {
          vm.manageSendProgress = false;
        });
    }

    sendEditedPost (postId, updatedPostContent, index, type) {
      const isRegularPostValid = type === 'regular' && vm.postContent.regular;
      const isManagedPostValid = type === 'manage' && vm.postContent.manage;

      if (type === 'regular' && !isRegularPostValid) {
        return;
      } else if (type === 'manage' && !isManagedPostValid) {
        return;
      }

      if (updatedPostContent === vm.postsAndActionsSortedByDate.content) {
        vm.postEditModes[type][index] = false;
      }
      ticketService.editPost(postId, updatedPostContent, type)
        .then((postResult) => {
          const message = 'התגובה נערכה בהצלחה';
          utils.notify(message, 'alert-success');
          vm.getTicket(vm.ticket.id)
            .then((ticket) => { // wait for updated ticket
              vm.postEditModes[type][index] = false;
            });
        })
        .finally(() => {
          vm.editProgress = false;
        });
    }

    canEditPost (post) {
      const isAdmin = post.user.permission === 'admin';
      const isPostAuthor = post.userId === $rootScope.user.id;

      return isAdmin && isPostAuthor;
    }

    editPost (type, index) {
      const isEditingAnotherPost = vm.postEditModes[type].some((postEdit) => postEdit);
      if (isEditingAnotherPost) {
        return;
      }

      if (type === 'regular') {
        vm.postContent[type] = vm.postsAndActionsSortedByDate[index].content;
      } else if (type === 'manage') {
        vm.postContent[type] = vm.postsAndActionsSortedByDate[index].content;
      }
      vm.postEditModes[type][index] = true;
    }

    postsInEditing (postArrays) {
      return postArrays.map((postGroup) => {
        return postGroup.findIndex((post) => post);
      });
    }

    focusEditingPosts () {
      const selectors = ['#edit-fields-regular', '#edit-fields-manage'];
      selectors.forEach((selector, i) => {
        let arePostFieldsActive = false;
        jQuery('.post-fields').each(function () {
          if ((jQuery(this).is(':focus'))) {
            arePostFieldsActive = true;
          }
        });

        if (arePostFieldsActive) {
          return;
        }

        $timeout(() => {
          jQuery(selectors[i]).focus();
        });
      });
    }

    openCloseTicket (type = 'close') {
      if (vm.sendProgress) {
        return;
      }
      vm.sendProgress = true;
      jQuery('#ticket-form').css('opacity', 0);
      ticketService[type === 'close' ? 'closeTicket' : type === 'open' ? 'openTicket' : ''](vm.ticket.id)
        .then((postResult) => {
          const message = type === 'close' ? 'הפניה נסגרה' : type === 'open' ? 'הפניה נפתחה מחדש' : '';
          utils.notify(message, 'alert-success');
          vm.ticket.open = type === 'open';
        })
        .finally(() => {
          vm.sendProgress = false;
        });
    }

    confirmOpenCloseTicket (type = 'close') {
      if (type === 'open' && $rootScope.user.permission !== 'admin') {
        return;
      }
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: `האם ${type === 'close' ? 'לסגור ' : type === 'open' ? 'לפתוח מחדש את ה' : ''}פניה?`,
        buttons: [{
          label: 'בטל',
          btnClass: 'btn-default',
          onClick ($uibModalInstance) {
            $uibModalInstance.dismiss('cancel');
          }
        },
        {
          label: (type === 'close' ? 'סגירה ' : type === 'open' ? 'פתיחה' : ''),
          btnClass: 'btn-primary',
          onClick ($uibModalInstance) {
            vm.openCloseTicket(type);
            $uibModalInstance.close();
          }
        }]
      };

      utils.modalAlert(modalParams);
    }

    getUserProfileImage (user) {
      return userService.getUserProfileImage(user);
    }

    onLabelSelected (label, labelType) {
      vm.ticket.labels = _.uniq([...(vm.ticket.labels || []), label]);
      ticketService.addLabel(vm.ticket.id, label);
    }

    onLabelRemoved (label) {
      vm.ticket.labels = vm.ticket.labels.filter((item) => item !== label);
      ticketService.removeLabel(vm.ticket.id, label);
    }

    labelStyle (title, labelType) {
      const label = vm[labelType].find((label) => label.title === title);
      return { color: label.color, background: label.background };
    }

    onPublicLabelSelected (label) {
      vm.ticket.publicLabels = _.uniq([...(vm.ticket.publicLabels || []), label]);
      ticketService.addPublicLabel(vm.ticket.id, label)
        .then((ticketAction) => {
          vm.postsAndActionsSortedByDate.push(ticketAction);
        });
    }

    onPublicLabelRemoved (label) {
      vm.ticket.publicLabels = vm.ticket.publicLabels.filter((item) => item !== label);
      ticketService.removePublicLabel(vm.ticket.id, label)
        .then((ticketAction) => {
          vm.postsAndActionsSortedByDate.push(ticketAction);
        });
    }

    deleteEntry (entryId, entryType) {
      const deleteMethods = {
        action: 'deletePublicAction',
        file: 'deleteFile'
      };

      vm.warnOnDeletion(entryType)
        .then((selection) => {
          if (selection) {
            vm.postsAndActionsSortedByDate = vm.postsAndActionsSortedByDate.filter((entry) => entry.id !== entryId);

            ticketService[deleteMethods[entryType]](vm.ticket.id, entryId)
              .then(() => {
                vm.getTicket(vm.ticket.id);
              });
          }
        });
    }

    completeMessage (messageType) {
      const messageList = {
        refresh: '\nבמידה והשינוי לא מופיע, יש לרענן באמצעות המקשים Ctrl + F5'
      };
      const message = messageList[messageType];
      if (message) {
        if (!_.get(vm, 'postFields.content')) {
          _.set(vm, 'postFields.content', '');
        }
        vm.postFields.content += message;
      }
    }

    openAddMedia () {
      $uibModal.open({
        template: attachMediaModal,
        controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
          $scope.vm = vm;
          vm.modalInstance = $uibModalInstance;
          vm.inputFocus = function () {
            $timeout(() => {
              document.querySelector('#add-files-drop-box input').focus();
            }, 100);
          };
          vm.inputFocus();
        }],
        windowClass: 'ticket-add-media-modal mobile-modal',
        size: 'md'
      });
    }

    closeModalBox () {
      vm.inputFiles = [];
      vm.modalInstance.dismiss('cancel');
    }

    uploadFiles ($files, $invalidFiles) {
      if ($invalidFiles.length) {
        utils.notify($invalidFiles.length + ' קבצים לא הועלו', 'alert-danger');
        vm.inputFiles = [];
      }
    }

    isImage (file) {
      const imageFormats = ['jpg', 'png', 'gif', 'jpeg', 'bmp', 'webp'];
      if (file.ext) { // coming from server
        return imageFormats.includes(file.ext.split('.')[1].toLowerCase());
      }
      const ext = _.get(file.name.split(/\.(?=[^.]+$)/), '1') || '';
      return ['jpg', 'png', 'gif', 'jpeg', 'bmp', 'webp'].includes(ext.toLowerCase());
    }

    isPdf (file) {
      const pdfFormat = 'pdf';
      if (file.ext) { // coming from server
        return file.ext.toLowerCase().split('.')[1] === pdfFormat;
      }
      const ext = _.get(file.name.split(/\.(?=[^.]+$)/), '1') || '';
      return ext.toLowerCase() === 'pdf';
    }

    getFileUrl (file, download) {
      const ownerQuery = `?${jquery.param({ ownerId: vm.ticket.ownerId })}`;
      const downloadQuery = download ? `${(ownerQuery ? '&' : '?')}download` : '';
      const url = `${window.SERVER_URL}/file/tickets/${vm.ticket.id}/${file.name}${file.ext}`;

      return `${url}${ownerQuery}${downloadQuery}`;
    }

    zoomImage (url) {
      $uibModal.open({
        template: `<div class="image-container"><img ng-src="${url}"/><div>`,
        windowClass: 'image-preview-zoom-view',
        size: 'lg'
      });
    }

    zoomPdf (url) {
      $uibModal.open({
        template: `<div class="pdf-container">
          <embed width="100%" height="100%" src="${url}" type="application/pdf" style="margin:0px;padding:0px;overflow:hidden;display:block;">
        </div>`,
        windowClass: 'pdf-preview-zoom-view',
        size: 'lg'
      });
    }

    sendFilePost () {
      if (vm.sendFileProgress || !vm.inputFiles.length) {
        return;
      }
      const fileNameWithoutExt = vm.inputFiles[0].name.replace(/\.[^/.]+$/, '');
      const fileName = `${Date.now()}-${fileNameWithoutExt}`;

      vm.sendFileProgress = true;
      ticketService.sendFilePost(vm.ticket.id, fileName, vm.inputFiles[0], undefined)
        .then(() => {
          vm.closeModalBox();
          const message = 'הקובץ נשלח';
          utils.notify(message, 'alert-success');
          vm.getTicket(vm.ticket.id);
        })
        .finally(() => {
          vm.sendFileProgress = false;
        });
    }

    sortByDate (postsArray) {
      if (postsArray.length === 0) {
        return [];
      }

      return postsArray.sort((a, b) => {
        return (a.createdAt < b.createdAt) ? -1 : ((a.createdAt > b.createdAt) ? 1 : 0);
      });
    }

    warnOnDeletion (entryType) {
      const entryNames = {
        action: 'תיעוד הפעולה',
        file: 'הקובץ'
      };

      const modalParams = {
        windowClass: 'hmodal-danger',
        title: `האם למחוק את ${entryNames[entryType]}?`,
        buttons: [{
          label: 'מחיקה',
          btnClass: 'btn-primary',
          onClick ($uibModalInstance) {
            $uibModalInstance.dismiss('cancel');
            return true;
          }
        },
        {
          label: 'ביטול',
          btnClass: 'btn-default',
          onClick ($uibModalInstance) {
            $uibModalInstance.close();
          }
        }]
      };
      return utils.modalAlert(modalParams);
    }
  }

  return new TicketShowCtrl();
}
