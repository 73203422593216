import template from './task-pin.html';
import controller from './task-pin.controller';
import './task-pin.scss';

const taskPinComponent = {
  bindings: {
    projectId: '<',
    task: '<',
    confirm: '<?',
    silent: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$rootScope', 'utils', 'taskService', 'counts'];

export default taskPinComponent;
