import template from './multi-select.html';
import controller from './multi-select.controller';
import './multi-select.scss';

const multiSelectComponent = {
  bindings: {
    title: '@',
    options: '<?',
    disabledOptions: '<?',
    model: '=?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', '$sce'];

export default multiSelectComponent;
