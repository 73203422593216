import template from './project-calendar.html';
import controller from './project-calendar.controller';
import './project-calendar.scss';

const projectCalendarComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'projectService', 'settingsService', 'utils', 'permissions'];

export default projectCalendarComponent;
