export default function ($scope, $rootScope, fileRequest, $uibModal, utils) {
  let vm;

  class DocumentSettingsCtrl {
    constructor () {
      vm = this;
      vm.images = {};
      vm.changedFiles = [];
    }

    $onInit () {
      vm.getImages();
      $scope.$on('settingsLoaded', (scope, settings) => {
        vm.getImages();
      });
    }

    getImages () {
      ['appraisalHeader', 'appraisalFooter', 'appraisalSignature'].forEach((imageName) => {
        const imageFile = $rootScope.owner.files.find((file) => file.name === imageName);
        if (imageFile) {
          vm.images[imageName] = this.getUrlForFile(imageFile);
        } else vm.images[imageName] = '';
      });
    }

    getUrlForFile (file) {
      return `${window.SERVER_URL}/file/${file.name}${file.ext}`;
    }

    editImages () {
      return $uibModal.open({
        component: 'documentSettingsEdit',
        windowClass: 'document-settings-edit-modal mobile-modal',
        size: 'lg',
        resolve: {
          images: () => vm.images,
          settingsCtrl: () => vm.settingsCtrl
        }
      });
    }
  }
  return new DocumentSettingsCtrl();
}
