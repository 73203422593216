import realEstateInformationSearch from './real-estate-information-search/real-estate-information-search';
import realEstateInformationService from './real-estate-information.service';

const realEstateInformationModule = angular.module('realEstateInformation', [
  realEstateInformationSearch,
  realEstateInformationService
])
  .name;

export default realEstateInformationModule;
