import template from './task-board.html';
import controller from './task-board.controller';
import './task-board.scss';

const taskBoardComponent = {
  bindings: {
    filter: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', '$filter', '$uibModal', 'dragularService', 'taskService', 'settingsService', 'userService', 'utils', 'permissions'];

export default taskBoardComponent;
