/* global localStorage */
import optionsList from '../../../components/project/project-options-list';

export default function ($scope, $rootScope, $state, utils, settingsService) {
  let vm;

  class AdvancedSearchController {
    constructor () {
      vm = this;
      vm.name = 'advancedSearch';

      vm.projectsFields = {};
      vm.customersFields = {};
      vm.tasksFields = {};

      [
        'referrerOptions'
      ].forEach((listName) => {
        vm[listName] = _.union(optionsList[listName]
          .filter((option) => option.default)
          .map((option) => option.name), settingsService.getOwnerProjectOptionsList(listName));
      });

      vm.statusOptions = _.union(optionsList.statusOptions
        .filter((option) => option.default)
        .map((option) => option.name), settingsService.getOwnerProjectStatusList());

      vm.ownerTagList = settingsService.getOwnerProjectTags();
    }

    $onInit () {
      vm.target = vm.searchCtrl.resolve.advancedSearchTarget || 'projects';
      vm[vm.target + 'Fields'] = vm.searchCtrl.resolve.advancedSearchQuery || {};
      vm.projectsFields.status = vm.projectsFields.status || '!מבוטל|$null';
      const projectAgentRelations = [{
        title: 'נציג אחראי', modelObjectName: 'agent'
      }];
      (_.get($rootScope, 'owner.settings.projectAgentRelations') || [])
        .forEach((relation) => {
          relation.modelObjectName = 'relatedAgents.' + relation.id;
          projectAgentRelations.push(relation);
        });
      vm.projectAgentRelations = projectAgentRelations;
      vm.projectsFields.tags = vm.projectsFields.tags || [];
    }

    cancel () {
      vm.searchCtrl.closeModalBox();
    }

    clearFields () {
      vm.projectsFields = {
        status: '!מבוטל|$null'
      };
      vm.customersFields = {};
      vm.tasksFields = {};
    }

    search () {
      if (vm[vm.target + 'Form'].$invalid) {
        document.getElementById(vm.target + '-form').reportValidity();
        utils.setFormFieldsDirty(vm[vm.target + 'Form']);
        return;
      }

      const searchFormFields = vm[vm.target + 'Fields'];
      Object.keys(searchFormFields).forEach((field) => {
        if (!searchFormFields[field] || searchFormFields[field].length === 0) {
          delete searchFormFields[field];
        }
      });

      localStorage.setItem('lastSearchType', vm.target);
      localStorage.setItem('lastSearchQuery', JSON.stringify(searchFormFields));
      vm.searchCtrl.closeModalBox();
      $state.go('search', {
        type: vm.target,
        query: encodeURIComponent(JSON.stringify(searchFormFields)),
        index: undefined
      });
    }

    selectedTagsString () {
      if (!angular.isArray(vm.projectsFields.tags)) { return; }
      return vm.projectsFields.tags.join(', ');
    }

    agentSelected (selectedAgent, relation) {
      vm.projectsFields[relation.modelObjectName] = selectedAgent;
    }
  }

  return new AdvancedSearchController();
}
