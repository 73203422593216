// let service;
let request;
let fileRequest;

class ownerService {
  constructor (_request, _fileRequest) {
    // service = this;
    request = _request;
    fileRequest = _fileRequest;
  }

  getOwner (ownerId, includeUsers) {
    return request('/owner/' + ownerId + (includeUsers ? '?includeUsers=true' : ''))
      .then((res) => res.data.data);
  }

  getOwnerCustomers (ownerId, args = {}) {
    return request('/owner/' + ownerId + '/customers?' + jquery.param(args))
      .then((res) => res.data.data);
  }

  getOwnerProjects (ownerId, args = {}) {
    return request('/owner/' + ownerId + '/projects?' + jquery.param(args))
      .then((res) => res.data.data);
  }

  getOwnerList (hiddenFilter) {
    return request('/owner' + (hiddenFilter ? '?hidden=' + hiddenFilter : ''))
      .then((res) => res.data.data);
  }

  getOwnerTickets (ownerId, args = {}) {
    args.ownerId = ownerId;
    return request('/admin/ticket?' + jquery.param(args))
      .then((res) => res.data.data);
  }

  getOwnerTransactions (ownerId, args = {}) {
    args.ownerId = ownerId;
    return request('/owner/' + ownerId + '/transactions?' + jquery.param(args))
      .then((res) => res.data.data);
  }

  getOwnerLog (ownerId, args = {}) {
    return request('/user-log/owner/' + ownerId + '?' + jquery.param(args))
      .then((res) => res.data.data);
  }

  saveOwner (ownerId, ownerObject) {
    if (!ownerId) {
      return Promise.reject(new Error('owner id is missing'));
    }

    return request('/owner/' + ownerId, { owner: ownerObject }, 'PUT')
      .then((res) => res.data.data);
  }

  saveOwnerCrmSettings (ownerId, crmSettings) {
    if (!ownerId) {
      return Promise.reject(new Error('owner id is missing'));
    }

    return request('/owner/' + ownerId + '/crm-settings', { crmSettings }, 'POST')
      .then((res) => res.data.data);
  }

  saveOwnerLogo (ownerId, ownerLogo) {
    return fileRequest('/owner/' + ownerId + '/logo', { ownerLogo })
      .then((res) => res.data.data);
  }

  getOwnerLogo (owner) {
    if (owner.logoUrl) {
      return window.SERVER_URL + owner.logoUrl;
    }
  }
}

ownerService.$inject = ['request', 'fileRequest'];

const ownerServiceModule = angular.module('ownerService', [])
  .service('ownerService', ownerService)
  .name;

export default ownerServiceModule;
