import template from './project-list-map.html';
import controller from './project-list-map.controller';
import './project-list-map.scss';

const projectListMapComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', 'projectService', 'userService', 'NgMap'];

export default projectListMapComponent;
