/* global $ */
/**
 * HOMER - Responsive Admin Theme
 * version 2.0
 *
 */

$(document).ready(function () {
  // Add special class to minimalize page elements when screen is less than 768px
  setBodySmall();
});

$(window).bind('load', function () {
  // Remove splash screen after load
  $('.splash').css('display', 'none');
});

$(window).bind('resize click', function () {
  // Add special class to minimalize page elements when screen is less than 768px
  setBodySmall();
});

function setBodySmall () {
  if ($(window).width() < 769) {
    $('body').addClass('page-small');
  } else {
    $('body').removeClass('page-small');
    $('body').removeClass('show-sidebar');
  }
}
