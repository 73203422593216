import dayjs from 'dayjs';

export default function ($scope, $rootScope, $state, $stateParams, $filter, $uibModal, projectService, settingsService, userService, utils) {
  let vm;
  const uiSref = `project.projectShow.payments({
    id: row.id,
    navigation: {
      query: {
        filter: vm.filter,
        order: vm.paging.orderBy,
        desc: vm.paging.orderDesc
      },
      sref: 'report.projectPayments'
    },
    navigationIndex: vm.paging.offset + rowIndex
  })`.replace(/\s+/g, '');

  class ProjectPaymentsReportCtrl {
    constructor () {
      vm = this;
      vm.name = 'projectPaymentsReport';
      vm.$stateParams = $stateParams;
      const projectListEnabledColumns = settingsService.getOwnerTableColumns('projectList');

      vm.tableHeadList = [
        { name: 'number', label: 'פרויקט', sortable: true, uiSref },
        { name: 'createdAt', label: 'תאריך יצירה', type: 'date', sortable: true, uiSref },
        { name: 'dueDate', label: 'תאריך יעד', type: 'customFunction', customFunction: vm.getProjectDueDate, sortable: true, enabled: projectListEnabledColumns.includes('dueDate'), uiSref },
        { name: 'customer.name', label: 'לקוח', sortable: true, type: 'customFunction', customFunction: vm.getCustomer, uiSref },
        { name: 'street', label: 'כתובת', type: 'customFunction', customFunction: vm.getAddress, sortable: true, uiSref, if: () => !!$rootScope.hasAddress },
        { name: 'status', label: 'סטטוס', type: 'customFunction', customFunction: vm.getStatus, sortable: true, uiSref },
        { name: 'agent', label: 'נציג אחראי', if: vm.isAgentColumnShown, type: 'compile', customFunction: vm.getAgentString, sortable: true, uiSref },
        { name: 'priceTotal', label: 'מחיר', sortable: true, type: 'customFunction', customFunction: vm.getPriceString, uiSref },
        { name: 'transactionTotal', label: 'התקבל', sortable: true, type: 'customFunction', customFunction: vm.getTransactionString, uiSref },
        { name: 'toPayTotal', label: 'יתרה לגביה', sortable: true, type: 'customFunction', customFunction: vm.getToPayString, uiSref },
        { name: 'paid', type: 'compile', customFunction: vm.isPaid, uiSref }
      ];
      vm.additionalRow = { name: 'projectTags', customFunction: vm.getProjectTags, if: vm.projectHasTags, uiSref };

      vm.statusOptions = settingsService.getOwnerProjectStatusList();
      vm.referrerOptions = settingsService.getOwnerProjectOptionsList('referrerOptions');
      vm.ownerTagList = settingsService.getOwnerProjectTags();
      if ($stateParams.query) {
        vm.tableFilters = $stateParams.query.filter;
      } else {
        vm.tableFilters = {
          priceDetails: true
        };
        vm.resetFilters();
      }
    }

    $onInit () {
      vm.isManyAgentsInOwner = _.get($rootScope, 'owner.users') || [].length > 1;
    }

    openCustomerFilter () {
      $uibModal.open({
        component: 'customerSearch',
        resolve: {
          placeholder: () => 'סינון לפי לקוח',
          onCustomerClicked: () => vm.filterByCustomer
        },
        windowClass: 'customer-search-modal mobile-modal',
        size: 'lg'
      });
    }

    filterByCustomer (customer) {
      vm.customerFilter = customer;
      vm.tableFilters.customerId = _.get(customer, 'id');
    }

    filterDateChanged () {
      const createdAtFilter = [];
      if (vm.dateFilters.from) {
        createdAtFilter.push('&gt:' + dayjs(vm.dateFilters.from).toISOString());
      }
      if (vm.dateFilters.to) {
        createdAtFilter.push('&lt:' + dayjs(vm.dateFilters.to).toISOString());
      }
      vm.tableFilters.createdAt = createdAtFilter.join('|');
    }

    agentFilterChanged (selectedAgent) {
      vm.tableFilters.agent = selectedAgent;
    }

    isAgentColumnShown () {
      return vm.isManyAgentsInOwner;
    }

    resetFilters () {
      _.set(vm, 'dateFilters.from', undefined);
      _.set(vm, 'dateFilters.to', undefined);
      _.set(vm, 'tableFilters.createdAt', undefined);
      _.set(vm, 'tableFilters.agent', undefined);
      _.set(vm, 'tableFilters.status', '!מבוטל|$null');
      _.set(vm, 'tableFilters.tags', []);
    }

    getProjectList (args) {
      vm.priceTotal = undefined;
      vm.transactionTotal = undefined;
      vm.toPayTotal = undefined;
      vm.getProjectListArgs = args;
      return projectService.getProjectPaymentsReportList(args)
        .then((res) => {
          vm.priceTotal = res.priceTotal;
          vm.transactionTotal = res.transactionTotal;
          vm.toPayTotal = res.toPayTotal;
          return res;
        });
    }

    getProjectDueDate (row) {
      if (
        row.status &&
        !['הצעה', 'הושלם', 'מבוטל'].includes(row.status) &&
        dayjs(row.dueDate).isBefore(dayjs().startOf('day').add(3, 'days'))
      ) {
        return `<span class="text-danger">${$filter('date')(row.dueDate, 'dd/MM/yyyy')}</span>`;
      }
      return $filter('date')(row.dueDate, 'dd/MM/yyyy');
    }

    getCustomer (row) {
      return (_.get(row, 'customer.name') || '');
    }

    getAddress (row) {
      return utils.joinStringArray([
        utils.joinStringArray([row.street, row.house], ' '),
        row.city
      ], ', ');
    }

    statusColor (status) {
      return projectService.getProjectStatusColor(status);
    }

    getStatus (row) {
      if (!row.status) {
        return '';
      }
      return `<i class="fa fa-circle" style="color:${vm.statusColor(row.status)}" aria-hidden="true"></i><span>${row.status}</span>`;
    }

    getAgentString (row) {
      const agent = $rootScope.userById(row.agent);
      if (!agent) { return ''; }
      return `<span uib-tooltip="${agent.firstName || ''} ${agent.lastName || ''}"><img class="img-circle" src="${userService.getUserProfileImage(agent)}" /></span>`;
    }

    getAgentPlainString (row) {
      const agent = $rootScope.userById(row.agent);
      if (!agent) { return ''; }
      return (agent.firstName || '') + ' ' + (agent.lastName || '');
    }

    getPriceString (row) {
      return `<span class="sum">${$filter('currency')(row.priceTotal || 0, '')}</span> ₪</span>`;
    }

    getTransactionString (row) {
      return `<span class="sum">${$filter('currency')(row.transactionTotal || 0, '')}</span> ₪</span>`;
    }

    getToPayString (row) {
      return `<span class="text-${row.toPayTotal > 0 ? 'danger' : 'success'}"><span class="sum">${$filter('currency')(row.toPayTotal || 0, '')}</span> ₪</span>`;
    }

    showProjectLink (id) {
      return uiSref;
    }

    isPaid (row) {
      if (row.toPayTotal > 0) {
        if (row.toPayTotal < row.priceTotal) {
          return `<pie-chart uib-tooltip="שולם ${vm.paidPercent(row)}%" data="[${row.priceTotal - row.toPayTotal}, ${row.toPayTotal}]" options="{type: 'pie'}"></pie-chart>`;
        } else {
          return '<i uib-tooltip="לא שולם" class="text-danger fa fa-times-circle"><i/>';
        }
      } else if (row.priceTotal || row.transactionTotal) {
        return '<i uib-tooltip="שולם" class="text-success fa fa-check-circle"><i/>';
      }
      return '<i uib-tooltip="לא הוגדר תשלום" class="fa fa-circle"></i>';
    }

    paidPercent (row) {
      return Math.min(100, ((row.transactionTotal || 0) / (row.priceTotal || 0)) * 100).toFixed();
    }

    tagStyle (name) {
      const tag = vm.ownerTagList.find((tag) => tag.title === name);
      return `background: ${_.get(tag, 'background') || '#afafaf'}; color: ${_.get(tag, 'color') || 'black'}`;
    }

    getProjectTags (row) {
      if (row.tagsString) {
        return row.tagsString;
      }

      if (!_.get(row, 'tags.length')) {
        row.tagsString = '';
      } else {
        row.tagsString = row.tags.map((tag) => {
          return `<span class="badge" style="${vm.tagStyle(tag)}">${tag}</span>`;
        }).join(' ');
      }
      return row.tagsString;
    }

    projectHasTags (row) {
      return _.get(row, 'tags.length') > 0;
    }

    onTagChanged (selectedTags) {
      vm.tableFilters.tags = selectedTags;
    }

    selectedTagsString () {
      if (!angular.isArray(vm.tableFilters.tags)) { return; }
      return vm.tableFilters.tags.join(', ');
    }

    exportToExcel () {
      if (vm.getExcelExportQueryProgress) {
        return;
      }
      vm.getExcelExportQueryProgress = true;
      const fileName = 'דוח תשלומים - ' + dayjs().format('DD-MM-YYYY');

      return projectService.getProjectPaymentsReportList({
        order: vm.getProjectListArgs.order,
        desc: vm.getProjectListArgs.desc,
        filter: vm.getProjectListArgs.filter,
        limit: 2000
      })
        .then((list) => {
          if (!_.get(list, 'rows.length')) {
            return;
          }
          const tableHeadList = angular.copy(vm.tableHeadList);
          tableHeadList.splice(-1, 1);

          const data = list.rows.map((row) => {
            return vm.parseExcelRow(tableHeadList, row);
          });

          try {
            const XLSX = window.XLSX;
            const ws = XLSX.utils.json_to_sheet(data, { dateNF: 'dd/mm/yyyy' });
            const wb = XLSX.utils.book_new();
            _.set(wb, 'Workbook.Views.0.RTL', true);
            XLSX.utils.book_append_sheet(wb, ws, fileName);

            XLSX.writeFile(wb, fileName + '.xlsx');
          } catch (error) {
            console.log(error);
          }
        })
        .finally(() => {
          vm.getExcelExportQueryProgress = false;
        });
    }

    parseExcelRow (tableHeadList, row) {
      const rowObject = {};

      tableHeadList.forEach((column) => {
        // custom parsers
        if (column.name === 'priceTotal' || column.name === 'transactionTotal' || column.name === 'toPayTotal') {
          rowObject[column.label] = row[column.name] || 0;
        } else if (column.name === 'agent' && vm.isAgentColumnShown()) {
          rowObject[column.label] = vm.getAgentPlainString(row) || '';
        } else if (column.name === 'dueDate') {
          rowObject[column.label] = $filter('date')(row.dueDate, 'dd/MM/yyyy') || '';
          // general parsers
        } else if (column.type === 'customFunction' || column.type === 'compile') {
          rowObject[column.label] = utils.stripHtml(column.customFunction(row)) || '';
        } else if (column.type === 'html') {
          rowObject[column.label] = utils.stripHtml(row[column.name]) || '';
        } else if (column.type === 'date') {
          rowObject[column.label] = $filter('date')(row[column.name], 'dd/MM/yyyy') || '';
        } else {
          rowObject[column.label] = _.get(row, column.name) || '';
        }
      });
      return rowObject;
    }
  }

  return new ProjectPaymentsReportCtrl();
}
