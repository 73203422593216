export default function ($scope, $rootScope, $state, $stateParams, $uibModal, taskService, taskListTableColumns, counts) {
  let vm;

  class ProjectTasksCtrl {
    constructor () {
      vm = this;
      vm.name = 'projectTasks';
      vm.orderBy = ['-dueDate', '-createdAt'];
      vm.$stateParams = $stateParams;

      vm.statusList = {
        toDo: 'לביצוע',
        inProgress: 'בביצוע',
        done: 'בוצע'
      };
      vm.taskListTableHeadList = taskListTableColumns.taskListTableHeadList;

      vm.taskTableFilters = {};
      vm.$state = $state;
    }

    $onInit () {
      vm.taskTableFilters.projectId = vm.projectShowCtrl.projectFields.id;

      vm.agentList = _.get($rootScope, 'owner.users') || [];

      $scope.$on('taskSaved', () => {
        vm.getTaskList()
          .then((taskList) => {
            vm.projectShowCtrl.projectFields.tasks = taskList.rows;
            return taskList;
          });
        counts.loadCounts('pinnedTasks');
      });
    }

    isCurrentState (stateName) {
      return vm.$state.current.name === `project.projectShow.projectTasks${stateName ? '.' + stateName : ''}`;
    }

    getTaskList (args = {}) {
      return taskService.getTaskList(args);
    }

    onAgentChanged (selectedAgent) {
      vm.taskTableFilters.assignUser = selectedAgent;
    }

    newTaskForm ($event) {
      $uibModal.open({
        component: 'taskEdit',
        resolve: {
          projectId: () => vm.projectShowCtrl.projectFields.id
        },
        windowClass: 'task-edit-modal mobile-modal',
        size: 'lg',
        backdrop: 'static'
      });
    }

    selectedStatusString () {
      return vm.taskTableFilters.status === '!done' ? 'לא בוצע' : vm.statusList[vm.taskTableFilters.status] || 'הכל';
    }
  }

  return new ProjectTasksCtrl();
}
