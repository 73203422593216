import template from './project-alerts.html';
import controller from './project-alerts.controller';
import './project-alerts.scss';

const projectAlertsComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', 'projectService'];

export default projectAlertsComponent;
