import template from './owner-details-edit.html';
import controller from './owner-details-edit.controller';
import './owner-details-edit.scss';

const ownerDetailsEditComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', 'utils', 'ownerService'];

export default ownerDetailsEditComponent;
