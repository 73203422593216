import template from './street.html';
import controller from './street.controller';
import './street.scss';

const streetComponent = {
  bindings: {
    model: '=',
    cityCode: '<?',
    onChange: '<?',
    inputGroup: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$http', '$timeout'];

export default streetComponent;
