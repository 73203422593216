export default function ($scope, $http, $timeout) {
  let vm;

  class CityController {
    constructor () {
      vm = this;
      vm.name = 'city';
      vm.list = [];
    }

    async $onInit () {
      if (vm.model && vm.model.length) {
        await vm.cityAutocomplete();
      }

      // vm.getCityList();
    }

    async onFieldChange () {
      await vm.cityAutocomplete();
      vm.isOpen = true;
      if (angular.isFunction(vm.onChange)) {
        vm.onChange();
      }
    }

    onBlur () {
      $timeout(() => {
        vm.isOpen = false;
      }, 500);
    }

    cityAutocomplete () {
      if (!vm.model.length) {
        vm.list = [];
        return Promise.resolve();
      }

      return $http.post('https://ags.govmap.gov.il/AdvancedSearch/GetSearchComponentValues', {
        Filter: vm.model,
        SourceTable: 'SDE.setl_area_v1',
        FieldValue: 'setl_name',
        FieldCode: 'setl_code',
        CondField: '',
        NumberOfRecords: '500',
        CondValue: '',
        SortMixedAlphaNumeric: false
      })
        .then((res) => {
          const list = _.get(res, 'data.data') || [];
          const telAvivItem = list.filter((item) => item.VALUE === 'תל אביב -יפו')[0];
          if (telAvivItem) {
            list.splice(list.indexOf(telAvivItem), 0, { CODE: '5000', VALUE: 'תל אביב' });
          }
          vm.list = list;

          const selectedItem = vm.list.filter((item) => item.VALUE === vm.model)[0];
          vm.cityCode = selectedItem ? selectedItem.CODE : '';
        });
    }

    async selectCity (item) {
      vm.model = item.VALUE;
      vm.cityCode = item.CODE;
      await vm.cityAutocomplete();
      if (angular.isFunction(vm.onChange)) {
        vm.onChange();
      }
    }

    // not in use
    getCityList () {
      vm.getListProgress = true;

      $http.get('https://www.nadlan.gov.il/Nadlan.REST/Main/GetCitysList?nb=true&st=true')
        .then((list) => {
          vm.list = list;
        })
        .finally(() => {
          vm.getListProgress = false;
        });
    }
  }

  return new CityController();
}
