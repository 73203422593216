import optionLists from '../../../project/project-options-list';

export default function ($scope, $rootScope, $uibModal, settingsService, request, utils) {
  let vm;

  class CustomOptionsStatusCtrl {
    constructor () {
      vm = this;
      vm.optionList = optionLists.statusOptions.map((option) => option.name);
      vm.disabledOptionList = ['בהכנה', 'הושלם', 'מבוטל'];

      vm.selectedValues = settingsService.getOwnerProjectStatusList();
      vm.savedObject = angular.copy(vm.selectedValues);
    }

    saveSettings () {
      if (vm.saveProgress) {
        return;
      }

      vm.saveProgress = true;

      return settingsService.saveSettings({ statusOptions: vm.selectedValues })
        .then((result) => {
          vm.savedObject = angular.copy(vm.selectedValues);
          utils.notify('הגדרות נשמרו', 'alert-success');
          $rootScope.$broadcast('ownerSaved');
          return result;
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    resetSettings () {
      vm.selectedValues = angular.copy(vm.savedObject);
    }
  }

  return new CustomOptionsStatusCtrl();
}
