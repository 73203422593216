import helpers from './helpers';

export default ['$stateProvider', ($stateProvider) => {
  $stateProvider
    .state('project', {
      url: '/projects',
      redirectTo: 'project.projectList'
    })
    .state('project.projectList', {
      params: {
        query: null,
        index: undefined
      },
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth]
      },
      component: 'projectList'
    })
    .state('project.projectBoard', {
      url: '/board',
      params: {
        query: null,
        index: undefined
      },
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth]
      },
      component: 'projectBoard'
    })
    .state('project.projectCalendar', {
      url: '/calendar',
      params: {
        query: null,
        index: undefined
      },
      component: 'projectCalendar',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth]
      }
    })
    .state('project.projectMap', {
      url: '/map',
      params: {
        query: null,
        index: undefined
      },
      component: 'projectListMap',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth]
      }
    })
    .state('project.projectShow', {
      url: '/:id',
      params: {
        navigation: null,
        navigationIndex: null
      },
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth],
        project: ['$stateParams', 'projectService', ($stateParams, projectService) => {
          return projectService.getProject($stateParams.id)
            .catch(() => null);
        }]
      },
      component: 'projectShow'
    })
    .state('project.projectShow.projectTasks', {
      url: '/tasks',
      resolve: {
        counts: ['counts', loadCounts]
      },
      component: 'projectTasks'
    })
    .state('project.projectShow.projectTasks.board', {
      url: '/board'
    })
    .state('project.projectShow.projectTasks.notes', {
      url: '/notes'
    })
    .state('project.projectShow.projectTasks.calls', {
      url: '/calls'
    })
    .state('project.projectShow.projectNadlan', {
      url: '/real-estate-information',
      resolve: {
        counts: ['counts', loadCounts]
      },
      component: 'projectNadlan'
    })
    .state('project.projectShow.projectNadlan.taba', {
      url: '/taba'
    })
    .state('project.projectShow.projectNadlan.shamautMachria', {
      url: '/shamaut-machria'
    })
    .state('project.projectShow.projectEvents', {
      url: '/events',
      resolve: {
        counts: ['counts', loadCounts]
      },
      component: 'projectEvents'
    })
    .state('project.projectShow.projectAdditionalDetailsShow', {
      url: '/additional-details',
      resolve: {
        counts: ['counts', loadCounts],
        fieldsSchema: ['$stateParams', 'templateFields', getProjectFields]
      },
      component: 'projectAdditionalDetailsShow'
    })
    .state('project.projectShow.projectDocuments', {
      url: '/documents',
      resolve: {
        counts: ['counts', loadCounts]
      },
      component: 'projectDocuments'
    })
    .state('project.projectShow.payments', {
      url: '/payments',
      resolve: {
        roleCheck: ['permissions', (permissions) => permissions.require('view', 'projectPayments')],
        counts: ['counts', loadCounts]
      },
      component: 'projectPayments'
    });
}];

async function getProjectFields (stateParams, templateFields) {
  const fields = await templateFields.getTemplateFieldsByProject(stateParams.id);
  return fields;
}

function loadCounts (counts) {
  counts.loadCounts();
  return true;
}
