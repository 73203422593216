import agentEdit from './agent-edit/agent-edit';
import agentListComponent from './agent-list.component';

const agentListModule = angular.module('agentList', [
  agentEdit
])
  .component('agentList', agentListComponent)
  .name;

export default agentListModule;
