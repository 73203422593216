import template from './user-log.html';
import controller from './user-log.controller';
import './user-log.scss';

const userLogComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$filter', 'request', 'utils', 'userService'];

export default userLogComponent;
