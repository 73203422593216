import ownerShowComponent from './owner-show.component';
import ownerLog from './owner-log/owner-log';
import ownerCustomers from './owner-customers/owner-customers';
import ownerProjects from './owner-projects/owner-projects';
import ownerTransactions from './owner-transactions/owner-transactions';

const ownerShowModule = angular.module('ownerShow', [
  ownerCustomers,
  ownerLog,
  ownerProjects,
  ownerTransactions
])
  .component('ownerShow', ownerShowComponent)
  .name;

export default ownerShowModule;
