import dayjs from 'dayjs';

export default function ($scope, $rootScope, $state, $timeout, $filter, $uibModal, dragularService, taskService, settingsService, userService, utils, permissions) {
  let vm;

  class TaskBoardCtrl {
    constructor () {
      vm = this;
      vm.name = 'taskBoard';

      vm.statusObject = {
        toDo: { title: 'לביצוע', color: '#e74c3c' },
        inProgress: { title: 'בביצוע', color: '#0033cc' },
        done: { title: 'בוצע', color: '#62cb31' }
      };
      Object.keys(vm.statusObject).forEach((status) => {
        vm.statusObject[status].count = 0;
        vm.statusObject[status].list = [];
      });
    }

    $onInit () {
      vm.getListRequestCounter = {};
      $timeout(() => {
        $rootScope.showFooter = false;
      });
      vm.userList = _.get($rootScope, 'owner.users') || [];

      vm.initialGetList()
        .then(() => {
          dragularService(document.querySelectorAll('.board-tasks'), {
            scope: $scope,
            containersModel: () => vm.dragularModelList,
            classes: { mirror: 'task-dragged-mirror' },
            mirrorContainer: document.querySelector('.task-board'),
            nameSpace: 'column',
            moves: (element) =>
              !element.classList.contains('status-list-empty') &&
              !element.classList.contains('status-list-loading')
          });
        });

      $scope.$on('dragulardrag', (event, el, container) => {
        vm.isDragging = true;
      });
      $scope.$on('dragulardragend', (event, el, container) => {
        setTimeout(() => {
          vm.isDragging = false;
        }, 300);
      });

      $scope.$on('dragulardrop', (event, element, target, source, model, previousModelIndexInColumn) => {
        vm.saveInProgress = true;
        $timeout(async () => {
          const { taskId } = element.dataset;
          const previousStatus = source.dataset.columnStatus;
          const newStatus = target.dataset.columnStatus;
          vm.updateStatus(taskId, previousStatus, newStatus, previousModelIndexInColumn);
        });
      });

      $scope.$watch('vm.filter', (newValue, oldValue) => {
        if (!_.isEqual(newValue, oldValue)) {
          vm.initialGetList();
        }
      }, true);
    }

    $onDestroy () {
      $rootScope.showFooter = true;
    }

    async initialGetList () {
      Object.values(vm.statusObject).forEach((status) => { status.list = []; });
      vm.getTaskListCounts();
      await Promise.all(Object.keys(vm.statusObject)
        .map((status) => {
          return vm.getTaskList({
            filter: { status }
          }, true);
        }));
    }

    getTaskList (args, cleanList) {
      const status = args.filter.status;
      vm.statusObject[status].loading = true;

      const defaults = {
        limit: 25,
        order: 'dueDate',
        desc: true,
        offset: cleanList ? 0 : vm.statusObject[status].list.length,
        filter: {}
      };
      defaults.filter = _.merge(defaults.filter, vm.filter); // is merging deep.
      args = _.merge(defaults, args); // is merging deep.

      if (!vm.getListRequestCounter[status]) {
        vm.getListRequestCounter[status] = 0;
      }
      vm.getListRequestCounter[status]++;
      const getListRequestCounter = vm.getListRequestCounter[status];
      return taskService.getTaskList(args)
        .then((results) => {
          if (vm.getListRequestCounter[status] <= getListRequestCounter) {
            vm.statusObject[status].count = results.count;
            if (cleanList) {
              vm.statusObject[status].list = results.rows;
            } else {
              vm.statusObject[status].list.push(...results.rows);
            }
          }
        })
        .finally(() => {
          if (vm.getListRequestCounter[status] <= getListRequestCounter) {
            vm.dragularModelList = Object.values(vm.statusObject)
              .map((status) => status.list);

            $timeout(() => {
              vm.statusObject[status].loading = false;
            }, 400);
          }
        });
    }

    async getTaskListCounts () {
      const counts = await taskService.getTaskListCounts(vm.filter.assignUser, vm.filter.projectId);
      Object.keys(vm.statusObject).forEach((status) => {
        vm.statusObject[status].count = (counts.find((item) => item.status === status) || {}).count || 0;
      });
    }

    async updateStatus (taskId, previousStatus, newStatus, previousModelIndexInColumn) {
      if (!taskId || previousStatus === newStatus) {
        return;
      }
      const task = vm.dragularModelList.flat().find((task) => task.id === taskId);

      try {
        task.status = newStatus;
        await taskService.saveTask(task.projectId, taskId, { status: newStatus });
        vm.getTaskListCounts();
        $rootScope.$broadcast('taskSaved');
      } catch (error) {
        task.status = previousStatus;
        // move back the task to previous status column, & in the old index place, using previousModelIndexInColumn.
        const newStatusModelList = vm.dragularModelList[Object.keys(vm.statusObject).indexOf(newStatus)];
        const prevStatusModelList = vm.dragularModelList[Object.keys(vm.statusObject).indexOf(previousStatus)];
        newStatusModelList.splice(newStatusModelList.indexOf(task), 1);
        prevStatusModelList.splice(previousModelIndexInColumn, 0, task);
        $timeout(() => $scope.$apply());
      }
      vm.saveInProgress = false;
    }

    agentName (agentId) {
      const agent = $rootScope.userById(agentId) || {};
      if (!agent) { return; }
      return `${agent.firstName || ''} ${agent.lastName || ''}`.trim();
    }

    getUserProfileImage (agent) {
      return userService.getUserProfileImage(agent);
    }

    taskTooltip (task) {
      return `נוצרה ${$filter('date')(task.createdAt, 'dd/MM/yyyy HH:mm')}
      ${task.userId && vm.userList.length > 1 ? 'ע"י ' + vm.agentName(task.userId) : ''}`;
    }

    stripHtml (taskContent) {
      return utils.stripHtml(taskContent, true);
    }

    editTaskForm (task) {
      return $uibModal.open({
        component: 'taskEdit',
        resolve: {
          task: () => task,
          onSaveTask: () => {
            return (results) => {
              $timeout(() => {
                $scope.$apply(() => {
                  if (results.savedTask && task.id) { // Exist task edited
                    task = _.merge(task, results.savedTask);
                  } else {
                    vm.initialGetList();
                  }
                });
              });
            };
          }
        },
        windowClass: 'task-edit-modal mobile-modal',
        size: 'lg',
        backdrop: 'static'
      });
    }

    newTaskForm (status) {
      const projectId = vm.filter.projectId;
      if (!projectId) { return; }
      const newTask = {
        projectId,
        status,
        assignUser: $rootScope.user.id,
        dueDate: dayjs().set('second', 0).set('millisecond', 0),
        content: ''
      };

      return vm.editTaskForm(newTask);
    }
  }

  return new TaskBoardCtrl();
}
