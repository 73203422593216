export default function ($scope, $rootScope, $timeout, $uibModal, request, utils, userService) {
  let vm;
  const uiSref = 'admin.userShow({id: row.id})';

  class UserListCtrl {
    constructor () {
      vm = this;
      vm.name = 'user-list';
      vm.list = [];

      vm.tableHeadList = [
        { name: 'createdAt', type: 'date', label: 'תאריך הצטרפות', uiSref, sortable: true },
        { name: 'image', type: 'customFunction', customFunction: vm.getUserProfileImage, uiSref },
        { name: 'firstName', label: 'שם', type: 'customFunction', customFunction: vm.getUserName, uiSref, sortable: true },
        { name: 'ownerName', label: 'שם ארגון', type: 'customFunction', customFunction: vm.getOwnerName, uiSref, sortable: true },
        { name: 'email', label: 'דוא"ל', type: 'customFunction', customFunction: vm.getEmail, elementClass: 'email' },
        { name: 'phone', label: 'טלפון', type: 'customFunction', customFunction: vm.getPhone, elementClass: 'phone' },
        { name: 'permission', type: 'customFunction', customFunction: vm.permissionLabel, label: 'תפקיד', uiSref, sortable: true },
        { name: 'lastProjectsCount', label: 'פרויקטים אחרונים', uiSref, sortable: true },
        { name: 'active', type: 'customFunction', customFunction: vm.getUserActive, label: 'פעיל', uiSref, sortable: true, tdClass: 'active-td' },
        { name: 'lastLogin', label: 'התחבר לאחרונה', type: 'date', uiSref, sortable: true }
      ];

      vm.permissionList = {
        admin: 'משתמש על',
        manager: 'מנהל',
        user: 'נציג'
      };
    }

    $onInit () {
      vm.getUserList();
    }

    getUserList () {
      vm.getListProgress = true;
      return userService.getUserList()
        .then((userList) => {
          vm.list = userList;
          vm.list.forEach((user) => {
            if (user.phone) {
              user.phone = user.phone.replace(/^\+972/, '0');
            }
          });
          return vm.list;
        })
        .finally(() => {
          vm.getListProgress = false;
        });
    }

    addUserForm () {
      $uibModal.open({
        component: 'userEdit',
        windowClass: 'user-edit-modal mobile-modal',
        size: 'md'
      });
    }

    getUserName (row) {
      return `${row.firstName || ''} ${row.lastName || ''} `;
    }

    getOwnerName (row) {
      return _.get(row, 'owner.name') || '';
    }

    getUserProfileImage (row) {
      return `<img class="img-circle" src="${userService.getUserProfileImage(row)}" />`;
    }

    getEmail (row) {
      if (!row.email) {
        return '';
      }
      return `<a href="mailto:${row.email}">${row.email}</a>`;
    }

    getPhone (row) {
      if (!row.phone) {
        return '';
      }
      return `<a href="tel:${row.phone}">${row.phone}</a>`;
    }

    permissionLabel (row) {
      return vm.permissionList[row.permission] || row.permission;
    }

    getUserActive (row) {
      return `<span class="badge ${row.active ? 'badge-success' : 'badge-off'}">${row.active ? 'פעיל' : 'לא פעיל'}</span>`;
    }
  }

  return new UserListCtrl();
}
