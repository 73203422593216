import template from './owner-list.html';
import controller from './owner-list.controller';
import './owner-list.scss';

const ownerListComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$filter', '$timeout', '$uibModal', 'request', 'utils', 'ownerService'];

export default ownerListComponent;
