export default function ($scope, $timeout, request) {
  let vm;

  class CustomerSearchController {
    constructor () {
      vm = this;
      vm.name = 'customerSearch';
    }

    $onInit () {
      vm.placeholder = vm.resolve.placeholder || 'חיפוש לקוח';
    }

    onSearchChanged () {
      if (!vm.searchValue.length) {
        vm.results = {
          customers: [],
          customersLength: 0
        };
        return;
      }
      vm.searchProgress = true;
      request('/search/customer?q=' + vm.searchValue)
        .then((results) => {
          const resultsObject = {
            customers: results.data.data || [],
            customersLength: results.data.data.length
          };

          vm.results = resultsObject;
        })
        .finally(() => {
          vm.searchProgress = false;
        });
    }

    customerClicked (customer) {
      if (vm.resolve.onCustomerClicked) {
        vm.resolve.onCustomerClicked(customer);
      }
      vm.closeModalBox();
    }

    closeModalBox () {
      vm.modalInstance.close();
    }

    clearSearch () {
      vm.modalInstance.close();
      vm.searchValue = '';
      vm.results = {};
    }
  }

  return new CustomerSearchController();
}
