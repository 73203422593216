import counts from './counts';
import fileRequest from './file-request';
import govmap from './govmap';
import recipient from './recipient';
import request from './request';
import sweetAlert from './sweet-alert';
import utils from './utils';
import templateFields from './template-fields';
import gdrive from './gdrive';
import permissions from './permissions';

const servicesModule = angular.module('app.services', [
  counts,
  fileRequest,
  govmap,
  recipient,
  request,
  sweetAlert,
  utils,
  templateFields,
  gdrive,
  permissions
])
  .name;

export default servicesModule;
