import template from './project-tags-settings.html';
import controller from './project-tags-settings.controller';
import './project-tags-settings.scss';

const projectTagsSettingsComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', 'settingsService', 'utils'];

export default projectTagsSettingsComponent;
