import template from './sorting-table.html';
import controller from './sorting-table.controller';
import './sorting-table.scss';

const sortingTableComponent = {
  bindings: {
    tableName: '@',
    orderBy: '@',
    forceOrder: '<?',
    tableHeadList: '<?',
    rowLink: '<?',
    filter: '=?',
    limit: '=?',
    list: '<',
    loading: '<?',
    targetRow: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', '$sce'];

export default sortingTableComponent;
