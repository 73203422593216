import template from './custom-options-values.html';
import controller from './custom-options-values.controller';
import './custom-options-values.scss';

const valuesOptionsCustomComponent = {
  require: {
    settingsCtrl: '^settings'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$uibModal', 'settingsService', 'request', 'utils'];

export default valuesOptionsCustomComponent;
