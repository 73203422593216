export default function ($scope, $rootScope, $timeout, settingsService, utils) {
  let vm;

  class ProjectEventsSettingsCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.projectEventList = (_.get(vm.settingsCtrl, 'settings.projectEvents') || '').split(',');
      vm.originalProjectEventList = angular.copy(vm.projectEventList);

      $rootScope.$on('settingsLoaded', (owner) => {
        vm.projectEventList = (_.get(vm.settingsCtrl, 'settings.projectEvents') || '').split(',');
        vm.originalProjectEventList = angular.copy(vm.projectEventList);
      });
    }

    addEvent () {
      vm.projectEventList.push('');
      $timeout(() => {
        jQuery('.project-default-events input.form-control:last').focus();
      });
      vm.defaultEventsForm.$setDirty();
    }

    removeEvent (index) {
      vm.projectEventList.splice(index, 1);
      if (!vm.projectEventList.length) {
        vm.projectEventList.push('');
      }
      vm.defaultEventsForm.$setDirty();
    }

    saveSettings (form) {
      if (vm.saveProgress) {
        return;
      }

      const settingsObject = {
        projectEvents: vm.projectEventList.filter(v => v).join(',')
      };

      vm.saveProgress = true;
      return settingsService.saveSettings(settingsObject)
        .then((result) => {
          utils.notify('הגדרות נשמרו', 'alert-success');
          $rootScope.$broadcast('ownerSaved');
          vm.defaultEventsForm.$setPristine();
          return result;
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    resetSettings () {
      vm.projectEventList = angular.copy(vm.originalProjectEventList);
      vm.defaultEventsForm.$setPristine();
    }
  }

  return new ProjectEventsSettingsCtrl();
}
