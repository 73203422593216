export default function ($scope, $timeout, request) {
  let vm;
  const fields = ['q', 'name', 'idNumber', 'passport', 'phone', 'email', 'address', 'city'];

  class CustomerAutocompleteController {
    constructor () {
      vm = this;
      vm.name = 'autocomplete';
      vm.cacheObject = {};
    }

    $onChanges (changes) {
      if (vm.show && Object.keys(vm.searchObject).some((key) => vm.searchObject[key] !== vm.cacheObject[key])) {
        vm.cacheObject = angular.copy(vm.searchObject);
        vm.search();
      }
    }

    search () {
      vm.searchProgress = true;
      const query = {};
      fields.forEach((field) => {
        if (_.get(vm, 'searchObject.' + field + '.length') > 2) {
          query[field] = vm.searchObject[field];
        }
      });
      if (!Object.keys(query).length) {
        vm.autocompleteList = [];
        return;
      }

      request('/search/customer?' + jquery.param(query))
        .then((results) => {
          vm.autocompleteList = results.data.data || [];
        })
        .finally(() => {
          vm.searchProgress = false;
        });
    }

    customerClicked (customer) {
      if (vm.onCustomerClicked) {
        vm.onCustomerClicked(customer);
      }
      $timeout(() => {
        vm.show = false;
      }, 100);
    }
  }

  return new CustomerAutocompleteController();
}
