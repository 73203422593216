import isEmail from 'validator/lib/isEmail';

export default function ($scope, $state, $location, $window, $timeout, request, utils) {
  let vm;

  const KEY_EVENT_ENTER = 13;

  class SignUpCtrl {
    constructor () {
      vm = this;
      vm.state = 'signUp';

      angular.element(document.querySelector('#sign-up-form'))
        .bind('keydown keypress', (event) => {
          if (event.which === KEY_EVENT_ENTER) {
            vm.nextStage();
            event.preventDefault();
          }
        });

      angular.element(document.querySelector('#new-owner-form'))
        .bind('keydown keypress', (event) => {
          if (event.which === KEY_EVENT_ENTER) {
            vm.send();
            event.preventDefault();
          }
        });
    }

    $onInit () {
      vm.$window = $window;
      _.set($state, 'current.data.specialClass', 'blank');
      vm.signUpFields = {};
      vm.ownerFields = {};
      vm.sendProgress = false;

      $scope.$watchGroup(['vm.signUpFields.password', 'vm.signUpFields.password2'], (newVal, oldVal) => {
        if (_.get(vm, 'signUpForm.password')) {
          const passwordMatch = vm.signUpFields.password === vm.signUpFields.password2;
          vm.signUpForm.password2.$setValidity('passwordMatch', passwordMatch);
          jQuery('#password2')[0].setCustomValidity(passwordMatch || !vm.signUpFields.password2 ? '' : 'הסיסמאות אינן תואמות');
        }
      });

      $scope.$watch('vm.signUpFields.email', (newVal, oldVal) => {
        if (vm.signUpForm.email) {
          vm.signUpForm.email.$setValidity('registeredEmail', true);
          const isValidEmail = isEmail(vm.signUpFields.email || '');
          vm.signUpForm.email.$setValidity('email', isValidEmail);
          jQuery('#email')[0].setCustomValidity(isValidEmail ? '' : 'דוא"ל לא תקין');
        }
      });

      let phoneField;
      $scope.$watch('vm.signUpForm.phone.$viewValue', (newVal, oldVal) => {
        if (!phoneField) {
          if (!jQuery('#phone')[0]) { return; }
          phoneField = jQuery('#phone')[0];
        }
        phoneField.setCustomValidity(vm.signUpFields.phone ? '' : 'מספר טלפון לא תקין');
      });
    }

    checkPassword () {
      if (!$window.zxcvbn) { return; }
      vm.result = $window.zxcvbn(vm.signUpForm.password.$viewValue);
    }

    async nextStage () {
      utils.setFormFieldsDirty(vm.signUpForm);
      if (vm.signUpForm.$invalid) {
        jQuery('#sign-up-form')[0].reportValidity();
        return;
      }
      let emailIsExist = false;
      await request('/sign-up/check-registered', { email: vm.signUpFields.email })
        .then((result) => {
          emailIsExist = _.get(result, 'data.data');
        });

      if (emailIsExist) {
        vm.signUpForm.email.$setValidity('registeredEmail', false);
        jQuery('#email')[0].setCustomValidity('כתובת הדוא"ל כבר רשומה במערכת');
        utils.notify('כתובת הדוא"ל כבר רשומה במערכת', 'alert-danger');
        return;
      }

      $scope.$apply(() => {
        $timeout(() => {
          vm.state = 'newOwner';
        });
      });
    }

    send () {
      utils.setFormFieldsDirty(vm.newOwnerForm);
      if (vm.sendProgress) {
        return;
      }
      if (vm.newOwnerForm.$invalid) {
        jQuery('#new-owner-form')[0].reportValidity();
        return;
      }

      vm.sendProgress = true;
      return request('/sign-up', {
        userDetails: vm.signUpFields,
        ownerDetails: vm.ownerFields
      })
        .then((result) => {
          utils.notify('חשבון נוצר בהצלחה', 'alert-success');
          $timeout(() => {
            $state.go('login', { email: vm.signUpFields.email });
          }, 900);
        })
        .catch((error) => {
          let errorMessage = 'שגיאה';
          if (error.data.data === 'SequelizeUniqueConstraintError: Email is already registered.') {
            errorMessage = 'כתובת הדוא"ל כבר רשומה במערכת';
          }
          if (error.data.data === 'SequelizeValidationError: Validation error: Invalid email.') {
            errorMessage = 'דוא"ל לא תקין';
          }
          utils.notify(errorMessage, 'alert-danger');
          console.log(error);
        })
        .finally(() => {
          vm.sendProgress = false;
        });
    }
  }

  return new SignUpCtrl();
}
