export default function ($scope, $rootScope, $timeout, settingsService, utils) {
  let vm;

  class ProjectFoldersSettingsCtrl {
    constructor () {
      vm = this;
      vm.projectFolderNameOptions = {
        streetFirst: 'רחוב עיר - שם לקוח - מספר פרויקט',
        cityFirst: 'עיר - רחוב - שם לקוח - מספר פרויקט',
        cityFirst2: 'עיר - רחוב - מספר פרויקט - שם לקוח',
        customerFirst: 'שם לקוח - רחוב עיר - מספר פרויקט',
        numberFirst: 'מספר פרויקט - רחוב עיר - שם לקוח',
        gushHelkaFirst: 'גוש וחלקה - רחוב עיר - שם לקוח - מספר פרויקט'
      };
    }

    $onInit () {
      vm.projectFolderList = (_.get(vm.settingsCtrl, 'settings.projectFolders') || '').split(',');
      vm.originalProjectFolderList = angular.copy(vm.projectFolderList);
      vm.projectFolderName = _.get(vm.settingsCtrl, 'settings.projectFolderName') || 'numberFirst';

      $rootScope.$on('settingsLoaded', (owner) => {
        vm.projectFolderList = (_.get(vm.settingsCtrl, 'settings.projectFolders') || '').split(',');
        vm.projectFolderName = _.get(vm.settingsCtrl, 'settings.projectFolderName') || 'numberFirst';
        vm.originalProjectFolderList = angular.copy(vm.projectFolderList);
      });
    }

    addFolder () {
      vm.projectFolderList.push('');
      $timeout(() => {
        jQuery('.project-folders-settings input.form-control:last').focus();
      });
      vm.defaultFoldersForm.$setDirty();
    }

    removeFolder (index) {
      vm.projectFolderList.splice(index, 1);
      if (!vm.projectFolderList.length) {
        vm.projectFolderList.push('');
      }
      vm.defaultFoldersForm.$setDirty();
    }

    setProjectFolderName () {
      vm.statusIcon = 'progress';
      return settingsService.saveSettings({ projectFolderName: vm.projectFolderName })
        .then((result) => {
          $rootScope.$broadcast('ownerSaved');
          vm.statusIcon = 'done animate__animated animate__fadeOut';
        })
        .catch(() => {
          vm.statusIcon = 'failed animate__animated animate__fadeOut';
        });
    }

    saveSettings (form) {
      if (vm.saveProgress) {
        return;
      }

      const settingsObject = {
        projectFolders: vm.projectFolderList.filter(v => v).join(',')
      };

      vm.saveProgress = true;
      return settingsService.saveSettings(settingsObject)
        .then((result) => {
          utils.notify('הגדרות נשמרו', 'alert-success');
          $rootScope.$broadcast('ownerSaved');
          vm.defaultFoldersForm.$setPristine();
          return result;
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    resetSettings () {
      vm.projectFolderList = angular.copy(vm.originalProjectFolderList);
      vm.defaultFoldersForm.$setPristine();
    }
  }

  return new ProjectFoldersSettingsCtrl();
}
