function gdriveService (request) {
  return {
    getAllImages: (projectId) => {
      return request(`/project/${projectId}/get-all-images`, {}, 'GET');
    },
    getImagesFromList: (projectId, imageIds) => {
      return request(`/project/${projectId}/get-images-from-list`, { imageIds }, 'POST');
    }
  };
}

gdriveService.$inject = ['request'];

const fileRequestServiceModule = angular.module('gdriveService', [])
  .factory('gdrive', gdriveService)
  .name;

export default fileRequestServiceModule;
