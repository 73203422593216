import template from './settings.html';
import controller from './settings.controller';
import './settings.scss';

const settingsComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', '$uibModal', 'utils', 'settingsService', 'ownerService', 'permissions'];

export default settingsComponent;
