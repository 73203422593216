// import optionsList from '../../../project-options-list';

export default function ($scope, $rootScope, $timeout, eventService, counts, utils) {
  let vm;

  class EventEditCtrl {
    constructor () {
      vm = this;
      // vm.affinityOptions = optionsList.affinityOptions;
    }

    $onInit () {
      vm.eventModelObject = angular.copy(vm.resolve.event);
      vm.projectId = vm.resolve.projectId || vm.eventModelObject.projectId;
      _.set(vm.eventModelObject, 'date', _.get(vm.eventModelObject, 'dateTime'));
      _.set(vm.eventModelObject, 'time', _.get(vm.eventModelObject, 'dateTime'));
    }

    closeModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    saveEvent () {
      if (vm.saveProgress || !vm.projectId) {
        return;
      }

      if (vm.eventForm.$invalid) {
        document.getElementById('event-form').reportValidity();
        utils.setFormFieldsDirty(vm.eventForm);
        return;
      }

      vm.saveProgress = true;
      eventService.saveEvent(vm.projectId, vm.eventModelObject.id, vm.eventModelObject)
        .then((savedEvent) => {
          const message = (vm.eventModelObject.id ? 'פרטי אירוע נשמרו' : 'אירוע חדש נשמר');
          utils.notify(message, 'alert-success');
          vm.modalInstance.close();
          $rootScope.$broadcast('eventSaved');
          counts.loadCounts('events');
          if (vm.resolve.onSaveEvent) {
            vm.resolve.onSaveEvent({ savedEvent });
          }
        })
        .finally(() => {
          vm.saveProgress = false;
        });
    }

    async deleteEvent () {
      const modalParams = {
        windowClass: 'hmodal-danger',
        title: 'האם למחוק אירוע?',
        buttons: [{
          label: 'לא, ביטול'
        },
        {
          label: 'כן, למחוק',
          btnClass: 'btn-danger',
          onClick ($uibModalInstance) {
            $uibModalInstance.close();
            return true;
          }
        }]
      };

      await utils.modalAlert(modalParams);
      await eventService.deleteEvent(vm.projectId, vm.eventModelObject.id);
      counts.loadCounts('events');
      vm.modalInstance.close();
      $rootScope.$broadcast('eventSaved');
      if (vm.resolve.onSaveEvent) {
        vm.resolve.onSaveEvent({ savedEvent: undefined });
      }
    }
  }

  return new EventEditCtrl();
}
