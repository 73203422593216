import ticketEdit from './ticket-edit/ticket-edit';
import ticketList from './ticket-list/ticket-list';
import ticketShow from './ticket-show/ticket-show';
import ticketService from './ticket.service';

const ticketModule = angular.module('ticket', [
  ticketEdit,
  ticketList,
  ticketShow,
  ticketService
])
  .name;

export default ticketModule;
