import projectDetailsEdit from './project-details-edit/project-details-edit';
import projectDetailsShow from './project-details-show/project-details-show';

const projectDetailsModule = angular.module('projectDetails', [
  projectDetailsEdit,
  projectDetailsShow
])
  .name;

export default projectDetailsModule;
