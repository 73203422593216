import dayjs from 'dayjs';

export default function ($scope, $rootScope, $filter, $timeout, $uibModal, request, utils, ownerService) {
  let vm;

  class OwnerListCtrl {
    constructor () {
      vm = this;
      vm.name = 'owner-list';
      vm.list = [];
      const uiSref = 'admin.ownerShow({id: row.id})';
      vm.tableHeadList = [
        { name: 'current', label: '', type: 'customFunction', customFunction: vm.getCurrentOwner, tdClass: 'text-center', uiSref },
        { name: 'createdAt', type: 'date', label: 'תאריך הצטרפות', sortable: true, uiSref },
        { name: 'name', label: 'שם ארגון', sortable: true, uiSref },
        { name: 'phone', label: 'טלפון', type: 'customFunction', customFunction: vm.getPhone, elementClass: 'phone' },
        { name: 'address', label: 'כתובת', sortable: true, uiSref },
        { name: 'city', label: 'עיר', sortable: true, uiSref },
        { name: 'hasDebit', label: 'הו"ק', type: 'customFunction', customFunction: vm.getOwnerDebit, uiSref },
        { name: 'price', label: 'מחיר', type: 'currency', uiSref },
        { name: 'users', type: 'customFunction', customFunction: vm.getOwnerUsers, label: 'משתמשים', sortable: true, uiSref },
        { name: 'lastProjectsCount', label: 'פרויקטים אחרונים', sortable: true, uiSref },
        { name: 'accountEnd', type: 'customFunction', customFunction: vm.getAccountEnd, label: 'תוקף חשבון', sortable: true, uiSref },
        { name: 'hidden', type: 'customFunction', if: vm.isHiddenBadgeShown, customFunction: vm.getHidden, label: 'מוסתר', sortable: true, uiSref },
        { name: 'actions', label: '', type: 'customFunction', customFunction: () => 'כניסה כמשתמש בארגון', clickFunction: vm.loginAsUser, elementClass: 'btn btn-default btn-sm', if: vm.isLoginButtonShown, uiSref }
      ];
    }

    $onInit () {
      vm.hiddenFilter = 'notHidden';
      vm.getOwnerList();
      vm.isHiddenSettingClicked = 0;
      vm.isHiddenSettingRightClicked = 0;
    }

    getOwnerList () {
      vm.getListProgress = true;
      return ownerService.getOwnerList(vm.hiddenFilter)
        .then((ownerList) => {
          ownerList.forEach((owner) => {
            if (owner.phone) {
              owner.phone = owner.phone.replace(/^\+972/, '0');
            }
          });

          vm.list = ownerList;
          return vm.list;
        })
        .finally(() => {
          vm.getListProgress = false;
        });
    }

    hiddenFilterChanged () {
      vm.getOwnerList();
    }

    addOwnerForm () {
      $uibModal.open({
        component: 'ownerEdit',
        windowClass: 'owner-edit-modal mobile-modal',
        size: 'md'
      });
    }

    isHiddenBadgeShown () {
      return vm.hiddenFilter !== 'notHidden';
    }

    loginAsUser ($event, owner) {
      request('/actions/make-temp-user', { ownerId: owner.id })
        .then((result) => {
          location.reload();
        });
    }

    getCurrentOwner (row) {
      if (row.id === $rootScope.user.ownerId) {
        return '<i class="fa fa-arrow-circle-o-left"></i>';
      }
      return '';
    }

    getPhone (row) {
      if (!row.phone) {
        return '';
      }
      return `<a href="tel:${row.phone}">${row.phone}</a>`;
    }

    getHidden (row) {
      if (!row.hidden) {
        return '';
      }
      return '<span class="badge">מוסתר</span>';
    }

    getOwnerDebit (row) {
      if (!_.get(row, 'ownerCreditCardTokens.length')) {
        return '';
      }
      return '<span class="text-success"><i class="ion ion-checkmark-circled"></i></span>';
    }

    getOwnerUsers (row) {
      return row.users.filter((user) => user.active).length || '0';
    }

    getAccountEnd (row) {
      return `<span class="${dayjs(row.accountEnd).isBefore(dayjs().add(5, 'days').startOf('day')) ? 'text-danger' : 'text-success-dark'}">${$filter('date')(row.accountEnd, 'dd/MM/yyyy')}</span>`;
    }

    isLoginButtonShown () {
      return vm.isHiddenSettingClicked > 1 &&
        vm.isHiddenSettingRightClicked > 1;
    }
  }

  return new OwnerListCtrl();
}
