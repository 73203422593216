import template from './advanced-search.html';
import controller from './advanced-search.controller';
import './advanced-search.scss';

const AdvancedSearchComponent = {
  require: {
    searchCtrl: '^search'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', 'utils', 'settingsService'];

export default AdvancedSearchComponent;
