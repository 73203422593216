import template from './image-preview.html';
import './image-preview.scss';

const imagePreviewModule = angular.module('imagePreview', [])
  .directive('imagePreview', [function () {
    return {
      restrict: 'EA',
      controllerAs: 'vm',
      bindToController: true,
      replace: true,
      scope: {
        imageUrl: '@',
        thumbnailUrl: '@',
        options: '<?',
        type: '@'
      },
      template,
      controller: ['$scope', '$uibModal', function ($scope, $uibModal) {
        const vm = this;
        vm.$uibModal = $uibModal;
        vm.options = {
          zoom: true,
          pdfZoom: true
        };

        vm.zoomImage = function () {
          vm.$uibModal.open({
            template: `<div class="image-container"><img ng-src="${vm.imageUrl}"/><div>`,
            windowClass: 'image-preview-zoom-view',
            size: 'lg'
          });
        };

        vm.zoomPdf = function () {
          vm.$uibModal.open({
            template: `<div class="pdf-container">
              <embed width="100%" height="100%" src="${vm.imageUrl}" type="application/pdf">
            </div>`,
            windowClass: 'pdf-preview-zoom-view',
            size: 'lg'
          });
        };

        vm.isImage = function () {
          if (vm.type === 'image') {
            return true;
          }
          return ['jpg', 'png', 'gif', 'jpeg', 'bmp'].includes(_.last(vm.imageUrl.split('.')).toLowerCase());
        };

        vm.files = {
          pdf: 'pdf',
          doc: 'word',
          docx: 'word',
          xls: 'excel',
          xlsx: 'excel',
          csv: 'excel',
          ppt: 'powerpoint',
          pptx: 'powerpoint',
          mp3: 'sound',
          wav: 'sound',
          mp4: 'movie',
          mov: 'movie',
          zip: 'zip',
          rar: 'zip'
        };

        vm.fileType = function () {
          if (!vm.imageUrl) {
            return false;
          }
          return vm.type || vm.files[_.last(vm.imageUrl.split('.')).toLowerCase()];
        };

        vm.isPdf = function () {
          if (vm.type === 'pdf') {
            return true;
          }
          return _.last(vm.imageUrl.split('.')).toLowerCase() === 'pdf';
        };
      }]

    };
  }])
  .name;

export default imagePreviewModule;
