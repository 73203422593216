import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

// import { json2excel, excel2json } from 'js2excel';

export default function ($scope, $rootScope, $timeout, $uibModal, $filter) {
  let vm;

  class NadlanDealsCtrl {
    constructor () {
      vm = this;
      vm.orderBy = 'DEALDATE';
      vm.orderDesc = true;
      vm.requestCounter = 0;

      vm.streetQuery = {};
      vm.tableHeadList = [
        { name: 'DEALDATE', label: 'יום מכירה', type: 'date', sortable: true },
        { name: 'ADDRESS', label: 'כתובת', type: 'customFunction', customFunction: vm.getDealAddress, sortable: true },
        { name: 'GUSHHELKATAT', label: 'גוש חלקה', sortable: true },
        { name: 'ASSETTYPE', label: 'סוג נכס', sortable: true },
        { name: 'ROOMNUM', label: 'חדרים', sortable: true },
        { name: 'FLOOR', label: 'קומה', sortable: true },
        { name: 'ASSETMETER', label: 'מ"ר', sortable: true },
        { name: 'pricePerMeter', label: 'מחיר למ"ר', type: 'currency', sortable: true },
        { name: 'DEALS_DEALAMOUNT', label: 'מחיר העסקה', type: 'currency', sortable: true }
      ];

      vm.propertyTypeOptions = [
        'דירה בבית קומות',
        'קרקע למגורים',
        'מחסנים',
        'דירה',
        'חנות',
        'קוטג\' דו משפחתי',
        'מלאכה',
        'קומבינציה',
        'דירת גג (פנטהאוז)',
        'תעשיה',
        'דירת גג',
        'בית בודד',
        'משרד',
        'בניני ציבור',
        'קוטג\' חד משפחתי',
        'מגורים',
        'עסק',
        'חניה',
        'דיור מוגן',
        'אחר'
      ];
      vm.queryType = 'xy';
      vm.queryRadius = 0;
      vm.filters = {
        fromDate: dayjs('01/01/1991', 'DD/MM/YYYY'),
        toDate: dayjs()
      };
    }

    $onInit () {
      vm.getNadlanDealsList()
        .then(() => {
          if (!_.get(vm, 'list.length')) {
            vm.queryType = 'radius';
            vm.queryRadius = 50;
            vm.getNadlanDealsList();
          }
        });

      $scope.$watchGroup(['vm.x', 'vm.y'], (newVal, oldVal) => {
        if (newVal[0] && newVal[1] && (newVal[0] !== oldVal[0] || newVal[1] !== oldVal[1])) {
          vm.getNadlanDealsList();
        }
      });
    }

    selectQueryType (type, radius) {
      vm.showCustomRadius = false;
      vm.queryType = type || vm.queryType;
      vm.queryRadius = radius || vm.queryRadius;
      vm.getNadlanDealsList();
    }

    selectCustomRadius () {
      vm.queryType = 'radius';
      vm.showCustomRadius = true;
    }

    sort (value) {
      if (vm.orderBy === value) {
        vm.orderDesc = !vm.orderDesc;
      } else {
        vm.orderBy = value;
        if (value === 'DEALDATE') {
          vm.orderDesc = true;
        } else {
          vm.orderDesc = false;
        }
      }
    }

    getDealAddress (row) {
      return row.ADDRESS + '<small>' + [row.NEIGHBORHOOD, row.CITY].filter((i) => !!i).join(', ') + '</small>';
    }

    toggleFilters () {
      vm.showFilters = !vm.showFilters;
    }

    resetFilters () {
      vm.filters = {
        fromDate: dayjs('01/01/1991', 'DD/MM/YYYY').format(),
        toDate: dayjs().format()
      };
      vm.filterList();
    }

    getNadlanDealsList () {
      vm.requestCounter++;
      const requestCounter = vm.requestCounter;
      const locationX = vm.x;
      const locationY = vm.y;
      return new Promise((resolve, reject) => {
        if (!locationX || !locationY) {
          return reject(new Error('locationX or locationY missing'));
        }

        let url;
        let query;
        const govmapBaseUrl = 'https://ags.govmap.gov.il/';
        if (vm.queryType === 'xy') {
          url = govmapBaseUrl + 'Identify/IdentifyByXY';
          query = {
            x: locationX, y: locationY, mapTolerance: 4.960947421894845, layers: [{ LayerType: 0, LayerName: 'nadlan', LayerFilter: '' }]
          };
        } else if (vm.queryType === 'radius') {
          url = govmapBaseUrl + 'AdvancedSearch/QueryByRadius';
          query = {
            Radius: vm.queryRadius, Wkt: `POINT(${locationX} ${locationY})`, LayerName: 'NADLAN'
          };
        } else if (vm.queryType === 'street' && vm.streetQuery.StreetCodes && vm.streetQuery.Extent) {
          url = govmapBaseUrl + 'Identify/GetDealsByStreetCodes';
          query = {
            StreetCodes: vm.streetQuery.StreetCodes, Extent: vm.streetQuery.Extent
          };
        }
        vm.getListProgress = true;
        vm.list = [];
        vm.polygonIds = null;
        vm.extent = null;
        vm.isShownAll = false;

        jQuery.ajax({
          url,
          method: 'POST',
          contentType: 'application/json;charset=UTF-8',
          dataType: 'json',
          data: JSON.stringify(query),
          success: (res) => {
            if (vm.requestCounter > requestCounter) {
              return resolve();
            }
            let data;
            let list;

            if (vm.queryType === 'xy') {
              data = _.get(res, 'data[0]') || {};
              vm.streetQuery.Extent = data.Extent;
              vm.streetQuery.StreetCodes = data.StreetCodes;
              list = data.Result;
            } else if (vm.queryType === 'radius') {
              data = _.get(res, 'data.Data') || {};
              list = data.Result;
            } else if (vm.queryType === 'street') {
              data = _.get(res, 'data') || [];
              list = data;
            }

            vm.polygonIds = data.PolygonIds || [];
            vm.extent = data.Extent || {};

            if (!list) {
              vm.list = [];
              return resolve();
            }
            list.forEach((row) => {
              if (row.DEALS_DEALAMOUNT && row.ASSETMETER) {
                row.pricePerMeter = Number((row.DEALS_DEALAMOUNT / row.ASSETMETER).toFixed(0));
              }
            });
            vm.originalList = list;
            vm.filterList(vm.originalList);
            return resolve();
          }
        })
          .fail((error) => {
            console.error(error);
            return reject(error);
          });
      })
        .finally(() => {
          vm.getListProgress = false;
          $scope.$apply();
        });
    }

    filterList () {
      vm.list = $filter('filter')(vm.originalList, function (item) {
        return (!vm.filters.propertyType || item.ASSETTYPE === vm.filters.propertyType) &&
          (!vm.filters.fromDate || !dayjs(item.DEALDATE).isBefore(vm.filters.fromDate)) &&
          (!vm.filters.toDate || !dayjs(item.DEALDATE).isAfter(vm.filters.toDate)) &&
          (!vm.filters.rooms || String(item.ROOMNUM).split('.')[0] === vm.filters.rooms);
      });
    }

    getAllList () {
      vm.isShownAll = true;
      vm.getDealsBubbleContent();
    }

    getDealsBubbleContent () {
      return new Promise((resolve, reject) => {
        if (!vm.polygonIds || !vm.extent) {
          return reject(new Error('no polygonIds or extent'));
        }
        vm.requestCounter++;
        const requestCounter = vm.requestCounter;

        const prevList = vm.list;
        vm.list = [];
        vm.getListProgress = true;
        jQuery.ajax({
          url: 'https://ags.govmap.gov.il/Identify/GetDealsBubbleContent',
          method: 'POST',
          contentType: 'application/json;charset=UTF-8',
          dataType: 'json',
          data: JSON.stringify({ PolygonIds: vm.polygonIds, Extent: vm.extent, Skip: prevList.length }),
          success: (res) => {
            const resList = _.get(res, 'data');
            if (!angular.isArray(resList) || !resList.length) {
              return resolve();
            }
            if (vm.requestCounter > requestCounter) {
              return resolve();
            }

            resList.forEach((row) => {
              if (row.DEALS_DEALAMOUNT && row.ASSETMETER) {
                row.pricePerMeter = Number((row.DEALS_DEALAMOUNT / row.ASSETMETER).toFixed(0));
              }
            });

            // filter duplicates of old and new list
            vm.originalList = _.unionBy(prevList, resList, 'OBJECTID');
            vm.filterList(vm.originalList);

            return resolve();
          }
        })
          .fail((error) => {
            console.error(error);
            return reject(error);
          });
      })
        .finally(() => {
          vm.getListProgress = false;
          $scope.$apply();
        });
    }

    exportToExcel () {
      if (!vm.list.length) {
        return;
      }
      const data = vm.list.map((row) => {
        return {
          תאריך: row.DEALDATESTRING || '',
          כתובת: row.ADDRESS || '',
          שכונה: row.NEIGHBORHOOD || '',
          עיר: row.CITY || '',
          'גוש חלקה': row.GUSHHELKATAT || '',
          'סוג נכס': row.ASSETTYPE || '',
          חדרים: row.ROOMNUM || '',
          קומה: row.FLOOR || '',
          'מ"ר': row.ASSETMETER || '',
          'מחיר למ"ר': row.pricePerMeter || '',
          'מחיר העסקה': row.DEALS_DEALAMOUNT || 0
        };
      });

      try {
        const XLSX = window.XLSX;
        const ws = XLSX.utils.json_to_sheet(data, { dateNF: 'dd/mm/yyyy' });
        const wb = XLSX.utils.book_new();
        _.set(wb, 'Workbook.Views.0.RTL', true);
        XLSX.utils.book_append_sheet(wb, ws, 'עסקאות נדל"ן');

        let fileName = 'עסקאות נדל-ן';
        if (vm.projectNumber) {
          fileName += ` פרויקט ${vm.projectNumber}`;
        }
        fileName += '.xlsx';
        XLSX.writeFile(wb, fileName);
      } catch (error) {
        console.log(error);
      }
      // try {
      //   json2excel({
      //     data,
      //     name: fileName,
      //     formateDate: 'dd/mm/yyyy'
      //   });
      // } catch (error) {
      //   console.log(error);
      // }
    }
  }

  return new NadlanDealsCtrl();
}
