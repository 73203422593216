import dayjs from 'dayjs';

export default function ($scope, $rootScope, $timeout, $uibModal, request, utils, taskService, noteService, callService, counts) {
  let vm;

  class ProjectNotesCtrl {
    constructor () {
      vm = this;
      vm.noteList = [];
      vm.callList = [];

      const inputTry = document.createElement('input');
      inputTry.setAttribute('type', 'datetime-local');
      vm.isDateTimeSupported = inputTry.type === 'datetime-local';
      vm.dateTimeField = vm.isDateTimeSupported ? 'datetime-local' : 'date';
    }

    $onInit () {
      vm.callModelObject = {
        assignUser: $rootScope.user.id,
        date: dayjs().set('second', 0).set('millisecond', 0),
        content: '',
        direction: 'in'
      };

      $scope.$on('noteSaved', () => {
        vm.noteModelObject = {};
      });
      $scope.$on('callSaved', () => {
        vm.callModelObject = {
          assignUser: $rootScope.user.id,
          date: dayjs().set('second', 0).set('millisecond', 0),
          content: '',
          direction: 'in'
        };
      });
    }

    isNoteEmpty (taskContent) {
      return !taskContent || !utils.stripHtml(taskContent).length;
    }

    saveNote (note, noteId) {
      if (note.progress || vm.isNoteEmpty(note.content)) {
        return;
      }
      note.progress = true;
      const noteObject = {
        content: note.content
      };

      noteService.saveNote(vm.projectId, (noteId || null), noteObject)
        .then((noteResponse) => {
          if (noteId) {
            note.changed = false;
          } else {
            vm.noteList.push(noteResponse);
          }
          $rootScope.$broadcast('noteSaved');
        })
        .finally(() => {
          note.progress = false;
        });
    }

    deleteNote (note) {
      note.deleted = true;
      noteService.deleteNote(vm.projectId, note.id)
        .then((noteResponse) => {
          $timeout(() => {
            $rootScope.$broadcast('noteSaved');
          }, 400);
        })
        .catch(() => {
          $timeout(() => {
            note.deleted = false;
          }, 400);
        });
    }

    saveCall (call, callId) {
      if (call.progress || (!callId && vm.isNoteEmpty(call.content))) {
        return Promise.reject(new Error('call save in progress or is empty'));
      }
      call.progress = true;
      const callObject = {
        content: call.content,
        direction: call.direction,
        date: call.date,
        assignUser: call.assignUser
      };

      return callService.saveCall(vm.projectId, (callId || null), callObject)
        .then((callResponse) => {
          if (callId) {
            call.changed = false;
          } else {
            vm.callList.push(callResponse);
          }
          $rootScope.$broadcast('callSaved');
        })
        .finally(() => {
          call.progress = false;
        });
    }

    saveCallProperty (call, property) {
      call[property + 'SaveProgress'] = true;
      if (property === 'date') {
        call[property] = dayjs(call[property]).set('second', 0).set('millisecond', 0);
        console.log(call[property]);
      }
      const callObject = {
        [property]: call[property] || ''
      };
      return vm.saveCall(callObject, call.id, false)
        .finally(() => {
          call[property + 'SaveProgress'] = false;
        });
    }

    deleteCall (call) {
      call.deleted = true;
      callService.deleteCall(vm.projectId, call.id)
        .then((callResponse) => {
          $timeout(() => {
            $rootScope.$broadcast('callSaved');
          }, 400);
        })
        .catch(() => {
          $timeout(() => {
            call.deleted = false;
          }, 400);
        });
    }

    onKeydown ($event, task, type = 'note') {
      if ($event.which === 13 && $event.ctrlKey) {
        $event.stopPropagation();
        if (task.content.trim().length) {
          if (type === 'note') {
            vm.saveNote(task, task.id);
          } else if (type === 'call') {
            vm.saveCall(task, task.id);
          }
        }
      }
    }

    agentIsActive (agentId) {
      return _.get($rootScope.userById(agentId), 'active');
    }

    agentName (agentId) {
      const agent = $rootScope.userById(agentId) || {};
      if (!agent) { return; }
      return `${agent.firstName || ''} ${agent.lastName || ''}`;
    }
  }

  return new ProjectNotesCtrl();
}
