import eventEdit from './event-edit/event-edit';
import eventService from './event.service';

const eventModule = angular.module('event', [
  eventEdit,
  eventService
])
  .name;

export default eventModule;
