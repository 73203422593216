import projectSidebarComponent from './project-sidebar.component';
import projectAgentSelector from './project-agent-selector/project-agent-selector';

const projectSidebarModule = angular.module('projectSidebar', [
  projectAgentSelector
])
  .component('projectSidebar', projectSidebarComponent)
  .name;

export default projectSidebarModule;
