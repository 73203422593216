export default function ($scope, $rootScope, $stateParams, $timeout, $filter, $uibModal, taskListTableColumns, utils, taskService, userService, counts) {
  let vm;

  class TaskListCtrl {
    constructor () {
      vm = this;
      vm.name = 'taskList';
      vm.orderBy = '-dueDate';
      vm.$stateParams = $stateParams;

      vm.statusList = {
        toDo: 'לביצוע',
        inProgress: 'בביצוע',
        done: 'בוצע'
      };
      vm.taskListTableHeadList = taskListTableColumns.taskListTableHeadList;

      vm.pinnedTableHeadList = taskListTableColumns.pinnedTableHeadList;
      vm.pinnedTableHeadList.getQuery = () => {
        return vm.tableFilters;
      };

      vm.tableFilters = {
        status: '!done'
      };
      vm.pinnedTasksCollapsed = localStorage.getItem('pinnedTasksCollapsed') === 'true';
    }

    $onInit () {
      vm.agentList = _.get($rootScope, 'owner.users') || [];
      if (vm.agentList.length > 1 && !$rootScope.isManager()) {
        vm.tableFilters.assignUser = $rootScope.user.id;
      }
      if (vm.$stateParams.query) {
        vm.tableFilters = vm.$stateParams.query.filter;
      }

      vm.getPinnedTaskList();
      $scope.$on('taskSaved', () => {
        vm.getTaskList();
        vm.getPinnedTaskList();
        counts.loadCounts('pinnedTasks');
      });
    }

    getTaskList (args) {
      return taskService.getTaskList(args);
    }

    onAgentChanged (selectedAgent) {
      vm.tableFilters.assignUser = selectedAgent;
    }

    getPinnedTaskList () {
      vm.getPinnedListProgress = true;
      const queryArgs = {
        offset: 0,
        order: 'createdAt',
        desc: true,
        filter: {
          pinned: true,
          assignUser: $rootScope.user.id,
          status: '!done'
        }
      };
      return taskService.getTaskList(queryArgs)
        .then((pinnedTaskList) => {
          vm.pinnedTaskList = pinnedTaskList;
          return pinnedTaskList;
        })
        .finally(() => {
          vm.getPinnedListProgress = false;
        });
    }

    togglePinnedTasksCollapsed () {
      vm.pinnedTasksCollapsed = !vm.pinnedTasksCollapsed;
      localStorage.setItem('pinnedTasksCollapsed', vm.pinnedTasksCollapsed);
    }

    selectedStatusString () {
      return vm.tableFilters.status === '!done' ? 'לא בוצע' : vm.statusList[vm.tableFilters.status] || 'הכל';
    }

    selectedPinnedFilterString () {
      return vm.tableFilters.pinned === false ? 'לא מוצמד' : vm.tableFilters.pinned ? 'מוצמד' : 'הכל';
    }
  }

  return new TaskListCtrl();
}
