let service;
let request;

class templateFieldsService {
  constructor (_request) {
    service = this;
    request = _request;
    service.templates = {};
    service.projects = {};
  }

  getTemplateFields (templateId) {
    if (service.templates[templateId]) {
      return Promise.resolve(service.templates[templateId]);
    }

    return request('/template/' + templateId + '/fields')
      .then((res) => {
        service.templates[templateId] = _.get(res, 'data.0');
        return service.templates[templateId];
      });
  }

  getTemplateFieldsByProject (projectId) {
    const templateId = service.projects[projectId];
    if (templateId) {
      const template = service.templates[templateId];
      if (template) {
        return Promise.resolve(template);
      }
    }

    return request('/template/project/' + projectId + '/fields')
      .then((res) => {
        const templateId = _.get(res, 'data.1.id');
        if (!templateId) {
          return;
        }
        service.templates[templateId] = _.get(res, 'data.0');
        service.storeProjectTemplatePointer(projectId, templateId);
        return service.templates[templateId];
      });
  }

  storeProjectTemplatePointer (projectId, templateId) {
    service.projects[projectId] = templateId;
  }

  removeProjectTemplatePointer (projectId) {
    delete service.projects[projectId];
  }
}

templateFieldsService.$inject = ['request'];

const templateFieldsModule = angular.module('templateFields', [])
  .service('templateFields', templateFieldsService)
  .name;

export default templateFieldsModule;
