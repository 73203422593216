
import bidEdit from './bid-edit/bid-edit';
import bidShow from './bid-show/bid-show';
import bidService from './bid.service';

const bidModule = angular.module('bid', [
  bidEdit,
  bidShow,
  bidService
])
  .name;

export default bidModule;
