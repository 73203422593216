import profileComponent from './profile.component';
import profileEdit from './profile-edit/profile-edit';

const profileModule = angular.module('profile', [
  profileEdit
])
  .component('profile', profileComponent)
  .name;

export default profileModule;
