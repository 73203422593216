import helpers from './helpers';

export default ['$stateProvider', ($stateProvider) => {
  $stateProvider
    .state('login', {
      url: '/login?email?next',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.checkLogin]
      },
      component: 'login',
      params: {
        noRedirect: true
      },
      data: { rootScopeData: { showHeader: false, showNavigation: false, showFooter: false } }
    })
    .state('logout', {
      url: '/logout',
      params: {
        noRedirect: true
      },
      resolve: {
        logout: ['$state', 'loginService', ($state, loginService) => {
          loginService.logout()
            .then((resLogout) => {
              $state.transitionTo('login');
            });
        }]
      },
      data: { rootScopeData: { showHeader: false, showNavigation: false, showFooter: false } }
    })
    .state('restorePassword', {
      url: '/restore',
      component: 'restorePassword',
      params: {
        noRedirect: true
      },
      data: { rootScopeData: { showHeader: false, showNavigation: false, showFooter: false } }
    })
    .state('newPassword', {
      url: '/new-password?token',
      component: 'newPassword',
      params: {
        noRedirect: true
      },
      data: { rootScopeData: { showHeader: false, showNavigation: false, showFooter: false } }
    })
    .state('signUp', {
      url: '/sign-up',
      component: 'signUp',
      params: {
        noRedirect: true
      },
      data: { rootScopeData: { showHeader: false, showNavigation: false, showFooter: false } }
    })
    .state('signUpLead', {
      url: '/sign-up-lead',
      component: 'signUpLead',
      params: {
        noRedirect: true
      },
      data: { rootScopeData: { showHeader: false, showNavigation: false, showFooter: false } }
    })
    .state('dashboard', {
      url: '/dashboard',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth]
      },
      component: 'dashboard',
      redirectTo: 'project.projectList'
    })
    .state('bidEdit', {
      url: '/bid/edit/:id?:projectId?:customerId?',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth]
      },
      component: 'bidEdit'
    })
    .state('search', {
      url: '/search/:type?query',
      params: {
        index: undefined
      },
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth]
      },
      component: 'advancedSearchResults'
    });
}];
