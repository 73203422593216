import template from './msg-viewer.html';
import controller from './msg-viewer.controller';
import './msg-viewer.scss';

const msgViewerComponent = {
  bindings: {
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', '$sce', 'request'];

export default msgViewerComponent;
