let service;
let request;
let fileRequest;
let templateFields;
let permissions;

const projectStatusColors = {
  הצעה: '#e74c3c',
  לבדיקה: '#3498db',
  בליווי: '#9b59b6',
  הושלם: '#5cb85c',
  מבוטל: '#888',
  מושהה: '#ad8d43',
  default: '#ff9500'
};

class projectService {
  constructor (_request, _fileRequest, _templateFields, _permissions) {
    service = this;
    request = _request;
    fileRequest = _fileRequest;
    templateFields = _templateFields;
    permissions = _permissions;
  }

  getProjectList (taskCounter, args = {}) {
    return request('/project/?' + (taskCounter ? 'taskCounter=true&' : '') + jQuery.param(args))
      .then((res) => res.data.data);
  }

  getProjectPaymentsReportList (args = {}) {
    permissions.require('view', 'projectPayments');
    return request('/project/payments-report?' + jQuery.param(args))
      .then((res) => res.data.data);
  }

  getProjectTransactionsReportList (args = {}) {
    permissions.require('view', 'projectPayments');
    return request('/project/transactions-report?' + jQuery.param(args))
      .then((res) => res.data.data);
  }

  getProject (projectId, onlyBasicDetails) {
    return request('/project/' + projectId + (onlyBasicDetails ? '?onlyBasicDetails' : ''))
      .then((res) => res.data.data)
      .catch((error) => {
        console.error('GetProject failed', error);
        return Promise.reject(error);
      });
  }

  getProjectSiblings (type, projectId, query, index) {
    switch (type) {
      case 'projects':
        return request(`/project/siblings/${projectId}?${jQuery.param({ query, index, type: 'projects' })}`)
          .then((res) => res.data.data)
          .catch((error) => {
            console.error('GetProjectSiblings failed', error);
            return Promise.reject(error);
          });
      /* case 'tasks':
        return request(`/task/siblings/${projectId}?${jQuery.param({ query, index })}`)
          .then((res) => res.data.data)
          .catch((error) => {
            console.error('GetProjectSiblings failed', error);
            return Promise.reject(error);
          }); */
      case 'report':
        return request(`/project/siblings/${projectId}?${jQuery.param({ query, index, type: 'report' })}`)
          .then((res) => res.data.data)
          .catch((error) => {
            console.error('GetProjectSiblings failed', error);
            return Promise.reject(error);
          });
    }
  }

  saveProject (projectId, projectObject, customerObject) {
    const requestObject = {
      project: projectObject
    };
    if (Object.values(customerObject || {}).length) {
      requestObject.customer = customerObject;
    }

    templateFields.removeProjectTemplatePointer(projectId);
    return request('/project/' + (projectId || ''), requestObject, (projectId ? 'PUT' : 'POST'))
      .then((res) => res.data.data);
  }

  duplicateProject (projectId) {
    if (!projectId) {
      return Promise.reject(new Error('projectId missing'));
    }
    return request('/project/' + projectId + '/duplicate', null, 'POST')
      .then((res) => res.data.data);
  }

  saveProjectAdditionalDetails (projectId, additionalDetails) {
    if (!projectId) {
      return Promise.reject(new Error('projectId is missing'));
    }

    return request(`/project/${projectId}/additional-details`, { additionalDetails }, 'PUT')
      .then((res) => res.data.data);
  }

  saveProjectMetadata (projectId, metadata) {
    if (!projectId) {
      return Promise.reject(new Error('projectId is missing'));
    }

    return request(`/project/${projectId}/metadata`, { metadata }, 'PUT')
      .then((res) => res.data.data);
  }

  deleteProject (projectId) {
    return request('/project/' + projectId || '', null, 'DELETE')
      .then((res) => res.data.data);
  }

  getProjectListCounts (agentId, dateFilter) {
    return request('/project/counts?' + jQuery.param({ agentId, dateFilter }))
      .then((res) => res.data.data);
  }

  getCloseProjects (projectId) {
    if (!projectId) {
      return Promise.reject(new Error('projectId is missing'));
    }
    return request('/project/' + projectId + '/close-projects')
      .then((res) => res.data.data);
  }

  getProjectTransactionList (projectId) {
    permissions.require('view', 'projectPayments');
    return request('/project/' + projectId + '/transaction')
      .then((res) => res.data.data);
  }

  saveProjectPrices (projectId, priceList) {
    permissions.require('edit', 'projectPayments');
    return request('/project/' + projectId + '/prices/', { prices: priceList }, 'POST')
      .then((res) => res.data.data);
  }

  saveProjectTransaction (projectId, transactionId, transactionObject) {
    permissions.require('edit', 'projectPayments');
    return request('/project/' + projectId + '/transaction/' + (transactionId || ''), { transaction: transactionObject }, (transactionId ? 'PUT' : 'POST'))
      .then((res) => res.data.data);
  }

  deleteProjectTransaction (projectId, transactionId) {
    permissions.require('view', 'projectPayments');
    return request('/project/' + projectId + '/transaction/' + transactionId, null, 'DELETE')
      .then((res) => res.data.data);
  }

  createInvoice (projectId, transactionId) {
    permissions.require('view', 'projectPayments');
    return request('/project/' + projectId + '/transaction/' + transactionId + '/create-invoice', null, 'POST');
  }

  async getProjectAlerts (refresh) {
    if (service.projectAlertList && !refresh) {
      return service.projectAlertList;
    }

    const { data: { data } } = await request('/project/project-alerts/', {}, 'get', { ignoreLoadingBar: true });
    service.projectAlertList = data;
    return service.projectAlertList;
  }

  saveProjectFile (projectId, name, file, title, source) {
    return fileRequest(`/project/${projectId}/file`, { name, file, title, source }, 'PUT')
      .then((res) => res.data.data);
  }

  deleteProjectFile (projectId, fileId) {
    return request(`/project/${projectId}/file/${fileId}`, {}, 'DELETE')
      .then((res) => res.data.data);
  }

  renameProjectFileTitle (projectId, fileId, newTitle) {
    return request(`/project/${projectId}/file/title-rename`, { fileId, title: newTitle }, 'PUT')
      .then((res) => res.data.data);
  }

  validateExcel (file) {
    return fileRequest('/project/validate-excel', { file }, 'POST')
      .then((res) => res.data.data);
  }

  importExcel (file) {
    return fileRequest('/project/import-excel', { file }, 'POST')
      .then((res) => res.data.data);
  }

  getProjectStatusColor (status) {
    if (!status) { return ''; }
    return projectStatusColors[status] || projectStatusColors.default;
  }

  addTag (projectId, tags) {
    return request('/project/' + projectId + '/add-tag', { tags }, 'POST')
      .then((res) => res.data.data);
  }

  removeTag (projectId, tags) {
    return request('/project/' + projectId + '/remove-tag', { tags }, 'POST')
      .then((res) => res.data.data);
  }
}

projectService.$inject = ['request', 'fileRequest', 'templateFields', 'permissions'];

const projectServiceModule = angular.module('projectService', [])
  .service('projectService', projectService)
  .name;

export default projectServiceModule;
