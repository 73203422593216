import dayjs from 'dayjs';

export default function ($scope, $rootScope, $state, $uibModal, notify, ownerService, userService) {
  let vm;
  const uiSref = 'admin.userShow({id: row.id})';

  class OwnerShowCtrl {
    constructor () {
      vm = this;
      vm.progress = true;
      vm.orderBy = '-createdAt';
      vm.$state = $state;

      vm.ownerFields = [
        [
          { name: 'createdAt', type: 'date', label: 'תאריך הצטרפות' },
          { name: 'name', label: 'שם ארגון' },
          { name: 'phone', label: 'טלפון' },
          { name: 'address', label: 'כתובת' },
          { name: 'city', label: 'עיר' },
          { name: 'usersCount', label: 'משתמשים' }
        ]
      ];

      vm.tableHeadList = [
        { name: 'createdAt', type: 'date', label: 'תאריך הצטרפות', uiSref, sortable: true },
        { name: 'image', type: 'customFunction', customFunction: vm.getUserProfileImage, uiSref },
        { name: 'firstName', label: 'שם', type: 'customFunction', customFunction: vm.getUserName, uiSref, sortable: true },
        { name: 'email', label: 'דוא"ל', type: 'customFunction', customFunction: vm.getEmail, elementClass: 'email' },
        { name: 'phone', label: 'טלפון', type: 'customFunction', customFunction: vm.getPhone, elementClass: 'phone' },
        { name: 'permission', type: 'customFunction', customFunction: vm.permissionLabel, label: 'תפקיד', uiSref, sortable: true },
        { name: 'active', type: 'customFunction', customFunction: vm.getUserActive, label: 'פעיל', uiSref, sortable: true, tdClass: 'active-td' },
        { name: 'lastLogin', label: 'התחבר לאחרונה', type: 'date', uiSref, sortable: true }
      ];

      vm.permissionList = {
        admin: 'משתמש על',
        manager: 'מנהל',
        user: 'נציג'
      };
    }

    $onInit () {
      vm.ownerObject = {};

      vm.ownerId = $state.params.id;
      if (!vm.ownerId) {
        return;
      }
      if (vm.ownerId) {
        vm.getOwner(vm.ownerId)
          .then(() => {
            vm.progress = false;
          });
      } else {
        vm.progress = false;
      }
      $scope.$on('ownerSaved', () => {
        vm.getOwner();
      });
    }

    getOwner () {
      vm.getOwnerProgress = true;
      const includeUsers = true;
      return ownerService.getOwner(vm.ownerId, includeUsers)
        .then((owner) => {
          if (!owner) {
            $state.go('admin.ownerList');
            return;
          }
          Object.keys(owner).forEach((field) => {
            vm.ownerObject[field] = owner[field];
          });
          vm.ownerObject.usersCount = owner.users.length;
          if (vm.ownerObject.phone) {
            vm.ownerObject.phone = vm.localizePhone(vm.ownerObject.phone);
          }
          vm.ownerObject.users.forEach((user) => {
            if (user.phone) {
              user.phone = vm.localizePhone(user.phone);
            }
          });

          return vm.ownerObject;
        })
        .finally(() => {
          vm.getOwnerProgress = false;
        });
    }

    localizePhone (phone) {
      if (phone) {
        return phone.replace(/^\+972/, '0');
      }
    }

    getUserProfileImage (row) {
      return `<img class="img-circle" src="${userService.getUserProfileImage(row)}" />`;
    }

    editOwnerForm () {
      $uibModal.open({
        component: 'ownerEdit',
        resolve: {
          owner: () => vm.ownerObject,
          ownerId: () => vm.ownerObject.id
        },
        windowClass: 'owner-edit-modal mobile-modal',
        size: 'lg'
      });
    }

    getUserName (row) {
      return `${row.firstName || ''} ${row.lastName || ''} `;
    }

    getEmail (row) {
      if (!row.email) {
        return '';
      }
      return `<a href="mailto:${row.email}">${row.email}</a>`;
    }

    getPhone (row) {
      if (!row.phone) {
        return '';
      }
      return `<a href="tel:${row.phone}">${row.phone}</a>`;
    }

    permissionLabel (row) {
      return vm.permissionList[row.permission] || row.permission;
    }

    getUserActive (row) {
      return `<span class="badge ${row.active ? 'badge-success' : 'badge-off'}">${row.active ? 'פעיל' : 'לא פעיל'}</span>`;
    }

    isAccountEnd () {
      return dayjs(vm.ownerObject.accountEnd).isBefore(dayjs().add(5, 'days').startOf('day')) &&
        !_.get(vm.ownerObject, 'ownerCreditCardTokens.length');
    }
  }

  return new OwnerShowCtrl();
}
