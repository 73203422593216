import template from './events.html';
import controller from './events.controller';
import './events.scss';

const eventsComponent = {
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', 'eventService', 'request'];

export default eventsComponent;
