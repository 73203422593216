/* global localStorage */

export default function ($scope, $rootScope, $timeout, $sce) {
  let vm;

  class SortingTableController {
    constructor () {
      vm = this;
      vm.name = 'sortingTable';
    }

    $onInit () {
      if (vm.limit) {
        vm.listLimit = Number(vm.limit);
      }
      if (vm.tableName) {
        const storedOrderBy = localStorage.getItem(vm.tableName + 'OrderBy');
        if (storedOrderBy && vm.forceOrder !== true) {
          $timeout(() => {
            $scope.$apply(() => {
              vm.orderBy = storedOrderBy;
            });
          });
        }
      }
    }

    selectTh (th) {
      vm.doOrder(th.name);
    }

    doOrder (orderBy) {
      if (vm.orderBy === orderBy) {
        vm.orderBy = '-' + vm.orderBy;
      } else if (orderBy === 'createdAt' && vm.orderBy !== '-createdAt') {
        vm.orderBy = '-createdAt';
      } else {
        vm.orderBy = orderBy;
      }

      if (vm.tableName) {
        localStorage.setItem(vm.tableName + 'OrderBy', vm.orderBy);
      }
    }

    getField (key, value) {
      return _.get(key, value);
    }

    tableHeadListLength () {
      return Object.keys(vm.tableHeadList).length;
    }

    getHtml (html) {
      return $sce.trustAsHtml(String(html));
    }
  }

  return new SortingTableController();
}
