export default function ($scope, $rootScope, $state) {
  let vm;

  class ReportsCtrl {
    constructor () {
      vm = this;
      vm.name = 'reports';
    }

    $onInit () {
      vm.agentList = _.get($rootScope, 'owner.users') || [];
    }
  }

  return new ReportsCtrl();
}
