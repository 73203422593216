import isUrl from 'is-url';

export default function ($scope, $rootScope, $timeout, paymentsService, utils) {
  let vm;

  class ChargeCtrl {
    constructor () {
      vm = this;
      vm.acceptTerms = true;
      vm.modules = {
        basicModule: {
          selected: true
        }
      };
    }

    $onInit () {
      vm.price = $rootScope.owner.price;
      vm.priceDescription = $rootScope.owner.priceDescription || 'מנוי בסיס חודשי';

      vm.form = {
        selectedModules: [],
        totalAmount: 0,
        directDebit: true,
        saveCard: true,
        invoiceName: $rootScope.user.owner.name
      };
      vm.toggleModule();
    }

    toggleModule () {
      vm.form.selectedModules = [];
      vm.form.totalAmount = 0;
      if (vm.basicModuleSelected) {
        vm.form.selectedModules.push('basicModule');
        vm.form.totalAmount = vm.price;
      }
    }

    toggleDirectDebit () {
      if (vm.form.directDebit) {
        vm.form.saveCard = true;
      }
    }

    getOwnerChargePage () {
      if (vm.chargeProgress) {
        return;
      }
      vm.chargeProgress = true;
      return paymentsService.getOwnerChargeUrl(vm.form.selectedModules.join(','), vm.form.invoiceName, vm.form.directDebit, vm.form.saveCard)
        .then((res) => {
          if (isUrl(res)) {
            $timeout(() => {
              window.open(res, '_self');
            });
          }
        })
        .finally(() => {
          vm.chargeProgress = false;
        });
    }
  }

  return new ChargeCtrl();
}
