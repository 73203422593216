import fieldsSchemaExample from '../project-details-fields-temp';

export default function ($scope, $rootScope, $state, $filter, $uibModal, $timeout, projectService) {
  let vm;

  const operators = {
    '=' (value, parentValue) {
      return parentValue == value;
    },
    '!=' (value, parentValue) {
      return parentValue != value;
    },
    '>' (value, parentValue) {
      return parentValue > value;
    },
    '<' (value, parentValue) {
      return !parentValue || parentValue < value;
    },
    '!' (value, parentValue) {
      return !parentValue;
    }
  };

  class ProjectDetailsShowCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.projectFields = {};
      vm.projectFields = angular.copy(vm.projectShowCtrl.projectFields);

      vm.buildFieldsSchema();
      vm.projectId = vm.projectShowCtrl.projectFields.id;

      $scope.$on('projectLoaded', () => {
        vm.projectFields = $scope.project = angular.copy(vm.projectShowCtrl.projectFields);
        vm.buildFieldsSchema();
      });
      $scope.$on('projectSaved', () => {
        vm.projectFields = $scope.project = angular.copy(vm.projectShowCtrl.projectFields);
        vm.buildFieldsSchema();
      });
    }

    buildFieldsSchema () {
      vm.fieldsSchema = [];
      const fieldsSchema = _.get($rootScope, 'owner.crmSettings.projectFieldsSchema') || fieldsSchemaExample;
      Object.values(angular.copy(fieldsSchema)).forEach((value) => {
        vm.fieldsSchema.push(...vm.flatFields(value));
      });
    }

    showPlain (field) {
      return field.type !== 'image' && field.type !== 'richtext';
    }

    projectDetailsValue (field) {
      const value = vm.projectFields.additionalDetails[field.name];
      if (!(vm.projectFields || {}).hasOwnProperty(field.name) && !value && field.defaultValue) {
        return field.defaultValue;
      }

      if (angular.isArray(value)) {
        if (value.length) {
          return value.join(',   ');
        }
        return '-';
      }
      if (field.type === 'date') {
        return $filter('date')(value, 'dd/MM/yyyy') || '-';
      }
      if (field.type === 'checkbox') {
        return value === false ? '✗' : value === true ? '✓' : '-';
      }
      return value || '-';
    }

    shouldShowField (field, parentField) {
      if (angular.isUndefined(_.get(field, 'if.operator'))) {
        return true;
      }

      return operators[field.if.operator](field.if.value, vm.projectFields.additionalDetails[parentField.name]);
    }

    flatFields (field) {
      if (!field.fields) {
        return [field];
      }
      let subFields = angular.copy(field.fields);
      subFields = subFields.filter(subField => vm.shouldShowField(subField, field));
      subFields = subFields.map(subField => vm.flatFields(subField)).flat();
      delete field.fields;
      return [field, ...subFields];
    }
  }

  return new ProjectDetailsShowCtrl();
}
