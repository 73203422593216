function countsService ($rootScope, request) {
  return {
    loadCounts (...params) {
      return request('/helpers/counts' + (params.length ? '?params=' + params.join(',') : ''))
        .then((res) => {
          const counts = _.get(res, 'data.data');
          $rootScope.$broadcast('countsChanged', counts);

          Object.keys(counts).forEach((param) => {
            _.set($rootScope, 'counts.' + param, counts[param]);
          });
        });
    }
  };
}

countsService.$inject = ['$rootScope', 'request'];

const countsServiceModule = angular.module('counts', [])
  .factory('counts', countsService)
  .name;

export default countsServiceModule;
