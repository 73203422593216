// import { json2excel, excel2json } from 'js2excel';

export default function ($scope, $rootScope, $timeout, nadlanService) {
  let vm;

  class ShamautMachriaCtrl {
    constructor () {
      vm = this;

      vm.orderBy = '-decisionDateFormatted';
      vm.tableHeadList = [
        { name: 'agent', label: 'שמאי מכריע', sortable: true },
        { name: 'gushList', label: 'גוש', sortable: true },
        { name: 'helkaList', label: 'חלקה', sortable: true },
        { name: 'city', label: 'יישוב', sortable: true },
        { name: 'decisionDateFormatted', label: 'תאריך הכרעה', type: 'date', sortable: true },
        { name: 'publishDateFormatted', label: 'תאריך פרסום', type: 'date', sortable: true },
        { name: 'description', label: 'הערה', type: 'compile', customFunction: vm.getDescription },
        { name: 'pdfFile', type: 'customFunction', customFunction: vm.pdfUrl, clickFunction: vm.pdfLinkClick }
      ];
    }

    $onInit () {
      vm.getShamautMachriaData(vm.gush);

      $scope.$watch('vm.gush', (newVal, oldVal) => {
        if (newVal && (newVal !== oldVal)) {
          vm.getShamautMachriaData(vm.gush);
        }
      });
    }

    getShamautMachriaData (gush) {
      if (!gush) {
        return;
      }
      vm.list = [];
      vm.getListProgress = true;
      nadlanService.getShamautMachriaData(gush)
        .then((data) => {
          data.forEach((item) => {
            item.gushList = vm.sortAndJoinArray(item.gushList);
            item.helkaList = vm.sortAndJoinArray(item.helkaList);
            item.pdfFile = (item.pdfFile || '').replace(/^http:/, 'https:');
          });

          vm.list = data;
        })
        .catch(() => {
          vm.getListProgress = false;
        })
        .finally(() => {
          vm.getListProgress = false;
        });
    }

    pdfUrl (row) {
      return `<a class="btn btn-default" href="${row.pdfFile}" target="_blank"><i class="fa fa-file-pdf-o"></i> הצגת הקובץ</a>`;
    }

    pdfLinkClick (event, row) {
      window.open(row.pdfFile, '_blank');
    }

    getDescription (row) {
      return `<span ng-init="popoverOpened=false" ng-mouseover="popoverOpened=true" ng-mouseleave="popoverOpened=false"><i class="fa fa-info-circle" uib-popover="${row.description || 'אין הערה'}" popover-trigger="none" popover-is-open="popoverOpened"></i></span>`;
    }

    sortAndJoinArray (array) {
      return (array || []).sort((a, b) => a - b).join(', ');
    }
  }

  return new ShamautMachriaCtrl();
}
