export default function ($scope, $rootScope, ticketService, request) {
  let vm;

  class UnreadPostsController {
    constructor () {
      vm = this;
      vm.name = 'unreadPosts';
      vm.today = new Date().getDate();
      vm.unreadPostsList = [];
    }

    $onInit () {
      $scope.$watch('$root.counts.unreadPosts', (newValue, oldValue) => {
        if (newValue !== oldValue && newValue > 0) {
          vm.getUnreadPosts(true);
        } else {
          vm.unreadPostsList = [];
        }
      });
      vm.getUnreadPosts();
    }

    getUnreadPosts (refresh) {
      ticketService.getUnreadPostsList(refresh)
        .then((list) => {
          vm.unreadPostsList = list;
        });
    }
  }

  return new UnreadPostsController();
}
