const govMapToken = 'd66319bd-9c00-4418-98ed-f9b39233ad7a';

let service;

class govmapService {
  constructor () {
    service = this;
    window.govmap.setToken(govMapToken);
  }

  setToken () {
    if (!_.get(window.govmap, 'authData.api_token')) {
      console.log('govmap api_token missing');
      window.govmap.setToken(govMapToken);
    }
  }

  async getGushHelka (address) {
    service.setToken();
    const params = {
      type: window.govmap.locateType.lotParcelToAddress,
      address
    };

    const { data } = await window.govmap.searchAndLocate(params);
    return [
      _.get(data, '[0].Values[0]') || '',
      _.get(data, '[0].Values[1]') || '',
      _.get(data, '[0].Values[2]') || ''
    ];
  }

  async getAddressByGushHelka (gush, helka) {
    service.setToken();
    const params = {
      type: window.govmap.locateType.addressToLotParcel,
      lot: gush,
      parcel: helka
    };
    const { data } = await window.govmap.searchAndLocate(params);

    return {
      city: _.get(data, '[0].Values[0]') || '',
      street: _.get(data, '[0].Values[1]') || '',
      house: _.get(data, '[0].Values[2]') || ''
    };
  }

  async getCoordinates (addressQuery) {
    service.setToken();
    const { data } = await window.govmap.geocode({ keyword: addressQuery, type: window.govmap.geocodeType.AccuracyOnly });
    return {
      x: _.get(data, '0.X'),
      y: _.get(data, '0.Y')
    };
  }

  async searchAddress (addressQuery) {
    service.setToken();
    const response = window.govmap.geocode({ keyword: addressQuery, type: window.govmap.geocodeType.FullResult });
    return _.get(response, 'data');
  }

  async createMap (elementId, mapParams = {}, zoomMapParams = {}) {
    const defaultParams = {
      token: govMapToken,
      layers: ['PARCEL_HOKS', 'KSHTANN_ASSETS'],
      showXY: false,
      identifyOnClick: true,
      isEmbeddedToggle: false,
      background: '',
      layersMode: 4,
      zoomButtons: false
    };
    if (!_.isNil(zoomMapParams)) {
      defaultParams.center = {
        x: zoomMapParams.x, y: zoomMapParams.y
      };
      defaultParams.level = 9;
      defaultParams.setMapMarker = true;
    }
    Object.keys(defaultParams).forEach((param) => {
      if (_.get(mapParams, param)) {
        defaultParams[param] = mapParams[param];
      }
    });

    window.govmap.createMap(elementId, defaultParams);
  }

  async zoomMap (zoomMapParams) {
    if (!zoomMapParams.x || !zoomMapParams.y) {
      return;
    }

    const defaultZoomParams = {
      x: null,
      y: null,
      level: 9,
      marker: true
    };

    Object.keys(defaultZoomParams).forEach((param) => {
      if (zoomMapParams[param]) {
        defaultZoomParams[param] = zoomMapParams[param];
      }
    });

    window.govmap.zoomToXY(defaultZoomParams);
  }

  clearMapMarker () {
    return window.govmap.clearMapMarker();
  }

  async createPoints (locationList) {
    const wkts = [];
    const names = [];
    const tooltips = [];
    const headers = [];
    locationList.forEach((location) => {
      wkts.push(`POINT(${location.x} ${location.y})`);
      names.push(location.name || '');
      tooltips.push(location.name || '');
      headers.push(location.name || '');
    });
    const data = {
      wkts,
      names: names,
      geometryType: window.govmap.drawType.Point,
      defaultSymbol: {
        url: 'https://old.amend.co.il/assets/icons/map-marker-red.png',
        width: 27,
        height: 27
      },
      symbols: [],
      clearExisting: false,
      data: {
        tooltips,
        headers
      }
    };

    window.govmap.displayGeometries(data);
  }
}

// govmapService.$inject = [''];

const govmapServiceModule = angular.module('govmapService', [])
  .service('govmapService', govmapService)
  .name;

export default govmapServiceModule;
