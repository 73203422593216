import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import he from 'dayjs/locale/he';

dayjs.extend(relativeTime);
dayjs.locale(he);

const timeagoModule = angular.module('timeago', [])
  .filter('timeago', function () {
    return function (date, units) {
      const today = dayjs().startOf('day');
      if (!date) { return ''; }
      if (units === 'days') {
        if (today.isSame(date, 'day')) {
          return 'היום';
        } else if (dayjs().startOf('day').subtract(1, 'day').isSame(date, 'day')) {
          return 'אתמול';
        } else if (dayjs().startOf('day').add(1, 'day').isSame(date, 'day')) {
          return 'מחר';
        }
      }
      if (units === 'months' && today.isSame(date, 'month')) {
        return 'החודש';
      }
      if (units === 'days' || units === 'months') {
        return dayjs(date).startOf('day').from(today);
      }
      return dayjs(date).fromNow();
    };
  })
  .name;

export default timeagoModule;
