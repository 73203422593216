export default function ($rootScope, gdrive) {
  let vm;

  class ProjectImagesSelectCtrl {
    constructor () {
      vm = this;
      vm.chosenImages = [];
      vm.changed = false;
    }

    $onInit () {
      Object.assign(vm, vm.resolve);
      vm.projectId = vm.projectShowCtrl.project.id;
      vm.images = vm.images || [];
      vm.images.forEach((imageId) => {
        vm.chosenImages.push(imageId);
      });
      if (!_.get($rootScope, 'gdriveImagesList.' + vm.projectId)) {
        vm.loading = true;
        gdrive.getAllImages(vm.projectId)
          .then((response) => {
            _.set($rootScope, 'gdriveImagesList.' + vm.projectId, response.data.data);
            vm.removeNonExistingImages();
          })
          .finally(() => {
            vm.loading = false;
          });
      } else {
        vm.removeNonExistingImages();
      }
    }

    removeNonExistingImages () {
      vm.chosenImages = vm.chosenImages.filter((imageId) => $rootScope.gdriveImagesList[vm.projectId].includes(imageId));
    }

    reloadImages () {
      if (vm.loading) return;
      vm.loading = true;
      _.set($rootScope, 'gdriveImagesList.' + vm.projectId, []);
      gdrive.getAllImages(vm.projectId)
        .then((response) => {
          _.set($rootScope, 'gdriveImagesList.' + vm.projectId, response.data.data);
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    clickedImage (imageId) {
      vm.changed = true;
      if (vm.limit === 1 && vm.chosenImages[0] !== imageId) { // special case when limit==1
        vm.chosenImages = [imageId];
      } else if (vm.chosenImages.includes(imageId)) {
        vm.chosenImages.splice(vm.chosenImages.indexOf(imageId), 1);
      } else if (vm.chosenImages.length < vm.limit) {
        vm.chosenImages.push(imageId);
      }
    }

    save () {
      if (!vm.changed) {
        return vm.modalInstance.dismiss();
      }
      vm.modalInstance.close(vm.chosenImages);
    }

    cancel () {
      vm.modalInstance.dismiss();
    }
  }

  return new ProjectImagesSelectCtrl();
}
