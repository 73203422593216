import closeProjects from './close-projects/close-projects';
import projectAddressEdit from './project-address-edit/project-address-edit';
import projectBoard from './project-board/project-board';
import projectCalendar from './project-calendar/project-calendar';
import projectEdit from './project-edit/project-edit';
import projectList from './project-list/project-list';
import projectListMap from './project-list-map/project-list-map';
import projectShow from './project-show/project-show';
import projectService from './project.service';

const projectModule = angular.module('project', [
  closeProjects,
  projectAddressEdit,
  projectBoard,
  projectCalendar,
  projectEdit,
  projectList,
  projectListMap,
  projectShow,
  projectService
])
  .name;

export default projectModule;
