// let service;
let request;

class callService {
  constructor (_request) {
    // service = this;
    request = _request;
  }

  getCallList (args = {}) {
    return request('/call/?includeProject=true&includeCustomer=true&' + jQuery.param(args))
      .then((res) => res.data.data);
  }

  getProjectCallList (projectId) {
    return request('/project/' + projectId + '/call')
      .then((res) => res.data.data);
  }

  saveCall (projectId, callId, callObject) {
    return request('/project/' + projectId + '/call/' + (callId || ''), { call: callObject }, (callId ? 'PUT' : 'POST'))
      .then((res) => res.data.data);
  }

  deleteCall (projectId, callId) {
    return request('/project/' + projectId + '/call/' + callId, null, 'DELETE')
      .then((res) => res.data.data);
  }
}

callService.$inject = ['request'];

const callServiceModule = angular.module('callService', [])
  .service('callService', callService)
  .name;

export default callServiceModule;
