const GLOBAL_SETTINGS = window.GLOBAL_SETTINGS || {};
const SERVER_URL = GLOBAL_SETTINGS.HOST + (GLOBAL_SETTINGS.PORT ? ':' + GLOBAL_SETTINGS.PORT : '');
window.SERVER_URL = SERVER_URL;

export default function ($http, $scope, $rootScope, $timeout) {
  // let vm;

  class AppCtrl {
    constructor () {
      // vm = this;
      $rootScope.rtl = true;
      $rootScope.hasAddress = false;

      $rootScope.isManager = function () {
        return ['manager', 'admin'].includes(_.get($rootScope, 'user.permission'));
      };

      $rootScope.isAdmin = function () {
        return _.get($rootScope, 'user.permission') === 'admin';
      };

      $rootScope.userById = function (userId) {
        return (_.get($rootScope, 'owner.users') || [])
          .find((user) => user.id === userId);
      };

      window.LogRocket && window.LogRocket.init('q0zkce/amend');
      $scope.$on('ownerSaved', () => {
        if (!$rootScope.logRocketIdentified) {
          window.LogRocket && window.LogRocket.identify($rootScope.user.id, {
            name: $rootScope.user.firstName + ' ' + $rootScope.user.lastName,
            email: $rootScope.user.email,
            organization: $rootScope.owner.name
          });
          $rootScope.logRocketIdentified = true;
        }
      });
    }
  }

  return new AppCtrl();
}
