export default [
  {
    name: 'a1',
    label: 'שדה לדוגמה',
    type: 'select',
    placeholder: '',
    class: '',
    values: [
      'אפשרות 1',
      'אפשרות 2',
      'אפשרות 3'
    ]
  },
  {
    name: 'a2',
    label: 'שדה לדוגמה 2',
    type: 'text',
    placeholder: 'משכנתא',
    class: ''
  },
  {
    name: 'a3',
    label: 'שדה לדוגמה 3',
    type: 'radio',
    placeholder: '',
    values: ['כן', 'לא']
  },
  {
    name: 'a4',
    label: 'שדה לדוגמה 4',
    type: 'textarea',
    placeholder: 'כתוב תיאור'
  }
];
