import template from './ticket-list.html';
import controller from './ticket-list.controller';
import './ticket-list.scss';

const ticketListComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$filter', 'ticketService'];

export default ticketListComponent;
