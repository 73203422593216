import template from './user-list.html';
import controller from './user-list.controller';
import './user-list.scss';

const userListComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', '$uibModal', 'request', 'utils', 'userService'];

export default userListComponent;
