export default function ($scope, $rootScope, $state, $location, $filter, $uibModal, projectService, customerService, taskService, userService, utils) {
  let vm;
  const uiSref = 'project.projectShow({id: row.id})';

  class CloseProjectsController {
    constructor () {
      vm = this;
      vm.name = 'closeProjects';

      const clickFunction = vm.closeModalBox;
      vm.tableHeadList = [
        { name: 'number', label: 'פרויקט', sortable: true, uiSref, clickFunction },
        { name: 'createdAt', label: 'תאריך יצירה', type: 'date', sortable: true, uiSref, clickFunction },
        { name: 'customer.name', label: 'לקוח', sortable: true, type: 'customFunction', customFunction: vm.getCustomer, uiSref, clickFunction },
        { name: 'street', label: 'כתובת', type: 'customFunction', customFunction: vm.getAddress, sortable: true, uiSref, clickFunction },
        { name: 'city', label: 'עיר', sortable: true, uiSref, clickFunction },
        { name: 'gush', label: 'גוש', sortable: true, uiSref, clickFunction },
        { name: 'helka', label: 'חלקה', sortable: true, uiSref, clickFunction },
        { name: 'subHelka', label: 'תת חלקה', sortable: true, uiSref, clickFunction },
        { name: 'status', label: 'סטטוס', sortable: true, uiSref, clickFunction },
        { name: 'agent', label: 'נציג אחראי', if: vm.isAgentColumnShown, type: 'compile', customFunction: vm.getAgentString, sortable: true, uiSref, clickFunction }
      ];
      vm.externalProjectsTableHeadList = [
        { name: 'createdAt', label: 'תאריך יצירה', type: 'date', sortable: true },
        { name: 'street', label: 'כתובת', type: 'customFunction', customFunction: vm.getAddress, sortable: true },
        { name: 'gush', label: 'גוש', sortable: true },
        { name: 'helka', label: 'חלקה', sortable: true },
        { name: 'subHelka', label: 'תת חלקה', sortable: true },
        { name: 'owner.name', label: 'ארגון', type: 'compile', customFunction: vm.getOwnerName }
      ];
    }

    async $onInit () {
      vm.tab = vm.resolve.tab === 'external' ? 1 : 0;
      vm.getListProgress = true;
      vm.projectId = vm.resolve.projectId;
      vm.agentList = _.get($rootScope, 'owner.users') || [];
      vm.agentColumnShown = vm.agentList.length > 1;

      const { closeProjects, closeExternalProjects } = await projectService.getCloseProjects(vm.projectId);
      vm.closeProjectsList = closeProjects;
      vm.closeExternalProjectsList = closeExternalProjects;
      vm.getListProgress = false;
    }

    closeModalBox () {
      vm.modalInstance.dismiss('cancel');
    }

    isExternalProjectEnabled () {
      return _.get($rootScope.owner, 'settings.externalProjectsSettings.show') === true;
    }

    getOwnerName (row) {
      if (row.ownerNameString) { return row.ownerNameString; }
      const ownerSettings = JSON.parse(_.get(row.owner, 'externalProjectsSettings', '{}'));
      const ownerName = ownerSettings.displayName || row.owner.name;
      const ownerPhone = ownerSettings.displayPhone || row.owner.phone;
      const ownerEmail = ownerSettings.displayEmail;
      row.ownerNameString = ownerName;
      if (ownerPhone || ownerEmail) {
        const popoverString = [
          ownerPhone ? `טלפון: <span class=phone>${ownerPhone}</span>` : '',
          ownerEmail ? `דוא&quot;ל: <span class=email>${ownerEmail}</span>` : ''
        ]
          .filter((i) => i)
          .join('<br>');
        row.ownerNameString += ` <span uib-popover-html="'${popoverString}'" popover-trigger="'mouseenter'" class="fa fa-info-circle m-r-sm"></span>`;
      }
      return row.ownerNameString;
    }

    isAgentColumnShown () {
      return vm.agentColumnShown;
    }

    getCustomer (row) {
      return (_.get(row, 'customer.name') || '');
    }

    getAddress (row) {
      return utils.joinStringArray([utils.joinStringArray([row.street, row.house], ' '), row.city], ', ');
    }

    getAgentString (row) {
      const agent = $rootScope.userById(row.agent);
      if (!agent) { return ''; }
      return `<span uib-tooltip="${agent.firstName || ''} ${agent.lastName || ''}"><img class="img-circle" src="${userService.getUserProfileImage(agent)}" /></span>`;
    }
  }

  return new CloseProjectsController();
}
