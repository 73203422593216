import helpers from './helpers';

export default ['$stateProvider', ($stateProvider) => {
  $stateProvider
    .state('payments', {
      url: '/payments',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.managerAuth]
      },
      params: {
        allowAccessForEndedAccount: true
      },
      component: 'payments'
    })
    .state('payments.charge', {
      url: '/charge',
      component: 'charge'
    })
    .state('payments.transactions', {
      url: '/history',
      component: 'paymentsTransactions'
    });
}];
