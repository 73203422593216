import template from './customer-list.html';
import controller from './customer-list.controller';
import './customer-list.scss';

const customerListComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$uibModal', 'customerService', '$stateParams'];

export default customerListComponent;
