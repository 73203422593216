let service;
let request;
let fileRequest;

class ticketService {
  constructor (_request, _fileRequest) {
    service = this;
    request = _request;
    fileRequest = _fileRequest;

    service.labels = [
      { title: 'לביצוע מיידי', background: '#34495E', color: '#fff' },
      { title: 'דחוף', background: '#FF0000', color: '#fff' },
      { title: 'באג', background: '#D9534F', color: '#fff' },
      { title: 'בטיפול', background: '#0033cc', color: '#fff' },
      { title: 'בטיפול טלפוני', background: '#D53F8C', color: '#fff' },
      { title: 'לביצוע בקרוב', background: '#D10069', color: '#fff' },
      { title: 'לביצוע בעתיד', background: '#AD8D43', color: '#fff' },
      { title: 'לא לביצוע', background: '#004E00', color: '#fff' },
      { title: 'טופל', background: '#5CB85C', color: '#fff' },
      { title: 'טופל חלקית', background: '#44AD8E', color: '#fff' },
      { title: 'ממתין למענה', background: '#F0AD4E', color: '#fff' },
      { title: 'לא מובן', background: '#7F8C8D', color: '#fff' },
      { title: 'בבירור', background: '#A295D6', color: '#fff' },
      { title: 'טעות', background: '#AD4363', color: '#fff' }
    ];

    service.publicLabels = [
      { title: 'בטיפול', background: '#0033cc', color: '#fff' },
      { title: 'לטיפול בזמן הקרוב', background: '#d10069', color: '#fff' },
      { title: 'לטיפול בעתיד', background: '#ad8d43', color: '#fff' },
      { title: 'טופל', background: '#5CB85C', color: '#fff' }
    ];
  }

  getTicketList (args = {}) {
    return request('/ticket/list?' + jQuery.param(args))
      .then((res) => res.data.data);
  }

  getTicket (ticketId) {
    return request('/ticket/' + ticketId)
      .then((res) => res.data.data)
      .catch((error) => {
        console.error('GetTicket failed', error);
        return Promise.reject(error);
      });
  }

  sendTicket (ticketObject) {
    return request('/ticket/', { ticket: ticketObject }, 'POST')
      .then((res) => res.data.data);
  }

  sendPost (ticketId, postObject) {
    return request('/ticket/' + ticketId + '/post', { post: postObject }, 'POST')
      .then((res) => res.data.data);
  }

  sendManagePost (ticketId, postObject) {
    return request('/ticket/' + ticketId + '/manage-post', { post: postObject }, 'POST')
      .then((res) => res.data.data);
  }

  sendFilePost (ticketId, name, file, title) {
    return fileRequest('/ticket/' + ticketId + '/file-post', { name, file, title }, 'POST')
      .then((res) => res.data.data);
  }

  editPost (postId, updatedContent, postType) {
    return request('/ticket/edit-post', { postId, updatedContent, postType }, 'POST')
      .then((res) => res.data.data);
  }

  markAsRead (ticketId) {
    return request('/ticket/' + ticketId + '/read', null, 'POST')
      .then((res) => res.data.data);
  }

  closeTicket (ticketId) {
    return request('/ticket/' + ticketId + '/close', null, 'POST')
      .then((res) => res.data.data);
  }

  openTicket (ticketId) {
    return request('/ticket/' + ticketId + '/open', null, 'POST')
      .then((res) => res.data.data);
  }

  addLabel (ticketId, label) {
    return request('/ticket/' + ticketId + '/add-label', { label }, 'POST')
      .then((res) => res.data.data);
  }

  removeLabel (ticketId, label) {
    return request('/ticket/' + ticketId + '/remove-label', { label }, 'POST')
      .then((res) => res.data.data);
  }

  publicLabelActions (ticketId, action, label) {
    return request('/ticket/' + ticketId + '/public-label-actions', { action }, 'POST')
      .then((res) => res.data.data);
  }

  addPublicLabel (ticketId, label) {
    return request('/ticket/' + ticketId + '/add-public-label', { label }, 'POST')
      .then((res) => res.data.data);
  }

  removePublicLabel (ticketId, label) {
    return request('/ticket/' + ticketId + '/remove-public-label', { label }, 'POST')
      .then((res) => res.data.data);
  }

  deletePublicAction (ticketId, actionId) {
    return request('/ticket/' + ticketId + '/delete-public-action/' + actionId, null, 'DELETE')
      .then((res) => res.data.data);
  }

  deleteFile (ticketId, fileId) {
    return request('/ticket/' + ticketId + '/delete-file/' + fileId, null, 'DELETE')
      .then((res) => res.data.data);
  }

  getUnreadPostsList (refresh) {
    if (service.unreadPostsList && !refresh) {
      return Promise.resolve(service.unreadPostsList);
    }

    return request('/ticket/unread/', {}, 'get', { ignoreLoadingBar: true })
      .then((res) => {
        service.unreadPostsList = res.data.data;
        return service.unreadPostsList;
      });
  }
}

ticketService.$inject = ['request', 'fileRequest'];

const ticketServiceModule = angular.module('ticketService', [])
  .service('ticketService', ticketService)
  .name;

export default ticketServiceModule;
