import template from './project-address-edit.html';
import controller from './project-address-edit.controller';
import './project-address-edit.scss';

const projectAddressEditComponent = {
  bindings: {
    modalInstance: '<?',
    resolve: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'utils', 'projectService', 'govmapService'];

export default projectAddressEditComponent;
