import admin from './admin/admin';
import bid from './bid/bid';
import customer from './customer/customer';
import dashboard from './dashboard/dashboard';
import event from './event/event';
import login from './login/login';
import nadlan from './nadlan/nadlan';
import payments from './payments/payments';
import profile from './profile/profile';
import project from './project/project';
import realEstateInformation from './real-estate-information/real-estate-information';
import reports from './reports/reports';
import settings from './settings/settings';
import task from './task/task';
import ticket from './ticket/ticket';

const componentModule = angular.module('app.components', [
  admin,
  bid,
  customer,
  dashboard,
  event,
  login,
  nadlan,
  payments,
  profile,
  project,
  realEstateInformation,
  reports,
  settings,
  task,
  ticket
])
  .name;

export default componentModule;
