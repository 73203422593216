export default function ($scope, $rootScope, $uibModal, settingsService, request, utils) {
  let vm;

  class ValuesOptionsCustomCtrl {
    constructor () {
      vm = this;

      vm.panels = [
        { isOpen: false }, { isOpen: false }, { isOpen: false }, { isOpen: false }, { isOpen: false }
      ];
    }
  }

  return new ValuesOptionsCustomCtrl();
}
