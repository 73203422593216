import 'ui-select/dist/select.min.css';
import 'angular-ui-sortable';
import 'icheck';
import 'animate.css';
import 'ng-content-editable';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'feather-webfont/dist/feather-icons.css';
import 'angular-loading-bar/src/loading-bar.css';

import uiRouter from '@uirouter/angularjs';
import uiBootstrap from 'angular-ui-bootstrap';
import uiSelect from 'ui-select';
import angularBindHtmlCompile from 'angular-bind-html-compile';
import ngFileUpload from 'ng-file-upload';
import angularSlick from 'angular-slick';
import angularLoadingBar from 'angular-loading-bar';
import angularMarked from 'angular-marked';
import 'angularjs-autogrow';
import 'dragular/dist/dragular.min.css';
import dragular from 'dragular';
import ngInfiniteScroll from 'ng-infinite-scroll';
import ngMap from 'ngmap';
import 'angular-bootstrap-contextmenu';

const vendorsModule = angular.module('app.vendors', [
  uiRouter,
  uiBootstrap, // AngularJS native directives for Bootstrap,
  'ngSanitize', // Angular-sanitize
  'angular-peity', // Peity (small) charts
  'cgNotify', // Angular notify
  'ngAnimate', // Angular animations
  'summernote', // Summernote plugin
  'bm.bsTour', // Angular bootstrap tour
  uiSelect, // AngularJS ui-select
  angularBindHtmlCompile,
  'ui.sortable', // AngularJS ui-sortable
  'ngCookies',
  'content-editable',
  ngFileUpload,
  'ngIntlTelInput',
  angularSlick,
  angularLoadingBar,
  angularMarked,
  'angularjs-autogrow',
  dragular,
  ngInfiniteScroll,
  ngMap,
  'ui.bootstrap.contextMenu'
])
  .config(['ngIntlTelInputProvider', function (ngIntlTelInputProvider) {
    ngIntlTelInputProvider.set({
      initialCountry: 'il',
      preferredCountries: ['il', 'us', 'gb'],
      excludeCountries: ['ps', 'sy', 'lb', 'ir', 'iq']
    });
  }])
  .config(['cfpLoadingBarProvider', function (cfpLoadingBarProvider) {
    cfpLoadingBarProvider.includeSpinner = false;
  }])
  .name;

angular.module('infinite-scroll').value('THROTTLE_MILLISECONDS', 500);

export default vendorsModule;
