export default function ($scope, $rootScope, $filter, ownerService, utils) {
  let vm;

  class OwnerTransactionsCtrl {
    constructor () {
      vm = this;
      vm.tableHeadList = [
        { name: 'createdAt', type: 'customFunction', customFunction: vm.getPaymentDate, label: 'תאריך ביצוע תשלום', sortable: true },
        { name: 'sum', label: 'סכום תשלום', type: 'currency', currency: '₪', sortable: true },
        { name: 'lastCardDigits', type: 'customFunction', customFunction: vm.getCardNumber, label: 'אמצעי תשלום', sortable: true },
        { name: 'type', label: 'מקור', type: 'customFunction', customFunction: vm.getPaymentType, sortable: true },
        { name: 'description', label: 'מטרת תשלום', sortable: true },
        { name: 'dealResponse', label: 'סטטוס מענה', type: 'customFunction', customFunction: vm.getPaymentStatus, sortable: true },
        { name: 'operationResponseText', label: 'תקין', sortable: true },
        { name: 'invoiceDocumentUrl', label: 'חשבונית / קבלה', type: 'customFunction', customFunction: vm.getPaymentInvoice, clickFunction: vm.downloadInvoice }
      ];
    }

    // $onInit () { }

    getTransactionsList (args) {
      return ownerService.getOwnerTransactions(vm.ownerShowCtrl.ownerObject.id, args);
    }

    getPaymentDate (row) {
      return $filter('date')(row.createdAt, 'dd/MM/yyyy HH:mm');
    }

    getCardNumber (row) {
      if (row.lastCardDigits) {
        return '**** ' + row.lastCardDigits;
      }
    }

    getPaymentType (row) {
      return row.type === 'charge' ? 'ידני' : row.type === 'debit' ? 'הו"ק' : '';
    }

    getPaymentStatus (row) {
      return row.dealResponse === '0' ? '<span class="badge badge-success">תקין</span>' : row.dealResponse;
    }

    getPaymentInvoice (row) {
      return row.invoiceDocumentUrl ? '<a target="_blank" class="btn btn-default btn-sm" href="' + row.invoiceDocumentUrl + '">הורדת חשבונית / קבלה</a>' : '';
    }

    downloadInvoice ($event, row) {
      window.open(row.invoiceDocumentUrl, '_blank');
    }
  }

  return new OwnerTransactionsCtrl();
}
