export default function ($scope, $rootScope, $uibModal, $timeout) {
  let vm;

  class HeaderController {
    constructor () {
      vm = this;
      vm.name = 'header';

      // const user = $rootScope.user || {};
      // window.announcekit = (window.announcekit || { queue: [], on (n, x) { window.announcekit.queue.push([n, x]); }, push (x) { window.announcekit.queue.push(x); } });
      // window.announcekit.push({
      //   widget: 'https://announcekit.co/widgets/v2/98JNu',
      //   selector: '#changelog-widget',
      //   lang: 'he',
      //   user: {
      //     id: user.id.substring(0, 7),
      //     email: user.email,
      //     name: user.firstName + ' ' + user.lastName
      //   },
      //   data: {
      //     userRole: user.permission,
      //     organization: user.owner.name
      //   }
      // });
      // window.announcekit.on('widget-init', function (widget) {
      //   vm.openChangelog = function () {
      //     widget.widget.open();
      //   };
      // });
      // window.announcekit.on('widget-unread', ({ widget, unread }) => {
      //   $timeout(() => {
      //     $scope.$apply(() => {
      //       $rootScope.changelogUnread = unread;
      //     });
      //   });
      // });
    }

    openSearchForm () {
      $uibModal.open({
        component: 'search',
        windowClass: 'search-modal mobile-modal',
        size: 'lg'
      });
    }
  }

  return new HeaderController();
}
