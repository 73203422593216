let request;

class nadlanService {
  constructor (_request) {
    request = _request;
  }

  getTabaList (locationX, locationY, radius = 200) {
    return new Promise((resolve, reject) => {
      if (!locationX || !locationY) {
        return reject(new Error('locationX or locationY missing'));
      }
      jQuery.ajax({
        url: 'https://ags.govmap.gov.il/Search/FindPlanByCircle',
        method: 'POST',
        contentType: 'application/json;charset=UTF-8',
        dataType: 'json',
        data: JSON.stringify({
          Radius: radius,
          Center: {
            spatialReference: { wkid: 2039 },
            x: locationX,
            y: locationY
          }
        }),
        success: (res) => {
          let resList = _.get(res, 'data.Data');
          if (!angular.isArray(resList)) {
            resList = [];
          }
          return resolve(resList);
        }
      })
        .fail((error) => {
          console.error(error);
          return reject(error);
        });
    });
  }

  getShamautMachriaData (gush) {
    return request(`/helpers/shamaut-machria?${jQuery.param({ gush })}`)
      .then((res) => res.data.data)
      .catch((error) => {
        console.log('Error: ', error);
      });
  }
}

nadlanService.$inject = ['request'];

const nadlanServiceModule = angular.module('nadlanService', [])
  .service('nadlanService', nadlanService)
  .name;

export default nadlanServiceModule;
