import userList from './user-list/user-list';
import userLog from './user-log/user-log';
import userShow from './user-show/user-show';
import userTickets from './user-tickets/user-tickets';
import userService from './user.service';

const userModule = angular.module('user', [
  userLog,
  userList,
  userShow,
  userTickets,
  userService
])
  .name;

export default userModule;
