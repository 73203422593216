import helpers from './helpers';

export default ['$stateProvider', ($stateProvider) => {
  $stateProvider
    .state('nadlan', {
      url: '/real-estate-information',
      resolve: {
        user: ['$location', '$rootScope', '$state', '$stateParams', 'loginService', 'counts', helpers.userAuth]
      },
      component: 'nadlan'
    })
    .state('nadlan.taba', {
      url: '/taba'
    })
    .state('nadlan.shamautMachria', {
      url: '/shamaut-machria'
    });
}];
