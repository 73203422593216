import template from './admin-payments-list.html';
import controller from './admin-payments-list.controller';
import './admin-payments-list.scss';

const AdminPaymentsListComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$filter', 'adminService'];

export default AdminPaymentsListComponent;
