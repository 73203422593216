import progressProjectsReport from './progress-projects-report/progress-projects-report';
import projectPaymentsReport from './project-payments-report/project-payments-report';
import projectTransactionsReport from './project-transactions-report/project-transactions-report';
import reportsComponent from './reports.component';

const reportsModule = angular.module('reports', [
  progressProjectsReport,
  projectPaymentsReport,
  projectTransactionsReport
])
  .component('reports', reportsComponent)
  .name;

export default reportsModule;
