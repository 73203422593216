import props from './props';
import timeago from './timeago';

const filtersModule = angular.module('app.filters', [
  props,
  timeago
])
  .name;

export default filtersModule;
