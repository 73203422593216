import queryString from 'querystring-es3';

// let service;
let request;

class realEstateInformationService {
  constructor (_request) {
    // service = this;
    request = _request;
  }

  query (queryObject) {
    return request('/real-estate-information/?' + queryString.stringify(queryObject))
      .then((res) => res.data.data);
  }

  getListById (idList) {
    const query = {
      id: '',
      limit: 200
    };
    idList.forEach((item, index) => {
      query.id += (index > 0 ? '|' : '') + item;
    });

    return this.query(query);
  }
}

realEstateInformationService.$inject = ['request'];

const realEstateInformationServiceModule = angular.module('realEstateInformationService', [])
  .service('realEstateInformationService', realEstateInformationService)
  .name;

export default realEstateInformationServiceModule;
