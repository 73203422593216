import template from './charge.html';
import controller from './charge.controller';
import './charge.scss';

const chargeComponent = {
  // bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', 'paymentsService', 'utils'];

export default chargeComponent;
