import template from './city.html';
import controller from './city.controller';
import './city.scss';

const cityComponent = {
  bindings: {
    model: '=',
    cityCode: '=?',
    onChange: '<?',
    inputGroup: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$http', '$timeout'];

export default cityComponent;
