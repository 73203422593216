import template from './project-map.html';
import controller from './project-map.controller';
import './project-map.scss';

const projectMapComponent = {
  bindings: {
    projectId: '@',
    addressString: '@',
    x: '@',
    y: '@',
    lat: '@',
    long: '@',
    gush: '@',
    helka: '@'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', '$uibModal', 'govmapService', 'projectService'];

export default projectMapComponent;
