import isEmail from 'validator/lib/isEmail';

export default function ($scope, $state, $location, $window, $timeout, request, utils) {
  let vm;

  const KEY_EVENT_ENTER = 13;

  class SignUpCtrl {
    constructor () {
      vm = this;

      angular.element(document.querySelector('#new-owner-form'))
        .bind('keydown keypress', (event) => {
          if (event.which === KEY_EVENT_ENTER) {
            vm.send();
            event.preventDefault();
          }
        });
    }

    $onInit () {
      vm.state = 'form';
      _.set($state, 'current.data.specialClass', 'blank');
      vm.signUpLeadFields = {};
      vm.sendProgress = false;

      $scope.$watch('vm.signUpLeadFields.email', (newVal, oldVal) => {
        if (vm.signUpLeadForm.email) {
          vm.signUpLeadForm.email.$setValidity('registeredEmail', true);
          const isValidEmail = isEmail(vm.signUpLeadFields.email || '');
          vm.signUpLeadForm.email.$setValidity('email', isValidEmail);
          jQuery('#email')[0].setCustomValidity(isValidEmail ? '' : 'דוא"ל לא תקין');
        }
      });

      let phoneField;
      $scope.$watch('vm.signUpLeadForm.phone.$viewValue', (newVal, oldVal) => {
        if (!phoneField) {
          if (!jQuery('#phone')[0]) { return; }
          phoneField = jQuery('#phone')[0];
        }
        phoneField.setCustomValidity(vm.signUpLeadFields.phone ? '' : 'מספר טלפון לא תקין');
      });
    }

    async send () {
      utils.setFormFieldsDirty(vm.signUpLeadForm);
      if (vm.sendProgress) {
        return;
      }
      if (vm.signUpLeadForm.$invalid) {
        jQuery('#sign-up-lead-form')[0].reportValidity();
        return;
      }
      let emailIsExist = false;
      await request('/sign-up/check-registered', { email: vm.signUpLeadFields.email })
        .then((result) => {
          emailIsExist = _.get(result, 'data.data');
        });

      if (emailIsExist) {
        vm.signUpLeadForm.email.$setValidity('registeredEmail', false);
        jQuery('#email')[0].setCustomValidity('כתובת הדוא"ל כבר רשומה במערכת');
        utils.notify('כתובת הדוא"ל כבר רשומה במערכת', 'alert-danger');
        return;
      }

      vm.sendProgress = true;
      return request('/sign-up/lead', {
        signUpLeadFields: vm.signUpLeadFields
      })
        .then((result) => {
          vm.state = 'successMessage';
        })
        .catch((error) => {
          let errorMessage = 'שגיאה';
          if (error.data.data === 'SequelizeUniqueConstraintError: Email is already registered.') {
            errorMessage = 'כתובת הדוא"ל כבר רשומה במערכת';
          }
          if (error.data.data === 'SequelizeValidationError: Validation error: Invalid email.') {
            errorMessage = 'דוא"ל לא תקין';
          }
          utils.notify(errorMessage, 'alert-danger');
          console.log(error);
        })
        .finally(() => {
          vm.sendProgress = false;
        });
    }
  }

  return new SignUpCtrl();
}
