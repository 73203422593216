export default {
  referrerOptions: [
  ],

  statusOptions: [
    { name: 'הצעה', default: false },
    { name: 'בהכנה', default: true },
    { name: 'בטיפול', default: true },
    { name: 'בליווי', default: false },
    { name: 'מושהה', default: true },
    { name: 'לבדיקה', default: true },
    { name: 'הושלם', default: true },
    { name: 'מבוטל', default: true }
  ],

  defaultTags: [
    { color: 'white', title: 'ממתין למסמכים', background: '#ae8e3c' },
    { color: 'white', title: 'בבדיקת הלקוח', background: '#8e44ad' },
    { color: 'white', title: 'ממתין לתשלום', background: '#ff0000' }
  ],

  transactionTypeList: {
    2: 'שיק',
    4: 'העברה בנקאית',
    3: 'אשראי',
    1: 'מזומן',
    10: 'אפליקציית תשלום'
  },

  transactionCardBrandsList: {
    0: 'לא ידוע',
    1: 'ישראכרט',
    2: 'ויזה',
    3: 'מאסטרקארד',
    4: 'אמריקן אקספרס',
    5: 'דיינרס'
  },

  transactionCardDealsList: {
    1: 'רגיל',
    2: 'תשלומים',
    3: 'קרדיט',
    4: 'חיוב נדחה',
    5: 'אחר'
  },

  transactionVatList: {
    1: 'כולל מע"מ',
    0: 'בתוספת מע"מ',
    2: 'סכום פטור ממע"מ'
  },

  transactionAppTypes: {
    1: 'Bit',
    2: 'Colu',
    3: 'Paybox',
    4: 'Pepper Pay'
  },

  transactionTypeDetails: {
    cheque: {
      chequeBankName: 'מספר בנק',
      chequeBankBranch: 'מספר סניף',
      chequeBankAccount: 'מספר חשבון',
      chequeNumber: 'מספר שיק'
    },
    transfer: {
      transferBankName: 'מספר בנק',
      transferBankBranch: 'מספר סניף',
      transferBankAccount: 'מספר חשבון'
    },
    creditCard: {
      creditNumber: 'מספר כרטיס',
      cardBrands: 'סוג כרטיס',
      cardDeals: 'סוג עסקה',
      creditPayments: 'תשלומים'
    },
    paymentApp: {
      paymentAppAppType: 'אפליקציה',
      paymentAppAccountId: 'חשבון משלם',
      paymentAppTransactionId: 'מס\' עסקה'
    }
  }
};
