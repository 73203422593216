export default function ($rootScope, utils, taskService, counts) {
  let vm;

  class TaskPinCtrl {
    constructor () {
      vm = this;
      vm.name = 'taskPin';
    }

    // $onInit () { }

    confirmTogglePinned () {
      if (!vm.confirm) {
        return vm.toggleTaskPinned();
      }

      let title = `האם ${vm.task.pinned ? 'לבטל הצמדה של משימה' : 'להצמיד משימה'}`;
      if (vm.task.assignUser !== $rootScope.user.id) {
        title += vm.getAgentName(vm.task.assignUser);
      }
      title += '?';

      const modalParams = {
        windowClass: 'hmodal-danger',
        title,
        buttons: [{
          label: 'לא',
          btnClass: 'btn-default',
          onClick ($uibModalInstance) {
            $uibModalInstance.dismiss('cancel');
          }
        },
        {
          label: vm.task.pinned ? 'בטל הצמדה' : 'הצמד משימה',
          btnClass: 'btn-primary',
          onClick ($uibModalInstance) {
            vm.toggleTaskPinned();
            $uibModalInstance.close();
          }
        }]
      };

      return utils.modalAlert(modalParams);
    }

    toggleTaskPinned () {
      if (vm.savePinnedProgress || !vm.task.id) {
        return;
      }

      const taskPinned = !vm.task.pinned;
      vm.savePinnedProgress = true;
      return taskService.saveTask(vm.projectId, vm.task.id, { pinned: taskPinned })
        .then(() => {
          if (!vm.silent) {
            $rootScope.$broadcast('taskSaved');
          } else {
            counts.loadCounts('pinnedTasks');
          }
          vm.task.pinned = taskPinned;
        })
        .finally(() => {
          vm.savePinnedProgress = false;
        });
    }

    getAgentName (agentId) {
      const agent = ($rootScope.owner.users || []).find((agent) => agent.id === agentId) || {};
      let agentName = (agent.firstName || '') + (agent.lastName ? ' ' + agent.lastName : '');
      if (agentName.length) {
        agentName = ' עבור ' + agentName;
      }
      return agentName;
    }
  }

  return new TaskPinCtrl();
}
