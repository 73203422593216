import template from './customer-autocomplete.html';
import controller from './customer-autocomplete.controller';
import './customer-autocomplete.scss';

const customerAutocompleteComponent = {
  bindings: {
    searchObject: '<?',
    show: '<?',
    onCustomerClicked: '<?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$timeout', 'request'];

export default customerAutocompleteComponent;
