function draggableCtrl ($scope) {
  $scope.sortableOptions = {
    connectWith: '.connectPanels',
    handler: '.panel-body'
  };
}

const draggableCtrlModule = angular.module('draggableCtrl', [])
  .controller('draggableCtrl', ['$scope', draggableCtrl])
  .name;

export default draggableCtrlModule;
