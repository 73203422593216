export default {
  projectList: [
    { name: 'number', label: 'פרויקט', alwaysShow: true },
    { name: 'createdAt', label: 'תאריך יצירה', alwaysShow: true },
    { name: 'dueDate', label: 'תאריך יעד' },
    { name: 'customer.name', label: 'לקוח', alwaysShow: true },
    { name: 'street', label: 'כתובת', alwaysShow: true, hasAddressOnly: true },
    { name: 'city', label: 'עיר', showByDefault: true, hasAddressOnly: true },
    { name: 'gush', label: 'גוש', showByDefault: false, hasAddressOnly: true },
    { name: 'helka', label: 'חלקה', showByDefault: false, hasAddressOnly: true },
    { name: 'subHelka', label: 'תת חלקה', showByDefault: false, hasAddressOnly: true },
    { name: 'status', label: 'סטטוס', showByDefault: true },
    // { name: 'referrer', label: 'גורם מפנה', showByDefault: false },
    { name: 'agent', label: 'נציג אחראי', showByDefault: true },
    { name: 'tasks', label: 'משימות', showByDefault: true },
    { name: 'paid', label: 'שולם', showByDefault: false }
  ]
};
