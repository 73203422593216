import template from './profile.html';
import controller from './profile.controller';
import './profile.scss';

const profileComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$window', '$timeout', '$uibModal', 'utils', 'settingsService', 'userService', 'ownerService', 'permissions'];

export default profileComponent;
