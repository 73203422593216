import template from './excel-import.html';
import controller from './excel-import.controller';
import './excel-import.scss';

const excelImportComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['projectService', 'utils'];

export default excelImportComponent;
