import template from './tag-list-selector.html';
import controller from './tag-list-selector.controller';
import './tag-list-selector.scss';

const tagListSelectorComponent = {
  bindings: {
    label: '@',
    tagList: '<?',
    selectedTags: '<',
    appendToBody: '<?',
    onTagSelected: '&?',
    onTagRemoved: '&?',
    onTagChanged: '&?',
    clearAll: '@',
    selectAll: '@',
    isOpen: '=?'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$timeout', '$sce'];

export default tagListSelectorComponent;
