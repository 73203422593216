import template from './sign-up.html';
import controller from './sign-up.controller';
import './sign-up.scss';

const signUpComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$state', '$location', '$window', '$timeout', 'request', 'utils'];

export default signUpComponent;
