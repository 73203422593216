import template from './form-field-repeatable.html';
import controller from './form-field-repeatable.controller';
import './form-field-repeatable.scss';

const formFieldRepeatableComponent = {
  bindings: {
    field: '<',
    onChange: '<?',
    onInit: '<?',
    onDestroy: '<?',
    modelObject: '='
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$timeout'];

export default formFieldRepeatableComponent;
