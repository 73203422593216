export default function ($scope, $rootScope, $timeout, projectService, utils) {
  let vm;

  class ProjectDocumentsCtrl {
    constructor () {
      vm = this;
    }

    $onInit () {
      vm.projectId = vm.projectShowCtrl.projectFields.id;
      vm.documentCreateBaseLink = window.SERVER_URL + '/project/' + vm.projectId + '/document';
    }
  }

  return new ProjectDocumentsCtrl();
}
