import template from './owner-log.html';
import controller from './owner-log.controller';
import './owner-log.scss';

const ownerLogComponent = {
  require: {
    ownerShowCtrl: '^ownerShow'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$filter', 'request', 'utils', 'ownerService'];

export default ownerLogComponent;
