import template from './user-show.html';
import controller from './user-show.controller';
import './user-show.scss';

const userShowComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$uibModal', 'notify', 'userService'];

export default userShowComponent;
