import MarkerClusterer from '@google/markerclusterer';

export default function ($scope, $rootScope, projectService, userService, NgMap) {
  let vm;

  class ProjectListMapCtrl {
    constructor () {
      vm = this;
      vm.name = 'ProjectListMap';

      vm.outlineMarkerIcon = '/assets/icons/map-marker-outline.png';
    }

    $onInit () {
      vm.agentList = _.get($rootScope, 'owner.users') || [];
      if (vm.agentList.length > 1 && !$rootScope.isManager()) {
        vm.agentFilter = $rootScope.user.id;
      }

      vm.map = {
        libLoaded: false,
        show: true,
        projectList: [],
        polygons: [],
        circles: []
      };
      vm.getMap();
    }

    $onDestroy () {
      vm.mapProjectInfoHide();
    }

    onAgentChanged (selectedAgent) {
      vm.agentFilter = selectedAgent;
      vm.getMap();
    }

    async getMap () {
      const query = {
        limit: 8000,
        filter: {
          status: '!מבוטל',
          lat: '!null',
          long: '!null'
        }
      };

      if (vm.agentFilter) {
        query.filter.agent = vm.agentFilter;
      }

      await projectService.getProjectList(false, query)
        .then((list) => {
          vm.map.projectList = _.get(list, 'rows') || [];
        });

      if (vm.map.map) {
        vm.mapClusterInit();
      }

      vm.map.libLoaded = true;

      if (!vm.map.map) {
        NgMap.getMap()
          .then((evtMap) => {
            vm.map.map = evtMap;
            if (vm.map.projectList) {
              vm.mapClusterInit();
            }
          });
      }
    }

    mapClusterInit () {
      if (vm.map.markerClusterer) {
        vm.map.markers.forEach((m) => m.setMap(null));
        vm.map.markerClusterer.clearMarkers();
      }
      const markerBounds = new window.google.maps.LatLngBounds();

      const projectLocationList = [];
      const markers = vm.map.projectList
        .filter((project) => project.lat && project.long && vm.withinLimits(project))
        .map((project) => {
          while (projectLocationList.find((item) => item.lat === project.lat && item.long === project.long)) {
            project.long += 0.000028;
          }
          projectLocationList.push({ lat: project.lat, long: project.long });
          const position = new window.google.maps.LatLng(project.lat, project.long);
          const marker = new window.google.maps.Marker({
            position,
            id: project.id,
            icon: {
              url: `/assets/icons/${vm.getProjectMarkerIcon(project)}`,
              size: new window.google.maps.Size(32, 32),
              origin: new window.google.maps.Point(0, 0)
            }
          });

          window.google.maps.event.addListener(marker, 'click', function (event) {
            vm.mapProjectInfoShow(project);
            vm.map.map.showInfoWindow('projectPreview', this);
          });

          markerBounds.extend(position);

          return marker;
        });

      vm.map.markers = markers;

      const mcOptions = {
        imagePath: 'https://cdn.rawgit.com/googlemaps/js-marker-clusterer/gh-pages/images/m'
      };
      vm.map.markerClusterer = new MarkerClusterer(vm.map.map, vm.map.markers, mcOptions);

      if (vm.map.projectList.length) {
        vm.map.map.fitBounds(markerBounds);
      }

      vm.map.map.setOptions({
        styles: [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          }
        ]
      });
    }

    mapProjectInfoShow (project) {
      project.address = vm.getProjectAddress(project);
      project.agentName = vm.getProjectAgentName(project);
      project.agentProfileImage = vm.getUserProfileImage($rootScope.userById(project.agent));

      vm.map.project = project;
      vm.map.map.showInfoWindow('projectPreview', this);
    }

    mapProjectInfoHide () {
      vm.map.map.hideInfoWindow('projectPreview', this);
      // vm.map.project = null;
    }

    mapClick () {
      vm.mapProjectInfoHide();
      vm.resultPlace = undefined;
    }

    withinLimits (project) {
      return (parseFloat(project.lat) < 33.291 && parseFloat(project.long) < 35.9) && (parseFloat(project.lat) > 29.488 && parseFloat(project.long) > 34.22);
    }

    getProjectAddress (project) {
      if (!project.id) { return; }
      return `${project.street || ''} ${project.house || ''} ${project.city || ''}`;
    }

    getProjectMarkerIcon (project) {
      if (!project.status) {
        return 'map-marker-red.png';
      }
      return `map-marker-${vm.getProjectStatusColor(project).replace(/^#/, '')}.png`;
    }

    getProjectStatusColor (project) {
      return projectService.getProjectStatusColor(project.status);
    }

    getProjectAgentName (project) {
      const agent = $rootScope.userById(project.agent);
      if (!agent) { return; }
      return `${agent.firstName || ''} ${agent.lastName || ''}`.trim();
    }

    getUserProfileImage (agent) {
      return userService.getUserProfileImage(agent);
    }

    resultPlaceChanged () {
      vm.resultPlace = this.getPlace();
      if (vm.resultPlace.geometry) {
        vm.map.map.panTo(vm.resultPlace.geometry.location);
        vm.map.map.setZoom(17);
      }
    }

    searchPlaceChanged () {
      vm.resultPlace = undefined;
    }
  }

  return new ProjectListMapCtrl();
}
