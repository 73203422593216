import template from './collaboration-settings.html';
import controller from './collaboration-settings.controller';
import './collaboration-settings.scss';

const collaborationSettingsComponent = {
  bindings: {},
  require: {
    settingsCtrl: '^settings'
  },
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', 'projectService', 'settingsService', 'utils'];

export default collaborationSettingsComponent;
